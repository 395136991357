import * as d3 from "d3"
import * as turf from "@turf/turf"
import _ from "underscore"
import mapboxgl from "mapbox-gl"

const maxZoom = 17

let bboxPoly,
  cd = 1,
  cDate = new Date(),
  startDate = new Date("09/12/2018")

let days = Math.floor(Math.abs(cDate - startDate) / 36e5 / 24)

// if the same date, make sure that it's after 7am PST
if (cDate.getUTCDate() === cDate.getDate()) {
  // UTC 14 is 7am PST
  if (cDate.getUTCHours() < 14) {
    days = days - 1
  }
}

let cDay = `day_${days}`
if (days > 7) {
  cDay = `day_7`
}

const colors = [
  ["Point of Interest", "#b847ff"],
  ["Competition Notes", "#00c7ff"],
  ["CN or RL/BC", "#ff0080"],
  ["RL/BC", "#FF8D1C"],
]

let routeCt = 0
let placeIds = {}

export const callDayMap = ({
  map,
  data,
  setPoints,
  setLineCoords,
  setBboxPoly,
  setBounds,
}) => {
  let points = []
  let lineCoords = []

  if (map.getLayer(`route-${String(routeCt)}`)) {
    map.removeLayer(`route-${String(routeCt)}`)
    map.removeSource(`route-${String(routeCt)}`)
  }

  if (map.getLayer(`pts-layer-${String(routeCt)}-clear`)) {
    map.removeLayer(`pts-layer-${String(routeCt)}-clear`)
  }
  if (map.getSource(`pts-${String(routeCt)}-clear`)) {
    map.removeSource(`pts-${String(routeCt)}-clear`)
  }
  d3.selectAll(".marker").remove()

  data.forEach((datapoint, i) => {
    if (!datapoint.node || !datapoint.node.data) {
      return
    }
    const item = datapoint.node.data
    const icon = datapoint.node.icon ? datapoint.node.icon.childImageSharp.fluid.src : ""

    try {
      if (item.TITLE && item.TITLE.length > 0 && item.LONGITUDE && item.LATITUDE) {
        if (item.TITLE.toLowerCase().match("checkpoint")) {
          lineCoords.push([
            parseFloat(item.LONGITUDE),
            parseFloat(item.LATITUDE),
          ])
        }
        placeIds[`marker${i}`] = [
          parseFloat(item.LONGITUDE),
          parseFloat(item.LATITUDE),
        ]

        const tmp = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(item.LONGITUDE),
              parseFloat(item.LATITUDE),
            ],
          },
          properties: {
            category: item.TYPE_OF_POINT_ON_THE_MAP,
            place: item.TITLE,
            description: item.DESCRIPTION,
            num: i,
            id: item.ID,
            icon: icon
          },
        }
        points.push(tmp)

        // window.instgrm.Embeds.process()
      }
    } catch (err) {
      console.log(err)
    }

    setLineCoords(lineCoords)
  })

  d3.selectAll(`.item`).each((d, i, nodes) => {
    if (i > 0) {
      if (d3.select(nodes[i]).classed("Checkpoint")) {
        if (d3.select(nodes[i - 1]).classed("Checkpoint")) {
          d3.select(nodes[i - 1])
            .select(".content")
            .classed("addBorder", true)
        }
      }
    }
  })

  // add markers to map
  setPoints(points)
  points.forEach(function(marker) {
    // create a DOM element for the marker
    let mHeight = 40
    var el = document.createElement("div")

    el.className = "marker"
    el.style.backgroundImage = `url(${marker.properties.icon})`
    if (marker.properties.category.toLowerCase().match("checkpoint") || marker.properties.category.toLowerCase().match("start") || marker.properties.category.toLowerCase().match("finish")) {
      el.style.width = `${String(mHeight * 0.60734463276)}px`
      el.style.height = `${mHeight}px`
    } else {
      el.style.width = "25px"
      el.style.height = "36px"
    }

    // add marker to map
    new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map)
  })

  map.addLayer({
    id: `route-${String(routeCt)}`,
    type: "line",
    source: {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: lineCoords,
        },
      },
    },
    layout: {
      "line-join": "round",
      "line-cap": "square",
    },
    paint: {
      "line-color": "#199fda",
      "line-width": 3,
      "line-dasharray": [3, 3],
    },
  })

  let fcTmp = {
    type: "FeatureCollection",
    features: points,
  }

  map.addSource(`pts-${String(routeCt)}-clear`, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: points,
    },
  })

  map.addLayer({
    id: `pts-layer-${String(routeCt)}-clear`,
    type: "circle",
    source: `pts-${String(routeCt)}-clear`,
    paint: {
      "circle-radius": 40,
      "circle-color": "#fff",
      "circle-opacity": 0,
    },
  })

  const combined = turf.combine(fcTmp)
  const bbox = turf.bbox(combined)
  bboxPoly = turf.bboxPolygon(bbox)
  setBboxPoly(bboxPoly)

  const coordinates = bboxPoly["geometry"]["coordinates"][0]
  const bounds = coordinates.reduce(function(bounds, coord) {
    return bounds.extend(coord)
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]))
  setBounds(bounds)

  let padding = { top: 40, bottom: 100, left: 40, right: 80 }
  if (window.innerWidth < 851) {
    padding = { top: 20, bottom: 20, left: 20, right: 20 }
  }

  map.fitBounds(bounds, {
    padding: padding,
  })
}
