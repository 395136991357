import * as d3 from "d3"
import * as turf from "@turf/turf"
import _ from "underscore"
import mapboxgl from "mapbox-gl"

const parseMiniMap = (miniMap, lineCoords, points) => {
  let routeCt = 0

  if (miniMap.getLayer(`route-${String(routeCt)}`)) {
    miniMap.removeLayer(`route-${String(routeCt)}`);
    miniMap.removeSource(`route-${String(routeCt)}`);
  }
  if (miniMap.getLayer(`pts-layer-${String(routeCt)}`)) {
    miniMap.removeLayer(`pts-layer-${String(routeCt)}`);
  }
  if (miniMap.getLayer(`pts-layer-highlighted-${String(routeCt)}`)) {
    miniMap.removeLayer(`pts-layer-highlighted-${String(routeCt)}`);
  }
  if (miniMap.getSource(`pts-${String(routeCt)}`)) {
    miniMap.removeSource(`pts-${String(routeCt)}`);
  }

  miniMap.addLayer({
    id: `route-${String(routeCt)}`,
    type: "line",
    source: {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: lineCoords
        }
      }
    },
    layout: {
      "line-join": "round",
      "line-cap": "square"
    },
    paint: {
      "line-color": "#000",
      "line-width": 1,
      "line-opacity": 0.3,
      "line-dasharray": [3, 3]
    }
  });

  miniMap.addSource(`pts-${String(routeCt)}`, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: points
    }
  });

  miniMap.addLayer({
    id: `pts-layer-${String(routeCt)}`,
    type: "circle",
    source: `pts-${String(routeCt)}`,
    paint: {
      "circle-radius": 4,
      "circle-color": [
        "match",
        ["string", ["get", "category"]],
        "Green Checkpoint",
        "#2cc61b",
        "Blue Checkpoint",
        "#00c7ff",
        "Black Checkpoint",
        "#000",
        "live",
        "#e60000",
        "#ccc"
      ],
      "circle-opacity": 0.15,
      "circle-stroke-color": "#707070",
      "circle-stroke-width": 0.5,
      "circle-stroke-opacity": 0.5
    }
  });

  miniMap.addLayer({
    id: `pts-layer-highlighted-${String(routeCt)}`,
    type: "circle",
    source: `pts-${String(routeCt)}`,
    paint: {
      "circle-radius": 4,
      "circle-color": [
        "match",
        ["string", ["get", "category"]],
        "Green Checkpoint",
        "#2cc61b",
        "Blue Checkpoint",
        "#00c7ff",
        "Black Checkpoint",
        "#000",
        "live",
        "#e60000",
        "#ccc"
      ],
      "circle-stroke-color": "#474747",
      "circle-stroke-width": 0.5
    },
    filter: ["==", "id", ""]
  });
}

export default parseMiniMap;