import React from "react"
import ListPoint from "./listPoint"
import LazyLoad from "vanilla-lazyload"

const ContentPanel = ({ data, mapLocation, setMapLocation, currentDay, setCurrentDay }) => {
  let scrolling
  const [isScrolling, setIsScrolling] = React.useState()

  let cDate = new Date();
  let startDate = new Date("10/11/2019");
  let diff = cDate - startDate
  // let daysShown = Math.floor((diff > 0 ? diff : 0) / 36e5 / 24);

  let daysShown = 7

  React.useLayoutEffect(() => {
    let myLazyLoad = new LazyLoad({
      container: document.getElementById("scroll-list"),
    })
  })

  const onScroll = e => {
    window.clearTimeout(scrolling)
    setIsScrolling(true)
    scrolling = setTimeout(function() {
      setIsScrolling(false)
    }, 100)
  }

  return (
    <div id="content">
      <div id="days">
        <span>Day</span>
        <ul>
          {[...Array(8)].map((x, i) => {
            let activeClass;
            if (i > daysShown) {
              activeClass = "disabled"
            } else if (i === currentDay) {
              activeClass = "active"
            } 
            return (
              <li key={i} className={activeClass} onClick={() => setCurrentDay(i)}>
                {i}
              </li>
            )
          })}
        </ul>
      </div>
      <div className="list-cont" id="scroll-list" onScroll={onScroll}>
        <div className="list-points" id="inner-scroll-list">
          {data &&
            data.map(point => (
              <ListPoint
                key={point.node.data.ID}
                node={point.node}
                mapLocation={mapLocation}
                setMapLocation={setMapLocation}
                isScrolling={isScrolling}
                setIsScrolling={setIsScrolling}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ContentPanel
