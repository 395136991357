const ca_nv = {
  "type": "FeatureCollection",
  "crs": {
    "type": "name",
    "properties": {
      "name": "urn:ogc:def:crs:EPSG::4269"
    }
  },
  "features": [{
      "type": "Feature",
      "properties": {
        "REGION": "4",
        "DIVISION": "9",
        "STATEFP": "06",
        "STATENS": "01779778",
        "GEOID": "06",
        "STUSPS": "CA",
        "NAME": "California",
        "LSAD": "00",
        "MTFCC": "G4000",
        "FUNCSTAT": "A",
        "ALAND": 403483182192,
        "AWATER": 20484637928,
        "INTPTLAT": "+37.1551773",
        "INTPTLON": "-119.5434183"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-119.636068, 33.280713],
              [-119.634795, 33.285956],
              [-119.63382, 33.289322],
              [-119.629076, 33.299337],
              [-119.625944, 33.305036],
              [-119.621653, 33.309252],
              [-119.618464, 33.311909],
              [-119.614531, 33.314799],
              [-119.609295, 33.318229],
              [-119.604819, 33.320402],
              [-119.599222, 33.323276],
              [-119.59252, 33.325823],
              [-119.588154, 33.326971],
              [-119.582684, 33.327793],
              [-119.578332, 33.328071],
              [-119.574907, 33.328277],
              [-119.569177, 33.328069],
              [-119.564931, 33.327637],
              [-119.559723, 33.329327],
              [-119.554604, 33.331178],
              [-119.549396, 33.33279],
              [-119.543545, 33.334159],
              [-119.53817, 33.33482],
              [-119.534184, 33.335259],
              [-119.529653, 33.335217],
              [-119.525313, 33.334862],
              [-119.519138, 33.3337],
              [-119.508552, 33.330997],
              [-119.500941, 33.329107],
              [-119.49512, 33.327154],
              [-119.490544, 33.325139],
              [-119.471305, 33.316828],
              [-119.443273, 33.305742],
              [-119.429996, 33.301031],
              [-119.42872, 33.300333],
              [-119.428164, 33.300029],
              [-119.426177, 33.298941],
              [-119.42594, 33.298766],
              [-119.422497, 33.296231],
              [-119.41337, 33.289509],
              [-119.405392, 33.283513],
              [-119.399906, 33.280067],
              [-119.390983, 33.274375],
              [-119.385217, 33.270924],
              [-119.37973, 33.267632],
              [-119.375938, 33.264756],
              [-119.375938, 33.263235],
              [-119.369083, 33.255778],
              [-119.367227, 33.2534],
              [-119.364722, 33.25003],
              [-119.362125, 33.245221],
              [-119.360926, 33.24206],
              [-119.360133, 33.234873],
              [-119.360181, 33.228319],
              [-119.360702, 33.221452],
              [-119.362442, 33.214821],
              [-119.365401, 33.208512],
              [-119.368451, 33.202596],
              [-119.371964, 33.197628],
              [-119.373861, 33.195026],
              [-119.375937, 33.192186],
              [-119.378991, 33.190714],
              [-119.381465, 33.189475],
              [-119.386142, 33.186283],
              [-119.394536, 33.180836],
              [-119.433717, 33.166987],
              [-119.444967, 33.16527],
              [-119.468359, 33.164606],
              [-119.472228, 33.164403],
              [-119.475258, 33.16364],
              [-119.476486, 33.163493],
              [-119.478745, 33.16367],
              [-119.47959, 33.163914],
              [-119.489445, 33.166601],
              [-119.491418, 33.167012],
              [-119.500939, 33.167388],
              [-119.508031, 33.167386],
              [-119.51025, 33.167417],
              [-119.512001, 33.167755],
              [-119.516881, 33.168998],
              [-119.522858, 33.170885],
              [-119.526709, 33.172663],
              [-119.529011, 33.173245],
              [-119.532238, 33.173916],
              [-119.541256, 33.176471],
              [-119.546763, 33.178821],
              [-119.550066, 33.180279],
              [-119.55154, 33.180613],
              [-119.553193, 33.181263],
              [-119.554946, 33.181522],
              [-119.562237, 33.18248],
              [-119.569323, 33.184615],
              [-119.574192, 33.186484],
              [-119.578319, 33.188502],
              [-119.580971, 33.190185],
              [-119.587258, 33.195531],
              [-119.589087, 33.196733],
              [-119.59395, 33.198996],
              [-119.600259, 33.20324],
              [-119.605282, 33.206919],
              [-119.611471, 33.212733],
              [-119.614814, 33.217253],
              [-119.618238, 33.222482],
              [-119.619489, 33.225012],
              [-119.61992, 33.226826],
              [-119.62092, 33.22786],
              [-119.621922, 33.228737],
              [-119.623559, 33.230407],
              [-119.625188, 33.23247],
              [-119.625943, 33.234552],
              [-119.627828, 33.236946],
              [-119.631283, 33.244974],
              [-119.631737, 33.247492],
              [-119.631629, 33.248907],
              [-119.631824, 33.250028],
              [-119.631536, 33.253224],
              [-119.631045, 33.255493],
              [-119.632842, 33.259019],
              [-119.634719, 33.265442],
              [-119.636302, 33.27304],
              [-119.636068, 33.280713]
            ]
          ],
          [
            [
              [-118.678204, 33.039726],
              [-118.675605, 33.049349],
              [-118.670782, 33.057966],
              [-118.663846, 33.066846],
              [-118.654363, 33.074952],
              [-118.641301, 33.081882],
              [-118.632642, 33.084539],
              [-118.625917, 33.085664],
              [-118.619621, 33.086719],
              [-118.608342, 33.08632],
              [-118.602262, 33.08605],
              [-118.589533, 33.087111],
              [-118.578337, 33.086243],
              [-118.564923, 33.082219],
              [-118.561411, 33.081061],
              [-118.552627, 33.078633],
              [-118.542103, 33.073788],
              [-118.534186, 33.067858],
              [-118.523727, 33.059972],
              [-118.514966, 33.049889],
              [-118.509273, 33.041567],
              [-118.507663, 33.037883],
              [-118.504769, 33.034623],
              [-118.500911, 33.030586],
              [-118.495582, 33.026336],
              [-118.491132, 33.017923],
              [-118.489497, 33.01063],
              [-118.487137, 33.008176],
              [-118.48546, 33.005992],
              [-118.484746, 33.004619],
              [-118.479952, 33.000033],
              [-118.481413, 33.000033],
              [-118.480189, 32.998596],
              [-118.477788, 32.995775],
              [-118.474868, 32.993088],
              [-118.471187, 32.989123],
              [-118.465825, 32.982439],
              [-118.460182, 32.975254],
              [-118.457301, 32.97092],
              [-118.454541, 32.967965],
              [-118.450303, 32.963698],
              [-118.448863, 32.96175],
              [-118.445907, 32.96027],
              [-118.442152, 32.957949],
              [-118.434403, 32.953207],
              [-118.427692, 32.949576],
              [-118.423338, 32.946483],
              [-118.418744, 32.942113],
              [-118.413951, 32.938617],
              [-118.411275, 32.936465],
              [-118.409597, 32.93455],
              [-118.406043, 32.931792],
              [-118.396935, 32.922548],
              [-118.390787, 32.917841],
              [-118.386436, 32.915083],
              [-118.381567, 32.911853],
              [-118.377616, 32.909596],
              [-118.375907, 32.908687],
              [-118.369922, 32.905067],
              [-118.362519, 32.900117],
              [-118.356393, 32.894495],
              [-118.350307, 32.889681],
              [-118.343584, 32.884428],
              [-118.337457, 32.879748],
              [-118.331966, 32.876782],
              [-118.328898, 32.875037],
              [-118.329719, 32.875037],
              [-118.325711, 32.871732],
              [-118.320336, 32.866264],
              [-118.31739, 32.863765],
              [-118.310343, 32.859505],
              [-118.30489, 32.853465],
              [-118.29725, 32.845094],
              [-118.292398, 32.836394],
              [-118.289894, 32.829383],
              [-118.288784, 32.820829],
              [-118.288946, 32.81615],
              [-118.289905, 32.81053],
              [-118.292813, 32.803804],
              [-118.295841, 32.798256],
              [-118.298469, 32.794257],
              [-118.302848, 32.788846],
              [-118.312797, 32.780825],
              [-118.318528, 32.7768],
              [-118.323819, 32.773514],
              [-118.331974, 32.77057],
              [-118.343192, 32.767062],
              [-118.353494, 32.765807],
              [-118.359102, 32.766156],
              [-118.364273, 32.767076],
              [-118.370997, 32.768202],
              [-118.373861, 32.768847],
              [-118.375907, 32.769394],
              [-118.378377, 32.770232],
              [-118.378975, 32.769593],
              [-118.381205, 32.768076],
              [-118.382959, 32.765852],
              [-118.385349, 32.763559],
              [-118.388933, 32.761131],
              [-118.392557, 32.758501],
              [-118.395901, 32.75715],
              [-118.399525, 32.755226],
              [-118.404143, 32.753301],
              [-118.408322, 32.751914],
              [-118.412621, 32.751102],
              [-118.416203, 32.750591],
              [-118.419799, 32.75004],
              [-118.436538, 32.75004],
              [-118.438846, 32.75039],
              [-118.440915, 32.751026],
              [-118.44748, 32.753137],
              [-118.453808, 32.755111],
              [-118.459021, 32.757695],
              [-118.464712, 32.760176],
              [-118.468374, 32.762594],
              [-118.473906, 32.766824],
              [-118.477926, 32.770621],
              [-118.481669, 32.774721],
              [-118.484377, 32.778619],
              [-118.484974, 32.779158],
              [-118.488796, 32.781371],
              [-118.494211, 32.785502],
              [-118.499667, 32.791044],
              [-118.50091, 32.792026],
              [-118.504743, 32.794553],
              [-118.50765, 32.796877],
              [-118.510238, 32.79782],
              [-118.515813, 32.799571],
              [-118.520433, 32.801963],
              [-118.524814, 32.804724],
              [-118.529832, 32.807889],
              [-118.531784, 32.808765],
              [-118.53509, 32.810581],
              [-118.538197, 32.81277],
              [-118.542502, 32.816574],
              [-118.546726, 32.820681],
              [-118.550515, 32.825329],
              [-118.553186, 32.828797],
              [-118.55546, 32.833174],
              [-118.557137, 32.83735],
              [-118.559454, 32.843479],
              [-118.562528, 32.849438],
              [-118.564083, 32.851391],
              [-118.567353, 32.854556],
              [-118.571382, 32.859302],
              [-118.575055, 32.865733],
              [-118.57721, 32.868022],
              [-118.581318, 32.871522],
              [-118.584431, 32.875036],
              [-118.587937, 32.878843],
              [-118.589501, 32.881439],
              [-118.591141, 32.883834],
              [-118.592082, 32.885061],
              [-118.59693, 32.890466],
              [-118.600859, 32.897862],
              [-118.603519, 32.903564],
              [-118.603847, 32.904787],
              [-118.604335, 32.906605],
              [-118.605289, 32.90854],
              [-118.605522, 32.91243],
              [-118.605701, 32.915616],
              [-118.605471, 32.918204],
              [-118.60668, 32.920974],
              [-118.608174, 32.926121],
              [-118.608802, 32.92769],
              [-118.610789, 32.929308],
              [-118.614688, 32.932811],
              [-118.618597, 32.936852],
              [-118.622117, 32.941434],
              [-118.625916, 32.947226],
              [-118.627566, 32.95031],
              [-118.629573, 32.953628],
              [-118.631501, 32.957149],
              [-118.633873, 32.962214],
              [-118.635522, 32.9656],
              [-118.636491, 32.96909],
              [-118.637099, 32.972445],
              [-118.637706, 32.975364],
              [-118.639194, 32.978115],
              [-118.640486, 32.982611],
              [-118.641858, 32.987039],
              [-118.643433, 32.992843],
              [-118.643879, 32.994889],
              [-118.644327, 32.997774],
              [-118.644732, 33.000033],
              [-118.670173, 33.000034],
              [-118.671016, 33.005851],
              [-118.674843, 33.012143],
              [-118.675941, 33.015421],
              [-118.678551, 33.026356],
              [-118.678204, 33.039726]
            ]
          ],
          [
            [
              [-119.117174, 33.5056],
              [-119.115079, 33.509864],
              [-119.111656, 33.515471],
              [-119.108718, 33.519264],
              [-119.104211, 33.522888],
              [-119.0987, 33.526442],
              [-119.090493, 33.530296],
              [-119.083736, 33.532907],
              [-119.076497, 33.535148],
              [-119.070143, 33.536416],
              [-119.066162, 33.536712],
              [-119.060414, 33.536435],
              [-119.056555, 33.536026],
              [-119.05394, 33.536761],
              [-119.050804, 33.537159],
              [-119.048472, 33.53729],
              [-119.047065, 33.537221],
              [-119.041394, 33.538622],
              [-119.036246, 33.539622],
              [-119.030737, 33.540116],
              [-119.023943, 33.540003],
              [-119.01735, 33.539186],
              [-119.012126, 33.538336],
              [-119.008067, 33.537555],
              [-119.000932, 33.535895],
              [-119.000932, 33.535823],
              [-118.997845, 33.534199],
              [-118.994453, 33.532711],
              [-118.992796, 33.531849],
              [-118.990046, 33.530289],
              [-118.986199, 33.527425],
              [-118.982149, 33.52426],
              [-118.9791, 33.52065],
              [-118.97654, 33.517676],
              [-118.972886, 33.513835],
              [-118.971007, 33.510655],
              [-118.968307, 33.505563],
              [-118.966018, 33.500023],
              [-118.964904, 33.495302],
              [-118.962759, 33.485205],
              [-118.962728, 33.473301],
              [-118.963751, 33.46683],
              [-118.966765, 33.457915],
              [-118.969829, 33.452889],
              [-118.97268, 33.44317],
              [-118.978481, 33.431106],
              [-118.980512, 33.428593],
              [-118.988726, 33.423441],
              [-118.995985, 33.419963],
              [-119.000931, 33.418493],
              [-119.000931, 33.421302],
              [-119.009524, 33.416767],
              [-119.017999, 33.415053],
              [-119.027044, 33.412874],
              [-119.039631, 33.41222],
              [-119.053071, 33.411024],
              [-119.064122, 33.412546],
              [-119.071893, 33.413799],
              [-119.078511, 33.416765],
              [-119.086513, 33.421389],
              [-119.094128, 33.426872],
              [-119.100761, 33.435242],
              [-119.103781, 33.440444],
              [-119.106154, 33.445012],
              [-119.108879, 33.451151],
              [-119.108802, 33.456323],
              [-119.109654, 33.462287],
              [-119.109085, 33.468942],
              [-119.109569, 33.474276],
              [-119.109202, 33.480071],
              [-119.108365, 33.485784],
              [-119.107909, 33.491187],
              [-119.10747, 33.495415],
              [-119.107944, 33.495184],
              [-119.107215, 33.500023],
              [-119.118949, 33.500022],
              [-119.117174, 33.5056]
            ]
          ],
          [
            [
              [-118.668203, 33.485937],
              [-118.666719, 33.49349],
              [-118.66371, 33.500025],
              [-118.662149, 33.501717],
              [-118.659924, 33.50576],
              [-118.65825, 33.507885],
              [-118.655735, 33.510818],
              [-118.652015, 33.514093],
              [-118.647859, 33.51821],
              [-118.644058, 33.521284],
              [-118.638404, 33.524026],
              [-118.632505, 33.526466],
              [-118.625916, 33.528978],
              [-118.618935, 33.530655],
              [-118.612158, 33.53185],
              [-118.605014, 33.532206],
              [-118.599756, 33.532321],
              [-118.593654, 33.532237],
              [-118.589719, 33.531978],
              [-118.58582, 33.53088],
              [-118.579144, 33.52841],
              [-118.575295, 33.52926],
              [-118.571523, 33.529471],
              [-118.567188, 33.529482],
              [-118.563173, 33.52929],
              [-118.55775, 33.528328],
              [-118.555025, 33.529207],
              [-118.550934, 33.530192],
              [-118.546602, 33.530841],
              [-118.538937, 33.531261],
              [-118.533639, 33.531442],
              [-118.529382, 33.531182],
              [-118.523236, 33.530153],
              [-118.517651, 33.528686],
              [-118.512385, 33.526916],
              [-118.506757, 33.524911],
              [-118.502614, 33.522668],
              [-118.500912, 33.52163],
              [-118.496713, 33.518375],
              [-118.493335, 33.515726],
              [-118.487876, 33.516145],
              [-118.482617, 33.516059],
              [-118.477276, 33.515434],
              [-118.468961, 33.512999],
              [-118.462853, 33.510427],
              [-118.456825, 33.506875],
              [-118.452203, 33.503388],
              [-118.44755, 33.500025],
              [-118.436835, 33.489443],
              [-118.43243, 33.48894],
              [-118.427095, 33.487161],
              [-118.42287, 33.483866],
              [-118.420841, 33.481782],
              [-118.411872, 33.480778],
              [-118.40323, 33.479267],
              [-118.395127, 33.476714],
              [-118.389388, 33.474494],
              [-118.386049, 33.473453],
              [-118.377982, 33.47157],
              [-118.375908, 33.471099],
              [-118.369217, 33.469005],
              [-118.359393, 33.464922],
              [-118.352548, 33.45938],
              [-118.350918, 33.457818],
              [-118.342057, 33.453876],
              [-118.332773, 33.448044],
              [-118.324653, 33.441983],
              [-118.316351, 33.434137],
              [-118.310947, 33.425137],
              [-118.308676, 33.416694],
              [-118.308587, 33.416364],
              [-118.307128, 33.409717],
              [-118.306591, 33.407155],
              [-118.304102, 33.403632],
              [-118.294666, 33.397593],
              [-118.28581, 33.389842],
              [-118.279629, 33.383187],
              [-118.27721, 33.380743],
              [-118.271323, 33.376686],
              [-118.268177, 33.375024],
              [-118.262748, 33.37044],
              [-118.257714, 33.364131],
              [-118.253811, 33.357222],
              [-118.251379, 33.353397],
              [-118.250903, 33.35165],
              [-118.245102, 33.338341],
              [-118.241286, 33.323023],
              [-118.241051, 33.313029],
              [-118.242701, 33.303113],
              [-118.24594, 33.291061],
              [-118.249075, 33.284142],
              [-118.250903, 33.280465],
              [-118.250897, 33.281444],
              [-118.259178, 33.272505],
              [-118.269178, 33.26568],
              [-118.280703, 33.259092],
              [-118.292458, 33.254078],
              [-118.302837, 33.251005],
              [-118.309671, 33.25003],
              [-118.308722, 33.25003],
              [-118.313773, 33.249138],
              [-118.318622, 33.248472],
              [-118.327993, 33.248083],
              [-118.334753, 33.248612],
              [-118.341467, 33.25003],
              [-118.343772, 33.25003],
              [-118.34995, 33.251682],
              [-118.363311, 33.255074],
              [-118.375908, 33.260074],
              [-118.381487, 33.262298],
              [-118.385589, 33.265358],
              [-118.389131, 33.265197],
              [-118.394137, 33.265909],
              [-118.402557, 33.267937],
              [-118.412049, 33.266275],
              [-118.427519, 33.265963],
              [-118.437654, 33.267018],
              [-118.44046, 33.267457],
              [-118.455072, 33.269019],
              [-118.471459, 33.272891],
              [-118.486688, 33.276905],
              [-118.500914, 33.28407],
              [-118.50368, 33.285314],
              [-118.510386, 33.289659],
              [-118.518037, 33.29665],
              [-118.534885, 33.315176],
              [-118.540849, 33.325088],
              [-118.542519, 33.331046],
              [-118.547476, 33.34048],
              [-118.549606, 33.350647],
              [-118.550966, 33.36161],
              [-118.551193, 33.369352],
              [-118.549609, 33.375028],
              [-118.548577, 33.375028],
              [-118.547929, 33.376799],
              [-118.553376, 33.380226],
              [-118.557333, 33.380696],
              [-118.563631, 33.38103],
              [-118.573197, 33.383479],
              [-118.59108, 33.390872],
              [-118.607228, 33.398294],
              [-118.618574, 33.406191],
              [-118.625917, 33.414265],
              [-118.625917, 33.415069],
              [-118.630734, 33.423203],
              [-118.636943, 33.428679],
              [-118.642913, 33.434323],
              [-118.655057, 33.44531],
              [-118.66175, 33.456317],
              [-118.665919, 33.467334],
              [-118.667602, 33.477489],
              [-118.668203, 33.485937]
            ]
          ],
          [
            [
              [-119.468064, 34.063139],
              [-119.467842, 34.062931],
              [-119.460036, 34.064738],
              [-119.451261, 34.066435],
              [-119.437318, 34.067716],
              [-119.42373, 34.067692],
              [-119.409352, 34.066789],
              [-119.397006, 34.063792],
              [-119.389754, 34.066035],
              [-119.375947, 34.068666],
              [-119.367877, 34.070416],
              [-119.356388, 34.070808],
              [-119.342462, 34.068964],
              [-119.330926, 34.065064],
              [-119.321146, 34.060611],
              [-119.312087, 34.054388],
              [-119.305153, 34.047449],
              [-119.297642, 34.036583],
              [-119.294398, 34.027503],
              [-119.293112, 34.020152],
              [-119.294047, 34.011081],
              [-119.296472, 34.000005],
              [-119.296238, 33.99724],
              [-119.296456, 33.996911],
              [-119.298768, 33.993447],
              [-119.304086, 33.986539],
              [-119.311615, 33.980023],
              [-119.318443, 33.974899],
              [-119.332794, 33.967721],
              [-119.34154, 33.96543],
              [-119.351856, 33.964454],
              [-119.356659, 33.96149],
              [-119.362829, 33.958762],
              [-119.369757, 33.956357],
              [-119.375947, 33.954903],
              [-119.377165, 33.954391],
              [-119.380456, 33.953611],
              [-119.384666, 33.953318],
              [-119.389397, 33.95327],
              [-119.39337, 33.954686],
              [-119.396176, 33.953864],
              [-119.402928, 33.953785],
              [-119.411344, 33.955415],
              [-119.416997, 33.953771],
              [-119.422891, 33.954112],
              [-119.432961, 33.955905],
              [-119.440822, 33.958934],
              [-119.454479, 33.963308],
              [-119.465191, 33.967426],
              [-119.472647, 33.970647],
              [-119.485323, 33.977721],
              [-119.49229, 33.983215],
              [-119.494536, 33.980065],
              [-119.496954, 33.976345],
              [-119.500952, 33.971484],
              [-119.505761, 33.967331],
              [-119.512244, 33.962199],
              [-119.523312, 33.954925],
              [-119.532825, 33.949874],
              [-119.538949, 33.947656],
              [-119.550467, 33.944953],
              [-119.563291, 33.944955],
              [-119.572447, 33.941138],
              [-119.581586, 33.93967],
              [-119.59069, 33.939578],
              [-119.595945, 33.938082],
              [-119.602085, 33.936768],
              [-119.610368, 33.937199],
              [-119.613704, 33.936444],
              [-119.618531, 33.935881],
              [-119.625957, 33.936036],
              [-119.630173, 33.93699],
              [-119.63673, 33.938277],
              [-119.640542, 33.936258],
              [-119.647423, 33.932812],
              [-119.653556, 33.930092],
              [-119.661161, 33.928338],
              [-119.663959, 33.926402],
              [-119.669135, 33.923128],
              [-119.675749, 33.920583],
              [-119.680587, 33.919487],
              [-119.682392, 33.918442],
              [-119.687596, 33.915639],
              [-119.693182, 33.913681],
              [-119.707491, 33.910388],
              [-119.718826, 33.909632],
              [-119.731419, 33.910274],
              [-119.741344, 33.912619],
              [-119.745496, 33.911409],
              [-119.750961, 33.911399],
              [-119.758558, 33.910798],
              [-119.767653, 33.911433],
              [-119.772829, 33.912414],
              [-119.778315, 33.910612],
              [-119.783652, 33.909983],
              [-119.789372, 33.910131],
              [-119.797002, 33.906343],
              [-119.810224, 33.901324],
              [-119.823208, 33.89939],
              [-119.83442, 33.900788],
              [-119.847437, 33.904225],
              [-119.855219, 33.907487],
              [-119.861952, 33.910836],
              [-119.869596, 33.916582],
              [-119.87596, 33.921384],
              [-119.878229, 33.922654],
              [-119.884053, 33.925984],
              [-119.890694, 33.930803],
              [-119.896244, 33.935742],
              [-119.904762, 33.939674],
              [-119.904811, 33.939504],
              [-119.905675, 33.936495],
              [-119.90673, 33.932377],
              [-119.911023, 33.923898],
              [-119.91893, 33.914354],
              [-119.928799, 33.905471],
              [-119.93919, 33.898643],
              [-119.950294, 33.894376],
              [-119.962681, 33.89244],
              [-119.972387, 33.891378],
              [-119.980842, 33.892317],
              [-120.00096, 33.885248],
              [-120.008621, 33.878836],
              [-120.015411, 33.875148],
              [-120.015473, 33.875076],
              [-120.015535, 33.875004],
              [-120.019339, 33.870555],
              [-120.02781, 33.866512],
              [-120.034538, 33.864476],
              [-120.042532, 33.864319],
              [-120.047909, 33.863592],
              [-120.058444, 33.859644],
              [-120.07065, 33.85649],
              [-120.080106, 33.850506],
              [-120.090455, 33.846319],
              [-120.104088, 33.842396],
              [-120.117266, 33.84158],
              [-120.125964, 33.842348],
              [-120.131204, 33.843198],
              [-120.139927, 33.844799],
              [-120.149551, 33.847962],
              [-120.157079, 33.853425],
              [-120.165511, 33.855641],
              [-120.17032, 33.857416],
              [-120.176946, 33.861545],
              [-120.183, 33.866287],
              [-120.188216, 33.86636],
              [-120.193391, 33.868528],
              [-120.199514, 33.875002],
              [-120.202377, 33.87598],
              [-120.207629, 33.878908],
              [-120.214953, 33.880784],
              [-120.221128, 33.884187],
              [-120.229634, 33.892329],
              [-120.236375, 33.900138],
              [-120.23872, 33.904106],
              [-120.247524, 33.906694],
              [-120.250969, 33.908436],
              [-120.25639, 33.911673],
              [-120.264495, 33.918916],
              [-120.273096, 33.929577],
              [-120.277705, 33.943362],
              [-120.279384, 33.948658],
              [-120.280033, 33.953939],
              [-120.285589, 33.957502],
              [-120.291872, 33.962237],
              [-120.298926, 33.968415],
              [-120.298973, 33.968457],
              [-120.308198, 33.966406],
              [-120.317386, 33.966371],
              [-120.327035, 33.966729],
              [-120.332253, 33.968425],
              [-120.346874, 33.963494],
              [-120.35863, 33.961395],
              [-120.367073, 33.961036],
              [-120.37598, 33.962917],
              [-120.384795, 33.964619],
              [-120.395636, 33.967427],
              [-120.405687, 33.973107],
              [-120.411766, 33.977045],
              [-120.418765, 33.975773],
              [-120.422594, 33.974945],
              [-120.432181, 33.974703],
              [-120.439985, 33.975538],
              [-120.446192, 33.977373],
              [-120.455794, 33.976273],
              [-120.466777, 33.976116],
              [-120.474372, 33.978427],
              [-120.480766, 33.980262],
              [-120.488151, 33.984283],
              [-120.497726, 33.991199],
              [-120.500992, 33.995502],
              [-120.500992, 33.996303],
              [-120.503084, 33.997347],
              [-120.506202, 33.999996],
              [-120.511407, 34.00569],
              [-120.518784, 34.01408],
              [-120.523139, 34.022819],
              [-120.525852, 34.032994],
              [-120.526452, 34.041455],
              [-120.523387, 34.051133],
              [-120.531063, 34.051687],
              [-120.535556, 34.052955],
              [-120.550051, 34.057286],
              [-120.559385, 34.062376],
              [-120.566995, 34.068638],
              [-120.573808, 34.075966],
              [-120.578288, 34.084205],
              [-120.581548, 34.091488],
              [-120.581897, 34.098557],
              [-120.582264, 34.107517],
              [-120.579054, 34.118591],
              [-120.575737, 34.124993],
              [-120.575089, 34.126474],
              [-120.572509, 34.13025],
              [-120.567847, 34.135618],
              [-120.560998, 34.140664],
              [-120.548701, 34.146416],
              [-120.538118, 34.150344],
              [-120.526861, 34.152026],
              [-120.516039, 34.152798],
              [-120.50717, 34.151343],
              [-120.500994, 34.150523],
              [-120.497378, 34.149892],
              [-120.491938, 34.147118],
              [-120.486911, 34.145076],
              [-120.481429, 34.142168],
              [-120.477309, 34.138181],
              [-120.471654, 34.134102],
              [-120.465295, 34.139292],
              [-120.457012, 34.145763],
              [-120.449074, 34.150593],
              [-120.441002, 34.154487],
              [-120.43272, 34.157478],
              [-120.426603, 34.159253],
              [-120.418787, 34.160569],
              [-120.411031, 34.160078],
              [-120.401169, 34.158729],
              [-120.391945, 34.156707],
              [-120.388619, 34.155054],
              [-120.387245, 34.154861],
              [-120.375984, 34.152424],
              [-120.368746, 34.151041],
              [-120.361978, 34.14878],
              [-120.355161, 34.145021],
              [-120.35049, 34.14145],
              [-120.346501, 34.136676],
              [-120.342712, 34.131502],
              [-120.33982, 34.124993],
              [-120.339177, 34.123284],
              [-120.338562, 34.121687],
              [-120.333175, 34.119218],
              [-120.327864, 34.116048],
              [-120.324337, 34.1138],
              [-120.321087, 34.11045],
              [-120.311711, 34.109506],
              [-120.304911, 34.107044],
              [-120.297787, 34.104116],
              [-120.29114, 34.099651],
              [-120.284651, 34.09475],
              [-120.278678, 34.087813],
              [-120.274285, 34.081799],
              [-120.272449, 34.078106],
              [-120.271579, 34.074141],
              [-120.266386, 34.069132],
              [-120.262408, 34.065084],
              [-120.257873, 34.062207],
              [-120.255802, 34.059849],
              [-120.25295, 34.058095],
              [-120.252846, 34.058031],
              [-120.250971, 34.058912],
              [-120.242674, 34.059826],
              [-120.23728, 34.060265],
              [-120.230998, 34.06114],
              [-120.224878, 34.061312],
              [-120.216543, 34.061217],
              [-120.210383, 34.060853],
              [-120.206113, 34.060219],
              [-120.199831, 34.058851],
              [-120.197576, 34.05815],
              [-120.194114, 34.058721],
              [-120.191096, 34.061365],
              [-120.185461, 34.06622],
              [-120.179181, 34.069468],
              [-120.171128, 34.073318],
              [-120.159007, 34.075697],
              [-120.150107, 34.076066],
              [-120.145235, 34.077739],
              [-120.139919, 34.078576],
              [-120.135006, 34.07881],
              [-120.12989, 34.078811],
              [-120.125967, 34.078815],
              [-120.120132, 34.078942],
              [-120.114961, 34.078052],
              [-120.112251, 34.076481],
              [-120.108899, 34.076122],
              [-120.106191, 34.075526],
              [-120.102918, 34.074494],
              [-120.098642, 34.076626],
              [-120.092471, 34.080513],
              [-120.085854, 34.084198],
              [-120.080285, 34.086401],
              [-120.071, 34.088447],
              [-120.063327, 34.089714],
              [-120.052867, 34.090349],
              [-120.045154, 34.090606],
              [-120.036186, 34.090026],
              [-120.026492, 34.089011],
              [-120.019503, 34.087718],
              [-120.01021, 34.084043],
              [-120.004472, 34.080427],
              [-120.000963, 34.078671],
              [-120.000963, 34.076819],
              [-119.991643, 34.064868],
              [-119.989951, 34.070374],
              [-119.985442, 34.080239],
              [-119.981947, 34.089377],
              [-119.974386, 34.096743],
              [-119.974166, 34.099417],
              [-119.972361, 34.10335],
              [-119.969178, 34.108074],
              [-119.965471, 34.11256],
              [-119.958651, 34.117556],
              [-119.947835, 34.124999],
              [-119.942676, 34.126565],
              [-119.936155, 34.128658],
              [-119.928795, 34.129902],
              [-119.919989, 34.129989],
              [-119.913364, 34.130148],
              [-119.905575, 34.129355],
              [-119.898528, 34.127069],
              [-119.894178, 34.125772],
              [-119.888316, 34.126371],
              [-119.879878, 34.126083],
              [-119.875985, 34.125567],
              [-119.875985, 34.126234],
              [-119.868828, 34.125002],
              [-119.859066, 34.122899],
              [-119.852087, 34.120957],
              [-119.84537, 34.120581],
              [-119.837957, 34.120195],
              [-119.82922, 34.118702],
              [-119.811568, 34.11341],
              [-119.798126, 34.108763],
              [-119.792601, 34.106375],
              [-119.789184, 34.1076],
              [-119.783006, 34.109083],
              [-119.778012, 34.109969],
              [-119.769032, 34.111943],
              [-119.760839, 34.112617],
              [-119.750982, 34.111716],
              [-119.750981, 34.112582],
              [-119.745222, 34.110917],
              [-119.736754, 34.107339],
              [-119.728495, 34.103325],
              [-119.725973, 34.10209],
              [-119.718042, 34.10175],
              [-119.709463, 34.100663],
              [-119.701107, 34.098263],
              [-119.693007, 34.094854],
              [-119.686067, 34.090309],
              [-119.678954, 34.086504],
              [-119.670148, 34.080897],
              [-119.659354, 34.073989],
              [-119.654931, 34.071218],
              [-119.6512, 34.07526],
              [-119.645169, 34.080055],
              [-119.639354, 34.083874],
              [-119.635229, 34.086732],
              [-119.629466, 34.08971],
              [-119.628112, 34.090067],
              [-119.626701, 34.091671],
              [-119.625969, 34.092697],
              [-119.614268, 34.099432],
              [-119.599892, 34.104007],
              [-119.590556, 34.105638],
              [-119.583461, 34.105879],
              [-119.581973, 34.105732],
              [-119.574116, 34.108113],
              [-119.565522, 34.108809],
              [-119.557426, 34.108603],
              [-119.548251, 34.107783],
              [-119.538419, 34.105413],
              [-119.528938, 34.101199],
              [-119.524588, 34.098709],
              [-119.51998, 34.097224],
              [-119.512106, 34.09346],
              [-119.500955, 34.085841],
              [-119.49749, 34.082755],
              [-119.488752, 34.079195],
              [-119.477308, 34.071826],
              [-119.468064, 34.063139]
            ]
          ],
          [
            [
              [-123.173825, 37.775727],
              [-123.173715, 37.776113],
              [-123.169107, 37.792365],
              [-123.166012, 37.796885],
              [-123.160936, 37.802002],
              [-123.153863, 37.807065],
              [-123.153335, 37.807444],
              [-123.147209, 37.811951],
              [-123.139441, 37.816719],
              [-123.132272, 37.819676],
              [-123.126114, 37.821664],
              [-123.118289, 37.823058],
              [-123.108256, 37.822542],
              [-123.095261, 37.821877],
              [-123.081646, 37.818151],
              [-123.070494, 37.81383],
              [-123.048817, 37.799816],
              [-123.038726, 37.788881],
              [-123.035097, 37.780431],
              [-123.033841, 37.778375],
              [-123.031436, 37.777153],
              [-123.02585, 37.77709],
              [-123.01697, 37.776069],
              [-123.010588, 37.774926],
              [-123.006154, 37.773524],
              [-123.00111, 37.772053],
              [-122.997538, 37.770784],
              [-122.99509, 37.769131],
              [-122.987414, 37.76387],
              [-122.981429, 37.758916],
              [-122.977596, 37.754976],
              [-122.975448, 37.752585],
              [-122.974061, 37.749929],
              [-122.973256, 37.748652],
              [-122.970449, 37.747818],
              [-122.967271, 37.746303],
              [-122.964099, 37.744281],
              [-122.961057, 37.741955],
              [-122.958391, 37.739734],
              [-122.95464, 37.736827],
              [-122.949985, 37.732187],
              [-122.945523, 37.725314],
              [-122.942294, 37.721057],
              [-122.939711, 37.71535],
              [-122.937917, 37.709956],
              [-122.937679, 37.709241],
              [-122.935865, 37.702459],
              [-122.93571, 37.697618],
              [-122.937339, 37.69093],
              [-122.939026, 37.686544],
              [-122.939661, 37.682353],
              [-122.941364, 37.67658],
              [-122.945009, 37.669773],
              [-122.949068, 37.663377],
              [-122.954876, 37.658348],
              [-122.963006, 37.651984],
              [-122.973012, 37.646581],
              [-122.980513, 37.6439],
              [-122.986747, 37.641783],
              [-122.992589, 37.641016],
              [-123.000912, 37.63993],
              [-123.00111, 37.63983],
              [-123.00111, 37.641775],
              [-123.011605, 37.641506],
              [-123.027153, 37.645221],
              [-123.040696, 37.650328],
              [-123.05358, 37.658648],
              [-123.063578, 37.667316],
              [-123.068589, 37.673041],
              [-123.073063, 37.682583],
              [-123.074921, 37.690066],
              [-123.077155, 37.692324],
              [-123.08188, 37.69684],
              [-123.086057, 37.70075],
              [-123.088784, 37.705792],
              [-123.0918, 37.711872],
              [-123.09285, 37.713688],
              [-123.094801, 37.714067],
              [-123.099647, 37.713591],
              [-123.104921, 37.712648],
              [-123.115575, 37.714045],
              [-123.119645, 37.715441],
              [-123.126114, 37.71673],
              [-123.132597, 37.719393],
              [-123.141495, 37.72616],
              [-123.149127, 37.732279],
              [-123.156757, 37.73784],
              [-123.162751, 37.743533],
              [-123.166699, 37.74993],
              [-123.169985, 37.75593],
              [-123.172926, 37.7636],
              [-123.173763, 37.771831],
              [-123.173825, 37.775727]
            ]
          ],
          [
            [
              [-124.136561, 41.464452],
              [-124.137892, 41.468298],
              [-124.13906, 41.472933],
              [-124.140544, 41.477235],
              [-124.142508, 41.48278],
              [-124.144128, 41.486881],
              [-124.147339, 41.493036],
              [-124.151305, 41.499842],
              [-124.151732, 41.503887],
              [-124.151821, 41.508214],
              [-124.153568, 41.510753],
              [-124.157943, 41.518962],
              [-124.160111, 41.529386],
              [-124.160516, 41.53801],
              [-124.160493, 41.543409],
              [-124.164464, 41.54524],
              [-124.172274, 41.550979],
              [-124.179961, 41.558325],
              [-124.18499, 41.565769],
              [-124.188336, 41.573996],
              [-124.188474, 41.575742],
              [-124.18873, 41.578964],
              [-124.188273, 41.583653],
              [-124.187127, 41.58897],
              [-124.189188, 41.59178],
              [-124.190533, 41.59874],
              [-124.190457, 41.604607],
              [-124.190113, 41.60819],
              [-124.195829, 41.613162],
              [-124.20134, 41.619404],
              [-124.204535, 41.624839],
              [-124.207386, 41.629295],
              [-124.209785, 41.635205],
              [-124.211419, 41.641688],
              [-124.210489, 41.649463],
              [-124.211823, 41.652701],
              [-124.211784, 41.661907],
              [-124.210002, 41.669319],
              [-124.208546, 41.673816],
              [-124.211706, 41.681193],
              [-124.21516, 41.682273],
              [-124.222605, 41.685267],
              [-124.229865, 41.689702],
              [-124.235915, 41.694145],
              [-124.236042, 41.694157],
              [-124.236856, 41.69464],
              [-124.241932, 41.695005],
              [-124.245433, 41.69595],
              [-124.249473, 41.696958],
              [-124.251191, 41.697535],
              [-124.262421, 41.701857],
              [-124.278062, 41.709674],
              [-124.284218, 41.713414],
              [-124.291914, 41.71809],
              [-124.300301, 41.724145],
              [-124.313704, 41.727491],
              [-124.316113, 41.72839],
              [-124.330609, 41.733793],
              [-124.345376, 41.744586],
              [-124.352289, 41.749781],
              [-124.352368, 41.749841],
              [-124.355988, 41.752235],
              [-124.361038, 41.755954],
              [-124.365508, 41.759671],
              [-124.369188, 41.763412],
              [-124.369977, 41.763456],
              [-124.372889, 41.763968],
              [-124.374726, 41.764376],
              [-124.3762, 41.764628],
              [-124.376199, 41.765569],
              [-124.384371, 41.768234],
              [-124.392055, 41.772377],
              [-124.39951, 41.777862],
              [-124.405715, 41.783141],
              [-124.410757, 41.788312],
              [-124.414235, 41.793004],
              [-124.416193, 41.796916],
              [-124.417526, 41.800017],
              [-124.422396, 41.803707],
              [-124.429185, 41.809726],
              [-124.435033, 41.816581],
              [-124.438909, 41.824504],
              [-124.439497, 41.827122],
              [-124.440414, 41.831204],
              [-124.440707, 41.83874],
              [-124.437723, 41.849321],
              [-124.435015, 41.856507],
              [-124.43092, 41.862912],
              [-124.426108, 41.868776],
              [-124.422332, 41.872291],
              [-124.418648, 41.874837],
              [-124.411317, 41.882056],
              [-124.401242, 41.885588],
              [-124.388791, 41.886764],
              [-124.376205, 41.886999],
              [-124.376205, 41.886954],
              [-124.373492, 41.886883],
              [-124.372058, 41.886746],
              [-124.365199, 41.886568],
              [-124.356556, 41.885145],
              [-124.347647, 41.883154],
              [-124.338613, 41.879789],
              [-124.332984, 41.877],
              [-124.330089, 41.874839],
              [-124.330035, 41.874794],
              [-124.329981, 41.87475],
              [-124.311936, 41.858788],
              [-124.304474, 41.860149],
              [-124.298004, 41.86067],
              [-124.293649, 41.860422],
              [-124.289249, 41.86014],
              [-124.285031, 41.859487],
              [-124.281042, 41.870863],
              [-124.279649, 41.874836],
              [-124.280279, 41.874836],
              [-124.280732, 41.874836],
              [-124.279659, 41.880296],
              [-124.277827, 41.891105],
              [-124.276376, 41.898901],
              [-124.27612, 41.903052],
              [-124.280664, 41.907012],
              [-124.285687, 41.912916],
              [-124.289939, 41.920057],
              [-124.291939, 41.928498],
              [-124.29168, 41.936346],
              [-124.291648, 41.937335],
              [-124.291636, 41.937391],
              [-124.289926, 41.945432],
              [-124.287814, 41.951788],
              [-124.284769, 41.956834],
              [-124.287274, 41.963971],
              [-124.288578, 41.969564],
              [-124.294595, 41.97547],
              [-124.301184, 41.982984],
              [-124.304735, 41.988716],
              [-124.306995, 41.992906],
              [-124.317941, 41.994637],
              [-124.327272, 41.997734],
              [-124.328292, 41.998073],
              [-124.328835, 41.998334],
              [-124.251201, 41.998343],
              [-124.2512, 41.998362],
              [-124.23323, 41.998412],
              [-124.21776, 41.998445],
              [-124.217027, 41.998447],
              [-124.215258, 41.998451],
              [-124.211993, 41.998459],
              [-124.211605, 41.99846],
              [-124.209598, 41.998425],
              [-124.208297, 41.998401],
              [-124.208283, 41.998401],
              [-124.198548, 41.998234],
              [-124.198506, 41.998233],
              [-124.197062, 41.998209],
              [-124.196961, 41.998207],
              [-124.196941, 41.998206],
              [-124.196928, 41.998206],
              [-124.19689, 41.998206],
              [-124.196847, 41.998205],
              [-124.196793, 41.998204],
              [-124.19667, 41.998202],
              [-124.196546, 41.9982],
              [-124.19312, 41.998141],
              [-124.193025, 41.99814],
              [-124.19297, 41.998139],
              [-124.192934, 41.998139],
              [-124.192843, 41.998138],
              [-124.192704, 41.998136],
              [-124.18873, 41.998064],
              [-124.186977, 41.998034],
              [-124.1816, 41.997941],
              [-124.178792, 41.997893],
              [-124.178066, 41.997881],
              [-124.168002, 41.997708],
              [-124.162263, 41.99761],
              [-124.146612, 41.997342],
              [-124.146403, 41.997338],
              [-124.142943, 41.997293],
              [-124.141582, 41.997256],
              [-124.121365, 41.997015],
              [-124.12133, 41.997015],
              [-124.121115, 41.997016],
              [-124.118786, 41.997027],
              [-124.116525, 41.997038],
              [-124.104557, 41.997094],
              [-124.104023, 41.997074],
              [-124.102783, 41.997026],
              [-124.102654, 41.996893],
              [-124.102532, 41.99681],
              [-124.102389, 41.996748],
              [-124.102149, 41.996692],
              [-124.102001, 41.996686],
              [-124.101857, 41.996714],
              [-124.101804, 41.996745],
              [-124.101714, 41.996788],
              [-124.101611, 41.996808],
              [-124.101309, 41.996785],
              [-124.100995, 41.99677],
              [-124.100705, 41.996792],
              [-124.100593, 41.996785],
              [-124.10047, 41.996761],
              [-124.100376, 41.996712],
              [-124.087967, 41.996808],
              [-124.087843, 41.996791],
              [-124.087758, 41.996759],
              [-124.087641, 41.996686],
              [-124.087488, 41.99665],
              [-124.087378, 41.996641],
              [-124.087246, 41.996652],
              [-124.084956, 41.996662],
              [-124.084937, 41.996662],
              [-124.079435, 41.996599],
              [-124.079403, 41.996598],
              [-124.077276, 41.996573],
              [-124.067556, 41.99646],
              [-124.057854, 41.996347],
              [-124.057713, 41.996345],
              [-124.046589, 41.996379],
              [-124.045824, 41.996379],
              [-124.033089, 41.996275],
              [-124.015681, 41.996204],
              [-124.001183, 41.996145],
              [-123.999902, 41.99615],
              [-123.999248, 41.996138],
              [-123.993252, 41.996028],
              [-123.990753, 41.996027],
              [-123.988383, 41.996027],
              [-123.983508, 41.996024],
              [-123.978633, 41.996021],
              [-123.973758, 41.996018],
              [-123.96889, 41.996016],
              [-123.968338, 41.996016],
              [-123.967826, 41.996016],
              [-123.967089, 41.996015],
              [-123.967046, 41.996015],
              [-123.966934, 41.996015],
              [-123.964022, 41.996014],
              [-123.959154, 41.996012],
              [-123.956367, 41.996011],
              [-123.954292, 41.99601],
              [-123.949421, 41.995997],
              [-123.948999, 41.995996],
              [-123.94455, 41.995984],
              [-123.942228, 41.995975],
              [-123.941723, 41.995978],
              [-123.939679, 41.99597],
              [-123.936313, 41.995963],
              [-123.934808, 41.995957],
              [-123.930121, 41.995947],
              [-123.928612, 41.995941],
              [-123.925261, 41.995942],
              [-123.920399, 41.995942],
              [-123.915537, 41.995942],
              [-123.913723, 41.995951],
              [-123.912055, 41.995948],
              [-123.908176, 41.995956],
              [-123.896111, 41.995975],
              [-123.894986, 41.995974],
              [-123.893236, 41.995978],
              [-123.891259, 41.995984],
              [-123.886409, 41.995997],
              [-123.88599, 41.995997],
              [-123.885316, 41.995999],
              [-123.883408, 41.996008],
              [-123.881556, 41.996015],
              [-123.879816, 41.996021],
              [-123.876703, 41.996035],
              [-123.874964, 41.996042],
              [-123.871907, 41.996058],
              [-123.871223, 41.99606],
              [-123.870573, 41.996063],
              [-123.870111, 41.996065],
              [-123.867012, 41.99608],
              [-123.866099, 41.996081],
              [-123.857462, 41.996089],
              [-123.857239, 41.996088],
              [-123.856119, 41.996087],
              [-123.855506, 41.996085],
              [-123.852388, 41.99608],
              [-123.850636, 41.996077],
              [-123.847537, 41.996072],
              [-123.845766, 41.996069],
              [-123.842686, 41.996064],
              [-123.841261, 41.99606],
              [-123.837835, 41.996055],
              [-123.836025, 41.996052],
              [-123.834485, 41.99605],
              [-123.83297, 41.995993],
              [-123.831154, 41.995925],
              [-123.828087, 41.995809],
              [-123.828046, 41.995808],
              [-123.826426, 41.995747],
              [-123.822041, 41.99558],
              [-123.821455, 41.99556],
              [-123.820335, 41.995519],
              [-123.818675, 41.995457],
              [-123.818553, 41.995452],
              [-123.817775, 41.995423],
              [-123.815613, 41.995341],
              [-123.815296, 41.995329],
              [-123.81479, 41.995307],
              [-123.813557, 41.995263],
              [-123.812956, 41.995241],
              [-123.811708, 41.995194],
              [-123.810993, 41.995168],
              [-123.810908, 41.995164],
              [-123.806116, 41.995305],
              [-123.80486, 41.995342],
              [-123.804706, 41.995347],
              [-123.80425, 41.99536],
              [-123.804197, 41.995362],
              [-123.800967, 41.995457],
              [-123.795982, 41.995604],
              [-123.793811, 41.995668],
              [-123.793648, 41.995673],
              [-123.792261, 41.99571],
              [-123.791497, 41.995732],
              [-123.791265, 41.995738],
              [-123.791161, 41.995741],
              [-123.790207, 41.995768],
              [-123.789914, 41.995776],
              [-123.789782, 41.99578],
              [-123.789563, 41.995785],
              [-123.78919, 41.995796],
              [-123.788489, 41.995816],
              [-123.787501, 41.995834],
              [-123.774955, 41.996015],
              [-123.77474, 41.996019],
              [-123.772039, 41.996097],
              [-123.767179, 41.996235],
              [-123.765987, 41.996269],
              [-123.765879, 41.996273],
              [-123.762321, 41.996374],
              [-123.758079, 41.996496],
              [-123.757463, 41.996514],
              [-123.754222, 41.996606],
              [-123.75394, 41.996614],
              [-123.751089, 41.996693],
              [-123.735167, 41.996944],
              [-123.733637, 41.996968],
              [-123.733504, 41.996971],
              [-123.73261, 41.996985],
              [-123.728169, 41.997056],
              [-123.721428, 41.996878],
              [-123.71864, 41.996805],
              [-123.718374, 41.996799],
              [-123.718136, 41.996791],
              [-123.714688, 41.9967],
              [-123.710046, 41.996577],
              [-123.70989, 41.996573],
              [-123.705093, 41.996446],
              [-123.700536, 41.996325],
              [-123.700296, 41.996319],
              [-123.695305, 41.996187],
              [-123.690315, 41.996054],
              [-123.685325, 41.995922],
              [-123.683766, 41.995839],
              [-123.681934, 41.995742],
              [-123.680406, 41.995706],
              [-123.677568, 41.995658],
              [-123.675549, 41.995571],
              [-123.672896, 41.995508],
              [-123.670693, 41.995445],
              [-123.668166, 41.995382],
              [-123.667174, 41.995355],
              [-123.665837, 41.995319],
              [-123.663444, 41.995256],
              [-123.66098, 41.995193],
              [-123.658723, 41.99513],
              [-123.657212, 41.995094],
              [-123.656124, 41.995247],
              [-123.653983, 41.995544],
              [-123.651267, 41.99593],
              [-123.649199, 41.996218],
              [-123.646411, 41.996614],
              [-123.644424, 41.996893],
              [-123.641555, 41.997297],
              [-123.6405, 41.997447],
              [-123.639711, 41.997558],
              [-123.636698, 41.997981],
              [-123.635008, 41.998224],
              [-123.631833, 41.998664],
              [-123.631511, 41.99871],
              [-123.630304, 41.99888],
              [-123.627571, 41.999271],
              [-123.626968, 41.999357],
              [-123.626165, 41.999468],
              [-123.625601, 41.999546],
              [-123.625404, 41.999574],
              [-123.625165, 41.999608],
              [-123.624022, 41.99977],
              [-123.623842, 41.999773],
              [-123.62323, 41.999782],
              [-123.622351, 41.999794],
              [-123.622238, 41.999796],
              [-123.622105, 41.999798],
              [-123.620964, 41.999817],
              [-123.620066, 41.999831],
              [-123.617318, 41.999878],
              [-123.616329, 41.999887],
              [-123.616183, 41.99989],
              [-123.615705, 41.999899],
              [-123.614472, 41.999918],
              [-123.612534, 41.99995],
              [-123.611607, 41.999968],
              [-123.610518, 41.999984],
              [-123.610223, 41.999988],
              [-123.610093, 41.99999],
              [-123.609885, 41.999992],
              [-123.607749, 42.000022],
              [-123.606877, 42.00004],
              [-123.602965, 42.000103],
              [-123.6022, 42.000112],
              [-123.59801, 42.000184],
              [-123.597524, 42.000193],
              [-123.593063, 42.000256],
              [-123.592812, 42.000265],
              [-123.592389, 42.000274],
              [-123.59209, 42.000279],
              [-123.591598, 42.000288],
              [-123.588117, 42.000337],
              [-123.583423, 42.000409],
              [-123.583162, 42.000409],
              [-123.578746, 42.000481],
              [-123.577118, 42.000508],
              [-123.576194, 42.000522],
              [-123.574061, 42.000553],
              [-123.573606, 42.00056],
              [-123.572226, 42.00058],
              [-123.571926, 42.000585],
              [-123.57174, 42.000588],
              [-123.570352, 42.00061],
              [-123.569836, 42.000618],
              [-123.569384, 42.000625],
              [-123.569305, 42.000626],
              [-123.567361, 42.000661],
              [-123.564789, 42.000697],
              [-123.564204, 42.000706],
              [-123.562495, 42.000706],
              [-123.559797, 42.000697],
              [-123.557263, 42.000689],
              [-123.556367, 42.000689],
              [-123.554803, 42.000691],
              [-123.552741, 42.000684],
              [-123.552068, 42.000678],
              [-123.549812, 42.000672],
              [-123.548487, 42.00067],
              [-123.547872, 42.00067],
              [-123.545192, 42.000661],
              [-123.545049, 42.000661],
              [-123.544594, 42.000669],
              [-123.544374, 42.000673],
              [-123.544106, 42.000677],
              [-123.543782, 42.000684],
              [-123.542989, 42.000694],
              [-123.540291, 42.000733],
              [-123.538142, 42.00076],
              [-123.535209, 42.000787],
              [-123.534799, 42.000787],
              [-123.534376, 42.000787],
              [-123.533294, 42.000787],
              [-123.530758, 42.000778],
              [-123.530232, 42.000779],
              [-123.530014, 42.000778],
              [-123.528448, 42.000776],
              [-123.526029, 42.000767],
              [-123.523601, 42.000768],
              [-123.519119, 42.00076],
              [-123.518734, 42.00076],
              [-123.517911, 42.000756],
              [-123.517425, 42.000754],
              [-123.516558, 42.000751],
              [-123.514013, 42.00075],
              [-123.513878, 42.000751],
              [-123.511828, 42.000742],
              [-123.511354, 42.000743],
              [-123.510816, 42.000743],
              [-123.509851, 42.000742],
              [-123.509711, 42.000742],
              [-123.509279, 42.000742],
              [-123.509013, 42.000742],
              [-123.507079, 42.000733],
              [-123.506468, 42.000733],
              [-123.505658, 42.000733],
              [-123.504407, 42.000733],
              [-123.504248, 42.000733],
              [-123.502322, 42.000724],
              [-123.501801, 42.000724],
              [-123.501787, 42.000724],
              [-123.500461, 42.000724],
              [-123.499624, 42.000724],
              [-123.499291, 42.000724],
              [-123.498863, 42.00072],
              [-123.498496, 42.00072],
              [-123.498217, 42.000718],
              [-123.497573, 42.000715],
              [-123.494426, 42.000706],
              [-123.492825, 42.000706],
              [-123.490063, 42.000698],
              [-123.489798, 42.000697],
              [-123.4897, 42.000697],
              [-123.48956, 42.000697],
              [-123.488068, 42.000697],
              [-123.484704, 42.000688],
              [-123.483319, 42.000679],
              [-123.482276, 42.000676],
              [-123.482012, 42.000675],
              [-123.481821, 42.000675],
              [-123.479839, 42.00067],
              [-123.479631, 42.00067],
              [-123.478562, 42.00067],
              [-123.474991, 42.000661],
              [-123.473831, 42.000661],
              [-123.471097, 42.000652],
              [-123.470126, 42.000652],
              [-123.46911, 42.000652],
              [-123.46527, 42.000652],
              [-123.464397, 42.000643],
              [-123.462797, 42.000643],
              [-123.459676, 42.000643],
              [-123.459442, 42.000643],
              [-123.455995, 42.00061],
              [-123.454964, 42.000636],
              [-123.454585, 42.000636],
              [-123.454375, 42.000636],
              [-123.452757, 42.000634],
              [-123.450617, 42.000632],
              [-123.44973, 42.000632],
              [-123.44775, 42.00063],
              [-123.446177, 42.000634],
              [-123.44597, 42.000626],
              [-123.444875, 42.000611],
              [-123.44467, 42.000608],
              [-123.443055, 42.000586],
              [-123.441322, 42.000562],
              [-123.440034, 42.000544],
              [-123.439988, 42.000544],
              [-123.439576, 42.000538],
              [-123.439114, 42.000532],
              [-123.438909, 42.000529],
              [-123.438468, 42.000523],
              [-123.438217, 42.00052],
              [-123.436674, 42.000498],
              [-123.434795, 42.000473],
              [-123.433379, 42.000453],
              [-123.432037, 42.000435],
              [-123.430352, 42.000412],
              [-123.42854, 42.000387],
              [-123.4274, 42.000372],
              [-123.425511, 42.000346],
              [-123.425317, 42.000343],
              [-123.423975, 42.000325],
              [-123.422762, 42.000308],
              [-123.420632, 42.000279],
              [-123.418125, 42.000245],
              [-123.415759, 42.000214],
              [-123.415556, 42.000211],
              [-123.414847, 42.000201],
              [-123.414325, 42.000193],
              [-123.414229, 42.000191],
              [-123.408266, 42.000111],
              [-123.408006, 42.000107],
              [-123.406967, 42.000094],
              [-123.40679, 42.00009],
              [-123.405996, 42.000079],
              [-123.405802, 42.000076],
              [-123.404264, 42.000055],
              [-123.402559, 42.000032],
              [-123.401123, 42.000012],
              [-123.399906, 41.999996],
              [-123.399824, 41.999995],
              [-123.397708, 41.999966],
              [-123.396256, 41.999946],
              [-123.396096, 41.999943],
              [-123.395015, 41.999928],
              [-123.393074, 41.999902],
              [-123.391753, 41.999883],
              [-123.391392, 41.999878],
              [-123.390372, 41.999864],
              [-123.387999, 41.999831],
              [-123.386518, 41.999811],
              [-123.385743, 41.999806],
              [-123.383125, 41.999764],
              [-123.381607, 41.999743],
              [-123.381511, 41.999745],
              [-123.381102, 41.999743],
              [-123.379872, 41.999721],
              [-123.37825, 41.999699],
              [-123.376748, 41.999678],
              [-123.376489, 41.999675],
              [-123.375007, 41.999654],
              [-123.373385, 41.999632],
              [-123.37187, 41.999611],
              [-123.36987, 41.999584],
              [-123.368328, 41.999563],
              [-123.367254, 41.999556],
              [-123.366992, 41.999546],
              [-123.365266, 41.999521],
              [-123.363641, 41.999499],
              [-123.362639, 41.999485],
              [-123.362106, 41.999478],
              [-123.360392, 41.999454],
              [-123.359401, 41.999441],
              [-123.358035, 41.999411],
              [-123.357221, 41.999411],
              [-123.357183, 41.99941],
              [-123.357145, 41.99941],
              [-123.357113, 41.999409],
              [-123.356299, 41.999398],
              [-123.355517, 41.999387],
              [-123.353461, 41.999359],
              [-123.352336, 41.999344],
              [-123.350642, 41.999321],
              [-123.348884, 41.999297],
              [-123.347451, 41.999277],
              [-123.346955, 41.999276],
              [-123.345475, 41.999332],
              [-123.344311, 41.999381],
              [-123.342666, 41.999451],
              [-123.342399, 41.99946],
              [-123.332591, 41.999869],
              [-123.331856, 41.999902],
              [-123.331786, 41.999906],
              [-123.330545, 41.999957],
              [-123.328096, 42.000058],
              [-123.326631, 42.000118],
              [-123.325843, 42.00015],
              [-123.325693, 42.000157],
              [-123.324531, 42.000205],
              [-123.323213, 42.000265],
              [-123.32135, 42.000338],
              [-123.319956, 42.000396],
              [-123.318338, 42.000463],
              [-123.316858, 42.000525],
              [-123.314934, 42.000605],
              [-123.313455, 42.00067],
              [-123.312366, 42.000707],
              [-123.310222, 42.000792],
              [-123.308581, 42.000859],
              [-123.30787, 42.000886],
              [-123.306663, 42.000933],
              [-123.305351, 42.000985],
              [-123.303706, 42.00105],
              [-123.301861, 42.001123],
              [-123.300509, 42.001177],
              [-123.298877, 42.001245],
              [-123.298855, 42.001245],
              [-123.298832, 42.001245],
              [-123.297227, 42.001281],
              [-123.295611, 42.001319],
              [-123.293978, 42.001385],
              [-123.292353, 42.001451],
              [-123.291401, 42.001504],
              [-123.29073, 42.001541],
              [-123.289085, 42.001632],
              [-123.287498, 42.001673],
              [-123.2865, 42.001699],
              [-123.286032, 42.001711],
              [-123.285734, 42.001721],
              [-123.284285, 42.00178],
              [-123.282675, 42.001846],
              [-123.281068, 42.001912],
              [-123.279461, 42.001978],
              [-123.277853, 42.002043],
              [-123.276246, 42.002109],
              [-123.274639, 42.002175],
              [-123.273745, 42.002211],
              [-123.273541, 42.002219],
              [-123.273414, 42.002225],
              [-123.272696, 42.002258],
              [-123.271971, 42.002295],
              [-123.271425, 42.002323],
              [-123.270813, 42.002354],
              [-123.269811, 42.002405],
              [-123.268202, 42.002455],
              [-123.266586, 42.002504],
              [-123.264971, 42.002571],
              [-123.263355, 42.002637],
              [-123.26174, 42.002715],
              [-123.260124, 42.002795],
              [-123.25851, 42.002857],
              [-123.256895, 42.002922],
              [-123.25528, 42.002986],
              [-123.255039, 42.002996],
              [-123.253317, 42.003064],
              [-123.252048, 42.003114],
              [-123.250432, 42.003179],
              [-123.248809, 42.003238],
              [-123.247185, 42.003298],
              [-123.245561, 42.003364],
              [-123.24432, 42.003415],
              [-123.243937, 42.003431],
              [-123.242314, 42.003497],
              [-123.240725, 42.003563],
              [-123.239214, 42.003623],
              [-123.237739, 42.003681],
              [-123.23587, 42.003758],
              [-123.235178, 42.003785],
              [-123.234516, 42.003811],
              [-123.23292, 42.003873],
              [-123.23278, 42.003878],
              [-123.231113, 42.003943],
              [-123.230761, 42.003958],
              [-123.228556, 42.004048],
              [-123.225075, 42.004185],
              [-123.224395, 42.004218],
              [-123.222408, 42.004286],
              [-123.21621, 42.004564],
              [-123.214567, 42.004629],
              [-123.211349, 42.004709],
              [-123.201797, 42.005095],
              [-123.196246, 42.005371],
              [-123.192324, 42.005473],
              [-123.191426, 42.005497],
              [-123.190021, 42.005551],
              [-123.185908, 42.005707],
              [-123.183923, 42.005789],
              [-123.181447, 42.00598],
              [-123.175568, 42.006426],
              [-123.171899, 42.006712],
              [-123.166256, 42.007155],
              [-123.156514, 42.007918],
              [-123.155793, 42.007967],
              [-123.154508, 42.008075],
              [-123.154207, 42.008098],
              [-123.153781, 42.008132],
              [-123.153522, 42.008153],
              [-123.153387, 42.008163],
              [-123.151957, 42.008279],
              [-123.145735, 42.008761],
              [-123.131862, 42.00844],
              [-123.130303, 42.008405],
              [-123.128935, 42.008372],
              [-123.125165, 42.00816],
              [-123.120465, 42.007885],
              [-123.116027, 42.007629],
              [-123.11207, 42.007406],
              [-123.104072, 42.006798],
              [-123.102955, 42.006711],
              [-123.101343, 42.006596],
              [-123.100602, 42.006538],
              [-123.093982, 42.006042],
              [-123.08574, 42.005424],
              [-123.082217, 42.00516],
              [-123.080938, 42.005064],
              [-123.072313, 42.004948],
              [-123.066779, 42.004875],
              [-123.064611, 42.004843],
              [-123.063751, 42.004831],
              [-123.062882, 42.00482],
              [-123.055994, 42.004186],
              [-123.051271, 42.003755],
              [-123.045311, 42.003204],
              [-123.043997, 42.003084],
              [-123.0432, 42.003087],
              [-123.042792, 42.003088],
              [-123.037709, 42.003107],
              [-123.032273, 42.003126],
              [-123.031677, 42.003128],
              [-123.023706, 42.003149],
              [-123.017874, 42.003141],
              [-123.012802, 42.003134],
              [-123.007716, 42.003127],
              [-123.001025, 42.003173],
              [-122.998141, 42.003199],
              [-122.998144, 42.003246],
              [-122.995483, 42.003265],
              [-122.994847, 42.003269],
              [-122.99347, 42.003246],
              [-122.992581, 42.003254],
              [-122.98819, 42.003287],
              [-122.987706, 42.00329],
              [-122.982285, 42.003354],
              [-122.97837, 42.0034],
              [-122.977416, 42.003412],
              [-122.973485, 42.003459],
              [-122.97254, 42.003476],
              [-122.968601, 42.003517],
              [-122.967979, 42.003524],
              [-122.967745, 42.003523],
              [-122.963801, 42.003507],
              [-122.962943, 42.003503],
              [-122.961661, 42.003498],
              [-122.961451, 42.003501],
              [-122.959129, 42.003487],
              [-122.958962, 42.003491],
              [-122.952396, 42.003451],
              [-122.941544, 42.003488],
              [-122.912952, 42.003556],
              [-122.902541, 42.003656],
              [-122.902248, 42.003659],
              [-122.902121, 42.00366],
              [-122.902039, 42.003661],
              [-122.893602, 42.003753],
              [-122.889935, 42.003785],
              [-122.888409, 42.003803],
              [-122.880123, 42.003906],
              [-122.875516, 42.003962],
              [-122.873226, 42.003986],
              [-122.870868, 42.003962],
              [-122.868127, 42.003947],
              [-122.867298, 42.003945],
              [-122.862411, 42.003919],
              [-122.854919, 42.003873],
              [-122.84167, 42.003998],
              [-122.839716, 42.004022],
              [-122.839177, 42.004025],
              [-122.839091, 42.004026],
              [-122.838271, 42.004032],
              [-122.837654, 42.004037],
              [-122.837244, 42.004044],
              [-122.830159, 42.00411],
              [-122.829868, 42.004115],
              [-122.826571, 42.004105],
              [-122.826445, 42.004105],
              [-122.82504, 42.00411],
              [-122.821571, 42.004123],
              [-122.820018, 42.004128],
              [-122.813845, 42.004141],
              [-122.81074, 42.004125],
              [-122.802794, 42.004097],
              [-122.801484, 42.004095],
              [-122.799792, 42.004087],
              [-122.799037, 42.004083],
              [-122.798165, 42.00408],
              [-122.797251, 42.004077],
              [-122.796298, 42.004073],
              [-122.796111, 42.004072],
              [-122.794195, 42.004065],
              [-122.792624, 42.004059],
              [-122.792431, 42.004058],
              [-122.791138, 42.004053],
              [-122.790447, 42.00405],
              [-122.78949, 42.004046],
              [-122.788009, 42.004041],
              [-122.784534, 42.004027],
              [-122.784271, 42.004026],
              [-122.784107, 42.004027],
              [-122.783601, 42.004024],
              [-122.782694, 42.00402],
              [-122.781118, 42.004014],
              [-122.78095, 42.004013],
              [-122.780795, 42.004016],
              [-122.780513, 42.004012],
              [-122.779031, 42.004005],
              [-122.777186, 42.004005],
              [-122.768808, 42.004058],
              [-122.768004, 42.004061],
              [-122.766071, 42.004073],
              [-122.762193, 42.004101],
              [-122.761645, 42.004103],
              [-122.761513, 42.004103],
              [-122.76113, 42.004105],
              [-122.760623, 42.004109],
              [-122.759927, 42.004115],
              [-122.757697, 42.004123],
              [-122.754665, 42.004128],
              [-122.753939, 42.004131],
              [-122.753635, 42.004133],
              [-122.749787, 42.004141],
              [-122.747749, 42.004145],
              [-122.747267, 42.004146],
              [-122.744412, 42.004157],
              [-122.743392, 42.004159],
              [-122.739872, 42.004159],
              [-122.738331, 42.004157],
              [-122.738221, 42.004153],
              [-122.738147, 42.004157],
              [-122.736739, 42.004154],
              [-122.725917, 42.00414],
              [-122.725773, 42.00414],
              [-122.724759, 42.00421],
              [-122.721968, 42.004136],
              [-122.7219, 42.004136],
              [-122.721386, 42.004123],
              [-122.720793, 42.004132],
              [-122.720671, 42.004143],
              [-122.720365, 42.00417],
              [-122.719933, 42.004208],
              [-122.718994, 42.004197],
              [-122.716862, 42.004167],
              [-122.716567, 42.004162],
              [-122.716167, 42.004157],
              [-122.715571, 42.004157],
              [-122.715162, 42.004243],
              [-122.713058, 42.004211],
              [-122.712351, 42.004201],
              [-122.710975, 42.00418],
              [-122.71066, 42.004176],
              [-122.709768, 42.004179],
              [-122.708451, 42.004187],
              [-122.706856, 42.004198],
              [-122.706419, 42.0042],
              [-122.704165, 42.004215],
              [-122.702787, 42.004224],
              [-122.695948, 42.004304],
              [-122.683549, 42.004455],
              [-122.678334, 42.004565],
              [-122.677916, 42.004574],
              [-122.677237, 42.004588],
              [-122.675924, 42.004616],
              [-122.673749, 42.004662],
              [-122.672521, 42.004687],
              [-122.664807, 42.00485],
              [-122.662256, 42.004873],
              [-122.661399, 42.004882],
              [-122.66087, 42.00488],
              [-122.658407, 42.004894],
              [-122.656049, 42.004907],
              [-122.65498, 42.004922],
              [-122.654415, 42.004916],
              [-122.653949, 42.004918],
              [-122.652543, 42.004926],
              [-122.651098, 42.004933],
              [-122.649598, 42.004941],
              [-122.649154, 42.004944],
              [-122.648444, 42.004948],
              [-122.6481, 42.00495],
              [-122.647651, 42.004952],
              [-122.646612, 42.004962],
              [-122.646425, 42.004964],
              [-122.64612, 42.004962],
              [-122.645776, 42.004967],
              [-122.645485, 42.004964],
              [-122.645221, 42.004968],
              [-122.637867, 42.005015],
              [-122.634696, 42.005036],
              [-122.63161, 42.005059],
              [-122.630552, 42.005067],
              [-122.625315, 42.005143],
              [-122.623209, 42.005206],
              [-122.621932, 42.005246],
              [-122.620387, 42.005295],
              [-122.618384, 42.005358],
              [-122.615692, 42.005441],
              [-122.615339, 42.005453],
              [-122.611599, 42.00557],
              [-122.607473, 42.005699],
              [-122.596005, 42.006086],
              [-122.59518, 42.006114],
              [-122.594415, 42.00614],
              [-122.592797, 42.006195],
              [-122.59166, 42.006233],
              [-122.590391, 42.006276],
              [-122.583817, 42.006498],
              [-122.574281, 42.00682],
              [-122.573916, 42.006832],
              [-122.566993, 42.007058],
              [-122.562305, 42.007182],
              [-122.56039, 42.007233],
              [-122.559272, 42.007268],
              [-122.54999, 42.007505],
              [-122.548911, 42.007518],
              [-122.537621, 42.007629],
              [-122.522521, 42.007918],
              [-122.522432, 42.007922],
              [-122.521861, 42.007931],
              [-122.520377, 42.007959],
              [-122.519037, 42.007983],
              [-122.518734, 42.007989],
              [-122.517423, 42.008013],
              [-122.515541, 42.008048],
              [-122.515301, 42.008052],
              [-122.513892, 42.008057],
              [-122.512321, 42.008062],
              [-122.510858, 42.008067],
              [-122.509082, 42.008073],
              [-122.506176, 42.008083],
              [-122.493322, 42.008121],
              [-122.492207, 42.00812],
              [-122.490147, 42.008124],
              [-122.477395, 42.00815],
              [-122.475594, 42.008152],
              [-122.474703, 42.008154],
              [-122.473748, 42.008155],
              [-122.472349, 42.008163],
              [-122.470666, 42.008173],
              [-122.469871, 42.008177],
              [-122.467641, 42.00819],
              [-122.465751, 42.0082],
              [-122.465051, 42.008205],
              [-122.462705, 42.008217],
              [-122.461011, 42.008226],
              [-122.45938, 42.008236],
              [-122.457716, 42.008246],
              [-122.457473, 42.008249],
              [-122.456158, 42.008256],
              [-122.455344, 42.008261],
              [-122.454909, 42.008281],
              [-122.454605, 42.008279],
              [-122.451357, 42.008272],
              [-122.450473, 42.008274],
              [-122.448165, 42.008279],
              [-122.446558, 42.008283],
              [-122.445604, 42.008285],
              [-122.444086, 42.008295],
              [-122.431385, 42.008321],
              [-122.416638, 42.00838],
              [-122.407598, 42.008533],
              [-122.403462, 42.008604],
              [-122.402144, 42.008629],
              [-122.401369, 42.00864],
              [-122.399026, 42.008683],
              [-122.397725, 42.008702],
              [-122.397271, 42.00871],
              [-122.388017, 42.009092],
              [-122.38264, 42.009306],
              [-122.378511, 42.009485],
              [-122.378353, 42.009491],
              [-122.378161, 42.009499],
              [-122.378049, 42.009503],
              [-122.374597, 42.009442],
              [-122.370441, 42.009368],
              [-122.368664, 42.009336],
              [-122.368255, 42.009329],
              [-122.36805, 42.00933],
              [-122.367849, 42.009327],
              [-122.367317, 42.009317],
              [-122.365921, 42.00929],
              [-122.363926, 42.009253],
              [-122.360161, 42.009192],
              [-122.35602, 42.009145],
              [-122.352014, 42.009113],
              [-122.350009, 42.009092],
              [-122.343299, 42.009045],
              [-122.338986, 42.008989],
              [-122.333888, 42.008892],
              [-122.329066, 42.008791],
              [-122.324068, 42.008697],
              [-122.320293, 42.008609],
              [-122.314452, 42.008521],
              [-122.314229, 42.008531],
              [-122.309587, 42.008449],
              [-122.309326, 42.008461],
              [-122.306742, 42.008407],
              [-122.304713, 42.008368],
              [-122.304431, 42.008393],
              [-122.296286, 42.008225],
              [-122.289748, 42.008098],
              [-122.289066, 42.008096],
              [-122.288045, 42.008084],
              [-122.286656, 42.008073],
              [-122.284836, 42.008003],
              [-122.282239, 42.007963],
              [-122.280784, 42.007941],
              [-122.280114, 42.00793],
              [-122.27861, 42.007907],
              [-122.276976, 42.007882],
              [-122.275308, 42.007856],
              [-122.270792, 42.007787],
              [-122.268702, 42.007733],
              [-122.265584, 42.007684],
              [-122.262203, 42.007667],
              [-122.261344, 42.007617],
              [-122.260687, 42.007618],
              [-122.255791, 42.007632],
              [-122.250893, 42.007646],
              [-122.250018, 42.007648],
              [-122.248908, 42.007645],
              [-122.247562, 42.007645],
              [-122.246001, 42.007645],
              [-122.24484, 42.007656],
              [-122.243165, 42.007645],
              [-122.241875, 42.007663],
              [-122.241087, 42.00766],
              [-122.239392, 42.007654],
              [-122.238253, 42.007644],
              [-122.236196, 42.007644],
              [-122.221648, 42.007592],
              [-122.220623, 42.00759],
              [-122.18902, 42.007481],
              [-122.188796, 42.00748],
              [-122.188773, 42.007479],
              [-122.188642, 42.007478],
              [-122.18858, 42.007479],
              [-122.186953, 42.007466],
              [-122.186699, 42.007464],
              [-122.186567, 42.007463],
              [-122.186335, 42.007462],
              [-122.186265, 42.007456],
              [-122.186155, 42.007456],
              [-122.185826, 42.007456],
              [-122.185524, 42.007456],
              [-122.185496, 42.007456],
              [-122.185425, 42.007455],
              [-122.184072, 42.007448],
              [-122.1838, 42.007446],
              [-122.182281, 42.007435],
              [-122.181998, 42.007433],
              [-122.180278, 42.00742],
              [-122.179127, 42.007412],
              [-122.176664, 42.007397],
              [-122.175983, 42.007389],
              [-122.174935, 42.007381],
              [-122.173486, 42.007371],
              [-122.172562, 42.007364],
              [-122.172069, 42.007361],
              [-122.170723, 42.00735],
              [-122.169233, 42.007339],
              [-122.168348, 42.007333],
              [-122.164571, 42.007306],
              [-122.163511, 42.007264],
              [-122.161991, 42.007207],
              [-122.161693, 42.007196],
              [-122.159918, 42.00713],
              [-122.159364, 42.007109],
              [-122.158656, 42.007083],
              [-122.15863, 42.007082],
              [-122.157073, 42.007024],
              [-122.156995, 42.007021],
              [-122.156877, 42.007017],
              [-122.155493, 42.006966],
              [-122.155263, 42.006957],
              [-122.1538, 42.006902],
              [-122.152858, 42.006867],
              [-122.152167, 42.006842],
              [-122.150608, 42.006783],
              [-122.148945, 42.006721],
              [-122.147612, 42.006668],
              [-122.147463, 42.006656],
              [-122.146345, 42.006618],
              [-122.145113, 42.00657],
              [-122.144272, 42.006556],
              [-122.144058, 42.006552],
              [-122.142353, 42.006523],
              [-122.132572, 42.006355],
              [-122.131266, 42.006333],
              [-122.129385, 42.0063],
              [-122.128873, 42.006292],
              [-122.128051, 42.006278],
              [-122.126197, 42.006246],
              [-122.125839, 42.00624],
              [-122.124504, 42.006227],
              [-122.119622, 42.00618],
              [-122.11857, 42.006169],
              [-122.115957, 42.006144],
              [-122.109731, 42.006082],
              [-122.106505, 42.005992],
              [-122.105082, 42.005987],
              [-122.098039, 42.005952],
              [-122.092749, 42.005931],
              [-122.087166, 42.005923],
              [-122.086479, 42.005912],
              [-122.0794, 42.005839],
              [-122.068847, 42.005728],
              [-122.067761, 42.005715],
              [-122.065547, 42.005685],
              [-122.064662, 42.005673],
              [-122.063714, 42.00566],
              [-122.060892, 42.005597],
              [-122.058832, 42.005561],
              [-122.05791, 42.005546],
              [-122.056583, 42.005526],
              [-122.053971, 42.005479],
              [-122.052854, 42.005459],
              [-122.051176, 42.005436],
              [-122.049063, 42.005406],
              [-122.048217, 42.00539],
              [-122.047234, 42.005339],
              [-122.04619, 42.005283],
              [-122.044218, 42.005178],
              [-122.04295, 42.005118],
              [-122.041307, 42.00504],
              [-122.039106, 42.004928],
              [-122.036424, 42.004796],
              [-122.034563, 42.004696],
              [-122.033174, 42.004683],
              [-122.031536, 42.004668],
              [-122.028729, 42.004643],
              [-122.026643, 42.004589],
              [-122.025005, 42.004552],
              [-122.023398, 42.004506],
              [-122.017345, 42.004351],
              [-122.014821, 42.004283],
              [-122.01086, 42.004185],
              [-122.009283, 42.004144],
              [-122.008229, 42.004125],
              [-122.007095, 42.004103],
              [-122.005599, 42.004079],
              [-122.003907, 42.004041],
              [-122.00238, 42.004008],
              [-122.000864, 42.003983],
              [-122.00041, 42.003973],
              [-122.00009, 42.003966],
              [-121.99906, 42.003943],
              [-121.997663, 42.003912],
              [-121.996127, 42.003887],
              [-121.994211, 42.003884],
              [-121.991201, 42.003879],
              [-121.987756, 42.003855],
              [-121.986274, 42.003842],
              [-121.982773, 42.00381],
              [-121.981348, 42.003797],
              [-121.97779, 42.003764],
              [-121.97627, 42.003749],
              [-121.975148, 42.003739],
              [-121.974673, 42.003737],
              [-121.971176, 42.003706],
              [-121.970429, 42.003695],
              [-121.967919, 42.003647],
              [-121.962913, 42.003573],
              [-121.957531, 42.003485],
              [-121.95743, 42.003485],
              [-121.956335, 42.003529],
              [-121.954086, 42.003628],
              [-121.953178, 42.003667],
              [-121.952547, 42.003656],
              [-121.950991, 42.003647],
              [-121.948696, 42.00366],
              [-121.948193, 42.003651],
              [-121.947772, 42.003647],
              [-121.946194, 42.003691],
              [-121.94428, 42.003746],
              [-121.943059, 42.003736],
              [-121.941455, 42.003772],
              [-121.939829, 42.003808],
              [-121.938313, 42.00378],
              [-121.935217, 42.003772],
              [-121.933431, 42.003743],
              [-121.932087, 42.003718],
              [-121.930586, 42.003701],
              [-121.928427, 42.003683],
              [-121.926725, 42.00367],
              [-121.925963, 42.003665],
              [-121.922538, 42.003649],
              [-121.918757, 42.003653],
              [-121.917767, 42.003622],
              [-121.914831, 42.003547],
              [-121.912644, 42.003494],
              [-121.909866, 42.003467],
              [-121.909074, 42.003458],
              [-121.908124, 42.003447],
              [-121.901904, 42.003384],
              [-121.901735, 42.003377],
              [-121.901488, 42.003396],
              [-121.899418, 42.003454],
              [-121.896879, 42.003323],
              [-121.892014, 42.003269],
              [-121.891775, 42.003266],
              [-121.891327, 42.003259],
              [-121.889217, 42.003237],
              [-121.884675, 42.003179],
              [-121.882292, 42.003151],
              [-121.880854, 42.003131],
              [-121.879733, 42.003116],
              [-121.879525, 42.003113],
              [-121.87748, 42.003088],
              [-121.876213, 42.003071],
              [-121.873904, 42.003062],
              [-121.869926, 42.003026],
              [-121.868699, 42.003017],
              [-121.863029, 42.002954],
              [-121.860072, 42.00296],
              [-121.857938, 42.002918],
              [-121.852848, 42.002855],
              [-121.851087, 42.002832],
              [-121.850224, 42.002821],
              [-121.847758, 42.002801],
              [-121.845248, 42.002774],
              [-121.844197, 42.002756],
              [-121.842667, 42.002738],
              [-121.840377, 42.002682],
              [-121.837802, 42.002666],
              [-121.835406, 42.002612],
              [-121.834391, 42.002598],
              [-121.832946, 42.002594],
              [-121.830523, 42.002543],
              [-121.825146, 42.002468],
              [-121.82511, 42.002467],
              [-121.825079, 42.002467],
              [-121.82502, 42.002466],
              [-121.824566, 42.00246],
              [-121.823966, 42.002451],
              [-121.820674, 42.002405],
              [-121.819613, 42.002386],
              [-121.817542, 42.002349],
              [-121.816189, 42.002324],
              [-121.816016, 42.002321],
              [-121.815711, 42.002316],
              [-121.815391, 42.00231],
              [-121.815132, 42.002306],
              [-121.814654, 42.002297],
              [-121.813364, 42.002278],
              [-121.810782, 42.00224],
              [-121.809142, 42.002216],
              [-121.807502, 42.002192],
              [-121.805862, 42.002168],
              [-121.80397, 42.002144],
              [-121.802464, 42.002133],
              [-121.800981, 42.002122],
              [-121.799302, 42.002081],
              [-121.796009, 42.002029],
              [-121.794634, 42.00201],
              [-121.791084, 42.00196],
              [-121.789964, 42.001944],
              [-121.78616, 42.001891],
              [-121.785294, 42.001879],
              [-121.782915, 42.001846],
              [-121.78042, 42.001811],
              [-121.779631, 42.001799],
              [-121.778167, 42.001779],
              [-121.777998, 42.001776],
              [-121.777856, 42.001774],
              [-121.777709, 42.001772],
              [-121.77754, 42.00177],
              [-121.776311, 42.001753],
              [-121.773962, 42.00172],
              [-121.772728, 42.001703],
              [-121.770501, 42.001672],
              [-121.769748, 42.001661],
              [-121.766466, 42.001615],
              [-121.76484, 42.001592],
              [-121.763552, 42.001572],
              [-121.756783, 42.001475],
              [-121.756642, 42.001475],
              [-121.751539, 42.001406],
              [-121.74564, 42.001323],
              [-121.741906, 42.001271],
              [-121.740263, 42.001247],
              [-121.737332, 42.001206],
              [-121.737089, 42.001223],
              [-121.729874, 42.001121],
              [-121.722684, 42.001019],
              [-121.722458, 42.001017],
              [-121.722308, 42.001014],
              [-121.718855, 42.000947],
              [-121.71849, 42.00094],
              [-121.717922, 42.000933],
              [-121.716151, 42.000913],
              [-121.713043, 42.000883],
              [-121.712617, 42.000877],
              [-121.709946, 42.000823],
              [-121.708233, 42.0008],
              [-121.708137, 42.000799],
              [-121.707887, 42.000795],
              [-121.707643, 42.000792],
              [-121.706847, 42.000782],
              [-121.706242, 42.000774],
              [-121.705382, 42.000763],
              [-121.70509, 42.00076],
              [-121.705017, 42.000759],
              [-121.704199, 42.000753],
              [-121.70312, 42.000745],
              [-121.701507, 42.000712],
              [-121.701377, 42.000709],
              [-121.700537, 42.000694],
              [-121.700233, 42.000688],
              [-121.699552, 42.000678],
              [-121.698221, 42.000659],
              [-121.695382, 42.000618],
              [-121.69468, 42.000608],
              [-121.693184, 42.000587],
              [-121.693051, 42.000585],
              [-121.690527, 42.00055],
              [-121.68908, 42.000529],
              [-121.68827, 42.000517],
              [-121.685574, 42.00048],
              [-121.683404, 42.000467],
              [-121.680619, 42.000408],
              [-121.67843, 42.000378],
              [-121.678249, 42.000375],
              [-121.676735, 42.000354],
              [-121.675741, 42.00034],
              [-121.674245, 42.000318],
              [-121.673494, 42.000307],
              [-121.67337, 42.000303],
              [-121.672935, 42.000285],
              [-121.671047, 42.000298],
              [-121.670823, 42.00027],
              [-121.670434, 42.00027],
              [-121.669649, 42.000264],
              [-121.669071, 42.000259],
              [-121.668551, 42.000235],
              [-121.665969, 42.000202],
              [-121.663608, 42.000163],
              [-121.66346, 42.000161],
              [-121.663184, 42.000157],
              [-121.662145, 42.000143],
              [-121.661086, 42.00013],
              [-121.660103, 42.000116],
              [-121.658666, 42.000092],
              [-121.657564, 42.000076],
              [-121.656931, 42.000067],
              [-121.656225, 42.000057],
              [-121.655521, 42.000047],
              [-121.654458, 42.000031],
              [-121.652312, 42.000004],
              [-121.649475, 41.999964],
              [-121.646154, 41.999918],
              [-121.644069, 41.999886],
              [-121.641894, 41.999855],
              [-121.641856, 41.999854],
              [-121.641749, 41.999853],
              [-121.640795, 41.999839],
              [-121.640774, 41.999848],
              [-121.640639, 41.999846],
              [-121.640511, 41.999835],
              [-121.639203, 41.999816],
              [-121.638938, 41.999812],
              [-121.637548, 41.999794],
              [-121.634486, 41.999752],
              [-121.634311, 41.999749],
              [-121.633491, 41.999738],
              [-121.632543, 41.999724],
              [-121.629258, 41.999681],
              [-121.628989, 41.999677],
              [-121.628802, 41.999674],
              [-121.62861, 41.999672],
              [-121.628447, 41.999669],
              [-121.628272, 41.999667],
              [-121.626717, 41.999646],
              [-121.623364, 41.9996],
              [-121.622655, 41.99959],
              [-121.619638, 41.999549],
              [-121.615944, 41.999503],
              [-121.612562, 41.999469],
              [-121.610539, 41.999447],
              [-121.607612, 41.99942],
              [-121.606342, 41.999405],
              [-121.603753, 41.999381],
              [-121.602257, 41.99937],
              [-121.601693, 41.999365],
              [-121.601331, 41.999363],
              [-121.600302, 41.999355],
              [-121.598465, 41.999326],
              [-121.598184, 41.99932],
              [-121.597699, 41.999313],
              [-121.59541, 41.999277],
              [-121.591106, 41.999164],
              [-121.589789, 41.999155],
              [-121.580822, 41.999005],
              [-121.577597, 41.998951],
              [-121.564777, 41.998747],
              [-121.564004, 41.998738],
              [-121.561022, 41.998701],
              [-121.559384, 41.998681],
              [-121.555282, 41.99863],
              [-121.546317, 41.998512],
              [-121.545082, 41.998506],
              [-121.539811, 41.998482],
              [-121.53586, 41.998463],
              [-121.535314, 41.998456],
              [-121.53516, 41.998455],
              [-121.535065, 41.998453],
              [-121.530251, 41.998395],
              [-121.525539, 41.998339],
              [-121.522418, 41.998288],
              [-121.520523, 41.998278],
              [-121.519446, 41.99826],
              [-121.512134, 41.998204],
              [-121.505976, 41.998137],
              [-121.502663, 41.998102],
              [-121.50241, 41.998099],
              [-121.497284, 41.998035],
              [-121.492693, 41.997987],
              [-121.492453, 41.997982],
              [-121.488153, 41.997942],
              [-121.486506, 41.997928],
              [-121.483204, 41.997899],
              [-121.48278, 41.997895],
              [-121.47217, 41.997767],
              [-121.470252, 41.997744],
              [-121.467077, 41.997716],
              [-121.464889, 41.997697],
              [-121.463105, 41.997662],
              [-121.463084, 41.997662],
              [-121.462312, 41.997652],
              [-121.460259, 41.997627],
              [-121.45673, 41.997587],
              [-121.452241, 41.997529],
              [-121.452087, 41.997528],
              [-121.448311, 41.997505],
              [-121.448211, 41.997488],
              [-121.448139, 41.99747],
              [-121.447739, 41.997467],
              [-121.447616, 41.997466],
              [-121.447538, 41.997465],
              [-121.447458, 41.997464],
              [-121.447205, 41.997461],
              [-121.44654, 41.997454],
              [-121.445186, 41.997438],
              [-121.442344, 41.997405],
              [-121.441103, 41.997391],
              [-121.440924, 41.997389],
              [-121.440664, 41.997386],
              [-121.440366, 41.997383],
              [-121.440332, 41.997382],
              [-121.44016, 41.99738],
              [-121.437483, 41.997349],
              [-121.435531, 41.997327],
              [-121.435477, 41.997326],
              [-121.433589, 41.997305],
              [-121.432622, 41.997294],
              [-121.432208, 41.997289],
              [-121.430639, 41.997271],
              [-121.430505, 41.997269],
              [-121.429761, 41.997261],
              [-121.429069, 41.997253],
              [-121.427762, 41.997238],
              [-121.425796, 41.997216],
              [-121.423052, 41.997185],
              [-121.422855, 41.997182],
              [-121.421507, 41.997167],
              [-121.420939, 41.997162],
              [-121.418153, 41.997127],
              [-121.417907, 41.997124],
              [-121.417506, 41.997119],
              [-121.417467, 41.997119],
              [-121.417447, 41.997119],
              [-121.417421, 41.997118],
              [-121.417392, 41.997123],
              [-121.417083, 41.997115],
              [-121.416081, 41.997103],
              [-121.413183, 41.99707],
              [-121.413154, 41.99707],
              [-121.411225, 41.997048],
              [-121.410131, 41.997036],
              [-121.409145, 41.997025],
              [-121.408324, 41.997015],
              [-121.407812, 41.99701],
              [-121.40686, 41.996998],
              [-121.40637, 41.996992],
              [-121.406224, 41.99699],
              [-121.403459, 41.996961],
              [-121.403286, 41.996959],
              [-121.402071, 41.996945],
              [-121.401505, 41.996937],
              [-121.398612, 41.996905],
              [-121.398593, 41.996905],
              [-121.39848, 41.996905],
              [-121.396649, 41.996883],
              [-121.393727, 41.99685],
              [-121.393686, 41.99685],
              [-121.388862, 41.996795],
              [-121.383928, 41.996737],
              [-121.382092, 41.996716],
              [-121.380927, 41.996703],
              [-121.379184, 41.996683],
              [-121.378995, 41.996685],
              [-121.378905, 41.996686],
              [-121.378826, 41.996686],
              [-121.378784, 41.996685],
              [-121.378074, 41.996677],
              [-121.377232, 41.996667],
              [-121.377125, 41.996665],
              [-121.37571, 41.996643],
              [-121.374061, 41.996624],
              [-121.372373, 41.996604],
              [-121.369127, 41.996567],
              [-121.369109, 41.996567],
              [-121.367508, 41.996548],
              [-121.365884, 41.99653],
              [-121.364268, 41.996511],
              [-121.363237, 41.996499],
              [-121.361405, 41.996529],
              [-121.360306, 41.99654],
              [-121.360082, 41.996547],
              [-121.359399, 41.996559],
              [-121.357664, 41.996517],
              [-121.357631, 41.996517],
              [-121.356649, 41.996493],
              [-121.355245, 41.996458],
              [-121.35454, 41.99644],
              [-121.349678, 41.996326],
              [-121.348076, 41.99629],
              [-121.344911, 41.996214],
              [-121.343554, 41.996247],
              [-121.343433, 41.99625],
              [-121.343229, 41.996254],
              [-121.340902, 41.996312],
              [-121.340658, 41.99634],
              [-121.340208, 41.996356],
              [-121.340061, 41.996361],
              [-121.339819, 41.996347],
              [-121.339786, 41.996341],
              [-121.339336, 41.996351],
              [-121.338694, 41.996362],
              [-121.338504, 41.996375],
              [-121.338368, 41.996384],
              [-121.334394, 41.996558],
              [-121.333571, 41.996587],
              [-121.33071, 41.996719],
              [-121.328988, 41.996798],
              [-121.328812, 41.996802],
              [-121.325594, 41.996866],
              [-121.324227, 41.996893],
              [-121.324045, 41.996897],
              [-121.322553, 41.996932],
              [-121.320958, 41.996961],
              [-121.319463, 41.996991],
              [-121.31927, 41.996995],
              [-121.317784, 41.997025],
              [-121.317606, 41.997028],
              [-121.316487, 41.997057],
              [-121.3162, 41.997062],
              [-121.314719, 41.997085],
              [-121.314452, 41.997089],
              [-121.309938, 41.997183],
              [-121.309649, 41.997189],
              [-121.306354, 41.997232],
              [-121.304865, 41.997242],
              [-121.300758, 41.997308],
              [-121.300232, 41.997315],
              [-121.300032, 41.997311],
              [-121.297304, 41.997356],
              [-121.296802, 41.997364],
              [-121.29653, 41.997365],
              [-121.295376, 41.997369],
              [-121.290211, 41.997436],
              [-121.288279, 41.997436],
              [-121.2864, 41.997441],
              [-121.285299, 41.997436],
              [-121.283995, 41.997435],
              [-121.27712, 41.997441],
              [-121.268043, 41.997438],
              [-121.267453, 41.997432],
              [-121.261145, 41.997392],
              [-121.252722, 41.997333],
              [-121.250404, 41.997312],
              [-121.250343, 41.997315],
              [-121.24781, 41.997293],
              [-121.247244, 41.997288],
              [-121.246738, 41.997294],
              [-121.2429, 41.997326],
              [-121.241935, 41.997334],
              [-121.240432, 41.997347],
              [-121.239636, 41.997354],
              [-121.23799, 41.997368],
              [-121.237075, 41.997375],
              [-121.236406, 41.997381],
              [-121.23408, 41.997411],
              [-121.212258, 41.997442],
              [-121.20985, 41.997414],
              [-121.208836, 41.997412],
              [-121.206673, 41.997407],
              [-121.205399, 41.997431],
              [-121.202435, 41.997409],
              [-121.201391, 41.997412],
              [-121.200903, 41.997427],
              [-121.193933, 41.997396],
              [-121.19341, 41.997397],
              [-121.189086, 41.997387],
              [-121.188992, 41.997388],
              [-121.188565, 41.997384],
              [-121.183825, 41.997317],
              [-121.181306, 41.997301],
              [-121.178986, 41.997272],
              [-121.177813, 41.997261],
              [-121.174533, 41.997231],
              [-121.174152, 41.997228],
              [-121.173215, 41.997219],
              [-121.169678, 41.997189],
              [-121.169597, 41.997189],
              [-121.169318, 41.99718],
              [-121.168477, 41.997178],
              [-121.154151, 41.996835],
              [-121.149866, 41.996739],
              [-121.149834, 41.996739],
              [-121.149803, 41.996739],
              [-121.149668, 41.996737],
              [-121.149357, 41.996729],
              [-121.146446, 41.996661],
              [-121.144938, 41.996613],
              [-121.141105, 41.996529],
              [-121.139959, 41.9965],
              [-121.13509, 41.99638],
              [-121.135053, 41.996378],
              [-121.135015, 41.996375],
              [-121.130011, 41.99625],
              [-121.125279, 41.996137],
              [-121.125007, 41.996125],
              [-121.111242, 41.995672],
              [-121.109735, 41.995636],
              [-121.10851, 41.995615],
              [-121.107285, 41.995595],
              [-121.106059, 41.995574],
              [-121.105309, 41.995559],
              [-121.103128, 41.995528],
              [-121.101443, 41.995502],
              [-121.099939, 41.99546],
              [-121.098439, 41.995409],
              [-121.097159, 41.995436],
              [-121.096587, 41.995428],
              [-121.094985, 41.995402],
              [-121.093515, 41.995379],
              [-121.091656, 41.995557],
              [-121.091525, 41.995544],
              [-121.090246, 41.995523],
              [-121.088642, 41.995478],
              [-121.08793, 41.995475],
              [-121.087097, 41.995462],
              [-121.085179, 41.995411],
              [-121.084431, 41.995399],
              [-121.083802, 41.995383],
              [-121.082259, 41.995354],
              [-121.080956, 41.995314],
              [-121.079858, 41.995287],
              [-121.078639, 41.995275],
              [-121.07777, 41.995261],
              [-121.074806, 41.995188],
              [-121.072515, 41.995144],
              [-121.071563, 41.995116],
              [-121.071069, 41.995102],
              [-121.06779, 41.994962],
              [-121.066348, 41.994898],
              [-121.065455, 41.994865],
              [-121.064869, 41.994855],
              [-121.064611, 41.99485],
              [-121.063037, 41.994785],
              [-121.062392, 41.994759],
              [-121.060186, 41.994677],
              [-121.059021, 41.99463],
              [-121.057543, 41.994574],
              [-121.056668, 41.994541],
              [-121.055712, 41.9945],
              [-121.055374, 41.994492],
              [-121.054014, 41.994432],
              [-121.051651, 41.994338],
              [-121.051605, 41.994336],
              [-121.050647, 41.994305],
              [-121.049735, 41.994283],
              [-121.048536, 41.994246],
              [-121.047337, 41.994204],
              [-121.044599, 41.994095],
              [-121.043852, 41.994073],
              [-121.04161, 41.994017],
              [-121.039281, 41.993949],
              [-121.036287, 41.993862],
              [-121.035253, 41.993822],
              [-121.035173, 41.993818],
              [-121.034613, 41.993791],
              [-121.033509, 41.993755],
              [-121.033323, 41.993746],
              [-121.03251, 41.993707],
              [-121.03204, 41.9937],
              [-121.030511, 41.993693],
              [-121.029811, 41.993663],
              [-121.029461, 41.993654],
              [-121.029154, 41.993656],
              [-121.026748, 41.99358],
              [-121.023839, 41.993527],
              [-121.022114, 41.993308],
              [-121.022056, 41.993302],
              [-121.021809, 41.993308],
              [-121.019095, 41.993386],
              [-121.017056, 41.99336],
              [-121.011961, 41.993278],
              [-120.993343, 41.993089],
              [-120.990083, 41.993056],
              [-120.989954, 41.993056],
              [-120.988889, 41.993053],
              [-120.98789, 41.993049],
              [-120.986891, 41.993045],
              [-120.985452, 41.993039],
              [-120.984013, 41.993033],
              [-120.983014, 41.993029],
              [-120.982015, 41.993025],
              [-120.980576, 41.993019],
              [-120.980416, 41.993023],
              [-120.974258, 41.992994],
              [-120.974135, 41.992988],
              [-120.967026, 41.993051],
              [-120.963784, 41.99308],
              [-120.963535, 41.993082],
              [-120.962549, 41.993091],
              [-120.959655, 41.993115],
              [-120.959085, 41.993121],
              [-120.958663, 41.993126],
              [-120.958338, 41.993128],
              [-120.957685, 41.993135],
              [-120.95406, 41.993167],
              [-120.95137, 41.99323],
              [-120.948935, 41.993287],
              [-120.946505, 41.993344],
              [-120.945815, 41.99336],
              [-120.945062, 41.993378],
              [-120.944069, 41.993401],
              [-120.943076, 41.993424],
              [-120.941639, 41.993458],
              [-120.940201, 41.993492],
              [-120.938925, 41.993522],
              [-120.938333, 41.993535],
              [-120.935339, 41.993606],
              [-120.934524, 41.993625],
              [-120.927323, 41.993635],
              [-120.925624, 41.993639],
              [-120.920859, 41.993647],
              [-120.907035, 41.993675],
              [-120.906904, 41.993676],
              [-120.906195, 41.993678],
              [-120.905215, 41.99368],
              [-120.904236, 41.993682],
              [-120.902787, 41.993686],
              [-120.901338, 41.99369],
              [-120.896567, 41.993706],
              [-120.896481, 41.993702],
              [-120.895638, 41.993704],
              [-120.891101, 41.993721],
              [-120.888232, 41.993732],
              [-120.879979, 41.993764],
              [-120.879926, 41.993764],
              [-120.879768, 41.993765],
              [-120.879477, 41.993766],
              [-120.879066, 41.993768],
              [-120.878698, 41.99377],
              [-120.878231, 41.993772],
              [-120.877692, 41.993774],
              [-120.877098, 41.993777],
              [-120.876893, 41.993777],
              [-120.876482, 41.993777],
              [-120.876073, 41.993777],
              [-120.875472, 41.993778],
              [-120.874849, 41.993778],
              [-120.874021, 41.99378],
              [-120.873572, 41.99378],
              [-120.872515, 41.993782],
              [-120.871897, 41.993783],
              [-120.871763, 41.993783],
              [-120.871279, 41.993784],
              [-120.870893, 41.993784],
              [-120.870168, 41.993785],
              [-120.869729, 41.993786],
              [-120.869187, 41.993787],
              [-120.868544, 41.993788],
              [-120.867901, 41.993789],
              [-120.867475, 41.993789],
              [-120.866731, 41.99379],
              [-120.866169, 41.993791],
              [-120.865416, 41.993792],
              [-120.86479, 41.993793],
              [-120.86418, 41.993794],
              [-120.863584, 41.993795],
              [-120.863014, 41.993796],
              [-120.862507, 41.993796],
              [-120.861632, 41.993798],
              [-120.861163, 41.993799],
              [-120.860555, 41.993799],
              [-120.860069, 41.9938],
              [-120.859357, 41.993801],
              [-120.858982, 41.993801],
              [-120.858025, 41.993803],
              [-120.857742, 41.993803],
              [-120.85676, 41.993804],
              [-120.856472, 41.993805],
              [-120.855127, 41.993807],
              [-120.854918, 41.993808],
              [-120.854736, 41.993809],
              [-120.854531, 41.993809],
              [-120.853985, 41.99381],
              [-120.853306, 41.993811],
              [-120.853186, 41.993811],
              [-120.85132, 41.993815],
              [-120.845335, 41.993827],
              [-120.843995, 41.99383],
              [-120.842924, 41.993832],
              [-120.83868, 41.99384],
              [-120.838373, 41.993841],
              [-120.830011, 41.993862],
              [-120.826455, 41.993865],
              [-120.821632, 41.993878],
              [-120.819299, 41.993884],
              [-120.816291, 41.993889],
              [-120.8114, 41.993906],
              [-120.810644, 41.99391],
              [-120.810426, 41.993908],
              [-120.810369, 41.993909],
              [-120.808912, 41.993914],
              [-120.807456, 41.993919],
              [-120.805532, 41.993926],
              [-120.804079, 41.99393],
              [-120.802626, 41.993935],
              [-120.801661, 41.993939],
              [-120.800695, 41.993942],
              [-120.799406, 41.993946],
              [-120.798117, 41.993951],
              [-120.797764, 41.993946],
              [-120.795852, 41.993924],
              [-120.794377, 41.993906],
              [-120.792903, 41.993889],
              [-120.791956, 41.993877],
              [-120.791009, 41.993866],
              [-120.789525, 41.993848],
              [-120.788042, 41.993831],
              [-120.787104, 41.99382],
              [-120.786166, 41.993809],
              [-120.784673, 41.993791],
              [-120.784072, 41.993784],
              [-120.779401, 41.993728],
              [-120.77901, 41.993724],
              [-120.778112, 41.993719],
              [-120.777861, 41.993718],
              [-120.776409, 41.993711],
              [-120.773233, 41.9937],
              [-120.771078, 41.993693],
              [-120.770662, 41.993692],
              [-120.770455, 41.993691],
              [-120.770073, 41.993689],
              [-120.76516, 41.993672],
              [-120.76458, 41.99367],
              [-120.763911, 41.993667],
              [-120.76331, 41.993667],
              [-120.762579, 41.993664],
              [-120.761815, 41.993662],
              [-120.761061, 41.993659],
              [-120.760749, 41.993656],
              [-120.759999, 41.993655],
              [-120.75931, 41.993653],
              [-120.758388, 41.993653],
              [-120.75768, 41.993656],
              [-120.756587, 41.993661],
              [-120.756114, 41.993663],
              [-120.754903, 41.993668],
              [-120.753627, 41.993674],
              [-120.752516, 41.993679],
              [-120.751714, 41.993683],
              [-120.751033, 41.993686],
              [-120.750691, 41.993687],
              [-120.75026, 41.993689],
              [-120.748819, 41.993692],
              [-120.748383, 41.993693],
              [-120.748248, 41.993699],
              [-120.748203, 41.993699],
              [-120.747902, 41.9937],
              [-120.746676, 41.993706],
              [-120.746187, 41.993708],
              [-120.74493, 41.993714],
              [-120.743628, 41.99372],
              [-120.742823, 41.993724],
              [-120.740754, 41.993733],
              [-120.739082, 41.993739],
              [-120.737735, 41.993742],
              [-120.735509, 41.993748],
              [-120.734353, 41.993751],
              [-120.732757, 41.993755],
              [-120.730667, 41.99376],
              [-120.729929, 41.993762],
              [-120.728817, 41.993764],
              [-120.727022, 41.993769],
              [-120.725522, 41.993773],
              [-120.724353, 41.993775],
              [-120.723187, 41.993778],
              [-120.721481, 41.993783],
              [-120.720469, 41.993785],
              [-120.718829, 41.993788],
              [-120.71732, 41.993791],
              [-120.716175, 41.993793],
              [-120.714747, 41.993796],
              [-120.713414, 41.993798],
              [-120.71208, 41.993801],
              [-120.710813, 41.993803],
              [-120.70934, 41.993806],
              [-120.70811, 41.993808],
              [-120.707455, 41.993809],
              [-120.706649, 41.993811],
              [-120.70602, 41.993813],
              [-120.700815, 41.993822],
              [-120.700471, 41.993822],
              [-120.700184, 41.993819],
              [-120.699161, 41.993805],
              [-120.698341, 41.993795],
              [-120.696682, 41.993773],
              [-120.694824, 41.993749],
              [-120.694428, 41.993746],
              [-120.69424, 41.993742],
              [-120.693544, 41.993733],
              [-120.692938, 41.993725],
              [-120.692376, 41.993717],
              [-120.691859, 41.993711],
              [-120.691358, 41.993704],
              [-120.691187, 41.993701],
              [-120.690569, 41.993694],
              [-120.680984, 41.99357],
              [-120.675728, 41.99347],
              [-120.667882, 41.993321],
              [-120.66552, 41.993276],
              [-120.662123, 41.993212],
              [-120.657551, 41.993187],
              [-120.654832, 41.993177],
              [-120.653406, 41.993172],
              [-120.651426, 41.993166],
              [-120.650382, 41.993161],
              [-120.64983, 41.993159],
              [-120.648698, 41.993165],
              [-120.647436, 41.99315],
              [-120.644712, 41.99314],
              [-120.644387, 41.993139],
              [-120.644062, 41.993138],
              [-120.642586, 41.993132],
              [-120.641382, 41.993127],
              [-120.640517, 41.993124],
              [-120.639193, 41.993119],
              [-120.63774, 41.993113],
              [-120.636519, 41.993109],
              [-120.635018, 41.993103],
              [-120.634618, 41.993101],
              [-120.633661, 41.993095],
              [-120.633356, 41.993096],
              [-120.631905, 41.993091],
              [-120.630485, 41.993085],
              [-120.63021, 41.993084],
              [-120.629064, 41.99308],
              [-120.628751, 41.993078],
              [-120.628542, 41.993078],
              [-120.62805, 41.993076],
              [-120.627716, 41.993074],
              [-120.627346, 41.993073],
              [-120.626976, 41.993071],
              [-120.626662, 41.99307],
              [-120.626385, 41.993069],
              [-120.625881, 41.993067],
              [-120.625613, 41.993066],
              [-120.625436, 41.993065],
              [-120.625106, 41.993064],
              [-120.624255, 41.99306],
              [-120.622009, 41.993052],
              [-120.621291, 41.993049],
              [-120.620947, 41.993048],
              [-120.619876, 41.993044],
              [-120.619722, 41.993043],
              [-120.616234, 41.993029],
              [-120.616171, 41.993029],
              [-120.61589, 41.993029],
              [-120.606497, 41.992992],
              [-120.606074, 41.99299],
              [-120.605566, 41.992988],
              [-120.605279, 41.992987],
              [-120.604467, 41.992984],
              [-120.604248, 41.992983],
              [-120.603942, 41.992982],
              [-120.603582, 41.99298],
              [-120.603195, 41.992979],
              [-120.603175, 41.992979],
              [-120.602978, 41.992978],
              [-120.592246, 41.992972],
              [-120.591913, 41.992972],
              [-120.591537, 41.992971],
              [-120.591043, 41.992971],
              [-120.59024, 41.992971],
              [-120.588531, 41.99297],
              [-120.587901, 41.992969],
              [-120.586589, 41.992968],
              [-120.584492, 41.992967],
              [-120.583377, 41.992971],
              [-120.581235, 41.993004],
              [-120.578655, 41.993046],
              [-120.577348, 41.993067],
              [-120.573418, 41.993126],
              [-120.572382, 41.993146],
              [-120.57034, 41.993174],
              [-120.569975, 41.99318],
              [-120.569171, 41.993189],
              [-120.567044, 41.993207],
              [-120.564863, 41.993225],
              [-120.560702, 41.993254],
              [-120.558554, 41.993269],
              [-120.552734, 41.99331],
              [-120.550725, 41.993323],
              [-120.545915, 41.993352],
              [-120.542491, 41.99337],
              [-120.537464, 41.993423],
              [-120.53596, 41.99341],
              [-120.532958, 41.993427],
              [-120.532543, 41.993423],
              [-120.522244, 41.993506],
              [-120.520514, 41.993525],
              [-120.517794, 41.993546],
              [-120.510902, 41.993612],
              [-120.510541, 41.993616],
              [-120.510225, 41.993615],
              [-120.508435, 41.993622],
              [-120.505692, 41.993619],
              [-120.492522, 41.993615],
              [-120.491219, 41.993626],
              [-120.491137, 41.993626],
              [-120.489013, 41.993613],
              [-120.487934, 41.993613],
              [-120.451548, 41.993379],
              [-120.450445, 41.993372],
              [-120.413985, 41.993141],
              [-120.408328, 41.993105],
              [-120.401182, 41.99306],
              [-120.39528, 41.993022],
              [-120.390818, 41.992994],
              [-120.38265, 41.992942],
              [-120.378026, 41.992913],
              [-120.367866, 41.992848],
              [-120.356358, 41.992775],
              [-120.337192, 41.992653],
              [-120.328539, 41.992594],
              [-120.328382, 41.992597],
              [-120.326387, 41.992586],
              [-120.326015, 41.993143],
              [-120.325751, 41.993143],
              [-120.323874, 41.993145],
              [-120.321093, 41.993134],
              [-120.318416, 41.993146],
              [-120.318377, 41.993147],
              [-120.31758, 41.993151],
              [-120.313966, 41.993122],
              [-120.31005, 41.993172],
              [-120.308619, 41.993159],
              [-120.307309, 41.993135],
              [-120.306943, 41.993134],
              [-120.306679, 41.993126],
              [-120.304039, 41.993049],
              [-120.301643, 41.993009],
              [-120.299839, 41.993011],
              [-120.299757, 41.993015],
              [-120.298075, 41.992998],
              [-120.297514, 41.992993],
              [-120.296506, 41.992985],
              [-120.294546, 41.993012],
              [-120.294412, 41.993008],
              [-120.293712, 41.993021],
              [-120.293579, 41.993024],
              [-120.292927, 41.993036],
              [-120.292747, 41.993049],
              [-120.292081, 41.993035],
              [-120.291374, 41.993004],
              [-120.290645, 41.993011],
              [-120.289198, 41.993028],
              [-120.287268, 41.993048],
              [-120.286424, 41.993058],
              [-120.284045, 41.992771],
              [-120.282126, 41.992817],
              [-120.280661, 41.992856],
              [-120.279158, 41.992896],
              [-120.278873, 41.992903],
              [-120.276921, 41.99288],
              [-120.272094, 41.992866],
              [-120.270534, 41.992881],
              [-120.263282, 41.992917],
              [-120.262564, 41.992922],
              [-120.257626, 41.992945],
              [-120.256988, 41.992949],
              [-120.256499, 41.992953],
              [-120.255625, 41.992982],
              [-120.254382, 41.993027],
              [-120.253482, 41.99305],
              [-120.249996, 41.99317],
              [-120.247998, 41.993234],
              [-120.247306, 41.993259],
              [-120.246536, 41.993284],
              [-120.245179, 41.993329],
              [-120.244581, 41.993349],
              [-120.244434, 41.993353],
              [-120.241988, 41.993435],
              [-120.241589, 41.993447],
              [-120.240907, 41.99347],
              [-120.23986, 41.993503],
              [-120.239237, 41.993515],
              [-120.23834, 41.993533],
              [-120.237245, 41.993554],
              [-120.23682, 41.993563],
              [-120.235578, 41.993587],
              [-120.235287, 41.993593],
              [-120.234223, 41.993613],
              [-120.23377, 41.993622],
              [-120.232607, 41.993645],
              [-120.231101, 41.993675],
              [-120.230862, 41.993679],
              [-120.230262, 41.993691],
              [-120.22861, 41.993723],
              [-120.228429, 41.993727],
              [-120.225699, 41.993779],
              [-120.223947, 41.993815],
              [-120.221954, 41.993855],
              [-120.220519, 41.993883],
              [-120.219931, 41.993895],
              [-120.217982, 41.99394],
              [-120.217121, 41.99395],
              [-120.214953, 41.993994],
              [-120.212882, 41.994037],
              [-120.209306, 41.99411],
              [-120.206384, 41.99417],
              [-120.204657, 41.994205],
              [-120.203946, 41.99422],
              [-120.203614, 41.994221],
              [-120.202672, 41.994241],
              [-120.202574, 41.994248],
              [-120.20204, 41.994259],
              [-120.201423, 41.994272],
              [-120.201123, 41.994278],
              [-120.200281, 41.994289],
              [-120.199529, 41.99431],
              [-120.199264, 41.994316],
              [-120.198833, 41.994324],
              [-120.198357, 41.994332],
              [-120.197174, 41.994345],
              [-120.195628, 41.994363],
              [-120.192923, 41.994394],
              [-120.190497, 41.994423],
              [-120.188026, 41.994452],
              [-120.184875, 41.994486],
              [-120.183573, 41.994503],
              [-120.181672, 41.994522],
              [-120.179089, 41.994555],
              [-120.178921, 41.994557],
              [-120.178515, 41.994558],
              [-120.178119, 41.994559],
              [-120.177709, 41.99456],
              [-120.177335, 41.994561],
              [-120.176703, 41.994562],
              [-120.175986, 41.994564],
              [-120.175266, 41.994566],
              [-120.174474, 41.994567],
              [-120.173745, 41.994569],
              [-120.172963, 41.994571],
              [-120.172125, 41.994573],
              [-120.171325, 41.994575],
              [-120.170641, 41.994576],
              [-120.169742, 41.994579],
              [-120.169579, 41.994579],
              [-120.169034, 41.99458],
              [-120.16865, 41.994581],
              [-120.168355, 41.994582],
              [-120.167811, 41.994583],
              [-120.166974, 41.994585],
              [-120.166008, 41.994588],
              [-120.165691, 41.994588],
              [-120.165547, 41.994589],
              [-120.165091, 41.99459],
              [-120.16496, 41.99459],
              [-120.164592, 41.994591],
              [-120.164117, 41.994592],
              [-120.163803, 41.994593],
              [-120.163568, 41.994593],
              [-120.163358, 41.994594],
              [-120.16306, 41.994594],
              [-120.162879, 41.994595],
              [-120.162611, 41.994595],
              [-120.162447, 41.994596],
              [-120.162232, 41.994596],
              [-120.162075, 41.994597],
              [-120.161806, 41.994597],
              [-120.161586, 41.994598],
              [-120.161385, 41.994599],
              [-120.161005, 41.9946],
              [-120.160857, 41.9946],
              [-120.160761, 41.9946],
              [-120.160711, 41.994601],
              [-120.160496, 41.994601],
              [-120.160256, 41.994601],
              [-120.160093, 41.994602],
              [-120.159608, 41.994603],
              [-120.15941, 41.994603],
              [-120.159235, 41.994604],
              [-120.15895, 41.994605],
              [-120.15873, 41.994606],
              [-120.158522, 41.994606],
              [-120.158254, 41.994607],
              [-120.158056, 41.994608],
              [-120.157812, 41.994609],
              [-120.15746, 41.99461],
              [-120.157164, 41.994611],
              [-120.156614, 41.994613],
              [-120.156095, 41.994615],
              [-120.155334, 41.994618],
              [-120.15473, 41.99462],
              [-120.154222, 41.994622],
              [-120.153661, 41.994624],
              [-120.153215, 41.994626],
              [-120.152528, 41.994628],
              [-120.152, 41.99463],
              [-120.151912, 41.99463],
              [-120.151175, 41.994633],
              [-120.149674, 41.994638],
              [-120.148477, 41.994642],
              [-120.147515, 41.994646],
              [-120.14664, 41.994648],
              [-120.145382, 41.994653],
              [-120.144492, 41.994656],
              [-120.142966, 41.994661],
              [-120.142145, 41.994664],
              [-120.140888, 41.994668],
              [-120.140093, 41.994671],
              [-120.138557, 41.994679],
              [-120.137788, 41.994683],
              [-120.136715, 41.994688],
              [-120.136087, 41.994691],
              [-120.13463, 41.994699],
              [-120.133777, 41.994703],
              [-120.133527, 41.994706],
              [-120.132777, 41.994708],
              [-120.132462, 41.99471],
              [-120.131819, 41.994713],
              [-120.131299, 41.994716],
              [-120.130413, 41.99472],
              [-120.129747, 41.994723],
              [-120.128613, 41.994729],
              [-120.127996, 41.994732],
              [-120.1258, 41.994742],
              [-120.124412, 41.994749],
              [-120.123814, 41.994752],
              [-120.122419, 41.994759],
              [-120.120866, 41.994767],
              [-120.11888, 41.994772],
              [-120.117387, 41.994776],
              [-120.116736, 41.994779],
              [-120.116423, 41.994782],
              [-120.116153, 41.994783],
              [-120.115578, 41.994786],
              [-120.114648, 41.994791],
              [-120.113823, 41.994795],
              [-120.11311, 41.994799],
              [-120.112947, 41.994799],
              [-120.112802, 41.994783],
              [-120.112051, 41.994785],
              [-120.111367, 41.994786],
              [-120.110597, 41.994787],
              [-120.109748, 41.994787],
              [-120.108892, 41.99479],
              [-120.108056, 41.994791],
              [-120.106739, 41.994794],
              [-120.106145, 41.994795],
              [-120.104936, 41.994796],
              [-120.104785, 41.994796],
              [-120.103093, 41.994799],
              [-120.101273, 41.994805],
              [-120.100193, 41.994809],
              [-120.099381, 41.99481],
              [-120.098953, 41.994811],
              [-120.098483, 41.994813],
              [-120.098156, 41.994816],
              [-120.097894, 41.994817],
              [-120.097157, 41.994818],
              [-120.096547, 41.994821],
              [-120.095743, 41.994824],
              [-120.094473, 41.994829],
              [-120.093343, 41.994832],
              [-120.092797, 41.994834],
              [-120.091203, 41.99484],
              [-120.091124, 41.99484],
              [-120.091106, 41.99484],
              [-120.088176, 41.99485],
              [-120.087302, 41.994853],
              [-120.085867, 41.994858],
              [-120.084933, 41.994862],
              [-120.083156, 41.99487],
              [-120.082564, 41.994872],
              [-120.081837, 41.994874],
              [-120.080869, 41.994876],
              [-120.079745, 41.994879],
              [-120.078882, 41.994882],
              [-120.077561, 41.994888],
              [-120.076803, 41.994892],
              [-120.075565, 41.994899],
              [-120.074839, 41.994898],
              [-120.073981, 41.994896],
              [-120.073338, 41.994902],
              [-120.072635, 41.99491],
              [-120.072445, 41.994912],
              [-120.071893, 41.994917],
              [-120.071546, 41.994923],
              [-120.070727, 41.99493],
              [-120.069935, 41.994924],
              [-120.069424, 41.994919],
              [-120.068288, 41.994915],
              [-120.067224, 41.994917],
              [-120.066882, 41.994918],
              [-120.066137, 41.994919],
              [-120.065359, 41.994921],
              [-120.064803, 41.994922],
              [-120.063612, 41.994924],
              [-120.06325, 41.994925],
              [-120.061796, 41.994928],
              [-120.060341, 41.99493],
              [-120.059969, 41.99493],
              [-120.059772, 41.994931],
              [-120.058489, 41.994932],
              [-120.056665, 41.994935],
              [-120.056442, 41.994936],
              [-120.056246, 41.994937],
              [-120.056054, 41.994936],
              [-120.055284, 41.994938],
              [-120.054247, 41.994939],
              [-120.052919, 41.994942],
              [-120.051564, 41.994945],
              [-120.049355, 41.994949],
              [-120.047659, 41.994953],
              [-120.047534, 41.994953],
              [-120.046983, 41.994954],
              [-120.046746, 41.994955],
              [-120.046415, 41.994955],
              [-120.045975, 41.994956],
              [-120.045043, 41.994959],
              [-120.044848, 41.994959],
              [-120.044304, 41.994959],
              [-120.044191, 41.994959],
              [-120.043391, 41.994961],
              [-120.04287, 41.994962],
              [-120.042817, 41.994962],
              [-120.042738, 41.994963],
              [-120.042453, 41.994965],
              [-120.042148, 41.994964],
              [-120.040825, 41.994968],
              [-120.03699, 41.994974],
              [-120.036, 41.994976],
              [-120.035207, 41.994978],
              [-120.034708, 41.994978],
              [-120.033115, 41.994981],
              [-120.03173, 41.994984],
              [-120.031256, 41.994987],
              [-120.030342, 41.994988],
              [-120.029273, 41.994989],
              [-120.028153, 41.994995],
              [-120.027408, 41.994995],
              [-120.025757, 41.994993],
              [-120.025097, 41.994993],
              [-120.023081, 41.994993],
              [-120.021857, 41.994993],
              [-120.021366, 41.994995],
              [-120.020486, 41.994993],
              [-120.019536, 41.994993],
              [-120.018616, 41.994992],
              [-120.017783, 41.994992],
              [-120.016277, 41.994992],
              [-120.015822, 41.994992],
              [-120.014749, 41.994991],
              [-120.013792, 41.994988],
              [-120.01355, 41.994986],
              [-120.012495, 41.994989],
              [-120.011769, 41.99498],
              [-120.01049, 41.994973],
              [-120.008157, 41.994963],
              [-120.007501, 41.994958],
              [-120.00618, 41.994951],
              [-120.005731, 41.99495],
              [-120.005146, 41.99495],
              [-120.004291, 41.994964],
              [-120.0004, 41.994933],
              [-119.999234, 41.994944],
              [-119.999169, 41.99373],
              [-119.99919, 41.979047],
              [-119.99919, 41.978975],
              [-119.999204, 41.975163],
              [-119.99923, 41.962047],
              [-119.99923, 41.961973],
              [-119.999265, 41.943486],
              [-119.9993, 41.924925],
              [-119.999302, 41.923748],
              [-119.999304, 41.92257],
              [-119.999305, 41.92223],
              [-119.999332, 41.907702],
              [-119.99933, 41.907643],
              [-119.999292, 41.906538],
              [-119.999292, 41.90635],
              [-119.999295, 41.902842],
              [-119.999295, 41.902498],
              [-119.999253, 41.899621],
              [-119.999253, 41.899456],
              [-119.999261, 41.891286],
              [-119.999269, 41.882112],
              [-119.999276, 41.874895],
              [-119.999214, 41.867141],
              [-119.999035, 41.844746],
              [-119.998958, 41.83519],
              [-119.998957, 41.835127],
              [-119.998896, 41.827465],
              [-119.998896, 41.827404],
              [-119.998693, 41.80218],
              [-119.998695, 41.80216],
              [-119.998694, 41.802071],
              [-119.998675, 41.799478],
              [-119.998674, 41.799293],
              [-119.998629, 41.793688],
              [-119.9985, 41.776985],
              [-119.998499, 41.776915],
              [-119.998473, 41.773717],
              [-119.998472, 41.773652],
              [-119.998332, 41.755575],
              [-119.998295, 41.749915],
              [-119.998698, 41.720632],
              [-119.99873, 41.691682],
              [-119.998747, 41.675943],
              [-119.998751, 41.674861],
              [-119.998751, 41.674798],
              [-119.998754, 41.671328],
              [-119.998825, 41.665162],
              [-119.998823, 41.665148],
              [-119.998819, 41.664321],
              [-119.998819, 41.664249],
              [-119.998822, 41.663767],
              [-119.998822, 41.663708],
              [-119.998893, 41.658511],
              [-119.998902, 41.657899],
              [-119.998897, 41.654431],
              [-119.998861, 41.624937],
              [-119.998287, 41.618818],
              [-119.998295, 41.617473],
              [-119.998303, 41.616192],
              [-119.998307, 41.615544],
              [-119.998575, 41.581432],
              [-119.998745, 41.56009],
              [-119.998762, 41.557943],
              [-119.998763, 41.557834],
              [-119.998859, 41.545765],
              [-119.99886, 41.545632],
              [-119.998868, 41.544665],
              [-119.998876, 41.543698],
              [-119.998898, 41.540871],
              [-119.998921, 41.538045],
              [-119.998957, 41.53357],
              [-119.998993, 41.529094],
              [-119.999018, 41.525979],
              [-119.999043, 41.522864],
              [-119.999046, 41.521809],
              [-119.999465, 41.500228],
              [-119.999471, 41.499894],
              [-119.999475, 41.498195],
              [-119.999478, 41.496499],
              [-119.999478, 41.496458],
              [-119.999486, 41.492751],
              [-119.999494, 41.489017],
              [-119.999489, 41.489001],
              [-119.999494, 41.488826],
              [-119.999496, 41.487984],
              [-119.999498, 41.487138],
              [-119.999503, 41.484932],
              [-119.999508, 41.482727],
              [-119.999512, 41.480898],
              [-119.999517, 41.478669],
              [-119.999517, 41.478567],
              [-119.999552, 41.470218],
              [-119.999566, 41.463084],
              [-119.999581, 41.45595],
              [-119.99959, 41.45178],
              [-119.999595, 41.449265],
              [-119.999599, 41.447326],
              [-119.999602, 41.445932],
              [-119.999612, 41.441172],
              [-119.999621, 41.436413],
              [-119.999621, 41.436335],
              [-119.999622, 41.436073],
              [-119.999622, 41.435811],
              [-119.999631, 41.43526],
              [-119.999633, 41.43247],
              [-119.999635, 41.429679],
              [-119.999642, 41.42641],
              [-119.999648, 41.423275],
              [-119.999648, 41.42314],
              [-119.999661, 41.422158],
              [-119.999653, 41.420604],
              [-119.999659, 41.417667],
              [-119.999665, 41.41473],
              [-119.999674, 41.410578],
              [-119.999682, 41.406427],
              [-119.999703, 41.398212],
              [-119.999757, 41.37465],
              [-119.999716, 41.367814],
              [-119.9997, 41.365323],
              [-119.999699, 41.36511],
              [-119.999734, 41.355576],
              [-119.99982, 41.32745],
              [-119.99982, 41.327319],
              [-119.999832, 41.323637],
              [-119.999836, 41.32261],
              [-119.999836, 41.322491],
              [-119.999865, 41.313101],
              [-119.999885, 41.307067],
              [-119.999908, 41.29975],
              [-119.999931, 41.292797],
              [-119.999932, 41.292433],
              [-119.999989, 41.275012],
              [-119.99999, 41.274794],
              [-120.000016, 41.267493],
              [-120.000016, 41.267423],
              [-120.000016, 41.266548],
              [-120.000016, 41.266487],
              [-120.000028, 41.264005],
              [-120.00003, 41.263357],
              [-120.000048, 41.256765],
              [-120.000049, 41.256375],
              [-120.000073, 41.249758],
              [-119.999928, 41.245887],
              [-119.999867, 41.24427],
              [-119.99978, 41.241948],
              [-119.99978, 41.241851],
              [-119.999767, 41.236625],
              [-119.99976, 41.236061],
              [-119.999763, 41.234289],
              [-119.99977, 41.23122],
              [-119.999776, 41.228152],
              [-119.999779, 41.226809],
              [-119.999782, 41.225466],
              [-119.999792, 41.22059],
              [-119.999802, 41.215714],
              [-119.999807, 41.213462],
              [-119.999812, 41.21121],
              [-119.999814, 41.210496],
              [-119.999815, 41.209782],
              [-119.999818, 41.208543],
              [-119.99982, 41.207304],
              [-119.99982, 41.207014],
              [-119.999821, 41.206723],
              [-119.999821, 41.206701],
              [-119.999823, 41.205872],
              [-119.999825, 41.205021],
              [-119.999831, 41.201992],
              [-119.999832, 41.201508],
              [-119.999846, 41.194573],
              [-119.999848, 41.193648],
              [-119.999864, 41.184773],
              [-119.999866, 41.183974],
              [-119.999874, 41.176444],
              [-119.999847, 41.173345],
              [-119.999847, 41.173322],
              [-119.999823, 41.170569],
              [-119.999815, 41.165752],
              [-119.999815, 41.165424],
              [-119.999815, 41.165251],
              [-119.999813, 41.164348],
              [-119.99981, 41.162347],
              [-119.999807, 41.162328],
              [-119.999809, 41.162308],
              [-119.999809, 41.162269],
              [-119.999799, 41.155921],
              [-119.999867, 41.144572],
              [-119.999867, 41.14451],
              [-119.999893, 41.140235],
              [-119.999895, 41.139893],
              [-119.999898, 41.139267],
              [-119.999903, 41.138362],
              [-119.999904, 41.138185],
              [-119.999904, 41.13816],
              [-119.999905, 41.138124],
              [-119.999905, 41.138084],
              [-119.999905, 41.138051],
              [-119.999906, 41.137904],
              [-119.999974, 41.126564],
              [-120.000011, 41.124898],
              [-119.99999, 41.122559],
              [-120.0, 41.112886],
              [-120.0, 41.112237],
              [-120.0, 41.112132],
              [-120.0, 41.109747],
              [-120.000008, 41.102462],
              [-120.000008, 41.102317],
              [-120.000039, 41.082999],
              [-119.999942, 41.068505],
              [-119.999959, 41.065821],
              [-119.999875, 41.054041],
              [-119.999888, 41.039538],
              [-119.999801, 41.022211],
              [-119.999802, 41.021951],
              [-119.999802, 41.021836],
              [-119.99981, 41.019971],
              [-119.99981, 41.019914],
              [-119.999817, 41.018392],
              [-119.999832, 41.014758],
              [-119.999844, 41.011925],
              [-119.999844, 41.011915],
              [-119.999862, 41.007514],
              [-119.999815, 41.006819],
              [-119.99982, 41.006763],
              [-119.999829, 41.005639],
              [-119.999829, 41.005584],
              [-119.999875, 40.999894],
              [-119.999682, 40.963991],
              [-119.999663, 40.959903],
              [-119.999667, 40.959889],
              [-119.99964, 40.952243],
              [-119.999593, 40.94916],
              [-119.999586, 40.948701],
              [-119.999505, 40.930287],
              [-119.999444, 40.920794],
              [-119.999421, 40.91716],
              [-119.999418, 40.916761],
              [-119.999405, 40.915627],
              [-119.999415, 40.915613],
              [-119.999413, 40.914993],
              [-119.999411, 40.914415],
              [-119.999409, 40.913844],
              [-119.999409, 40.913699],
              [-119.999409, 40.913676],
              [-119.999371, 40.901339],
              [-119.999312, 40.889001],
              [-119.999288, 40.88387],
              [-119.999251, 40.875931],
              [-119.999252, 40.8759],
              [-119.99926, 40.875629],
              [-119.999267, 40.875384],
              [-119.999282, 40.874898],
              [-119.999284, 40.873966],
              [-119.999285, 40.873751],
              [-119.999285, 40.873635],
              [-119.999286, 40.873159],
              [-119.999286, 40.873101],
              [-119.999295, 40.873084],
              [-119.999288, 40.872363],
              [-119.999281, 40.871466],
              [-119.99928, 40.87137],
              [-119.999279, 40.871232],
              [-119.999279, 40.871206],
              [-119.999278, 40.871077],
              [-119.999263, 40.869339],
              [-119.999247, 40.867448],
              [-119.999234, 40.865894],
              [-119.999234, 40.865878],
              [-119.999217, 40.863799],
              [-119.999167, 40.85786],
              [-119.998903, 40.83209],
              [-119.998903, 40.83198],
              [-119.998902, 40.831811],
              [-119.998901, 40.831529],
              [-119.998901, 40.831367],
              [-119.998873, 40.821786],
              [-119.998815, 40.814381],
              [-119.998758, 40.80704],
              [-119.998757, 40.806929],
              [-119.998755, 40.80674],
              [-119.998755, 40.806728],
              [-119.998741, 40.804874],
              [-119.99869, 40.798342],
              [-119.998689, 40.798257],
              [-119.998673, 40.796212],
              [-119.998672, 40.796119],
              [-119.998657, 40.794156],
              [-119.998676, 40.788503],
              [-119.998719, 40.782735],
              [-119.998743, 40.779521],
              [-119.998772, 40.775541],
              [-119.998785, 40.77385],
              [-119.998762, 40.771596],
              [-119.998634, 40.759233],
              [-119.998502, 40.751444],
              [-119.998486, 40.750472],
              [-119.998479, 40.749899],
              [-119.998409, 40.744323],
              [-119.998387, 40.742686],
              [-119.998098, 40.721257],
              [-119.998095, 40.72104],
              [-119.998091, 40.720702],
              [-119.998088, 40.720488],
              [-119.997847, 40.702633],
              [-119.997844, 40.702416],
              [-119.997838, 40.701952],
              [-119.997837, 40.70189],
              [-119.99759, 40.683547],
              [-119.99748, 40.673185],
              [-119.997478, 40.67304],
              [-119.997466, 40.671913],
              [-119.997426, 40.671423],
              [-119.997477, 40.671422],
              [-119.997235, 40.657369],
              [-119.997238, 40.656902],
              [-119.997062, 40.642908],
              [-119.997073, 40.64232],
              [-119.996991, 40.63634],
              [-119.996903, 40.629928],
              [-119.996882, 40.628386],
              [-119.996857, 40.627853],
              [-119.996848, 40.627271],
              [-119.996833, 40.626293],
              [-119.996833, 40.626271],
              [-119.996827, 40.625897],
              [-119.996821, 40.625516],
              [-119.996817, 40.625209],
              [-119.996816, 40.625141],
              [-119.996812, 40.624905],
              [-119.996811, 40.624764],
              [-119.996793, 40.620753],
              [-119.996772, 40.616317],
              [-119.996765, 40.61464],
              [-119.996763, 40.614237],
              [-119.996761, 40.613909],
              [-119.996741, 40.613342],
              [-119.996739, 40.613132],
              [-119.996737, 40.612808],
              [-119.996726, 40.61128],
              [-119.996723, 40.610778],
              [-119.996644, 40.599447],
              [-119.9966, 40.598743],
              [-119.99626, 40.585004],
              [-119.996231, 40.584284],
              [-119.996171, 40.581045],
              [-119.996167, 40.580826],
              [-119.995976, 40.57055],
              [-119.995923, 40.556644],
              [-119.995922, 40.556511],
              [-119.99592, 40.556068],
              [-119.99592, 40.556049],
              [-119.995919, 40.555884],
              [-119.995908, 40.55431],
              [-119.995908, 40.554255],
              [-119.995897, 40.552808],
              [-119.995896, 40.552651],
              [-119.995872, 40.549192],
              [-119.995871, 40.549074],
              [-119.99582, 40.541628],
              [-119.995811, 40.535639],
              [-119.99581, 40.535255],
              [-119.995798, 40.526928],
              [-119.995806, 40.522923],
              [-119.995806, 40.522716],
              [-119.99583, 40.510725],
              [-119.995784, 40.499903],
              [-119.995796, 40.496248],
              [-119.995687, 40.481752],
              [-119.995678, 40.470159],
              [-119.995664, 40.452902],
              [-119.995632, 40.445741],
              [-119.995631, 40.445571],
              [-119.995603, 40.439405],
              [-119.995598, 40.438366],
              [-119.995625, 40.428877],
              [-119.995627, 40.428275],
              [-119.995639, 40.424195],
              [-119.99564, 40.420405],
              [-119.99564, 40.420204],
              [-119.995641, 40.4186],
              [-119.995641, 40.416525],
              [-119.995641, 40.416445],
              [-119.995645, 40.406268],
              [-119.995645, 40.404945],
              [-119.995646, 40.404718],
              [-119.995652, 40.40326],
              [-119.995655, 40.402408],
              [-119.995655, 40.402322],
              [-119.995656, 40.402189],
              [-119.995656, 40.402071],
              [-119.995656, 40.401995],
              [-119.995658, 40.401459],
              [-119.995663, 40.400204],
              [-119.995674, 40.397562],
              [-119.995675, 40.397194],
              [-119.995687, 40.394336],
              [-119.995702, 40.390414],
              [-119.995703, 40.384845],
              [-119.995705, 40.37583],
              [-119.995734, 40.374015],
              [-119.99576, 40.372427],
              [-119.99576, 40.372416],
              [-119.995923, 40.362199],
              [-119.995936, 40.361416],
              [-119.995953, 40.360547],
              [-119.995955, 40.360453],
              [-119.99596, 40.360155],
              [-119.996194, 40.347955],
              [-119.996212, 40.346999],
              [-119.996332, 40.339603],
              [-119.996336, 40.339365],
              [-119.996339, 40.339208],
              [-119.996339, 40.339194],
              [-119.996445, 40.332641],
              [-119.996491, 40.325272],
              [-119.996492, 40.325175],
              [-119.996498, 40.324162],
              [-119.996499, 40.324079],
              [-119.99651, 40.322244],
              [-119.996513, 40.32184],
              [-119.996517, 40.321252],
              [-119.996517, 40.321227],
              [-119.996517, 40.321146],
              [-119.996518, 40.320963],
              [-119.996526, 40.319792],
              [-119.996533, 40.31854],
              [-119.996536, 40.318131],
              [-119.996523, 40.315976],
              [-119.996522, 40.315832],
              [-119.996516, 40.314929],
              [-119.996515, 40.314743],
              [-119.996512, 40.314324],
              [-119.996508, 40.313671],
              [-119.996505, 40.313208],
              [-119.996504, 40.313055],
              [-119.996504, 40.312985],
              [-119.996381, 40.293172],
              [-119.996303, 40.28069],
              [-119.996302, 40.280545],
              [-119.9963, 40.280138],
              [-119.9963, 40.280076],
              [-119.996284, 40.277565],
              [-119.996265, 40.274521],
              [-119.996259, 40.264247],
              [-119.996259, 40.264117],
              [-119.996258, 40.263616],
              [-119.996258, 40.263495],
              [-119.996258, 40.262471],
              [-119.996258, 40.262429],
              [-119.996256, 40.260347],
              [-119.996256, 40.259969],
              [-119.996257, 40.25979],
              [-119.996259, 40.259331],
              [-119.996259, 40.259298],
              [-119.99626, 40.259278],
              [-119.99626, 40.25926],
              [-119.99626, 40.259209],
              [-119.996262, 40.258747],
              [-119.996262, 40.258228],
              [-119.996262, 40.2581],
              [-119.996265, 40.249906],
              [-119.996284, 40.245463],
              [-119.996284, 40.245449],
              [-119.996286, 40.245317],
              [-119.996332, 40.244268],
              [-119.99646, 40.230856],
              [-119.996451, 40.229681],
              [-119.996527, 40.219],
              [-119.996528, 40.21892],
              [-119.996567, 40.213522],
              [-119.996611, 40.207432],
              [-119.99662, 40.206145],
              [-119.996659, 40.200694],
              [-119.996666, 40.200393],
              [-119.996923, 40.188728],
              [-119.996951, 40.187449],
              [-119.997006, 40.186235],
              [-119.996995, 40.183567],
              [-119.996994, 40.183286],
              [-119.996944, 40.17158],
              [-119.996967, 40.159223],
              [-119.996968, 40.158842],
              [-119.996968, 40.158584],
              [-119.997019, 40.157158],
              [-119.997018, 40.15602],
              [-119.997016, 40.154492],
              [-119.997016, 40.154331],
              [-119.997015, 40.153427],
              [-119.997015, 40.153277],
              [-119.997002, 40.142579],
              [-119.997096, 40.12944],
              [-119.99717, 40.126322],
              [-119.997164, 40.12608],
              [-119.997137, 40.124904],
              [-119.997151, 40.120032],
              [-119.997152, 40.119786],
              [-119.997189, 40.116695],
              [-119.997215, 40.114498],
              [-119.997229, 40.113301],
              [-119.997251, 40.111338],
              [-119.997256, 40.110909],
              [-119.997285, 40.107567],
              [-119.997304, 40.10572],
              [-119.997304, 40.105495],
              [-119.997302, 40.105412],
              [-119.997304, 40.105316],
              [-119.997304, 40.105264],
              [-119.997309, 40.105062],
              [-119.99731, 40.104633],
              [-119.997312, 40.104427],
              [-119.997316, 40.103958],
              [-119.997317, 40.103794],
              [-119.997352, 40.099991],
              [-119.997344, 40.096363],
              [-119.997334, 40.092729],
              [-119.997333, 40.092114],
              [-119.997333, 40.091788],
              [-119.997332, 40.091564],
              [-119.99733, 40.090826],
              [-119.997329, 40.090513],
              [-119.997329, 40.090499],
              [-119.997326, 40.08934],
              [-119.997326, 40.089242],
              [-119.997321, 40.086958],
              [-119.997318, 40.085973],
              [-119.997318, 40.085781],
              [-119.997321, 40.084523],
              [-119.997323, 40.083907],
              [-119.997323, 40.083779],
              [-119.997328, 40.081805],
              [-119.997328, 40.081742],
              [-119.997319, 40.080166],
              [-119.997314, 40.079227],
              [-119.99731, 40.078499],
              [-119.99731, 40.078412],
              [-119.997307, 40.077756],
              [-119.997304, 40.077266],
              [-119.997304, 40.077208],
              [-119.997292, 40.074777],
              [-119.997278, 40.072095],
              [-119.997277, 40.071767],
              [-119.997276, 40.071637],
              [-119.997273, 40.071039],
              [-119.997234, 40.070142],
              [-119.99722, 40.069812],
              [-119.997308, 40.060254],
              [-119.997332, 40.057585],
              [-119.997328, 40.057569],
              [-119.997332, 40.057538],
              [-119.997346, 40.056075],
              [-119.997368, 40.052035],
              [-119.997369, 40.051893],
              [-119.997409, 40.044423],
              [-119.997409, 40.044372],
              [-119.997425, 40.041561],
              [-119.99742, 40.039203],
              [-119.997477, 40.032017],
              [-119.997534, 40.024951],
              [-119.997546, 40.022867],
              [-119.997619, 40.010653],
              [-119.997706, 39.999905],
              [-119.9978, 39.991211],
              [-119.997871, 39.984616],
              [-119.997875, 39.984262],
              [-119.997903, 39.981636],
              [-119.997908, 39.981409],
              [-119.997914, 39.981128],
              [-119.998056, 39.974041],
              [-119.998095, 39.972117],
              [-119.998107, 39.971502],
              [-119.998134, 39.970172],
              [-119.998136, 39.97006],
              [-119.998137, 39.970014],
              [-119.998138, 39.96999],
              [-119.998138, 39.969979],
              [-119.998177, 39.968035],
              [-119.998183, 39.967762],
              [-119.998189, 39.967473],
              [-119.998212, 39.966391],
              [-119.998248, 39.964653],
              [-119.998261, 39.964011],
              [-119.998263, 39.963903],
              [-119.998277, 39.96321],
              [-119.998279, 39.963105],
              [-119.998286, 39.962812],
              [-119.998298, 39.962234],
              [-119.998317, 39.961307],
              [-119.99833, 39.960677],
              [-119.998334, 39.960455],
              [-119.99837, 39.958749],
              [-119.998375, 39.958499],
              [-119.998445, 39.95511],
              [-119.998457, 39.954538],
              [-119.998498, 39.952568],
              [-119.998626, 39.938032],
              [-119.998653, 39.936166],
              [-119.998658, 39.935814],
              [-119.998658, 39.935789],
              [-119.998661, 39.935553],
              [-119.998679, 39.934335],
              [-119.998687, 39.93378],
              [-119.998696, 39.933132],
              [-119.998706, 39.932444],
              [-119.998714, 39.931881],
              [-119.998721, 39.931377],
              [-119.998729, 39.930844],
              [-119.998735, 39.930425],
              [-119.99882, 39.924461],
              [-119.998823, 39.924255],
              [-119.998833, 39.923559],
              [-119.999081, 39.906156],
              [-119.999084, 39.904257],
              [-119.999084, 39.904143],
              [-119.999085, 39.903968],
              [-119.999085, 39.903912],
              [-119.999085, 39.903817],
              [-119.999086, 39.903104],
              [-119.999099, 39.895434],
              [-119.999099, 39.895338],
              [-119.9991, 39.894773],
              [-119.999104, 39.893889],
              [-119.999104, 39.89383],
              [-119.999126, 39.887714],
              [-119.999127, 39.887384],
              [-119.999174, 39.874913],
              [-119.999461, 39.865535],
              [-119.999598, 39.857692],
              [-119.999603, 39.857399],
              [-119.999692, 39.852032],
              [-119.999716, 39.85103],
              [-119.999721, 39.850757],
              [-119.999744, 39.849386],
              [-119.999776, 39.847453],
              [-119.999843, 39.84342],
              [-119.999941, 39.83758],
              [-119.999907, 39.836513],
              [-120.000022, 39.823287],
              [-120.000028, 39.822084],
              [-120.000069, 39.820194],
              [-120.000092, 39.81913],
              [-120.000099, 39.818765],
              [-120.00017, 39.81525],
              [-120.000306, 39.808523],
              [-120.000308, 39.808396],
              [-120.000454, 39.799434],
              [-120.000455, 39.799344],
              [-120.000475, 39.798135],
              [-120.000476, 39.798081],
              [-120.000489, 39.797269],
              [-120.000491, 39.795709],
              [-120.000491, 39.795673],
              [-120.000495, 39.791706],
              [-120.000495, 39.791669],
              [-120.000495, 39.791448],
              [-120.000496, 39.790936],
              [-120.000503, 39.782914],
              [-120.000518, 39.782083],
              [-120.000528, 39.781525],
              [-120.000538, 39.780945],
              [-120.000538, 39.780788],
              [-120.00054, 39.779955],
              [-120.000548, 39.775223],
              [-120.000651, 39.77082],
              [-120.000677, 39.769372],
              [-120.000683, 39.768725],
              [-120.000702, 39.767508],
              [-120.000723, 39.766161],
              [-120.000805, 39.759846],
              [-120.000859, 39.758584],
              [-120.000891, 39.757838],
              [-120.001118, 39.752577],
              [-120.001133, 39.752229],
              [-120.001033, 39.742096],
              [-120.001032, 39.739739],
              [-120.001006, 39.735416],
              [-120.001006, 39.735405],
              [-120.001013, 39.734189],
              [-120.001014, 39.734012],
              [-120.001018, 39.732608],
              [-120.001062, 39.722418],
              [-120.000979, 39.714348],
              [-120.000959, 39.680613],
              [-120.000942, 39.679169],
              [-120.000903, 39.675144],
              [-120.000905, 39.674339],
              [-120.000906, 39.674268],
              [-120.000908, 39.674048],
              [-120.000912, 39.673642],
              [-120.000915, 39.673332],
              [-120.000918, 39.673],
              [-120.000922, 39.672585],
              [-120.000925, 39.672255],
              [-120.000934, 39.672002],
              [-120.000942, 39.671798],
              [-120.001006, 39.669819],
              [-120.001049, 39.66702],
              [-120.00105, 39.666961],
              [-120.001079, 39.665037],
              [-120.001084, 39.664743],
              [-120.00109, 39.664583],
              [-120.001089, 39.663228],
              [-120.00109, 39.662987],
              [-120.001152, 39.651253],
              [-120.001157, 39.650294],
              [-120.001151, 39.644804],
              [-120.001151, 39.644787],
              [-120.001151, 39.644726],
              [-120.001149, 39.643964],
              [-120.001149, 39.643751],
              [-120.001149, 39.643476],
              [-120.001149, 39.643372],
              [-120.00114, 39.636389],
              [-120.00114, 39.636182],
              [-120.00114, 39.635887],
              [-120.00114, 39.635795],
              [-120.001135, 39.635329],
              [-120.001067, 39.628536],
              [-120.001059, 39.627715],
              [-120.001058, 39.627634],
              [-120.00103, 39.624889],
              [-120.001027, 39.624551],
              [-120.000994, 39.621224],
              [-120.000871, 39.61109],
              [-120.00087, 39.610981],
              [-120.00082, 39.606828],
              [-120.00083, 39.606411],
              [-120.000834, 39.606244],
              [-120.000835, 39.606199],
              [-120.000835, 39.606183],
              [-120.00084, 39.605966],
              [-120.000843, 39.605854],
              [-120.000854, 39.605376],
              [-120.001185, 39.591253],
              [-120.001188, 39.591113],
              [-120.001195, 39.59081],
              [-120.001198, 39.590693],
              [-120.00121, 39.590161],
              [-120.001476, 39.578856],
              [-120.001477, 39.578827],
              [-120.0015, 39.577816],
              [-120.0015, 39.577356],
              [-120.001502, 39.575444],
              [-120.001504, 39.572666],
              [-120.001505, 39.57057],
              [-120.001581, 39.566811],
              [-120.001585, 39.56661],
              [-120.001587, 39.566531],
              [-120.001597, 39.566042],
              [-120.001599, 39.565922],
              [-120.001644, 39.563694],
              [-120.001947, 39.548724],
              [-120.001901, 39.545552],
              [-120.001847, 39.541785],
              [-120.001826, 39.540356],
              [-120.001826, 39.540338],
              [-120.001822, 39.540115],
              [-120.001806, 39.539007],
              [-120.001789, 39.537815],
              [-120.001774, 39.536772],
              [-120.00174, 39.534444],
              [-120.001736, 39.530583],
              [-120.001761, 39.529186],
              [-120.001764, 39.529027],
              [-120.001772, 39.52855],
              [-120.001789, 39.527642],
              [-120.00179, 39.527564],
              [-120.001793, 39.527391],
              [-120.001823, 39.525693],
              [-120.001841, 39.524678],
              [-120.001859, 39.523514],
              [-120.001863, 39.523275],
              [-120.001878, 39.522402],
              [-120.0019, 39.521064],
              [-120.001918, 39.520095],
              [-120.001921, 39.519959],
              [-120.001926, 39.519702],
              [-120.001936, 39.519201],
              [-120.002017, 39.514991],
              [-120.002018, 39.514956],
              [-120.002061, 39.51268],
              [-120.002035, 39.512183],
              [-120.002039, 39.51189],
              [-120.002082, 39.509099],
              [-120.002103, 39.508643],
              [-120.002114, 39.508412],
              [-120.002129, 39.507483],
              [-120.002149, 39.506161],
              [-120.00215, 39.506093],
              [-120.00216, 39.50548],
              [-120.00215, 39.50542],
              [-120.002139, 39.505357],
              [-120.002139, 39.505333],
              [-120.002154, 39.504444],
              [-120.002155, 39.504362],
              [-120.002181, 39.502882],
              [-120.002187, 39.502567],
              [-120.0022, 39.501832],
              [-120.002201, 39.501774],
              [-120.002207, 39.501433],
              [-120.002208, 39.501353],
              [-120.00222, 39.500645],
              [-120.002289, 39.496373],
              [-120.002291, 39.496279],
              [-120.002299, 39.495799],
              [-120.002303, 39.495515],
              [-120.002317, 39.494657],
              [-120.002338, 39.493404],
              [-120.002375, 39.491087],
              [-120.002487, 39.487393],
              [-120.002496, 39.487086],
              [-120.002579, 39.484331],
              [-120.00259, 39.483962],
              [-120.002819, 39.476404],
              [-120.002826, 39.474604],
              [-120.002827, 39.474381],
              [-120.002833, 39.472717],
              [-120.002836, 39.472507],
              [-120.002837, 39.472446],
              [-120.002887, 39.468894],
              [-120.002882, 39.468873],
              [-120.002888, 39.468169],
              [-120.002889, 39.468041],
              [-120.00289, 39.467956],
              [-120.002893, 39.467581],
              [-120.002898, 39.466598],
              [-120.002898, 39.466509],
              [-120.002898, 39.466462],
              [-120.002899, 39.466295],
              [-120.0029, 39.466039],
              [-120.002901, 39.46592],
              [-120.002903, 39.465635],
              [-120.002905, 39.465181],
              [-120.002906, 39.465008],
              [-120.002908, 39.464631],
              [-120.002909, 39.464402],
              [-120.003022, 39.463468],
              [-120.003056, 39.463185],
              [-120.003216, 39.461857],
              [-120.003383, 39.456849],
              [-120.00339, 39.456625],
              [-120.003773, 39.445056],
              [-120.003778, 39.44489],
              [-120.003794, 39.444395],
              [-120.0038, 39.44423],
              [-120.00394, 39.440057],
              [-120.004081, 39.435856],
              [-120.004247, 39.430769],
              [-120.004354, 39.427539],
              [-120.004468, 39.424104],
              [-120.004492, 39.423367],
              [-120.004562, 39.421239],
              [-120.004692, 39.417353],
              [-120.004774, 39.414855],
              [-120.004845, 39.412728],
              [-120.004928, 39.410197],
              [-120.005181, 39.402606],
              [-120.005265, 39.400076],
              [-120.00534, 39.397792],
              [-120.005358, 39.39727],
              [-120.005472, 39.393844],
              [-120.005567, 39.390943],
              [-120.005626, 39.389184],
              [-120.005643, 39.38866],
              [-120.005694, 39.387108],
              [-120.005848, 39.382454],
              [-120.005864, 39.381993],
              [-120.005901, 39.380903],
              [-120.005901, 39.38089],
              [-120.005902, 39.380851],
              [-120.005903, 39.380839],
              [-120.00591, 39.380603],
              [-120.005934, 39.379896],
              [-120.005942, 39.379661],
              [-120.005968, 39.37886],
              [-120.006048, 39.376458],
              [-120.006075, 39.375658],
              [-120.006077, 39.375572],
              [-120.006085, 39.375315],
              [-120.006089, 39.37523],
              [-120.006127, 39.374106],
              [-120.00614, 39.370179],
              [-120.006192, 39.355024],
              [-120.00621, 39.349973],
              [-120.006212, 39.349106],
              [-120.00622, 39.346369],
              [-120.006237, 39.341498],
              [-120.006258, 39.335557],
              [-120.006271, 39.331954],
              [-120.006274, 39.330953],
              [-120.006276, 39.330683],
              [-120.006284, 39.32795],
              [-120.006288, 39.326949],
              [-120.006294, 39.324858],
              [-120.006316, 39.318586],
              [-120.006323, 39.316496],
              [-120.006323, 39.31636],
              [-120.006325, 39.315954],
              [-120.006326, 39.315819],
              [-120.006326, 39.315582],
              [-120.006329, 39.314875],
              [-120.00633, 39.314639],
              [-120.00633, 39.31458],
              [-120.00633, 39.314405],
              [-120.006331, 39.314347],
              [-120.006331, 39.31426],
              [-120.006331, 39.314001],
              [-120.006332, 39.313915],
              [-120.006332, 39.313829],
              [-120.006333, 39.313573],
              [-120.006334, 39.313488],
              [-120.006334, 39.313387],
              [-120.006335, 39.313087],
              [-120.006336, 39.312987],
              [-120.00635, 39.308751],
              [-120.006393, 39.296042],
              [-120.006408, 39.291807],
              [-120.006408, 39.291676],
              [-120.006409, 39.291285],
              [-120.00641, 39.291155],
              [-120.00641, 39.291035],
              [-120.00641, 39.290674],
              [-120.006411, 39.290555],
              [-120.006416, 39.289501],
              [-120.006419, 39.288609],
              [-120.006439, 39.282773],
              [-120.006446, 39.280828],
              [-120.006446, 39.280577],
              [-120.006448, 39.279824],
              [-120.00645, 39.279574],
              [-120.006452, 39.278897],
              [-120.006456, 39.277729],
              [-120.006473, 39.272878],
              [-120.006455, 39.272194],
              [-120.006408, 39.27035],
              [-120.006293, 39.265901],
              [-120.006103, 39.258491],
              [-120.005929, 39.252557],
              [-120.005799, 39.24811],
              [-120.005788, 39.247746],
              [-120.005756, 39.246655],
              [-120.005746, 39.246292],
              [-120.005686, 39.244263],
              [-120.00568, 39.244038],
              [-120.005679, 39.236249],
              [-120.005693, 39.233234],
              [-120.005698, 39.229772],
              [-120.005668, 39.22936],
              [-120.005629, 39.228857],
              [-120.005611, 39.228335],
              [-120.005598, 39.227987],
              [-120.005577, 39.227426],
              [-120.005722, 39.226973],
              [-120.005724, 39.226446],
              [-120.005726, 39.22604],
              [-120.005727, 39.225828],
              [-120.00571, 39.225226],
              [-120.005634, 39.22261],
              [-120.00561, 39.221754],
              [-120.005563, 39.220022],
              [-120.005563, 39.218695],
              [-120.005541, 39.218029],
              [-120.005484, 39.216307],
              [-120.00453, 39.187445],
              [-120.004429, 39.177981],
              [-120.004297, 39.165603],
              [-120.004024, 39.148764],
              [-120.004021, 39.148478],
              [-120.003941, 39.141319],
              [-120.003834, 39.131627],
              [-120.003741, 39.12491],
              [-120.003611, 39.119844],
              [-120.003429, 39.112687],
              [-120.003283, 39.105886],
              [-120.003076, 39.096244],
              [-120.002845, 39.085486],
              [-120.002724, 39.079801],
              [-120.002712, 39.079244],
              [-120.0027, 39.078686],
              [-120.002652, 39.076446],
              [-120.002508, 39.069728],
              [-120.002461, 39.067489],
              [-120.002446, 39.066798],
              [-120.002401, 39.064727],
              [-120.002387, 39.064037],
              [-120.002267, 39.058463],
              [-120.002168, 39.053784],
              [-120.001948, 39.043531],
              [-120.001909, 39.041742],
              [-120.001869, 39.03985],
              [-120.00179, 39.036169],
              [-120.001694, 39.031706],
              [-120.001568, 39.025791],
              [-120.001407, 39.018317],
              [-120.001345, 39.015413],
              [-120.001328, 39.014634],
              [-120.001312, 39.013855],
              [-120.001283, 39.012526],
              [-120.001199, 39.008541],
              [-120.001171, 39.007213],
              [-120.001139, 39.005751],
              [-120.001045, 39.001369],
              [-120.001014, 38.999908],
              [-120.001014, 38.999841],
              [-120.001014, 38.99964],
              [-120.001014, 38.999574],
              [-119.999205, 38.998331],
              [-119.99378, 38.994603],
              [-119.991972, 38.993361],
              [-119.988916, 38.99126],
              [-119.979748, 38.98496],
              [-119.976692, 38.98286],
              [-119.974801, 38.981561],
              [-119.969131, 38.977664],
              [-119.967241, 38.976365],
              [-119.966988, 38.976191],
              [-119.966231, 38.975671],
              [-119.965979, 38.975498],
              [-119.965836, 38.975399],
              [-119.965407, 38.975104],
              [-119.965264, 38.975006],
              [-119.965124, 38.974909],
              [-119.964983, 38.974813],
              [-119.962479, 38.973092],
              [-119.959259, 38.970879],
              [-119.954125, 38.96735],
              [-119.953535, 38.966945],
              [-119.952438, 38.966191],
              [-119.95134, 38.965437],
              [-119.951, 38.965203],
              [-119.94998, 38.964503],
              [-119.949641, 38.96427],
              [-119.948912, 38.963799],
              [-119.946726, 38.962386],
              [-119.945998, 38.961916],
              [-119.945929, 38.96187],
              [-119.945215, 38.961408],
              [-119.945021, 38.961283],
              [-119.943227, 38.96008],
              [-119.942897, 38.959841],
              [-119.942143, 38.959294],
              [-119.942042, 38.959224],
              [-119.941851, 38.959089],
              [-119.940975, 38.958475],
              [-119.94056, 38.958183],
              [-119.94019, 38.957923],
              [-119.940067, 38.957837],
              [-119.940025, 38.957807],
              [-119.939899, 38.957719],
              [-119.939857, 38.95769],
              [-119.939636, 38.957535],
              [-119.938976, 38.957072],
              [-119.938795, 38.956945],
              [-119.938756, 38.956919],
              [-119.938718, 38.956893],
              [-119.938604, 38.956816],
              [-119.938566, 38.956791],
              [-119.938139, 38.956503],
              [-119.93686, 38.955642],
              [-119.936434, 38.955355],
              [-119.93579, 38.954926],
              [-119.933861, 38.953642],
              [-119.933218, 38.953214],
              [-119.933106, 38.953139],
              [-119.932943, 38.953031],
              [-119.932121, 38.952483],
              [-119.931847, 38.952301],
              [-119.928395, 38.949922],
              [-119.918041, 38.942785],
              [-119.91459, 38.940406],
              [-119.912785, 38.939162],
              [-119.907373, 38.935432],
              [-119.905569, 38.934189],
              [-119.905318, 38.934016],
              [-119.904565, 38.933497],
              [-119.904315, 38.933324],
              [-119.903626, 38.932849],
              [-119.901561, 38.931426],
              [-119.900873, 38.930952],
              [-119.900817, 38.930913],
              [-119.900651, 38.930798],
              [-119.900596, 38.93076],
              [-119.900391, 38.930617],
              [-119.899776, 38.930191],
              [-119.899572, 38.930049],
              [-119.897106, 38.928336],
              [-119.88971, 38.923199],
              [-119.887245, 38.921487],
              [-119.877821, 38.914995],
              [-119.877773, 38.914962],
              [-119.876011, 38.913748],
              [-119.870204, 38.909776],
              [-119.864501, 38.905874],
              [-119.864489, 38.905866],
              [-119.857493, 38.90106],
              [-119.857455, 38.901034],
              [-119.851986, 38.897278],
              [-119.850592, 38.896335],
              [-119.85057, 38.89632],
              [-119.849684, 38.89572],
              [-119.847026, 38.893921],
              [-119.846275, 38.893413],
              [-119.846141, 38.893322],
              [-119.84567, 38.893003],
              [-119.84426, 38.892049],
              [-119.84379, 38.891731],
              [-119.843001, 38.891197],
              [-119.840637, 38.889597],
              [-119.839939, 38.889125],
              [-119.839849, 38.889064],
              [-119.839547, 38.888859],
              [-119.838285, 38.888006],
              [-119.835142, 38.885879],
              [-119.833638, 38.884861],
              [-119.833596, 38.884833],
              [-119.832445, 38.884053],
              [-119.832004, 38.88374],
              [-119.831968, 38.883715],
              [-119.831187, 38.883161],
              [-119.830371, 38.882584],
              [-119.822084, 38.876686],
              [-119.821061, 38.875957],
              [-119.820958, 38.875894],
              [-119.816709, 38.873019],
              [-119.8142, 38.871325],
              [-119.813523, 38.870861],
              [-119.810544, 38.868823],
              [-119.803525, 38.86402],
              [-119.802597, 38.863376],
              [-119.797453, 38.859836],
              [-119.797368, 38.859776],
              [-119.790942, 38.85535],
              [-119.790794, 38.855248],
              [-119.78675, 38.852456],
              [-119.78669, 38.852415],
              [-119.779129, 38.847196],
              [-119.779027, 38.847126],
              [-119.769668, 38.840661],
              [-119.764833, 38.837323],
              [-119.764631, 38.837183],
              [-119.763551, 38.836437],
              [-119.760503, 38.834334],
              [-119.760408, 38.834268],
              [-119.757618, 38.832341],
              [-119.757429, 38.83221],
              [-119.756736, 38.831731],
              [-119.754657, 38.830297],
              [-119.753965, 38.829819],
              [-119.753903, 38.829776],
              [-119.751007, 38.827778],
              [-119.750031, 38.827066],
              [-119.749977, 38.827029],
              [-119.744356, 38.82318],
              [-119.74405, 38.822968],
              [-119.741908, 38.821486],
              [-119.729324, 38.81279],
              [-119.726793, 38.81104],
              [-119.725904, 38.810425],
              [-119.703814, 38.795158],
              [-119.695561, 38.789453],
              [-119.695505, 38.789415],
              [-119.695468, 38.789389],
              [-119.695458, 38.789382],
              [-119.695382, 38.78933],
              [-119.695346, 38.789305],
              [-119.695291, 38.789267],
              [-119.691765, 38.786827],
              [-119.691435, 38.786601],
              [-119.691009, 38.786307],
              [-119.689231, 38.785078],
              [-119.683897, 38.781391],
              [-119.68212, 38.780163],
              [-119.681479, 38.77972],
              [-119.679558, 38.778392],
              [-119.678918, 38.77795],
              [-119.67855, 38.777695],
              [-119.677446, 38.776932],
              [-119.677078, 38.776678],
              [-119.675068, 38.775288],
              [-119.669038, 38.771121],
              [-119.667029, 38.769733],
              [-119.666967, 38.76969],
              [-119.666781, 38.769561],
              [-119.66672, 38.769519],
              [-119.666626, 38.769454],
              [-119.666018, 38.769034],
              [-119.663914, 38.76758],
              [-119.663213, 38.767096],
              [-119.661077, 38.765595],
              [-119.656579, 38.762436],
              [-119.642113, 38.752497],
              [-119.638339, 38.749904],
              [-119.635047, 38.747355],
              [-119.634292, 38.74677],
              [-119.634239, 38.746729],
              [-119.633586, 38.74627],
              [-119.631464, 38.744779],
              [-119.630757, 38.744282],
              [-119.629778, 38.743594],
              [-119.626842, 38.74153],
              [-119.626004, 38.740941],
              [-119.625863, 38.740845],
              [-119.62461, 38.739993],
              [-119.621866, 38.738126],
              [-119.620854, 38.737437],
              [-119.619602, 38.736586],
              [-119.613199, 38.73223],
              [-119.60937, 38.729625],
              [-119.600273, 38.72338],
              [-119.594301, 38.71928],
              [-119.594037, 38.719099],
              [-119.590212, 38.716473],
              [-119.587647, 38.714727],
              [-119.587508, 38.714695],
              [-119.587474, 38.714688],
              [-119.587422, 38.714644],
              [-119.587174, 38.714436],
              [-119.587066, 38.714345],
              [-119.585437, 38.713212],
              [-119.558899, 38.694761],
              [-119.558726, 38.694641],
              [-119.552579, 38.690353],
              [-119.551992, 38.689944],
              [-119.551635, 38.6897],
              [-119.550104, 38.68865],
              [-119.548604, 38.687623],
              [-119.548528, 38.68757],
              [-119.548167, 38.687323],
              [-119.548089, 38.68727],
              [-119.547933, 38.687163],
              [-119.547767, 38.687049],
              [-119.547659, 38.686975],
              [-119.5476, 38.686934],
              [-119.54738, 38.686785],
              [-119.546586, 38.686241],
              [-119.546293, 38.686039],
              [-119.542712, 38.683587],
              [-119.540864, 38.682321],
              [-119.530647, 38.675321],
              [-119.530623, 38.675305],
              [-119.51181, 38.662415],
              [-119.511529, 38.662222],
              [-119.51147, 38.662182],
              [-119.501, 38.654374],
              [-119.500922, 38.654322],
              [-119.495035, 38.650417],
              [-119.494958, 38.650366],
              [-119.494571, 38.650133],
              [-119.494176, 38.649862],
              [-119.494069, 38.649758],
              [-119.49402, 38.649735],
              [-119.49347, 38.649327],
              [-119.492565, 38.648768],
              [-119.492537, 38.64875],
              [-119.491747, 38.648226],
              [-119.491567, 38.648107],
              [-119.488058, 38.645685],
              [-119.477569, 38.63837],
              [-119.477257, 38.638152],
              [-119.473687, 38.635668],
              [-119.47224, 38.634663],
              [-119.464356, 38.629519],
              [-119.464305, 38.629486],
              [-119.464027, 38.629304],
              [-119.463984, 38.629276],
              [-119.461282, 38.627533],
              [-119.459231, 38.626157],
              [-119.459131, 38.626089],
              [-119.457405, 38.62491],
              [-119.453823, 38.622433],
              [-119.453783, 38.622405],
              [-119.453769, 38.622395],
              [-119.450634, 38.619981],
              [-119.450655, 38.619968],
              [-119.448611, 38.618534],
              [-119.435064, 38.609037],
              [-119.433791, 38.6082],
              [-119.428445, 38.60435],
              [-119.428134, 38.604126],
              [-119.42789, 38.60395],
              [-119.427302, 38.603526],
              [-119.40139, 38.584871],
              [-119.401217, 38.584746],
              [-119.399335, 38.583453],
              [-119.398943, 38.583171],
              [-119.387962, 38.575301],
              [-119.379543, 38.569302],
              [-119.379403, 38.569205],
              [-119.379123, 38.569012],
              [-119.37862, 38.568663],
              [-119.375994, 38.566793],
              [-119.3703, 38.563391],
              [-119.370117, 38.563281],
              [-119.360341, 38.5567],
              [-119.360265, 38.556648],
              [-119.360167, 38.556583],
              [-119.357007, 38.554456],
              [-119.355044, 38.553114],
              [-119.354915, 38.553025],
              [-119.354565, 38.552787],
              [-119.333423, 38.538328],
              [-119.330005, 38.535928],
              [-119.329948, 38.535887],
              [-119.329092, 38.535283],
              [-119.328398, 38.534792],
              [-119.308778, 38.52094],
              [-119.307813, 38.520258],
              [-119.297704, 38.513122],
              [-119.279552, 38.500313],
              [-119.279262, 38.499914],
              [-119.267655, 38.492059],
              [-119.264244, 38.48975],
              [-119.263905, 38.489519],
              [-119.263837, 38.48948],
              [-119.263828, 38.489467],
              [-119.250988, 38.48078],
              [-119.250377, 38.480382],
              [-119.249809, 38.479968],
              [-119.24946, 38.479711],
              [-119.234964, 38.468996],
              [-119.23455, 38.468708],
              [-119.234117, 38.468405],
              [-119.233805, 38.468189],
              [-119.232184, 38.467061],
              [-119.232164, 38.467048],
              [-119.231623, 38.46667],
              [-119.229727, 38.465351],
              [-119.212811, 38.45358],
              [-119.212796, 38.45357],
              [-119.212703, 38.453506],
              [-119.212375, 38.453307],
              [-119.211355, 38.452599],
              [-119.207638, 38.450013],
              [-119.207018, 38.449581],
              [-119.205034, 38.448199],
              [-119.205016, 38.448186],
              [-119.204017, 38.44749],
              [-119.203986, 38.447468],
              [-119.203488, 38.44712],
              [-119.20347, 38.447107],
              [-119.195294, 38.441416],
              [-119.195256, 38.44139],
              [-119.181957, 38.432132],
              [-119.18173, 38.431975],
              [-119.161477, 38.417878],
              [-119.161087, 38.417606],
              [-119.160205, 38.416992],
              [-119.160074, 38.416901],
              [-119.156978, 38.414746],
              [-119.152264, 38.411468],
              [-119.141551, 38.404008],
              [-119.141537, 38.403998],
              [-119.128199, 38.394713],
              [-119.1281, 38.394644],
              [-119.127878, 38.394489],
              [-119.127421, 38.394171],
              [-119.126717, 38.393681],
              [-119.126678, 38.393654],
              [-119.125982, 38.39317],
              [-119.124487, 38.391901],
              [-119.124282, 38.391727],
              [-119.100246, 38.374916],
              [-119.097159, 38.372851],
              [-119.0968, 38.372578],
              [-119.088114, 38.366005],
              [-119.082438, 38.361332],
              [-119.082358, 38.361267],
              [-119.074268, 38.355685],
              [-119.074222, 38.355653],
              [-119.067278, 38.350871],
              [-119.057199, 38.343914],
              [-119.04349, 38.33445],
              [-119.031743, 38.326331],
              [-119.031721, 38.326316],
              [-119.031706, 38.326306],
              [-119.0306, 38.325541],
              [-119.030459, 38.325444],
              [-119.03035, 38.325369],
              [-119.03034, 38.325362],
              [-119.030327, 38.325353],
              [-119.030299, 38.325334],
              [-119.029972, 38.325108],
              [-119.029872, 38.325039],
              [-119.029813, 38.324998],
              [-119.029783, 38.324978],
              [-119.029767, 38.324962],
              [-119.029757, 38.324951],
              [-119.029601, 38.324788],
              [-119.029427, 38.324605],
              [-119.028752, 38.324041],
              [-119.028691, 38.324047],
              [-119.028271, 38.323736],
              [-119.028156, 38.323651],
              [-119.028069, 38.323587],
              [-119.013689, 38.312972],
              [-119.011901, 38.311652],
              [-119.011215, 38.311146],
              [-119.011142, 38.311092],
              [-119.011089, 38.311053],
              [-119.011077, 38.311044],
              [-119.010712, 38.310775],
              [-119.005462, 38.3069],
              [-119.005296, 38.30678],
              [-119.000975, 38.303675],
              [-118.94971, 38.268965],
              [-118.949673, 38.26894],
              [-118.949356, 38.268718],
              [-118.94918, 38.268595],
              [-118.945339, 38.265904],
              [-118.945024, 38.265683],
              [-118.944979, 38.265652],
              [-118.944968, 38.265644],
              [-118.93302, 38.257273],
              [-118.922518, 38.249919],
              [-118.915214, 38.244651],
              [-118.910346, 38.241142],
              [-118.901297, 38.234662],
              [-118.901014, 38.234459],
              [-118.895501, 38.230512],
              [-118.890629, 38.227025],
              [-118.890157, 38.226687],
              [-118.890148, 38.22668],
              [-118.890134, 38.22667],
              [-118.890118, 38.226659],
              [-118.890044, 38.226606],
              [-118.88809, 38.225207],
              [-118.887932, 38.225094],
              [-118.875873, 38.216456],
              [-118.862017, 38.206859],
              [-118.86045, 38.205773],
              [-118.859294, 38.204957],
              [-118.859084, 38.204809],
              [-118.858963, 38.204722],
              [-118.856435, 38.202905],
              [-118.856312, 38.202817],
              [-118.856132, 38.202687],
              [-118.854824, 38.201746],
              [-118.854649, 38.20162],
              [-118.836921, 38.188853],
              [-118.83626, 38.188377],
              [-118.836014, 38.1882],
              [-118.835336, 38.187711],
              [-118.816022, 38.173802],
              [-118.815812, 38.173651],
              [-118.797714, 38.160617],
              [-118.797624, 38.160551],
              [-118.791032, 38.155773],
              [-118.784441, 38.150995],
              [-118.783178, 38.15008],
              [-118.782319, 38.149457],
              [-118.782276, 38.149426],
              [-118.782254, 38.14941],
              [-118.782244, 38.149403],
              [-118.782187, 38.149362],
              [-118.782011, 38.149234],
              [-118.781915, 38.149164],
              [-118.781838, 38.149108],
              [-118.781045, 38.148532],
              [-118.777551, 38.145996],
              [-118.777363, 38.14586],
              [-118.771867, 38.141871],
              [-118.771822, 38.141841],
              [-118.752225, 38.128773],
              [-118.746598, 38.124926],
              [-118.743988, 38.123085],
              [-118.741378, 38.121244],
              [-118.739216, 38.119721],
              [-118.737053, 38.118198],
              [-118.734192, 38.116178],
              [-118.731332, 38.114157],
              [-118.730801, 38.113783],
              [-118.73031, 38.113436],
              [-118.729819, 38.11309],
              [-118.72318, 38.10842],
              [-118.716541, 38.10375],
              [-118.716523, 38.103737],
              [-118.715372, 38.102928],
              [-118.714257, 38.102144],
              [-118.714201, 38.102105],
              [-118.711962, 38.100475],
              [-118.711759, 38.100327],
              [-118.68636, 38.081835],
              [-118.685938, 38.081565],
              [-118.668197, 38.068594],
              [-118.666217, 38.067146],
              [-118.664237, 38.065699],
              [-118.652355, 38.057019],
              [-118.648324, 38.054079],
              [-118.648304, 38.054064],
              [-118.648223, 38.054005],
              [-118.648194, 38.053984],
              [-118.64814, 38.053945],
              [-118.647914, 38.05378],
              [-118.647472, 38.053457],
              [-118.645092, 38.05172],
              [-118.645081, 38.051712],
              [-118.640929, 38.04868],
              [-118.636432, 38.045396],
              [-118.625963, 38.03774],
              [-118.621676, 38.034438],
              [-118.575339, 38.002277],
              [-118.575175, 38.002163],
              [-118.575123, 38.002127],
              [-118.57511, 38.002118],
              [-118.575101, 38.002112],
              [-118.575078, 38.002096],
              [-118.575068, 38.002089],
              [-118.575003, 38.002044],
              [-118.573934, 38.001302],
              [-118.571958, 37.99993],
              [-118.554727, 37.987572],
              [-118.554104, 37.987125],
              [-118.553481, 37.986678],
              [-118.550118, 37.984266],
              [-118.546755, 37.981854],
              [-118.546091, 37.981379],
              [-118.544914, 37.980537],
              [-118.543073, 37.97922],
              [-118.541494, 37.97809],
              [-118.539916, 37.976961],
              [-118.536849, 37.974763],
              [-118.533782, 37.972565],
              [-118.532094, 37.971356],
              [-118.530418, 37.97016],
              [-118.530407, 37.970147],
              [-118.527736, 37.968232],
              [-118.525065, 37.966318],
              [-118.522327, 37.964341],
              [-118.522283, 37.964309],
              [-118.522235, 37.964274],
              [-118.517223, 37.960654],
              [-118.517184, 37.960626],
              [-118.516301, 37.959874],
              [-118.500964, 37.949023],
              [-118.496043, 37.945146],
              [-118.495588, 37.944819],
              [-118.492573, 37.942651],
              [-118.48906, 37.940119],
              [-118.486821, 37.938505],
              [-118.486418, 37.938215],
              [-118.482203, 37.935174],
              [-118.482186, 37.935162],
              [-118.482171, 37.935151],
              [-118.482159, 37.935142],
              [-118.482145, 37.935132],
              [-118.475406, 37.93027],
              [-118.473681, 37.929033],
              [-118.473595, 37.928971],
              [-118.47074, 37.926925],
              [-118.4621, 37.920695],
              [-118.461969, 37.920604],
              [-118.461838, 37.920512],
              [-118.46158, 37.920332],
              [-118.461333, 37.920162],
              [-118.461322, 37.920152],
              [-118.45206, 37.913453],
              [-118.452049, 37.913445],
              [-118.45203, 37.913431],
              [-118.45198, 37.913395],
              [-118.451923, 37.913354],
              [-118.451714, 37.913203],
              [-118.450654, 37.912437],
              [-118.444066, 37.907691],
              [-118.439126, 37.904133],
              [-118.437263, 37.902776],
              [-118.435178, 37.901289],
              [-118.433929, 37.900391],
              [-118.428144, 37.89622],
              [-118.406571, 37.880678],
              [-118.406534, 37.880651],
              [-118.404002, 37.878778],
              [-118.403866, 37.878682],
              [-118.398531, 37.874883],
              [-118.380195, 37.86172],
              [-118.377525, 37.859803],
              [-118.375936, 37.858663],
              [-118.372572, 37.856012],
              [-118.366868, 37.851912],
              [-118.361161, 37.84781],
              [-118.359163, 37.846376],
              [-118.357165, 37.844941],
              [-118.353878, 37.842578],
              [-118.35059, 37.840216],
              [-118.344572, 37.835889],
              [-118.338547, 37.831562],
              [-118.334578, 37.82871],
              [-118.332204, 37.827005],
              [-118.32861, 37.824423],
              [-118.327656, 37.823739],
              [-118.318569, 37.817258],
              [-118.313684, 37.813743],
              [-118.313354, 37.813506],
              [-118.313089, 37.813315],
              [-118.310375, 37.811362],
              [-118.310349, 37.811343],
              [-118.31, 37.811092],
              [-118.301589, 37.80504],
              [-118.296738, 37.801553],
              [-118.296509, 37.801389],
              [-118.296305, 37.801243],
              [-118.295717, 37.800812],
              [-118.295389, 37.800583],
              [-118.290501, 37.797068],
              [-118.289292, 37.796198],
              [-118.285061, 37.793156],
              [-118.284777, 37.792953],
              [-118.28395, 37.792358],
              [-118.278814, 37.788663],
              [-118.278607, 37.788514],
              [-118.278099, 37.788148],
              [-118.278084, 37.788137],
              [-118.278068, 37.788126],
              [-118.278051, 37.788114],
              [-118.278035, 37.788102],
              [-118.278004, 37.78808],
              [-118.274528, 37.785578],
              [-118.273625, 37.784928],
              [-118.273399, 37.784765],
              [-118.271219, 37.783196],
              [-118.271212, 37.783181],
              [-118.271166, 37.78316],
              [-118.269766, 37.782152],
              [-118.262635, 37.777021],
              [-118.259887, 37.775045],
              [-118.257139, 37.773069],
              [-118.256823, 37.772842],
              [-118.256507, 37.772615],
              [-118.253581, 37.770512],
              [-118.251756, 37.769198],
              [-118.250956, 37.768622],
              [-118.248203, 37.766574],
              [-118.244972, 37.76417],
              [-118.244765, 37.764019],
              [-118.244709, 37.76398],
              [-118.244693, 37.763969],
              [-118.244681, 37.76396],
              [-118.244642, 37.763931],
              [-118.244098, 37.763537],
              [-118.243491, 37.763096],
              [-118.243212, 37.762883],
              [-118.243004, 37.762743],
              [-118.242955, 37.762707],
              [-118.242686, 37.762512],
              [-118.242372, 37.762284],
              [-118.241897, 37.761939],
              [-118.239546, 37.760233],
              [-118.239227, 37.760002],
              [-118.238031, 37.759134],
              [-118.233762, 37.756037],
              [-118.233692, 37.755987],
              [-118.233452, 37.755813],
              [-118.232825, 37.755358],
              [-118.232638, 37.755222],
              [-118.229828, 37.753183],
              [-118.229785, 37.75315],
              [-118.229718, 37.753085],
              [-118.229687, 37.753056],
              [-118.22964, 37.753012],
              [-118.225439, 37.749993],
              [-118.21361, 37.741565],
              [-118.213507, 37.741491],
              [-118.211262, 37.739866],
              [-118.208628, 37.737956],
              [-118.201177, 37.732558],
              [-118.201078, 37.732486],
              [-118.193225, 37.726766],
              [-118.192478, 37.726223],
              [-118.192155, 37.725989],
              [-118.191719, 37.725689],
              [-118.191708, 37.725681],
              [-118.191344, 37.725402],
              [-118.189427, 37.72403],
              [-118.189398, 37.724009],
              [-118.187917, 37.72292],
              [-118.186619, 37.721981],
              [-118.185407, 37.721103],
              [-118.185344, 37.721058],
              [-118.185206, 37.720957],
              [-118.184698, 37.720547],
              [-118.184494, 37.7204],
              [-118.183162, 37.719436],
              [-118.18242, 37.718899],
              [-118.180345, 37.717398],
              [-118.18016, 37.717445],
              [-118.179081, 37.716578],
              [-118.177906, 37.715635],
              [-118.177844, 37.715622],
              [-118.177714, 37.715568],
              [-118.177478, 37.715489],
              [-118.175788, 37.714188],
              [-118.173939, 37.712766],
              [-118.173754, 37.71263],
              [-118.167913, 37.708406],
              [-118.165784, 37.706867],
              [-118.164056, 37.705618],
              [-118.164031, 37.7056],
              [-118.16402, 37.705592],
              [-118.163011, 37.704863],
              [-118.162902, 37.704784],
              [-118.162375, 37.704403],
              [-118.161291, 37.703619],
              [-118.161167, 37.703531],
              [-118.160543, 37.703079],
              [-118.159985, 37.702675],
              [-118.159493, 37.702319],
              [-118.157663, 37.700994],
              [-118.157105, 37.70059],
              [-118.153977, 37.698329],
              [-118.151391, 37.696459],
              [-118.150848, 37.696076],
              [-118.150733, 37.695983],
              [-118.138247, 37.686981],
              [-118.137917, 37.686741],
              [-118.134545, 37.684296],
              [-118.133555, 37.683543],
              [-118.131302, 37.681943],
              [-118.13117, 37.681849],
              [-118.130672, 37.681487],
              [-118.130179, 37.68114],
              [-118.130173, 37.681125],
              [-118.130141, 37.681102],
              [-118.12939, 37.680555],
              [-118.129378, 37.680547],
              [-118.129356, 37.680531],
              [-118.128271, 37.679744],
              [-118.125849, 37.677986],
              [-118.123467, 37.676269],
              [-118.123442, 37.676251],
              [-118.119592, 37.673477],
              [-118.113499, 37.669081],
              [-118.107407, 37.664687],
              [-118.10725, 37.664574],
              [-118.101943, 37.660752],
              [-118.101879, 37.660706],
              [-118.101046, 37.660109],
              [-118.099183, 37.658766],
              [-118.097317, 37.657422],
              [-118.097278, 37.657394],
              [-118.089429, 37.651743],
              [-118.089348, 37.651686],
              [-118.083689, 37.64761],
              [-118.083548, 37.647508],
              [-118.083216, 37.647269],
              [-118.083191, 37.647251],
              [-118.082223, 37.646554],
              [-118.082178, 37.64652],
              [-118.080722, 37.645472],
              [-118.076851, 37.642684],
              [-118.074575, 37.641047],
              [-118.073516, 37.640285],
              [-118.072457, 37.639523],
              [-118.072213, 37.639347],
              [-118.072024, 37.639211],
              [-118.07199, 37.639186],
              [-118.070836, 37.638356],
              [-118.06974, 37.637565],
              [-118.069205, 37.63718],
              [-118.068092, 37.636379],
              [-118.067823, 37.636187],
              [-118.067243, 37.635769],
              [-118.066896, 37.635519],
              [-118.066805, 37.635453],
              [-118.064883, 37.634069],
              [-118.063714, 37.633227],
              [-118.063115, 37.632796],
              [-118.059925, 37.630499],
              [-118.05886, 37.629732],
              [-118.058789, 37.629681],
              [-118.054943, 37.626913],
              [-118.054721, 37.626753],
              [-118.05461, 37.626673],
              [-118.054308, 37.626456],
              [-118.054254, 37.626417],
              [-118.053248, 37.625693],
              [-118.052189, 37.62493],
              [-118.051598, 37.624474],
              [-118.051358, 37.62429],
              [-118.051347, 37.624282],
              [-118.051006, 37.624021],
              [-118.050119, 37.623337],
              [-118.049719, 37.62303],
              [-118.048408, 37.622005],
              [-118.047099, 37.620981],
              [-118.045752, 37.619928],
              [-118.044424, 37.61887],
              [-118.044415, 37.618863],
              [-118.044164, 37.618686],
              [-118.043925, 37.618501],
              [-118.041903, 37.616919],
              [-118.041332, 37.616473],
              [-118.040332, 37.615691],
              [-118.040302, 37.615667],
              [-118.040264, 37.615636],
              [-118.040218, 37.615599],
              [-118.039963, 37.615389],
              [-118.039912, 37.615347],
              [-118.039894, 37.615332],
              [-118.037481, 37.613538],
              [-118.034762, 37.611577],
              [-118.03324, 37.610479],
              [-118.033112, 37.610387],
              [-118.032656, 37.610058],
              [-118.032041, 37.609614],
              [-118.031897, 37.60951],
              [-118.0286, 37.607124],
              [-118.028037, 37.606716],
              [-118.02455, 37.604194],
              [-118.024512, 37.604165],
              [-118.024045, 37.603809],
              [-118.023652, 37.603543],
              [-118.023286, 37.603276],
              [-118.019533, 37.600561],
              [-118.019522, 37.600553],
              [-118.019508, 37.600542],
              [-118.019487, 37.600527],
              [-118.019466, 37.600512],
              [-118.019409, 37.600471],
              [-118.0192, 37.60032],
              [-118.016265, 37.598196],
              [-118.015126, 37.59737],
              [-118.014192, 37.596697],
              [-118.013262, 37.596022],
              [-118.012466, 37.595446],
              [-118.01242, 37.595412],
              [-118.010417, 37.593963],
              [-118.009875, 37.593571],
              [-118.009331, 37.593179],
              [-118.009082, 37.592997],
              [-118.008829, 37.592815],
              [-118.008108, 37.592292],
              [-118.007388, 37.59177],
              [-118.00733, 37.591731],
              [-118.004272, 37.589649],
              [-118.001158, 37.587529],
              [-118.000962, 37.587396],
              [-118.000099, 37.586776],
              [-117.997967, 37.585242],
              [-117.995837, 37.583709],
              [-117.995572, 37.583518],
              [-117.992672, 37.581272],
              [-117.992655, 37.581259],
              [-117.991758, 37.580564],
              [-117.99172, 37.580535],
              [-117.991614, 37.580454],
              [-117.98899, 37.57852],
              [-117.987908, 37.577723],
              [-117.986954, 37.57702],
              [-117.986827, 37.576933],
              [-117.983391, 37.574616],
              [-117.98311, 37.574435],
              [-117.983035, 37.574387],
              [-117.979542, 37.572141],
              [-117.976643, 37.569949],
              [-117.975955, 37.569429],
              [-117.975776, 37.569293],
              [-117.975439, 37.569039],
              [-117.942725, 37.544386],
              [-117.942666, 37.544342],
              [-117.937025, 37.540077],
              [-117.935976, 37.539313],
              [-117.931446, 37.536],
              [-117.923791, 37.530259],
              [-117.919702, 37.527193],
              [-117.915611, 37.524125],
              [-117.913501, 37.522541],
              [-117.913304, 37.522393],
              [-117.913106, 37.522245],
              [-117.908041, 37.518406],
              [-117.904625, 37.515836],
              [-117.878323, 37.498764],
              [-117.875927, 37.497267],
              [-117.871407, 37.493884],
              [-117.866828, 37.490456],
              [-117.866811, 37.490445],
              [-117.866504, 37.490214],
              [-117.866144, 37.489946],
              [-117.858695, 37.484369],
              [-117.858241, 37.484029],
              [-117.856198, 37.482499],
              [-117.852708, 37.479887],
              [-117.852696, 37.479878],
              [-117.852675, 37.479862],
              [-117.851301, 37.478834],
              [-117.846251, 37.475054],
              [-117.846229, 37.475037],
              [-117.846194, 37.47501],
              [-117.844003, 37.473371],
              [-117.843989, 37.473361],
              [-117.843725, 37.473163],
              [-117.839157, 37.469742],
              [-117.838513, 37.469261],
              [-117.83846, 37.469222],
              [-117.837499, 37.468503],
              [-117.832726, 37.464929],
              [-117.83149, 37.464004],
              [-117.831475, 37.463993],
              [-117.827575, 37.461075],
              [-117.827533, 37.461044],
              [-117.827217, 37.460807],
              [-117.823332, 37.457898],
              [-117.821989, 37.456893],
              [-117.816764, 37.452982],
              [-117.814635, 37.451387],
              [-117.808475, 37.446776],
              [-117.805781, 37.44476],
              [-117.803147, 37.442788],
              [-117.802326, 37.442174],
              [-117.802223, 37.442097],
              [-117.800218, 37.440596],
              [-117.800193, 37.440577],
              [-117.80017, 37.440559],
              [-117.799961, 37.440403],
              [-117.799679, 37.440192],
              [-117.799443, 37.440015],
              [-117.795628, 37.437151],
              [-117.795015, 37.436691],
              [-117.787908, 37.431356],
              [-117.786929, 37.430621],
              [-117.77613, 37.422518],
              [-117.77554, 37.422074],
              [-117.774949, 37.421631],
              [-117.774116, 37.421004],
              [-117.773279, 37.420377],
              [-117.77091, 37.418598],
              [-117.770318, 37.418153],
              [-117.770181, 37.41805],
              [-117.770149, 37.418026],
              [-117.770131, 37.418013],
              [-117.770111, 37.417997],
              [-117.768546, 37.41682],
              [-117.767824, 37.41628],
              [-117.766658, 37.415405],
              [-117.766282, 37.415123],
              [-117.764771, 37.41399],
              [-117.760311, 37.410642],
              [-117.755851, 37.407294],
              [-117.754614, 37.406366],
              [-117.753377, 37.405437],
              [-117.753329, 37.405401],
              [-117.75305, 37.405192],
              [-117.752207, 37.40457],
              [-117.752197, 37.404551],
              [-117.750927, 37.403598],
              [-117.748548, 37.402002],
              [-117.748483, 37.401953],
              [-117.739919, 37.395569],
              [-117.739208, 37.395039],
              [-117.739125, 37.394977],
              [-117.739116, 37.39497],
              [-117.739075, 37.394939],
              [-117.738968, 37.394859],
              [-117.738954, 37.394849],
              [-117.738851, 37.394772],
              [-117.734249, 37.391339],
              [-117.731368, 37.389193],
              [-117.729413, 37.387735],
              [-117.728485, 37.387042],
              [-117.725625, 37.384911],
              [-117.721968, 37.382181],
              [-117.719901, 37.380644],
              [-117.716333, 37.377987],
              [-117.713843, 37.376127],
              [-117.713466, 37.375845],
              [-117.713073, 37.375552],
              [-117.712804, 37.375319],
              [-117.712358, 37.374931],
              [-117.709139, 37.372748],
              [-117.70592, 37.370564],
              [-117.704284, 37.369455],
              [-117.702651, 37.368344],
              [-117.702461, 37.368217],
              [-117.702271, 37.36809],
              [-117.698373, 37.365446],
              [-117.694475, 37.362802],
              [-117.69403, 37.3625],
              [-117.693585, 37.362199],
              [-117.69195, 37.36109],
              [-117.690315, 37.359981],
              [-117.685462, 37.35669],
              [-117.68061, 37.353399],
              [-117.680522, 37.353334],
              [-117.680464, 37.35329],
              [-117.680454, 37.353283],
              [-117.680241, 37.353124],
              [-117.680096, 37.353015],
              [-117.672638, 37.347451],
              [-117.672578, 37.347406],
              [-117.671885, 37.34689],
              [-117.669865, 37.345374],
              [-117.669834, 37.345351],
              [-117.669818, 37.345339],
              [-117.669617, 37.345188],
              [-117.669528, 37.34512],
              [-117.65341, 37.332934],
              [-117.647342, 37.32833],
              [-117.634468, 37.318526],
              [-117.625914, 37.312062],
              [-117.625457, 37.31179],
              [-117.610925, 37.300945],
              [-117.609316, 37.299744],
              [-117.606373, 37.297548],
              [-117.581418, 37.278936],
              [-117.552035, 37.257929],
              [-117.551888, 37.257829],
              [-117.551878, 37.257816],
              [-117.551209, 37.257338],
              [-117.544576, 37.252572],
              [-117.540885, 37.249931],
              [-117.536752, 37.246632],
              [-117.53415, 37.244714],
              [-117.534076, 37.244659],
              [-117.527244, 37.239622],
              [-117.512766, 37.229002],
              [-117.506173, 37.224155],
              [-117.500909, 37.220282],
              [-117.500117, 37.22038],
              [-117.498812, 37.219399],
              [-117.496739, 37.217841],
              [-117.496656, 37.217778],
              [-117.49658, 37.217721],
              [-117.496563, 37.217708],
              [-117.496533, 37.217685],
              [-117.478996, 37.204503],
              [-117.476552, 37.202666],
              [-117.4757, 37.202025],
              [-117.474517, 37.201136],
              [-117.474295, 37.200969],
              [-117.460736, 37.190776],
              [-117.454651, 37.186202],
              [-117.448638, 37.181682],
              [-117.444455, 37.178526],
              [-117.43151, 37.16876],
              [-117.417543, 37.158223],
              [-117.405485, 37.149184],
              [-117.386399, 37.134748],
              [-117.375905, 37.126843],
              [-117.373451, 37.124932],
              [-117.372462, 37.124512],
              [-117.371219, 37.123614],
              [-117.357894, 37.113642],
              [-117.343983, 37.103232],
              [-117.322727, 37.087326],
              [-117.31883, 37.084409],
              [-117.307916, 37.076243],
              [-117.286457, 37.060185],
              [-117.286036, 37.059869],
              [-117.280762, 37.055923],
              [-117.266297, 37.045098],
              [-117.266046, 37.04491],
              [-117.250903, 37.03451],
              [-117.250903, 37.034618],
              [-117.244917, 37.030244],
              [-117.240435, 37.02687],
              [-117.226619, 37.016464],
              [-117.205027, 37.000202],
              [-117.204655, 36.999922],
              [-117.204346, 36.999694],
              [-117.202789, 36.998547],
              [-117.201899, 36.997893],
              [-117.201186, 36.997368],
              [-117.200763, 36.997057],
              [-117.200534, 36.996889],
              [-117.199284, 36.995969],
              [-117.197603, 36.994731],
              [-117.19657, 36.993971],
              [-117.196426, 36.993865],
              [-117.196391, 36.993839],
              [-117.196363, 36.993819],
              [-117.19631, 36.99378],
              [-117.195982, 36.993539],
              [-117.194894, 36.992738],
              [-117.194639, 36.99255],
              [-117.192232, 36.990778],
              [-117.191309, 36.990099],
              [-117.191154, 36.989985],
              [-117.191138, 36.989973],
              [-117.19105, 36.989909],
              [-117.190913, 36.989808],
              [-117.190606, 36.989582],
              [-117.187476, 36.987278],
              [-117.186479, 36.98654],
              [-117.184948, 36.985407],
              [-117.182891, 36.983882],
              [-117.173134, 36.976514],
              [-117.16955, 36.973806],
              [-117.169537, 36.973796],
              [-117.169495, 36.973764],
              [-117.169474, 36.973748],
              [-117.169436, 36.97372],
              [-117.169002, 36.973392],
              [-117.166001, 36.971111],
              [-117.151197, 36.959868],
              [-117.144561, 36.955021],
              [-117.144198, 36.954756],
              [-117.13757, 36.949929],
              [-117.133666, 36.947004],
              [-117.133132, 36.946604],
              [-117.131975, 36.945777],
              [-117.131592, 36.945488],
              [-117.128994, 36.943526],
              [-117.128701, 36.943304],
              [-117.125899, 36.941186],
              [-117.120101, 36.936762],
              [-117.110822, 36.929678],
              [-117.105644, 36.925752],
              [-117.100143, 36.921581],
              [-117.098581, 36.920397],
              [-117.09838, 36.920245],
              [-117.0947, 36.917455],
              [-117.0866, 36.911314],
              [-117.068336, 36.897568],
              [-117.064482, 36.894681],
              [-117.056829, 36.888989],
              [-117.037976, 36.874943],
              [-117.025269, 36.865662],
              [-117.024158, 36.86485],
              [-117.0129, 36.856349],
              [-117.006443, 36.851545],
              [-117.004011, 36.849735],
              [-117.000895, 36.847694],
              [-116.999427, 36.846585],
              [-116.999322, 36.846506],
              [-116.99908, 36.846323],
              [-116.999048, 36.846299],
              [-116.998882, 36.846174],
              [-116.998626, 36.84598],
              [-116.998614, 36.845971],
              [-116.998536, 36.845912],
              [-116.998475, 36.845866],
              [-116.995161, 36.843359],
              [-116.994836, 36.843113],
              [-116.987832, 36.83783],
              [-116.987239, 36.837383],
              [-116.987068, 36.837254],
              [-116.975362, 36.828415],
              [-116.975348, 36.828404],
              [-116.975201, 36.828293],
              [-116.968407, 36.823163],
              [-116.958747, 36.815869],
              [-116.958533, 36.815707],
              [-116.957877, 36.815213],
              [-116.953384, 36.811821],
              [-116.952985, 36.81152],
              [-116.95071, 36.809802],
              [-116.950518, 36.809657],
              [-116.95042, 36.809583],
              [-116.950348, 36.809529],
              [-116.950287, 36.809483],
              [-116.95026, 36.809462],
              [-116.950243, 36.809449],
              [-116.950182, 36.809403],
              [-116.950117, 36.809354],
              [-116.942454, 36.803567],
              [-116.929999, 36.794162],
              [-116.929952, 36.794126],
              [-116.929241, 36.793591],
              [-116.927908, 36.792588],
              [-116.89402, 36.766998],
              [-116.889365, 36.763482],
              [-116.880674, 36.75692],
              [-116.875893, 36.753308],
              [-116.874254, 36.752081],
              [-116.873732, 36.751685],
              [-116.872324, 36.750616],
              [-116.8723, 36.750597],
              [-116.872287, 36.750587],
              [-116.872268, 36.750573],
              [-116.872214, 36.750532],
              [-116.872108, 36.750452],
              [-116.871442, 36.749947],
              [-116.866285, 36.746031],
              [-116.863874, 36.7442],
              [-116.858237, 36.739919],
              [-116.856646, 36.738711],
              [-116.849245, 36.733091],
              [-116.848166, 36.732264],
              [-116.847333, 36.731632],
              [-116.846219, 36.730784],
              [-116.845285, 36.730074],
              [-116.845162, 36.72998],
              [-116.845049, 36.729894],
              [-116.843472, 36.728698],
              [-116.84287, 36.72824],
              [-116.838155, 36.724657],
              [-116.83755, 36.724198],
              [-116.837522, 36.724177],
              [-116.837318, 36.724022],
              [-116.836628, 36.723498],
              [-116.830283, 36.718676],
              [-116.82625, 36.715612],
              [-116.82125, 36.711812],
              [-116.820223, 36.711033],
              [-116.818824, 36.709973],
              [-116.811886, 36.7047],
              [-116.81154, 36.704437],
              [-116.797826, 36.694015],
              [-116.792503, 36.689969],
              [-116.791488, 36.689198],
              [-116.787009, 36.685796],
              [-116.786859, 36.685682],
              [-116.786102, 36.685105],
              [-116.77494, 36.676624],
              [-116.762929, 36.667498],
              [-116.761005, 36.666036],
              [-116.760759, 36.665849],
              [-116.760698, 36.665803],
              [-116.760469, 36.665629],
              [-116.760428, 36.665598],
              [-116.7604, 36.665576],
              [-116.760391, 36.665569],
              [-116.76035, 36.665538],
              [-116.75908, 36.664573],
              [-116.753159, 36.660074],
              [-116.753148, 36.660066],
              [-116.750926, 36.658377],
              [-116.730232, 36.642683],
              [-116.727732, 36.640798],
              [-116.718507, 36.633797],
              [-116.711943, 36.628822],
              [-116.710248, 36.627535],
              [-116.706833, 36.624947],
              [-116.706282, 36.624527],
              [-116.691917, 36.613635],
              [-116.689629, 36.611901],
              [-116.678291, 36.603306],
              [-116.661668, 36.590706],
              [-116.658243, 36.588109],
              [-116.657349, 36.587438],
              [-116.656883, 36.587088],
              [-116.625941, 36.563618],
              [-116.618206, 36.557752],
              [-116.607991, 36.550004],
              [-116.596566, 36.541339],
              [-116.595297, 36.540376],
              [-116.595151, 36.540266],
              [-116.593966, 36.539367],
              [-116.59043, 36.536687],
              [-116.585487, 36.532942],
              [-116.560967, 36.514346],
              [-116.559748, 36.513422],
              [-116.544647, 36.501971],
              [-116.543618, 36.501191],
              [-116.541983, 36.499952],
              [-116.534534, 36.494173],
              [-116.529897, 36.490581],
              [-116.529694, 36.490425],
              [-116.523202, 36.48542],
              [-116.523071, 36.485319],
              [-116.523054, 36.485306],
              [-116.522977, 36.485247],
              [-116.522816, 36.485123],
              [-116.522728, 36.485055],
              [-116.522703, 36.485036],
              [-116.52266, 36.485003],
              [-116.522648, 36.484993],
              [-116.522639, 36.484986],
              [-116.522599, 36.484955],
              [-116.522577, 36.484938],
              [-116.522567, 36.484931],
              [-116.519867, 36.482853],
              [-116.500882, 36.468223],
              [-116.488431, 36.45924],
              [-116.488402, 36.459219],
              [-116.488233, 36.459097],
              [-116.473496, 36.447605],
              [-116.472579, 36.446869],
              [-116.472136, 36.446558],
              [-116.445229, 36.425523],
              [-116.445201, 36.425501],
              [-116.442358, 36.423289],
              [-116.439642, 36.421176],
              [-116.422299, 36.407682],
              [-116.419235, 36.405294],
              [-116.41871, 36.404885],
              [-116.418562, 36.404769],
              [-116.418521, 36.404737],
              [-116.417184, 36.403695],
              [-116.416865, 36.403446],
              [-116.415044, 36.402022],
              [-116.415006, 36.401992],
              [-116.41175, 36.399441],
              [-116.404643, 36.393912],
              [-116.404615, 36.39389],
              [-116.380347, 36.374961],
              [-116.377821, 36.373699],
              [-116.3759, 36.372583],
              [-116.375878, 36.372564],
              [-116.375425, 36.372176],
              [-116.375402, 36.372156],
              [-116.375305, 36.372073],
              [-116.375277, 36.372049],
              [-116.375258, 36.372033],
              [-116.375189, 36.371974],
              [-116.375012, 36.371822],
              [-116.374361, 36.371264],
              [-116.373909, 36.370876],
              [-116.372799, 36.369924],
              [-116.371221, 36.368714],
              [-116.368935, 36.366972],
              [-116.365645, 36.364464],
              [-116.355068, 36.356414],
              [-116.355034, 36.356388],
              [-116.350014, 36.35255],
              [-116.349991, 36.352532],
              [-116.34931, 36.352013],
              [-116.349217, 36.351942],
              [-116.349179, 36.351913],
              [-116.349156, 36.351895],
              [-116.349017, 36.351789],
              [-116.345579, 36.349166],
              [-116.34297, 36.347175],
              [-116.33596, 36.341833],
              [-116.33551, 36.341483],
              [-116.327804, 36.335606],
              [-116.326918, 36.334944],
              [-116.321138, 36.330532],
              [-116.320858, 36.330318],
              [-116.261828, 36.285331],
              [-116.261692, 36.285227],
              [-116.250818, 36.276939],
              [-116.250809, 36.276932],
              [-116.250781, 36.276911],
              [-116.249986, 36.27629],
              [-116.249975, 36.276282],
              [-116.2499, 36.276223],
              [-116.249662, 36.276037],
              [-116.245838, 36.273051],
              [-116.241328, 36.269593],
              [-116.230542, 36.261314],
              [-116.228918, 36.260067],
              [-116.22885, 36.260015],
              [-116.219319, 36.252701],
              [-116.215736, 36.249951],
              [-116.215054, 36.249453],
              [-116.215041, 36.249443],
              [-116.21264, 36.247595],
              [-116.208402, 36.244323],
              [-116.204986, 36.241686],
              [-116.204465, 36.241284],
              [-116.204329, 36.241179],
              [-116.204289, 36.241148],
              [-116.204249, 36.241117],
              [-116.204238, 36.241109],
              [-116.204226, 36.2411],
              [-116.204212, 36.241089],
              [-116.203818, 36.240785],
              [-116.203225, 36.240327],
              [-116.202685, 36.23991],
              [-116.198993, 36.23706],
              [-116.194986, 36.233967],
              [-116.194094, 36.233278],
              [-116.194083, 36.23327],
              [-116.19404, 36.233236],
              [-116.193737, 36.233002],
              [-116.19123, 36.231067],
              [-116.191145, 36.231001],
              [-116.189653, 36.229846],
              [-116.183884, 36.225393],
              [-116.176469, 36.21967],
              [-116.169054, 36.213947],
              [-116.168726, 36.213694],
              [-116.1637, 36.209819],
              [-116.159376, 36.206485],
              [-116.159301, 36.206427],
              [-116.159284, 36.206414],
              [-116.158345, 36.20569],
              [-116.155787, 36.203718],
              [-116.155361, 36.20339],
              [-116.155326, 36.203363],
              [-116.155287, 36.203333],
              [-116.15517, 36.203243],
              [-116.15446, 36.202696],
              [-116.153996, 36.202338],
              [-116.148596, 36.198176],
              [-116.14848, 36.198086],
              [-116.146359, 36.196451],
              [-116.145652, 36.195906],
              [-116.134064, 36.186974],
              [-116.130122, 36.183935],
              [-116.129137, 36.183176],
              [-116.129123, 36.183168],
              [-116.125888, 36.180664],
              [-116.125253, 36.180156],
              [-116.123977, 36.179134],
              [-116.122009, 36.177628],
              [-116.115614, 36.172651],
              [-116.115608, 36.172636],
              [-116.110275, 36.168495],
              [-116.108531, 36.167138],
              [-116.105191, 36.164545],
              [-116.098669, 36.159473],
              [-116.097216, 36.158346],
              [-116.09466, 36.156601],
              [-116.094166, 36.156263],
              [-116.093601, 36.155805],
              [-116.093133, 36.155374],
              [-116.093099, 36.155349],
              [-116.092575, 36.154945],
              [-116.090952, 36.153688],
              [-116.089308, 36.152415],
              [-116.089294, 36.152404],
              [-116.088682, 36.151932],
              [-116.088413, 36.151724],
              [-116.087343, 36.150897],
              [-116.084558, 36.148741],
              [-116.084385, 36.148607],
              [-116.08422, 36.148479],
              [-116.083454, 36.147886],
              [-116.083013, 36.147545],
              [-116.082941, 36.147489],
              [-116.082914, 36.147469],
              [-116.082905, 36.147462],
              [-116.082843, 36.147414],
              [-116.082553, 36.14719],
              [-116.081118, 36.14608],
              [-116.08072, 36.145771],
              [-116.080707, 36.145761],
              [-116.080694, 36.145751],
              [-116.080426, 36.145543],
              [-116.079692, 36.144974],
              [-116.079644, 36.144937],
              [-116.076196, 36.142271],
              [-116.075889, 36.142033],
              [-116.072785, 36.139633],
              [-116.072772, 36.139623],
              [-116.072576, 36.139471],
              [-116.071853, 36.138912],
              [-116.071624, 36.138735],
              [-116.0716, 36.138716],
              [-116.07155, 36.138678],
              [-116.071211, 36.138416],
              [-116.070698, 36.138019],
              [-116.069437, 36.137043],
              [-116.0683, 36.136164],
              [-116.068171, 36.136064],
              [-116.068061, 36.135979],
              [-116.068023, 36.13595],
              [-116.067922, 36.135872],
              [-116.066224, 36.134559],
              [-116.065856, 36.134274],
              [-116.063859, 36.13273],
              [-116.046739, 36.119441],
              [-116.041313, 36.115233],
              [-116.037788, 36.112498],
              [-116.036985, 36.111874],
              [-116.035692, 36.11087],
              [-116.031762, 36.107846],
              [-116.029482, 36.106088],
              [-116.023238, 36.101273],
              [-116.015577, 36.095365],
              [-116.012223, 36.092778],
              [-116.012163, 36.092732],
              [-116.012118, 36.092697],
              [-116.012091, 36.092676],
              [-116.012074, 36.092663],
              [-116.012061, 36.092653],
              [-116.010091, 36.091133],
              [-116.009481, 36.090663],
              [-116.005785, 36.087813],
              [-116.005666, 36.087721],
              [-116.005575, 36.087651],
              [-116.00555, 36.087632],
              [-116.00552, 36.087609],
              [-116.005398, 36.087515],
              [-116.004892, 36.087124],
              [-116.000655, 36.083857],
              [-115.9953, 36.079477],
              [-115.993107, 36.077771],
              [-115.988967, 36.074551],
              [-115.988844, 36.074457],
              [-115.988045, 36.073842],
              [-115.985436, 36.071805],
              [-115.978832, 36.066669],
              [-115.96643, 36.057024],
              [-115.962489, 36.05396],
              [-115.95761, 36.050165],
              [-115.951759, 36.045614],
              [-115.948798, 36.043311],
              [-115.948002, 36.042692],
              [-115.947521, 36.042318],
              [-115.947505, 36.042306],
              [-115.94747, 36.042278],
              [-115.947447, 36.04226],
              [-115.947435, 36.042251],
              [-115.946725, 36.041698],
              [-115.944593, 36.040041],
              [-115.943171, 36.038935],
              [-115.941445, 36.037592],
              [-115.940008, 36.036482],
              [-115.93962, 36.036182],
              [-115.939507, 36.036095],
              [-115.939472, 36.036068],
              [-115.939461, 36.036059],
              [-115.939056, 36.035746],
              [-115.93739, 36.034459],
              [-115.936569, 36.03382],
              [-115.929943, 36.028659],
              [-115.926931, 36.026313],
              [-115.924988, 36.0248],
              [-115.92444, 36.024373],
              [-115.924379, 36.024325],
              [-115.924364, 36.024313],
              [-115.924305, 36.024267],
              [-115.924257, 36.02423],
              [-115.924066, 36.024081],
              [-115.92385, 36.023913],
              [-115.923556, 36.023684],
              [-115.921842, 36.022349],
              [-115.921788, 36.022307],
              [-115.92134, 36.021958],
              [-115.918743, 36.019936],
              [-115.918727, 36.019923],
              [-115.918087, 36.019446],
              [-115.913964, 36.016369],
              [-115.913914, 36.016329],
              [-115.912564, 36.015324],
              [-115.912478, 36.015285],
              [-115.912452, 36.015267],
              [-115.912423, 36.015246],
              [-115.912387, 36.015221],
              [-115.912201, 36.015071],
              [-115.911992, 36.014902],
              [-115.911774, 36.014733],
              [-115.911759, 36.014721],
              [-115.90943, 36.012917],
              [-115.908747, 36.012377],
              [-115.908421, 36.012144],
              [-115.907693, 36.011574],
              [-115.906701, 36.010798],
              [-115.904089, 36.008777],
              [-115.903315, 36.008191],
              [-115.901442, 36.006722],
              [-115.900785, 36.0062],
              [-115.90059, 36.006045],
              [-115.899498, 36.005197],
              [-115.898281, 36.004268],
              [-115.898003, 36.004051],
              [-115.897609, 36.003744],
              [-115.897452, 36.00363],
              [-115.896183, 36.002648],
              [-115.895642, 36.002218],
              [-115.895151, 36.001832],
              [-115.895116, 36.001804],
              [-115.894459, 36.00129],
              [-115.894283, 36.001152],
              [-115.894256, 36.001131],
              [-115.893951, 36.000892],
              [-115.893398, 36.00047],
              [-115.89285, 36.000051],
              [-115.891554, 35.999057],
              [-115.891071, 35.998672],
              [-115.891037, 35.998645],
              [-115.890615, 35.998323],
              [-115.890604, 35.998315],
              [-115.890082, 35.997917],
              [-115.888353, 35.996585],
              [-115.887461, 35.995892],
              [-115.884932, 35.993946],
              [-115.884054, 35.993268],
              [-115.883607, 35.992924],
              [-115.88355, 35.992863],
              [-115.881633, 35.991397],
              [-115.873875, 35.985391],
              [-115.873491, 35.985099],
              [-115.872622, 35.98443],
              [-115.870681, 35.982921],
              [-115.869153, 35.981728],
              [-115.865818, 35.979137],
              [-115.857677, 35.972803],
              [-115.854051, 35.96998],
              [-115.845994, 35.963707],
              [-115.845431, 35.963269],
              [-115.835931, 35.955871],
              [-115.834582, 35.954817],
              [-115.834211, 35.954529],
              [-115.826809, 35.948763],
              [-115.826465, 35.948496],
              [-115.797575, 35.925992],
              [-115.78928, 35.919524],
              [-115.789101, 35.919248],
              [-115.784504, 35.915799],
              [-115.779525, 35.911914],
              [-115.775131, 35.908455],
              [-115.761135, 35.89743],
              [-115.758219, 35.895161],
              [-115.755784, 35.893268],
              [-115.755674, 35.893183],
              [-115.742049, 35.882581],
              [-115.709064, 35.85687],
              [-115.707478, 35.85558],
              [-115.702824, 35.851941],
              [-115.702771, 35.851899],
              [-115.695733, 35.846481],
              [-115.695437, 35.846258],
              [-115.695126, 35.84601],
              [-115.694849, 35.84576],
              [-115.694764, 35.845639],
              [-115.694631, 35.845426],
              [-115.694551, 35.845335],
              [-115.694317, 35.845162],
              [-115.693977, 35.844892],
              [-115.693749, 35.844671],
              [-115.693646, 35.84458],
              [-115.693312, 35.844347],
              [-115.69298, 35.844094],
              [-115.69245, 35.843658],
              [-115.692055, 35.843365],
              [-115.690481, 35.842149],
              [-115.68994, 35.841708],
              [-115.689581, 35.841437],
              [-115.689568, 35.841431],
              [-115.689483, 35.841388],
              [-115.689408, 35.841279],
              [-115.689285, 35.841157],
              [-115.688686, 35.840685],
              [-115.688274, 35.840373],
              [-115.687029, 35.839444],
              [-115.686054, 35.838693],
              [-115.686001, 35.83865],
              [-115.685952, 35.83861],
              [-115.685879, 35.83855],
              [-115.685678, 35.838407],
              [-115.683679, 35.836842],
              [-115.683494, 35.8367],
              [-115.68196, 35.835523],
              [-115.681205, 35.834923],
              [-115.680381, 35.834267],
              [-115.680122, 35.834064],
              [-115.678707, 35.832956],
              [-115.677764, 35.832229],
              [-115.673589, 35.828964],
              [-115.671527, 35.827327],
              [-115.67136, 35.827195],
              [-115.671341, 35.82718],
              [-115.670555, 35.826572],
              [-115.670407, 35.826457],
              [-115.669842, 35.826023],
              [-115.668965, 35.825349],
              [-115.667377, 35.824105],
              [-115.667217, 35.823984],
              [-115.666941, 35.823776],
              [-115.665052, 35.822273],
              [-115.663609, 35.821141],
              [-115.663098, 35.820753],
              [-115.662564, 35.820332],
              [-115.662388, 35.820193],
              [-115.662182, 35.820038],
              [-115.661635, 35.819627],
              [-115.66135, 35.819399],
              [-115.660558, 35.818765],
              [-115.659316, 35.817771],
              [-115.659218, 35.817697],
              [-115.658751, 35.817342],
              [-115.65857, 35.817203],
              [-115.658165, 35.816892],
              [-115.658136, 35.816872],
              [-115.657774, 35.816631],
              [-115.6578, 35.816565],
              [-115.657734, 35.81654],
              [-115.657662, 35.816479],
              [-115.657565, 35.816415],
              [-115.657351, 35.816236],
              [-115.657331, 35.816219],
              [-115.657078, 35.816035],
              [-115.656982, 35.815959],
              [-115.656203, 35.815342],
              [-115.656072, 35.815238],
              [-115.655689, 35.814935],
              [-115.655628, 35.814887],
              [-115.655125, 35.8145],
              [-115.654611, 35.814104],
              [-115.654052, 35.813669],
              [-115.652903, 35.812775],
              [-115.652813, 35.812702],
              [-115.652327, 35.81231],
              [-115.651894, 35.811972],
              [-115.651812, 35.811908],
              [-115.650326, 35.810746],
              [-115.650065, 35.810537],
              [-115.649978, 35.810468],
              [-115.649423, 35.810034],
              [-115.649373, 35.809995],
              [-115.649167, 35.809827],
              [-115.648973, 35.809668],
              [-115.648944, 35.809644],
              [-115.648896, 35.809605],
              [-115.648794, 35.809522],
              [-115.648727, 35.809474],
              [-115.648357, 35.809211],
              [-115.648323, 35.809189],
              [-115.647748, 35.808785],
              [-115.646689, 35.807997],
              [-115.646336, 35.807735],
              [-115.64584, 35.807348],
              [-115.64438, 35.806205],
              [-115.637295, 35.800657],
              [-115.635554, 35.799293],
              [-115.628218, 35.793547],
              [-115.626355, 35.792088],
              [-115.626008, 35.791815],
              [-115.621322, 35.788145],
              [-115.621299, 35.788126],
              [-115.621208, 35.788055],
              [-115.620967, 35.787865],
              [-115.610125, 35.779365],
              [-115.601407, 35.772533],
              [-115.601333, 35.772474],
              [-115.59827, 35.770071],
              [-115.593869, 35.766618],
              [-115.591802, 35.765001],
              [-115.591538, 35.764795],
              [-115.591513, 35.764774],
              [-115.589942, 35.763532],
              [-115.588854, 35.762675],
              [-115.587527, 35.76163],
              [-115.584272, 35.759069],
              [-115.574304, 35.751246],
              [-115.572613, 35.749918],
              [-115.557025, 35.737675],
              [-115.549856, 35.732036],
              [-115.54096, 35.725038],
              [-115.528901, 35.715552],
              [-115.525767, 35.713085],
              [-115.525358, 35.71276],
              [-115.524427, 35.712029],
              [-115.521888, 35.710031],
              [-115.517703, 35.706736],
              [-115.514974, 35.704585],
              [-115.514596, 35.704288],
              [-115.510205, 35.700829],
              [-115.507909, 35.699021],
              [-115.506429, 35.697855],
              [-115.498266, 35.691423],
              [-115.485063, 35.681016],
              [-115.484144, 35.680292],
              [-115.483642, 35.679897],
              [-115.482471, 35.678977],
              [-115.481134, 35.677927],
              [-115.472937, 35.671487],
              [-115.470931, 35.66991],
              [-115.462727, 35.663464],
              [-115.462059, 35.662939],
              [-115.458269, 35.659962],
              [-115.454318, 35.656857],
              [-115.450253, 35.653663],
              [-115.447047, 35.651144],
              [-115.444783, 35.649365],
              [-115.44125, 35.646589],
              [-115.439137, 35.644929],
              [-115.43273, 35.639895],
              [-115.429534, 35.637384],
              [-115.424481, 35.633413],
              [-115.420508, 35.630292],
              [-115.418086, 35.628389],
              [-115.408622, 35.6209],
              [-115.407639, 35.620121],
              [-115.407503, 35.620013],
              [-115.405926, 35.618766],
              [-115.405971, 35.618579],
              [-115.405629, 35.618478],
              [-115.405566, 35.61846],
              [-115.404847, 35.618018],
              [-115.404739, 35.617901],
              [-115.404685, 35.617842],
              [-115.404499, 35.617639],
              [-115.404111, 35.617333],
              [-115.40295, 35.616415],
              [-115.402874, 35.616355],
              [-115.402563, 35.616109],
              [-115.401207, 35.615038],
              [-115.397142, 35.611825],
              [-115.395787, 35.610755],
              [-115.395661, 35.610655],
              [-115.395286, 35.610356],
              [-115.395162, 35.610257],
              [-115.394098, 35.609422],
              [-115.391467, 35.607319],
              [-115.391139, 35.607057],
              [-115.391007, 35.606942],
              [-115.390548, 35.606546],
              [-115.389658, 35.605915],
              [-115.389485, 35.605778],
              [-115.388968, 35.60537],
              [-115.388796, 35.605234],
              [-115.386613, 35.60351],
              [-115.38075, 35.598879],
              [-115.380065, 35.598338],
              [-115.377883, 35.596614],
              [-115.376607, 35.595607],
              [-115.375723, 35.594909],
              [-115.372799, 35.592566],
              [-115.372408, 35.592252],
              [-115.372223, 35.592111],
              [-115.371515, 35.591572],
              [-115.371413, 35.591494],
              [-115.37111, 35.591263],
              [-115.371009, 35.591186],
              [-115.370991, 35.59117],
              [-115.37094, 35.591125],
              [-115.370923, 35.591111],
              [-115.370729, 35.590959],
              [-115.370148, 35.590504],
              [-115.369955, 35.590353],
              [-115.369946, 35.590346],
              [-115.369915, 35.590322],
              [-115.369816, 35.590233],
              [-115.369698, 35.590128],
              [-115.369514, 35.589977],
              [-115.369412, 35.589894],
              [-115.369392, 35.589877],
              [-115.369335, 35.589829],
              [-115.369317, 35.589813],
              [-115.369053, 35.589596],
              [-115.368588, 35.589276],
              [-115.366799, 35.587866],
              [-115.365191, 35.586598],
              [-115.361141, 35.583404],
              [-115.359394, 35.582027],
              [-115.356781, 35.579967],
              [-115.352516, 35.576604],
              [-115.346607, 35.571944],
              [-115.342334, 35.568576],
              [-115.336941, 35.564323],
              [-115.336667, 35.564107],
              [-115.334074, 35.562063],
              [-115.330969, 35.559614],
              [-115.329567, 35.558509],
              [-115.328512, 35.557671],
              [-115.325601, 35.555358],
              [-115.32428, 35.554307],
              [-115.323541, 35.55372],
              [-115.321593, 35.552172],
              [-115.320189, 35.551053],
              [-115.314992, 35.546912],
              [-115.311595, 35.544204],
              [-115.309544, 35.542569],
              [-115.307438, 35.540891],
              [-115.307368, 35.540835],
              [-115.305494, 35.539343],
              [-115.303884, 35.538062],
              [-115.283996, 35.52222],
              [-115.283972, 35.522201],
              [-115.282534, 35.521105],
              [-115.279194, 35.518572],
              [-115.276271, 35.516354],
              [-115.275475, 35.515752],
              [-115.271514, 35.512748],
              [-115.271497, 35.512734],
              [-115.271448, 35.512694],
              [-115.271432, 35.512681],
              [-115.271006, 35.512354],
              [-115.270878, 35.512256],
              [-115.26973, 35.511375],
              [-115.269457, 35.511166],
              [-115.269306, 35.511048],
              [-115.267761, 35.509838],
              [-115.267388, 35.509546],
              [-115.266348, 35.508731],
              [-115.265471, 35.508044],
              [-115.264879, 35.507581],
              [-115.261637, 35.505042],
              [-115.261509, 35.504942],
              [-115.25972, 35.503541],
              [-115.258173, 35.502287],
              [-115.257286, 35.501568],
              [-115.256692, 35.501086],
              [-115.25414, 35.499018],
              [-115.249987, 35.495651],
              [-115.24812, 35.494138],
              [-115.247554, 35.493679],
              [-115.246501, 35.492842],
              [-115.246486, 35.49283],
              [-115.245071, 35.49171],
              [-115.243281, 35.49029],
              [-115.242213, 35.489444],
              [-115.241786, 35.489105],
              [-115.2398, 35.487508],
              [-115.237685, 35.485807],
              [-115.233218, 35.482214],
              [-115.232568, 35.481691],
              [-115.230158, 35.479753],
              [-115.229615, 35.479316],
              [-115.229201, 35.478981],
              [-115.226557, 35.476842],
              [-115.226334, 35.476661],
              [-115.225378, 35.475889],
              [-115.225186, 35.475732],
              [-115.224225, 35.474956],
              [-115.22263, 35.473668],
              [-115.220778, 35.472145],
              [-115.219633, 35.471204],
              [-115.21951, 35.471103],
              [-115.218304, 35.470153],
              [-115.215511, 35.467954],
              [-115.214314, 35.46701],
              [-115.212984, 35.465963],
              [-115.212853, 35.465859],
              [-115.209179, 35.462945],
              [-115.204512, 35.459244],
              [-115.203526, 35.458462],
              [-115.197764, 35.453896],
              [-115.195163, 35.451836],
              [-115.194131, 35.451019],
              [-115.193958, 35.450882],
              [-115.193389, 35.450432],
              [-115.190641, 35.44826],
              [-115.184332, 35.443273],
              [-115.180937, 35.440584],
              [-115.180696, 35.440393],
              [-115.177383, 35.437769],
              [-115.176975, 35.437446],
              [-115.175359, 35.436162],
              [-115.169291, 35.431339],
              [-115.167269, 35.429732],
              [-115.166846, 35.429398],
              [-115.166796, 35.429358],
              [-115.165377, 35.428239],
              [-115.164904, 35.427867],
              [-115.162067, 35.424983],
              [-115.161281, 35.424184],
              [-115.161057, 35.423958],
              [-115.160652, 35.423632],
              [-115.159944, 35.423065],
              [-115.157749, 35.421303],
              [-115.151164, 35.416019],
              [-115.14897, 35.414258],
              [-115.148515, 35.413893],
              [-115.147151, 35.412798],
              [-115.146697, 35.412434],
              [-115.143957, 35.410234],
              [-115.142198, 35.408822],
              [-115.13574, 35.403637],
              [-115.133001, 35.401438],
              [-115.129645, 35.398742],
              [-115.124157, 35.394335],
              [-115.119579, 35.390654],
              [-115.116225, 35.387958],
              [-115.113844, 35.386044],
              [-115.112692, 35.385118],
              [-115.106704, 35.380301],
              [-115.104325, 35.378387],
              [-115.103264, 35.377534],
              [-115.100957, 35.375681],
              [-115.100066, 35.374998],
              [-115.099454, 35.37453],
              [-115.098986, 35.374172],
              [-115.098367, 35.373694],
              [-115.097341, 35.372904],
              [-115.09651, 35.372263],
              [-115.095891, 35.371787],
              [-115.094438, 35.370758],
              [-115.093274, 35.369934],
              [-115.092842, 35.369628],
              [-115.09206, 35.369074],
              [-115.089293, 35.367114],
              [-115.089231, 35.36707],
              [-115.08883, 35.366786],
              [-115.087927, 35.366147],
              [-115.086318, 35.365007],
              [-115.085365, 35.364332],
              [-115.085092, 35.364144],
              [-115.084274, 35.363581],
              [-115.084002, 35.363394],
              [-115.080698, 35.360626],
              [-115.070788, 35.352324],
              [-115.067699, 35.349736],
              [-115.067479, 35.349564],
              [-115.062866, 35.345952],
              [-115.057051, 35.341399],
              [-115.053432, 35.338565],
              [-115.051726, 35.33723],
              [-115.04903, 35.335119],
              [-115.047152, 35.333649],
              [-115.045225, 35.33214],
              [-115.044418, 35.331508],
              [-115.044111, 35.331268],
              [-115.043266, 35.330606],
              [-115.040194, 35.328199],
              [-115.039811, 35.327899],
              [-115.03866, 35.326997],
              [-115.038384, 35.326781],
              [-115.035288, 35.324355],
              [-115.032207, 35.321941],
              [-115.025175, 35.316431],
              [-115.021804, 35.31379],
              [-115.015928, 35.309182],
              [-115.01168, 35.305852],
              [-114.994666, 35.292511],
              [-114.990233, 35.288836],
              [-114.984368, 35.283975],
              [-114.982801, 35.282732],
              [-114.981551, 35.28174],
              [-114.971472, 35.273747],
              [-114.968602, 35.27147],
              [-114.959992, 35.264639],
              [-114.957123, 35.262362],
              [-114.95654, 35.261899],
              [-114.954791, 35.260512],
              [-114.954209, 35.26005],
              [-114.952997, 35.259088],
              [-114.949364, 35.256204],
              [-114.948153, 35.255243],
              [-114.948046, 35.255158],
              [-114.947725, 35.254903],
              [-114.947619, 35.254819],
              [-114.946779, 35.254153],
              [-114.943181, 35.251294],
              [-114.936449, 35.245946],
              [-114.931459, 35.241981],
              [-114.92987, 35.240719],
              [-114.925434, 35.237194],
              [-114.925402, 35.237168],
              [-114.92537, 35.237143],
              [-114.925305, 35.237097],
              [-114.925272, 35.237075],
              [-114.924767, 35.236674],
              [-114.924167, 35.236197],
              [-114.923255, 35.235472],
              [-114.922919, 35.235205],
              [-114.922752, 35.235072],
              [-114.920137, 35.232994],
              [-114.917835, 35.231166],
              [-114.915422, 35.229248],
              [-114.913444, 35.227677],
              [-114.912292, 35.226762],
              [-114.909678, 35.224685],
              [-114.90922, 35.224321],
              [-114.909042, 35.22418],
              [-114.907137, 35.222665],
              [-114.906503, 35.222161],
              [-114.905816, 35.221615],
              [-114.905548, 35.221402],
              [-114.905134, 35.221073],
              [-114.90428, 35.220394],
              [-114.90415, 35.220291],
              [-114.904106, 35.220256],
              [-114.90286, 35.219265],
              [-114.901487, 35.218174],
              [-114.897611, 35.215094],
              [-114.895987, 35.213803],
              [-114.895389, 35.213328],
              [-114.893899, 35.212103],
              [-114.88862, 35.207763],
              [-114.887097, 35.206511],
              [-114.885376, 35.205096],
              [-114.87904, 35.199888],
              [-114.874884, 35.196471],
              [-114.874283, 35.195977],
              [-114.863701, 35.187278],
              [-114.862223, 35.186063],
              [-114.860131, 35.184343],
              [-114.853932, 35.179247],
              [-114.853021, 35.17864],
              [-114.852973, 35.178608],
              [-114.852802, 35.178494],
              [-114.850209, 35.176534],
              [-114.84929, 35.17584],
              [-114.846574, 35.173783],
              [-114.840297, 35.169031],
              [-114.838426, 35.167615],
              [-114.835711, 35.165559],
              [-114.832673, 35.163128],
              [-114.827811, 35.159237],
              [-114.827555, 35.159031],
              [-114.826118, 35.157882],
              [-114.824408, 35.156513],
              [-114.823562, 35.155835],
              [-114.820525, 35.153405],
              [-114.81915, 35.152304],
              [-114.81878, 35.152013],
              [-114.815122, 35.149135],
              [-114.813531, 35.147856],
              [-114.811792, 35.146458],
              [-114.811591, 35.146296],
              [-114.81064, 35.145531],
              [-114.807793, 35.143242],
              [-114.807185, 35.142753],
              [-114.806034, 35.141827],
              [-114.805807, 35.141644],
              [-114.805128, 35.141098],
              [-114.804902, 35.140916],
              [-114.804571, 35.14065],
              [-114.803864, 35.140082],
              [-114.801313, 35.13803],
              [-114.797341, 35.134927],
              [-114.792967, 35.13138],
              [-114.791971, 35.130572],
              [-114.791245, 35.129984],
              [-114.788982, 35.128151],
              [-114.788697, 35.12792],
              [-114.787988, 35.127342],
              [-114.787878, 35.127253],
              [-114.785068, 35.124973],
              [-114.784631, 35.124619],
              [-114.783733, 35.12389],
              [-114.780264, 35.121175],
              [-114.779626, 35.120675],
              [-114.776247, 35.117944],
              [-114.773324, 35.115581],
              [-114.773092, 35.115393],
              [-114.772397, 35.114832],
              [-114.772166, 35.114645],
              [-114.769568, 35.112544],
              [-114.765601, 35.109337],
              [-114.761801, 35.106212],
              [-114.759222, 35.104091],
              [-114.756809, 35.102106],
              [-114.749572, 35.096154],
              [-114.749318, 35.095945],
              [-114.747157, 35.094174],
              [-114.747022, 35.094063],
              [-114.746618, 35.093732],
              [-114.746484, 35.093622],
              [-114.74616, 35.093357],
              [-114.74519, 35.092562],
              [-114.744867, 35.092297],
              [-114.744733, 35.092187],
              [-114.743536, 35.091206],
              [-114.739546, 35.087934],
              [-114.738825, 35.087343],
              [-114.738216, 35.086844],
              [-114.737773, 35.086481],
              [-114.736445, 35.085392],
              [-114.736003, 35.08503],
              [-114.735655, 35.084745],
              [-114.734614, 35.08389],
              [-114.734267, 35.083606],
              [-114.733186, 35.082719],
              [-114.731561, 35.081386],
              [-114.729901, 35.080116],
              [-114.729802, 35.08004],
              [-114.728816, 35.079236],
              [-114.72818, 35.078717],
              [-114.726275, 35.077162],
              [-114.72564, 35.076644],
              [-114.725489, 35.076521],
              [-114.725037, 35.076152],
              [-114.724887, 35.076029],
              [-114.722548, 35.07412],
              [-114.715534, 35.068394],
              [-114.714117, 35.067237],
              [-114.713182, 35.066503],
              [-114.712211, 35.065741],
              [-114.711354, 35.065068],
              [-114.70932, 35.063428],
              [-114.70836, 35.062654],
              [-114.708186, 35.062514],
              [-114.707665, 35.062094],
              [-114.707492, 35.061954],
              [-114.706796, 35.061393],
              [-114.703155, 35.058456],
              [-114.702737, 35.058119],
              [-114.695759, 35.052491],
              [-114.693499, 35.050619],
              [-114.690173, 35.047931],
              [-114.68584, 35.04443],
              [-114.685374, 35.044054],
              [-114.683978, 35.042926],
              [-114.683513, 35.04255],
              [-114.683438, 35.042489],
              [-114.682995, 35.04213],
              [-114.681441, 35.040874],
              [-114.680923, 35.040456],
              [-114.680541, 35.040147],
              [-114.680443, 35.040068],
              [-114.679003, 35.038904],
              [-114.678524, 35.038517],
              [-114.677882, 35.037993],
              [-114.675956, 35.036421],
              [-114.675315, 35.035897],
              [-114.674009, 35.03483],
              [-114.670093, 35.031629],
              [-114.668788, 35.030562],
              [-114.668748, 35.030529],
              [-114.667729, 35.029696],
              [-114.664554, 35.0271],
              [-114.663496, 35.026235],
              [-114.663407, 35.026162],
              [-114.662231, 35.0252],
              [-114.658436, 35.022098],
              [-114.657877, 35.021641],
              [-114.657171, 35.021064],
              [-114.656489, 35.020507],
              [-114.654446, 35.018836],
              [-114.654149, 35.018593],
              [-114.653765, 35.018279],
              [-114.653716, 35.018239],
              [-114.651735, 35.016633],
              [-114.645645, 35.011699],
              [-114.643615, 35.010055],
              [-114.64346, 35.009929],
              [-114.643375, 35.009861],
              [-114.642995, 35.009553],
              [-114.642841, 35.009428],
              [-114.64116, 35.008066],
              [-114.63612, 35.00398],
              [-114.63444, 35.002618],
              [-114.63437, 35.002562],
              [-114.63416, 35.002394],
              [-114.634091, 35.002338],
              [-114.63397, 35.002241],
              [-114.633607, 35.001953],
              [-114.633487, 35.001857],
              [-114.633432, 35.001773],
              [-114.632665, 34.999806],
              [-114.631807, 34.998632],
              [-114.631608, 34.998124],
              [-114.630244, 34.99464],
              [-114.629443, 34.991825],
              [-114.629214, 34.989037],
              [-114.629129, 34.986132],
              [-114.629152, 34.985873],
              [-114.629369, 34.983433],
              [-114.629392, 34.983171],
              [-114.629694, 34.981704],
              [-114.629907, 34.980791],
              [-114.630609, 34.978689],
              [-114.630816, 34.978065],
              [-114.630843, 34.977987],
              [-114.632413, 34.974586],
              [-114.633573, 34.97158],
              [-114.634607, 34.96906],
              [-114.634684, 34.96874],
              [-114.634779, 34.968345],
              [-114.634874, 34.96795],
              [-114.634899, 34.967848],
              [-114.63495, 34.967439],
              [-114.635237, 34.965149],
              [-114.635215, 34.964595],
              [-114.635196, 34.964122],
              [-114.635177, 34.963648],
              [-114.635146, 34.962853],
              [-114.635115, 34.962058],
              [-114.635104, 34.961771],
              [-114.634953, 34.958918],
              [-114.634274, 34.956662],
              [-114.634081, 34.956264],
              [-114.632483, 34.952964],
              [-114.631681, 34.95131],
              [-114.629984, 34.945413],
              [-114.629811, 34.94481],
              [-114.62978, 34.943501],
              [-114.629769, 34.94304],
              [-114.629754, 34.938881],
              [-114.629753, 34.938684],
              [-114.630071, 34.937477],
              [-114.630492, 34.93588],
              [-114.630844, 34.934626],
              [-114.631037, 34.933941],
              [-114.631393, 34.932923],
              [-114.631528, 34.932538],
              [-114.631663, 34.932152],
              [-114.632196, 34.930628],
              [-114.632497, 34.928983],
              [-114.632644, 34.928177],
              [-114.632792, 34.927371],
              [-114.632836, 34.927137],
              [-114.632901, 34.926739],
              [-114.633127, 34.92537],
              [-114.633189, 34.924993],
              [-114.633213, 34.924843],
              [-114.633253, 34.924608],
              [-114.633251, 34.924331],
              [-114.633251, 34.924206],
              [-114.633249, 34.923868],
              [-114.633244, 34.922854],
              [-114.633243, 34.922517],
              [-114.633237, 34.92123],
              [-114.633056, 34.920555],
              [-114.633013, 34.920394],
              [-114.63297, 34.920233],
              [-114.632943, 34.920135],
              [-114.63247, 34.918368],
              [-114.631537, 34.916153],
              [-114.630861, 34.913202],
              [-114.630552, 34.911852],
              [-114.630625, 34.91082],
              [-114.63075, 34.909047],
              [-114.630877, 34.907263],
              [-114.63185, 34.903942],
              [-114.631882, 34.903868],
              [-114.632595, 34.902241],
              [-114.633175, 34.900917],
              [-114.63452, 34.89785],
              [-114.634838, 34.896914],
              [-114.635304, 34.895546],
              [-114.635425, 34.895192],
              [-114.635461, 34.895041],
              [-114.635669, 34.894184],
              [-114.636105, 34.892388],
              [-114.636299, 34.891361],
              [-114.636654, 34.889481],
              [-114.636725, 34.889107],
              [-114.636768, 34.885705],
              [-114.636535, 34.88414],
              [-114.636397, 34.883213],
              [-114.635928, 34.880058],
              [-114.635458, 34.876902],
              [-114.635176, 34.875003],
              [-114.635126, 34.874871],
              [-114.634382, 34.87289],
              [-114.63423, 34.872557],
              [-114.633639, 34.87126],
              [-114.633322, 34.870566],
              [-114.633051, 34.869971],
              [-114.632092, 34.868505],
              [-114.631152, 34.867069],
              [-114.630682, 34.866352],
              [-114.63037, 34.865995],
              [-114.629702, 34.86523],
              [-114.629562, 34.865069],
              [-114.628583, 34.863948],
              [-114.628276, 34.863596],
              [-114.628062, 34.863399],
              [-114.627632, 34.863003],
              [-114.627474, 34.862858],
              [-114.626885, 34.862318],
              [-114.626465, 34.861932],
              [-114.625938, 34.861662],
              [-114.625798, 34.86159],
              [-114.625592, 34.861385],
              [-114.625216, 34.86101],
              [-114.623939, 34.859738],
              [-114.621918, 34.858237],
              [-114.620169, 34.857067],
              [-114.619878, 34.856873],
              [-114.618281, 34.856062],
              [-114.617398, 34.855614],
              [-114.616919, 34.855371],
              [-114.614691, 34.854395],
              [-114.613785, 34.853999],
              [-114.613207, 34.853724],
              [-114.612825, 34.853541],
              [-114.61263, 34.853448],
              [-114.611012, 34.852675],
              [-114.609963, 34.852131],
              [-114.609173, 34.851721],
              [-114.609013, 34.851655],
              [-114.607819, 34.851156],
              [-114.607758, 34.85113],
              [-114.605681, 34.850251],
              [-114.604255, 34.849573],
              [-114.604045, 34.849449],
              [-114.60316, 34.848927],
              [-114.603018, 34.848843],
              [-114.602875, 34.848759],
              [-114.602557, 34.848572],
              [-114.60188, 34.848141],
              [-114.600653, 34.847361],
              [-114.598941, 34.846219],
              [-114.598838, 34.84615],
              [-114.597999, 34.845525],
              [-114.595998, 34.844035],
              [-114.595607, 34.84374],
              [-114.594242, 34.842711],
              [-114.593485, 34.842091],
              [-114.592339, 34.841153],
              [-114.591921, 34.840757],
              [-114.590144, 34.839071],
              [-114.588897, 34.837833],
              [-114.587705, 34.83665],
              [-114.586842, 34.835672],
              [-114.5868, 34.835602],
              [-114.586675, 34.835392],
              [-114.586634, 34.835322],
              [-114.58659, 34.835247],
              [-114.586545, 34.835173],
              [-114.585667, 34.833698],
              [-114.584274, 34.83135],
              [-114.583655, 34.830171],
              [-114.583642, 34.830147],
              [-114.583592, 34.830052],
              [-114.583477, 34.829906],
              [-114.582821, 34.829068],
              [-114.582197, 34.828188],
              [-114.581126, 34.826115],
              [-114.580015, 34.823784],
              [-114.578681, 34.820977],
              [-114.57777, 34.818672],
              [-114.57762, 34.818292],
              [-114.57731, 34.817498],
              [-114.577161, 34.817117],
              [-114.577013, 34.816736],
              [-114.576452, 34.8153],
              [-114.576156, 34.813954],
              [-114.575811, 34.812381],
              [-114.575345, 34.810171],
              [-114.574851, 34.808121],
              [-114.574825, 34.808014],
              [-114.574807, 34.80794],
              [-114.574694, 34.807471],
              [-114.574688, 34.807396],
              [-114.574675, 34.807211],
              [-114.574569, 34.805746],
              [-114.574578, 34.80496],
              [-114.574581, 34.804674],
              [-114.574496, 34.804309],
              [-114.574491, 34.80429],
              [-114.574474, 34.804214],
              [-114.574282, 34.803726],
              [-114.573295, 34.801222],
              [-114.572547, 34.798851],
              [-114.571847, 34.796343],
              [-114.571688, 34.795955],
              [-114.57101, 34.794294],
              [-114.570426, 34.793315],
              [-114.56953, 34.791814],
              [-114.569383, 34.791568],
              [-114.567347, 34.789115],
              [-114.566281, 34.787704],
              [-114.566275, 34.787695],
              [-114.565365, 34.786261],
              [-114.565345, 34.786229],
              [-114.565184, 34.785976],
              [-114.565, 34.785218],
              [-114.564931, 34.784964],
              [-114.564859, 34.784702],
              [-114.564747, 34.784291],
              [-114.563979, 34.782597],
              [-114.562947, 34.780838],
              [-114.562675, 34.780396],
              [-114.562375, 34.779907],
              [-114.562103, 34.779464],
              [-114.561831, 34.77902],
              [-114.561749, 34.778886],
              [-114.560658, 34.777224],
              [-114.560049, 34.7762],
              [-114.558653, 34.773852],
              [-114.556607, 34.771383],
              [-114.554795, 34.76926],
              [-114.552682, 34.766871],
              [-114.551383, 34.765804],
              [-114.550089, 34.76474],
              [-114.549196, 34.763921],
              [-114.546884, 34.761802],
              [-114.543649, 34.759476],
              [-114.540306, 34.757109],
              [-114.535921, 34.754346],
              [-114.533843, 34.753128],
              [-114.533627, 34.753002],
              [-114.533411, 34.752875],
              [-114.533202, 34.752752],
              [-114.532994, 34.75263],
              [-114.531924, 34.752003],
              [-114.531483, 34.751828],
              [-114.530355, 34.75138],
              [-114.529615, 34.750822],
              [-114.529568, 34.750751],
              [-114.529079, 34.750006],
              [-114.527795, 34.748823],
              [-114.526804, 34.748076],
              [-114.526638, 34.747927],
              [-114.525611, 34.747005],
              [-114.52384, 34.744987],
              [-114.522619, 34.74373],
              [-114.522501, 34.743469],
              [-114.522072, 34.742517],
              [-114.521048, 34.741173],
              [-114.519876, 34.739916],
              [-114.518238, 34.738326],
              [-114.516619, 34.736745],
              [-114.515837, 34.736256],
              [-114.515695, 34.736167],
              [-114.515605, 34.736115],
              [-114.514178, 34.735288],
              [-114.512093, 34.734406],
              [-114.510292, 34.733582],
              [-114.508323, 34.732926],
              [-114.505984, 34.732107],
              [-114.504701, 34.731686],
              [-114.503361, 34.731247],
              [-114.500795, 34.730418],
              [-114.500091, 34.729819],
              [-114.499838, 34.729604],
              [-114.499007, 34.729096],
              [-114.498127, 34.728727],
              [-114.496886, 34.728406],
              [-114.496774, 34.728357],
              [-114.495858, 34.727956],
              [-114.495289, 34.727614],
              [-114.494588, 34.727192],
              [-114.492789, 34.726177],
              [-114.492388, 34.72596],
              [-114.492017, 34.725702],
              [-114.491401, 34.725218],
              [-114.491201, 34.725046],
              [-114.490971, 34.724848],
              [-114.490891, 34.72467],
              [-114.490746, 34.724347],
              [-114.490599, 34.723951],
              [-114.489346, 34.720323],
              [-114.489287, 34.720155],
              [-114.489164, 34.719938],
              [-114.489046, 34.71973],
              [-114.488928, 34.719522],
              [-114.488808, 34.719308],
              [-114.48876, 34.719224],
              [-114.488627, 34.718988],
              [-114.488525, 34.718808],
              [-114.48844, 34.718622],
              [-114.487777, 34.717219],
              [-114.487769, 34.717203],
              [-114.487731, 34.717118],
              [-114.487694, 34.717037],
              [-114.487508, 34.716626],
              [-114.48656, 34.716134],
              [-114.484762, 34.715201],
              [-114.48293, 34.714563],
              [-114.482779, 34.714511],
              [-114.482358, 34.714534],
              [-114.480668, 34.714628],
              [-114.478704, 34.714576],
              [-114.477297, 34.714514],
              [-114.475929, 34.714323],
              [-114.474962, 34.71422],
              [-114.473682, 34.713964],
              [-114.472597, 34.71357],
              [-114.471965, 34.713179],
              [-114.4719, 34.713139],
              [-114.471835, 34.713099],
              [-114.47162, 34.712966],
              [-114.470985, 34.712216],
              [-114.470477, 34.711368],
              [-114.469685, 34.710004],
              [-114.469128, 34.708833],
              [-114.46862, 34.707573],
              [-114.468374, 34.706302],
              [-114.468317, 34.706005],
              [-114.46813, 34.704445],
              [-114.468217, 34.703313],
              [-114.46809, 34.701786],
              [-114.467894, 34.70084],
              [-114.467591, 34.69941],
              [-114.46752, 34.699196],
              [-114.467161, 34.69811],
              [-114.466994, 34.697597],
              [-114.466741, 34.696817],
              [-114.466487, 34.695492],
              [-114.466174, 34.694191],
              [-114.46591, 34.693189],
              [-114.465607, 34.69226],
              [-114.465246, 34.691202],
              [-114.464767, 34.690209],
              [-114.463808, 34.688291],
              [-114.463633, 34.68794],
              [-114.463398, 34.687594],
              [-114.463091, 34.687144],
              [-114.462785, 34.686693],
              [-114.46265, 34.686494],
              [-114.462514, 34.686294],
              [-114.462178, 34.6858],
              [-114.460078, 34.683485],
              [-114.459377, 34.682634],
              [-114.459134, 34.68234],
              [-114.458892, 34.682045],
              [-114.45875, 34.681873],
              [-114.458608, 34.681701],
              [-114.458163, 34.681161],
              [-114.456503, 34.678893],
              [-114.455536, 34.677335],
              [-114.455346, 34.676842],
              [-114.454881, 34.675639],
              [-114.454823, 34.674491],
              [-114.454869, 34.673746],
              [-114.454895, 34.673329],
              [-114.45491, 34.673092],
              [-114.454803, 34.672317],
              [-114.454738, 34.672175],
              [-114.454305, 34.671234],
              [-114.453016, 34.669895],
              [-114.451532, 34.668605],
              [-114.450877, 34.667822],
              [-114.450506, 34.666836],
              [-114.450614, 34.665793],
              [-114.450631, 34.665756],
              [-114.451014, 34.664911],
              [-114.451208, 34.664655],
              [-114.451785, 34.663891],
              [-114.452071, 34.663638],
              [-114.452262, 34.663469],
              [-114.452454, 34.6633],
              [-114.452986, 34.66283],
              [-114.454549, 34.661824],
              [-114.456345, 34.660471],
              [-114.457185, 34.659992],
              [-114.457702, 34.659328],
              [-114.457748, 34.659253],
              [-114.458093, 34.658689],
              [-114.4582, 34.658055],
              [-114.45821, 34.657994],
              [-114.458182, 34.657885],
              [-114.457985, 34.657113],
              [-114.457028, 34.656266],
              [-114.45531, 34.655154],
              [-114.45407, 34.654429],
              [-114.452547, 34.653494],
              [-114.451014, 34.652422],
              [-114.449549, 34.651423],
              [-114.449267, 34.651129],
              [-114.448836, 34.65068],
              [-114.44871, 34.650558],
              [-114.447636, 34.649519],
              [-114.446679, 34.648494],
              [-114.445664, 34.647542],
              [-114.444276, 34.646542],
              [-114.443242, 34.645307],
              [-114.442363, 34.644153],
              [-114.441927, 34.643365],
              [-114.441858, 34.643241],
              [-114.44179, 34.643116],
              [-114.441724, 34.642997],
              [-114.441658, 34.642878],
              [-114.441465, 34.64253],
              [-114.441007, 34.641229],
              [-114.440519, 34.640066],
              [-114.440511, 34.640002],
              [-114.440294, 34.63824],
              [-114.44046, 34.636404],
              [-114.440685, 34.634739],
              [-114.441095, 34.633113],
              [-114.4415, 34.631622],
              [-114.441525, 34.631529],
              [-114.441523, 34.631512],
              [-114.441502, 34.631284],
              [-114.441481, 34.631056],
              [-114.441398, 34.630171],
              [-114.440813, 34.628645],
              [-114.440696, 34.6284],
              [-114.440579, 34.628155],
              [-114.439934, 34.626803],
              [-114.439495, 34.625858],
              [-114.43928, 34.625009],
              [-114.439227, 34.624662],
              [-114.439197, 34.62447],
              [-114.439174, 34.624315],
              [-114.439022, 34.623316],
              [-114.438951, 34.622854],
              [-114.43887, 34.622316],
              [-114.438739, 34.621455],
              [-114.43803, 34.620976],
              [-114.434762, 34.618769],
              [-114.428648, 34.614641],
              [-114.428545, 34.614544],
              [-114.427273, 34.613346],
              [-114.426001, 34.612147],
              [-114.424202, 34.610453],
              [-114.424249, 34.607368],
              [-114.424255, 34.606932],
              [-114.42428, 34.605354],
              [-114.424311, 34.60334],
              [-114.424326, 34.602338],
              [-114.424928, 34.601448],
              [-114.425076, 34.60123],
              [-114.425224, 34.601011],
              [-114.425338, 34.600842],
              [-114.427502, 34.599227],
              [-114.427622, 34.599194],
              [-114.436363, 34.596797],
              [-114.43681, 34.596074],
              [-114.436733, 34.595923],
              [-114.436466, 34.5954],
              [-114.435671, 34.593841],
              [-114.434386, 34.592572],
              [-114.422382, 34.580711],
              [-114.421989, 34.580457],
              [-114.405946, 34.5701],
              [-114.405228, 34.569637],
              [-114.404708, 34.568585],
              [-114.401391, 34.563246],
              [-114.399461, 34.560139],
              [-114.398847, 34.559149],
              [-114.396912, 34.555766],
              [-114.396618, 34.555252],
              [-114.396324, 34.554737],
              [-114.39612, 34.55438],
              [-114.395916, 34.554024],
              [-114.394892, 34.552232],
              [-114.393868, 34.550441],
              [-114.391956, 34.547098],
              [-114.390045, 34.543755],
              [-114.389603, 34.542982],
              [-114.388956, 34.542004],
              [-114.382229, 34.531828],
              [-114.382108, 34.531644],
              [-114.380838, 34.529724],
              [-114.380329, 34.527153],
              [-114.379387, 34.522397],
              [-114.378223, 34.516521],
              [-114.378131, 34.507968],
              [-114.378124, 34.507288],
              [-114.379671, 34.503419],
              [-114.379943, 34.502737],
              [-114.380154, 34.502212],
              [-114.380638, 34.501004],
              [-114.380761, 34.500695],
              [-114.380836, 34.500509],
              [-114.381034, 34.500014],
              [-114.381402, 34.499245],
              [-114.381464, 34.499029],
              [-114.381755, 34.498028],
              [-114.381803, 34.497848],
              [-114.382316, 34.495914],
              [-114.382358, 34.495758],
              [-114.3825, 34.494854],
              [-114.382675, 34.493735],
              [-114.382843, 34.492199],
              [-114.382919, 34.491503],
              [-114.382935, 34.491133],
              [-114.383038, 34.488903],
              [-114.382975, 34.488303],
              [-114.382817, 34.486791],
              [-114.382652, 34.485397],
              [-114.382603, 34.484978],
              [-114.38216, 34.482372],
              [-114.381935, 34.481049],
              [-114.381806, 34.479979],
              [-114.381693, 34.479033],
              [-114.381566, 34.477981],
              [-114.381555, 34.477883],
              [-114.381684, 34.476252],
              [-114.381701, 34.47604],
              [-114.381751, 34.475858],
              [-114.382494, 34.473123],
              [-114.382726, 34.472512],
              [-114.382864, 34.472149],
              [-114.383001, 34.471786],
              [-114.383525, 34.470405],
              [-114.384829, 34.467698],
              [-114.385611, 34.46597],
              [-114.38633, 34.464381],
              [-114.386584, 34.463821],
              [-114.387023, 34.462511],
              [-114.387187, 34.462021],
              [-114.387407, 34.460492],
              [-114.387356, 34.460087],
              [-114.387242, 34.459179],
              [-114.386699, 34.457911],
              [-114.385328, 34.4564],
              [-114.385175, 34.456231],
              [-114.384426, 34.455458],
              [-114.382985, 34.453971],
              [-114.378852, 34.450376],
              [-114.375789, 34.447798],
              [-114.375435, 34.447584],
              [-114.375212, 34.447449],
              [-114.374408, 34.447112],
              [-114.373719, 34.446938],
              [-114.373029, 34.446884],
              [-114.371217, 34.446992],
              [-114.367282, 34.447358],
              [-114.364605, 34.447644],
              [-114.364528, 34.447652],
              [-114.364451, 34.447661],
              [-114.363404, 34.447773],
              [-114.362408, 34.44808],
              [-114.360212, 34.448677],
              [-114.359462, 34.448867],
              [-114.357756, 34.449303],
              [-114.357062, 34.44948],
              [-114.356368, 34.449657],
              [-114.356025, 34.449744],
              [-114.355976, 34.449749],
              [-114.354144, 34.449939],
              [-114.353122, 34.449988],
              [-114.351827, 34.450011],
              [-114.349306, 34.450137],
              [-114.348974, 34.450166],
              [-114.346862, 34.450679],
              [-114.346525, 34.450761],
              [-114.344998, 34.451071],
              [-114.344379, 34.451197],
              [-114.342615, 34.451442],
              [-114.340794, 34.4515],
              [-114.339627, 34.451435],
              [-114.339322, 34.451372],
              [-114.338074, 34.451116],
              [-114.337476, 34.450917],
              [-114.336659, 34.450645],
              [-114.335967, 34.450319],
              [-114.335372, 34.450038],
              [-114.334351, 34.449397],
              [-114.334251, 34.449335],
              [-114.332991, 34.448082],
              [-114.331762, 34.446651],
              [-114.331389, 34.446188],
              [-114.330669, 34.445295],
              [-114.328991, 34.442294],
              [-114.328305, 34.440953],
              [-114.328125, 34.4406],
              [-114.32724, 34.438786],
              [-114.32688, 34.438048],
              [-114.326717, 34.437856],
              [-114.326429, 34.437519],
              [-114.32613, 34.437251],
              [-114.324779, 34.437119],
              [-114.323667, 34.436901],
              [-114.32276, 34.436724],
              [-114.32115, 34.436348],
              [-114.320904, 34.436287],
              [-114.319054, 34.435831],
              [-114.316962, 34.435064],
              [-114.315298, 34.434267],
              [-114.313605, 34.433411],
              [-114.312251, 34.432726],
              [-114.310347, 34.431693],
              [-114.308659, 34.430485],
              [-114.304566, 34.428571],
              [-114.304259, 34.428418],
              [-114.302577, 34.427583],
              [-114.301982, 34.427287],
              [-114.301386, 34.426991],
              [-114.301016, 34.426807],
              [-114.300593, 34.426441],
              [-114.299101, 34.425132],
              [-114.294836, 34.421389],
              [-114.293669, 34.419881],
              [-114.29339, 34.419521],
              [-114.292226, 34.417606],
              [-114.291903, 34.416231],
              [-114.291947, 34.415268],
              [-114.29196, 34.414968],
              [-114.291969, 34.414794],
              [-114.291934, 34.41205],
              [-114.291751, 34.411104],
              [-114.291631, 34.410838],
              [-114.291154, 34.409784],
              [-114.290219, 34.408291],
              [-114.28879, 34.40676],
              [-114.288663, 34.406623],
              [-114.286874, 34.40539],
              [-114.286802, 34.40534],
              [-114.285698, 34.40496],
              [-114.284053, 34.404236],
              [-114.282261, 34.403641],
              [-114.281867, 34.403551],
              [-114.280108, 34.403147],
              [-114.278363, 34.403061],
              [-114.278302, 34.403058],
              [-114.277133, 34.403001],
              [-114.272184, 34.402961],
              [-114.270571, 34.402794],
              [-114.268658, 34.402601],
              [-114.267521, 34.402486],
              [-114.265883, 34.402021],
              [-114.264317, 34.401329],
              [-114.262909, 34.400373],
              [-114.261709, 34.399047],
              [-114.2613, 34.398548],
              [-114.260349, 34.397389],
              [-114.25822, 34.395046],
              [-114.255241, 34.392188],
              [-114.254241, 34.391354],
              [-114.252739, 34.3901],
              [-114.251906, 34.389756],
              [-114.251345, 34.389524],
              [-114.250784, 34.389293],
              [-114.248649, 34.388113],
              [-114.247706, 34.38743],
              [-114.245261, 34.385659],
              [-114.245168, 34.385589],
              [-114.242682, 34.383715],
              [-114.241106, 34.382433],
              [-114.239867, 34.381426],
              [-114.238909, 34.380583],
              [-114.237266, 34.379135],
              [-114.236411, 34.378428],
              [-114.234275, 34.376662],
              [-114.233564, 34.375693],
              [-114.233065, 34.375013],
              [-114.232941, 34.37479],
              [-114.232572, 34.374124],
              [-114.23245, 34.373902],
              [-114.231614, 34.372392],
              [-114.230779, 34.370882],
              [-114.229686, 34.368908],
              [-114.227171, 34.366805],
              [-114.226107, 34.365916],
              [-114.220564, 34.36419],
              [-114.219857, 34.36397],
              [-114.213774, 34.36246],
              [-114.212348, 34.362368],
              [-114.205971, 34.361954],
              [-114.203231, 34.361709],
              [-114.201055, 34.361514],
              [-114.199482, 34.361373],
              [-114.19648, 34.359187],
              [-114.195361, 34.358551],
              [-114.194316, 34.357956],
              [-114.193271, 34.357362],
              [-114.193212, 34.357328],
              [-114.192825, 34.357108],
              [-114.192438, 34.356889],
              [-114.191094, 34.356125],
              [-114.185556, 34.354386],
              [-114.181145, 34.352186],
              [-114.179591, 34.351129],
              [-114.176909, 34.349306],
              [-114.176721, 34.349106],
              [-114.176296, 34.348654],
              [-114.175871, 34.348201],
              [-114.175176, 34.347461],
              [-114.174481, 34.346721],
              [-114.172845, 34.344979],
              [-114.168807, 34.339513],
              [-114.167087, 34.336038],
              [-114.166952, 34.335766],
              [-114.164249, 34.330816],
              [-114.162605, 34.328145],
              [-114.16223, 34.327537],
              [-114.160474, 34.324566],
              [-114.157939, 34.320277],
              [-114.157206, 34.317862],
              [-114.156769, 34.317525],
              [-114.156591, 34.317388],
              [-114.156413, 34.317251],
              [-114.156269, 34.317139],
              [-114.156124, 34.317028],
              [-114.154431, 34.315724],
              [-114.153441, 34.314977],
              [-114.149631, 34.3121],
              [-114.147374, 34.310874],
              [-114.146117, 34.309806],
              [-114.144317, 34.308275],
              [-114.14123, 34.306128],
              [-114.14093, 34.305919],
              [-114.140605, 34.305589],
              [-114.138862, 34.303819],
              [-114.138282, 34.30323],
              [-114.138167, 34.300936],
              [-114.139187, 34.298074],
              [-114.13939, 34.296763],
              [-114.139402, 34.296684],
              [-114.139415, 34.296604],
              [-114.139446, 34.296405],
              [-114.139534, 34.295844],
              [-114.139395, 34.294969],
              [-114.139121, 34.293234],
              [-114.139024, 34.292639],
              [-114.138365, 34.288564],
              [-114.136677, 34.283936],
              [-114.13605, 34.280833],
              [-114.136504, 34.278934],
              [-114.137045, 34.277018],
              [-114.136671, 34.274377],
              [-114.136075, 34.272326],
              [-114.134768, 34.268965],
              [-114.132722, 34.265444],
              [-114.131718, 34.26364],
              [-114.131485, 34.263223],
              [-114.131211, 34.26273],
              [-114.131327, 34.261745],
              [-114.131489, 34.260387],
              [-114.131798, 34.26005],
              [-114.132652, 34.259125],
              [-114.133264, 34.258462],
              [-114.13545, 34.257886],
              [-114.137743, 34.258135],
              [-114.141737, 34.259084],
              [-114.144779, 34.259623],
              [-114.147159, 34.259564],
              [-114.150277, 34.258898],
              [-114.153346, 34.258289],
              [-114.156853, 34.258415],
              [-114.16109, 34.259251],
              [-114.164648, 34.259699],
              [-114.165249, 34.259125],
              [-114.165335, 34.258486],
              [-114.164808, 34.257242],
              [-114.163959, 34.255377],
              [-114.163867, 34.253349],
              [-114.164476, 34.251667],
              [-114.166124, 34.250015],
              [-114.166536, 34.249647],
              [-114.168578, 34.249086],
              [-114.171393, 34.248332],
              [-114.171635, 34.248268],
              [-114.173459, 34.24776],
              [-114.173657, 34.247706],
              [-114.174597, 34.247303],
              [-114.175635, 34.246174],
              [-114.175906, 34.245587],
              [-114.175956, 34.244165],
              [-114.175948, 34.242695],
              [-114.176403, 34.241512],
              [-114.17805, 34.239969],
              [-114.17827, 34.239812],
              [-114.190876, 34.230858],
              [-114.199036, 34.223407],
              [-114.204725, 34.218529],
              [-114.20613, 34.217323],
              [-114.20692, 34.216647],
              [-114.208253, 34.215505],
              [-114.211761, 34.211539],
              [-114.215454, 34.208956],
              [-114.223384, 34.205136],
              [-114.225194, 34.203642],
              [-114.225861, 34.201774],
              [-114.22579, 34.199236],
              [-114.225075, 34.196814],
              [-114.225053, 34.196349],
              [-114.224941, 34.193896],
              [-114.225814, 34.191238],
              [-114.227034, 34.188866],
              [-114.229023, 34.187427],
              [-114.229715, 34.186928],
              [-114.235417, 34.185122],
              [-114.239519, 34.183706],
              [-114.240712, 34.183232],
              [-114.241849, 34.181885],
              [-114.244191, 34.179625],
              [-114.244421, 34.179403],
              [-114.250783, 34.175736],
              [-114.25239, 34.174711],
              [-114.25268, 34.174533],
              [-114.253018, 34.174355],
              [-114.253444, 34.174129],
              [-114.254141, 34.17383],
              [-114.255147, 34.173475],
              [-114.256076, 34.173119],
              [-114.257034, 34.172837],
              [-114.258001, 34.172626],
              [-114.259085, 34.172392],
              [-114.260169, 34.172117],
              [-114.261242, 34.171818],
              [-114.262375, 34.171567],
              [-114.263855, 34.171244],
              [-114.268267, 34.170209],
              [-114.26846, 34.170177],
              [-114.268993, 34.170264],
              [-114.274926, 34.172047],
              [-114.274959, 34.172056],
              [-114.275267, 34.172149],
              [-114.275417, 34.172131],
              [-114.279011, 34.17168],
              [-114.286724, 34.170715],
              [-114.287014, 34.170658],
              [-114.287294, 34.170529],
              [-114.287894, 34.170101],
              [-114.289373, 34.169093],
              [-114.289408, 34.169069],
              [-114.290775, 34.168138],
              [-114.292371, 34.167056],
              [-114.292806, 34.166725],
              [-114.293086, 34.166377],
              [-114.293511, 34.165917],
              [-114.297337, 34.161314],
              [-114.298168, 34.160321],
              [-114.298522, 34.159954],
              [-114.29889, 34.159571],
              [-114.299009, 34.15945],
              [-114.299531, 34.158941],
              [-114.300497, 34.158069],
              [-114.301425, 34.157116],
              [-114.301567, 34.156948],
              [-114.30205, 34.156378],
              [-114.302095, 34.156327],
              [-114.302188, 34.156219],
              [-114.302565, 34.155807],
              [-114.303396, 34.154943],
              [-114.304121, 34.154281],
              [-114.304624, 34.153837],
              [-114.3053, 34.153174],
              [-114.30589, 34.152657],
              [-114.306498, 34.151995],
              [-114.30703, 34.15143],
              [-114.307802, 34.150574],
              [-114.308411, 34.149702],
              [-114.308512, 34.149566],
              [-114.308591, 34.149458],
              [-114.309096, 34.148773],
              [-114.309676, 34.148224],
              [-114.310024, 34.147901],
              [-114.310294, 34.147562],
              [-114.311067, 34.146496],
              [-114.311317, 34.145987],
              [-114.311655, 34.145381],
              [-114.311945, 34.145058],
              [-114.312206, 34.144776],
              [-114.312592, 34.144453],
              [-114.313056, 34.144186],
              [-114.318287, 34.140479],
              [-114.319956, 34.139297],
              [-114.320777, 34.138635],
              [-114.321618, 34.138093],
              [-114.324331, 34.136869],
              [-114.324576, 34.136759],
              [-114.325427, 34.136596],
              [-114.325813, 34.136516],
              [-114.326096, 34.136458],
              [-114.326684, 34.136337],
              [-114.329479, 34.13564],
              [-114.329796, 34.135507],
              [-114.329904, 34.135462],
              [-114.330736, 34.135114],
              [-114.331649, 34.13496],
              [-114.331703, 34.134951],
              [-114.331954, 34.134944],
              [-114.333056, 34.134684],
              [-114.334327, 34.134413],
              [-114.336112, 34.134034],
              [-114.3383, 34.134032],
              [-114.338622, 34.134064],
              [-114.339198, 34.134119],
              [-114.340021, 34.1342],
              [-114.341617, 34.134246],
              [-114.343921, 34.134309],
              [-114.344054, 34.134318],
              [-114.345571, 34.134375],
              [-114.345605, 34.134376],
              [-114.345738, 34.134427],
              [-114.348051, 34.134457],
              [-114.348486, 34.134416],
              [-114.349163, 34.134327],
              [-114.350478, 34.134107],
              [-114.351735, 34.133613],
              [-114.35303, 34.13312],
              [-114.353542, 34.132732],
              [-114.354228, 34.132263],
              [-114.354875, 34.131753],
              [-114.355618, 34.131067],
              [-114.356372, 34.130428],
              [-114.357028, 34.129564],
              [-114.357654, 34.128659],
              [-114.358358, 34.127617],
              [-114.358753, 34.126721],
              [-114.358986, 34.126231],
              [-114.3591, 34.125994],
              [-114.359174, 34.125739],
              [-114.359274, 34.125404],
              [-114.359389, 34.125016],
              [-114.359698, 34.124678],
              [-114.35977, 34.124527],
              [-114.35981, 34.124434],
              [-114.359923, 34.124223],
              [-114.360402, 34.123577],
              [-114.361344, 34.122802],
              [-114.362634, 34.121713],
              [-114.364524, 34.120013],
              [-114.366517, 34.118577],
              [-114.367711, 34.118111],
              [-114.369292, 34.117519],
              [-114.372171, 34.11696],
              [-114.374493, 34.116691],
              [-114.375778, 34.116647],
              [-114.379223, 34.11599],
              [-114.380723, 34.115262],
              [-114.380719, 34.115238],
              [-114.380924, 34.11515],
              [-114.381506, 34.114897],
              [-114.384078, 34.113319],
              [-114.385734, 34.112424],
              [-114.386006, 34.112275],
              [-114.386105, 34.112221],
              [-114.38618, 34.11218],
              [-114.386805, 34.111839],
              [-114.387013, 34.111726],
              [-114.387523, 34.111468],
              [-114.387817, 34.11132],
              [-114.389093, 34.110675],
              [-114.390565, 34.110084],
              [-114.392774, 34.110533],
              [-114.395332, 34.110804],
              [-114.397264, 34.111038],
              [-114.397306, 34.111043],
              [-114.397631, 34.111083],
              [-114.397776, 34.1111],
              [-114.397873, 34.111112],
              [-114.397971, 34.111124],
              [-114.398093, 34.111139],
              [-114.398489, 34.111196],
              [-114.399208, 34.111301],
              [-114.399443, 34.111335],
              [-114.400108, 34.111434],
              [-114.400697, 34.111518],
              [-114.400823, 34.111544],
              [-114.401087, 34.111598],
              [-114.401336, 34.111638],
              [-114.401571, 34.11163],
              [-114.402255, 34.111588],
              [-114.405201, 34.111457],
              [-114.405916, 34.111468],
              [-114.40686, 34.111297],
              [-114.406896, 34.111288],
              [-114.407995, 34.110997],
              [-114.409801, 34.11051],
              [-114.410106, 34.110432],
              [-114.410236, 34.110399],
              [-114.41168, 34.110031],
              [-114.414591, 34.108337],
              [-114.414837, 34.108206],
              [-114.415908, 34.107636],
              [-114.417701, 34.105957],
              [-114.420499, 34.103466],
              [-114.421848, 34.101322],
              [-114.422117, 34.100897],
              [-114.422899, 34.099661],
              [-114.424162, 34.098595],
              [-114.42428, 34.098495],
              [-114.42432, 34.098464],
              [-114.425271, 34.097648],
              [-114.42554, 34.097482],
              [-114.426168, 34.097042],
              [-114.426759, 34.095686],
              [-114.427065, 34.094985],
              [-114.427139, 34.094814],
              [-114.427202, 34.094669],
              [-114.428026, 34.092787],
              [-114.43165, 34.089715],
              [-114.43338, 34.088413],
              [-114.434181, 34.087379],
              [-114.434353, 34.086499],
              [-114.435429, 34.079727],
              [-114.435907, 34.077491],
              [-114.43632, 34.076208],
              [-114.436983, 34.074326],
              [-114.437683, 34.071937],
              [-114.438416, 34.067021],
              [-114.43899, 34.061033],
              [-114.43934, 34.057893],
              [-114.439406, 34.05381],
              [-114.438602, 34.050205],
              [-114.437298, 34.047552],
              [-114.435816, 34.04373],
              [-114.435507, 34.042615],
              [-114.435498, 34.042566],
              [-114.434949, 34.037784],
              [-114.43541, 34.033023],
              [-114.436171, 34.028083],
              [-114.436852, 34.026016],
              [-114.438266, 34.022609],
              [-114.44054, 34.019329],
              [-114.443821, 34.016176],
              [-114.446815, 34.01421],
              [-114.450206, 34.012574],
              [-114.454807, 34.010968],
              [-114.459861, 34.011838],
              [-114.462126, 34.012112],
              [-114.463336, 34.012259],
              [-114.464525, 34.011982],
              [-114.465867, 34.010987],
              [-114.466704, 34.009436],
              [-114.467027, 34.008519],
              [-114.467282, 34.007795],
              [-114.467404, 34.00745],
              [-114.467356, 34.006304],
              [-114.46731, 34.00519],
              [-114.466651, 34.004355],
              [-114.46628, 34.003885],
              [-114.465465, 34.003463],
              [-114.463131, 34.002253],
              [-114.460965, 34.001255],
              [-114.460689, 34.001128],
              [-114.459777, 34.000412],
              [-114.459184, 34.000016],
              [-114.458789, 33.999289],
              [-114.458443, 33.998679],
              [-114.458166, 33.998108],
              [-114.458026, 33.99782],
              [-114.458028, 33.997158],
              [-114.458194, 33.996438],
              [-114.458629, 33.995555],
              [-114.459258, 33.994711],
              [-114.46012, 33.993888],
              [-114.461133, 33.993541],
              [-114.462255, 33.993442],
              [-114.46361, 33.993431],
              [-114.46486, 33.993428],
              [-114.465499, 33.993423],
              [-114.466187, 33.993465],
              [-114.466834, 33.993387],
              [-114.467336, 33.993229],
              [-114.467932, 33.992877],
              [-114.468372, 33.992405],
              [-114.468792, 33.991885],
              [-114.469152, 33.991253],
              [-114.469581, 33.990636],
              [-114.469922, 33.99006],
              [-114.470253, 33.989371],
              [-114.470357, 33.988972],
              [-114.470737, 33.988488],
              [-114.47077, 33.98845],
              [-114.471138, 33.98804],
              [-114.472237, 33.987104],
              [-114.472259, 33.987087],
              [-114.472773, 33.98671],
              [-114.473219, 33.986384],
              [-114.47409, 33.985756],
              [-114.474649, 33.985355],
              [-114.475907, 33.984424],
              [-114.476619, 33.984063],
              [-114.477427, 33.983677],
              [-114.478244, 33.983227],
              [-114.478456, 33.983111],
              [-114.479226, 33.982741],
              [-114.479689, 33.982482],
              [-114.480198, 33.9822],
              [-114.480947, 33.981677],
              [-114.481455, 33.981261],
              [-114.481971, 33.980708],
              [-114.482333, 33.980181],
              [-114.482586, 33.979525],
              [-114.482778, 33.978579],
              [-114.483097, 33.977745],
              [-114.483573, 33.977031],
              [-114.484107, 33.97634],
              [-114.484784, 33.975519],
              [-114.485348, 33.974933],
              [-114.486455, 33.974399],
              [-114.487059, 33.973922],
              [-114.4875, 33.973575],
              [-114.488459, 33.972832],
              [-114.489215, 33.972067],
              [-114.489623, 33.971518],
              [-114.489729, 33.971377],
              [-114.490128, 33.970924],
              [-114.490398, 33.97062],
              [-114.490788, 33.970294],
              [-114.491327, 33.969845],
              [-114.491508, 33.969712],
              [-114.492115, 33.96937],
              [-114.492195, 33.969277],
              [-114.492766, 33.968784],
              [-114.494051, 33.96774],
              [-114.495047, 33.966835],
              [-114.496042, 33.96589],
              [-114.497237, 33.964645],
              [-114.498135, 33.963725],
              [-114.498756, 33.963034],
              [-114.498919, 33.962838],
              [-114.499194, 33.962457],
              [-114.499653, 33.962009],
              [-114.499883, 33.961789],
              [-114.500123, 33.961666],
              [-114.500298, 33.961608],
              [-114.50079, 33.961572],
              [-114.500888, 33.961515],
              [-114.501719, 33.961084],
              [-114.502549, 33.960597],
              [-114.503379, 33.960118],
              [-114.504161, 33.959768],
              [-114.504807, 33.959411],
              [-114.50501, 33.959321],
              [-114.505676, 33.959029],
              [-114.506352, 33.958736],
              [-114.50698, 33.958378],
              [-114.507453, 33.958191],
              [-114.50808, 33.957834],
              [-114.508669, 33.957525],
              [-114.508747, 33.957502],
              [-114.509568, 33.957264],
              [-114.510428, 33.957043],
              [-114.511231, 33.95704],
              [-114.511918, 33.957191],
              [-114.512205, 33.957267],
              [-114.512577, 33.957367],
              [-114.513449, 33.957703],
              [-114.51434, 33.957934],
              [-114.51497, 33.958149],
              [-114.51586, 33.958106],
              [-114.51671, 33.957877],
              [-114.517667, 33.957591],
              [-114.518352, 33.957193],
              [-114.518979, 33.956812],
              [-114.519588, 33.956584],
              [-114.520302, 33.956307],
              [-114.520727, 33.956192],
              [-114.521104, 33.956062],
              [-114.521529, 33.955858],
              [-114.522002, 33.955623],
              [-114.522339, 33.955307],
              [-114.522734, 33.955039],
              [-114.523129, 33.954666],
              [-114.523311, 33.954425],
              [-114.523504, 33.954172],
              [-114.523793, 33.953824],
              [-114.524014, 33.953517],
              [-114.52439, 33.953128],
              [-114.524832, 33.952618],
              [-114.525179, 33.952189],
              [-114.525804, 33.95146],
              [-114.526353, 33.950917],
              [-114.526776, 33.950294],
              [-114.52715, 33.949704],
              [-114.528084, 33.948602],
              [-114.52868, 33.947817],
              [-114.529246, 33.946742],
              [-114.529897, 33.945302],
              [-114.530566, 33.943629],
              [-114.531391, 33.942117],
              [-114.531947, 33.941049],
              [-114.532513, 33.940006],
              [-114.533156, 33.938857],
              [-114.533674, 33.937951],
              [-114.534463, 33.936891],
              [-114.534961, 33.935759],
              [-114.535478, 33.934651],
              [-114.535676, 33.933552],
              [-114.535791, 33.931308],
              [-114.535834, 33.93025],
              [-114.535866, 33.928708],
              [-114.535853, 33.928103],
              [-114.535765, 33.927604],
              [-114.535753, 33.92753],
              [-114.535411, 33.926837],
              [-114.535205, 33.926297],
              [-114.534951, 33.9257],
              [-114.534572, 33.925379],
              [-114.534146, 33.925187],
              [-114.534132, 33.925184],
              [-114.533694, 33.925091],
              [-114.533284, 33.925004],
              [-114.532297, 33.924871],
              [-114.531107, 33.924633],
              [-114.529489, 33.924041],
              [-114.528385, 33.923674],
              [-114.526698, 33.922905],
              [-114.525361, 33.922272],
              [-114.524382, 33.921759],
              [-114.523393, 33.921221],
              [-114.522141, 33.920289],
              [-114.521035, 33.919518],
              [-114.520094, 33.918795],
              [-114.51924, 33.918152],
              [-114.518434, 33.917518],
              [-114.517085, 33.91632],
              [-114.515881, 33.915372],
              [-114.514503, 33.914214],
              [-114.513202, 33.912919],
              [-114.513182, 33.912846],
              [-114.512386, 33.912082],
              [-114.511511, 33.911092],
              [-114.510547, 33.909594],
              [-114.509874, 33.908482],
              [-114.509163, 33.907209],
              [-114.508558, 33.906098],
              [-114.508147, 33.904961],
              [-114.50792, 33.903807],
              [-114.507848, 33.902815],
              [-114.507988, 33.901813],
              [-114.508708, 33.90064],
              [-114.509681, 33.899789],
              [-114.510944, 33.899099],
              [-114.512383, 33.898383],
              [-114.513715, 33.897959],
              [-114.51499, 33.897809],
              [-114.516344, 33.897918],
              [-114.517381, 33.89835],
              [-114.519318, 33.899231],
              [-114.520704, 33.89992],
              [-114.521828, 33.900538],
              [-114.522943, 33.901131],
              [-114.524289, 33.901587],
              [-114.525053, 33.901641],
              [-114.52569, 33.901428],
              [-114.525872, 33.901008],
              [-114.525868, 33.900233],
              [-114.525749, 33.899402],
              [-114.525541, 33.898378],
              [-114.525254, 33.89699],
              [-114.524813, 33.895684],
              [-114.524782, 33.89563],
              [-114.523634, 33.893638],
              [-114.522768, 33.892583],
              [-114.521506, 33.891272],
              [-114.519981, 33.889776],
              [-114.51866, 33.888263],
              [-114.516709, 33.886696],
              [-114.515535, 33.885586],
              [-114.513768, 33.884203],
              [-114.512467, 33.882884],
              [-114.511001, 33.881525],
              [-114.510138, 33.880777],
              [-114.508074, 33.87963],
              [-114.506649, 33.87865],
              [-114.505272, 33.877662],
              [-114.50434, 33.876882],
              [-114.503796, 33.876068],
              [-114.503395, 33.875018],
              [-114.503599, 33.874019],
              [-114.503686, 33.873316],
              [-114.503821, 33.872517],
              [-114.503889, 33.87188],
              [-114.50386, 33.871234],
              [-114.503666, 33.870524],
              [-114.503279, 33.869547],
              [-114.503066, 33.868781],
              [-114.503017, 33.867998],
              [-114.503104, 33.867166],
              [-114.503414, 33.866448],
              [-114.503887, 33.865754],
              [-114.504603, 33.865172],
              [-114.505638, 33.864276],
              [-114.506635, 33.863484],
              [-114.507476, 33.863122],
              [-114.508666, 33.862766],
              [-114.509895, 33.862257],
              [-114.511346, 33.86157],
              [-114.512459, 33.860689],
              [-114.513213, 33.85989],
              [-114.513977, 33.859002],
              [-114.514673, 33.858638],
              [-114.515631, 33.858266],
              [-114.516811, 33.85812],
              [-114.517589, 33.858266],
              [-114.517837, 33.858313],
              [-114.518998, 33.858563],
              [-114.520054, 33.85899],
              [-114.520838, 33.859288],
              [-114.521757, 33.859643],
              [-114.52287, 33.859965],
              [-114.524292, 33.860133],
              [-114.525666, 33.860003],
              [-114.527069, 33.859429],
              [-114.52791, 33.85892],
              [-114.528877, 33.858306],
              [-114.529883, 33.857563],
              [-114.530011, 33.857275],
              [-114.530327, 33.85657],
              [-114.530607, 33.85544],
              [-114.530536, 33.855001],
              [-114.530423, 33.854294],
              [-114.530335, 33.853878],
              [-114.53018, 33.853148],
              [-114.530103, 33.852663],
              [-114.530074, 33.852228],
              [-114.530209, 33.85134],
              [-114.530111, 33.850403],
              [-114.529966, 33.8495],
              [-114.529597, 33.848063],
              [-114.529026, 33.846675],
              [-114.528009, 33.844464],
              [-114.527215, 33.84256],
              [-114.526053, 33.840002],
              [-114.525539, 33.838614],
              [-114.524561, 33.837057],
              [-114.523815, 33.836121],
              [-114.523409, 33.835323],
              [-114.522711, 33.833386],
              [-114.522169, 33.832256],
              [-114.52091, 33.829053],
              [-114.520465, 33.827778],
              [-114.51997, 33.825381],
              [-114.520153, 33.823839],
              [-114.520733, 33.822031],
              [-114.521409, 33.820844],
              [-114.522714, 33.818979],
              [-114.524628, 33.817557],
              [-114.525923, 33.816862],
              [-114.527161, 33.816191],
              [-114.527886, 33.815617],
              [-114.52805, 33.814963],
              [-114.527866, 33.814382],
              [-114.527662, 33.813624],
              [-114.527188, 33.812639],
              [-114.526962, 33.812321],
              [-114.526394, 33.811518],
              [-114.525446, 33.810227],
              [-114.524682, 33.808961],
              [-114.524033, 33.807492],
              [-114.523365, 33.80612],
              [-114.522813, 33.805241],
              [-114.522407, 33.804217],
              [-114.52201, 33.803047],
              [-114.521555, 33.801982],
              [-114.52081, 33.800812],
              [-114.520094, 33.799473],
              [-114.519484, 33.798279],
              [-114.518942, 33.797302],
              [-114.518642, 33.796415],
              [-114.518438, 33.794994],
              [-114.517751, 33.792242],
              [-114.517276, 33.790184],
              [-114.516734, 33.788345],
              [-114.515969, 33.786545],
              [-114.515224, 33.784939],
              [-114.514286, 33.783366],
              [-114.513338, 33.781405],
              [-114.512003, 33.778759],
              [-114.510958, 33.776314],
              [-114.509894, 33.773756],
              [-114.50882, 33.771569],
              [-114.507814, 33.769439],
              [-114.507182, 33.768123],
              [-114.507089, 33.76793],
              [-114.507005, 33.767643],
              [-114.506672, 33.766501],
              [-114.506024, 33.764734],
              [-114.505521, 33.763193],
              [-114.505172, 33.761563],
              [-114.50507, 33.761202],
              [-114.504863, 33.760465],
              [-114.504839, 33.760205],
              [-114.504766, 33.759408],
              [-114.504658, 33.75864],
              [-114.50434, 33.756381],
              [-114.504329, 33.75492],
              [-114.504377, 33.753815],
              [-114.504445, 33.752305],
              [-114.504483, 33.750998],
              [-114.50483, 33.750021],
              [-114.506, 33.746344],
              [-114.508206, 33.741587],
              [-114.510622, 33.737815],
              [-114.510777, 33.737574],
              [-114.511972, 33.735019],
              [-114.512348, 33.734214],
              [-114.51089, 33.732766],
              [-114.510876, 33.732753],
              [-114.510501, 33.73238],
              [-114.510265, 33.732146],
              [-114.5098, 33.731802],
              [-114.506799, 33.730518],
              [-114.504176, 33.728055],
              [-114.502661, 33.724584],
              [-114.500788, 33.722204],
              [-114.500068, 33.721709],
              [-114.499201, 33.721192],
              [-114.498133, 33.720634],
              [-114.497363, 33.720028],
              [-114.496565, 33.719155],
              [-114.496086, 33.718064],
              [-114.495387, 33.716393],
              [-114.4951, 33.715234],
              [-114.494901, 33.71443],
              [-114.494626, 33.712662],
              [-114.494333, 33.710635],
              [-114.494204, 33.708054],
              [-114.494197, 33.707922],
              [-114.494407, 33.705395],
              [-114.495099, 33.702949],
              [-114.495537, 33.701506],
              [-114.495719, 33.698454],
              [-114.496489, 33.696901],
              [-114.498681, 33.695868],
              [-114.500788, 33.695059],
              [-114.502899, 33.694255],
              [-114.504993, 33.693022],
              [-114.507996, 33.692018],
              [-114.509819, 33.691714],
              [-114.512409, 33.691282],
              [-114.519113, 33.688473],
              [-114.523959, 33.685879],
              [-114.527782, 33.682684],
              [-114.530348, 33.679245],
              [-114.531038, 33.676816],
              [-114.531523, 33.675108],
              [-114.532123, 33.669702],
              [-114.530267, 33.666821],
              [-114.526439, 33.66388],
              [-114.521957, 33.662948],
              [-114.520671, 33.662681],
              [-114.517112, 33.662877],
              [-114.515336, 33.662033],
              [-114.514057, 33.660179],
              [-114.514559, 33.658014],
              [-114.518337, 33.655927],
              [-114.525163, 33.655939],
              [-114.530583, 33.654461],
              [-114.532164, 33.653194],
              [-114.533194, 33.65166],
              [-114.533215, 33.648443],
              [-114.532262, 33.647253],
              [-114.529549, 33.643861],
              [-114.528633, 33.643158],
              [-114.525394, 33.640669],
              [-114.524908, 33.638849],
              [-114.523802, 33.6347],
              [-114.52637, 33.630259],
              [-114.529057, 33.629549],
              [-114.529401, 33.629382],
              [-114.529935, 33.629156],
              [-114.530311, 33.629037],
              [-114.531034, 33.628213],
              [-114.531085, 33.627915],
              [-114.531202, 33.625024],
              [-114.53121, 33.624621],
              [-114.531215, 33.623913],
              [-114.530979, 33.623591],
              [-114.529624, 33.622154],
              [-114.52908, 33.621711],
              [-114.528578, 33.619994],
              [-114.527938, 33.618839],
              [-114.527735, 33.618473],
              [-114.527378, 33.617828],
              [-114.525716, 33.616626],
              [-114.524681, 33.615878],
              [-114.524582, 33.615805],
              [-114.522367, 33.614172],
              [-114.522107, 33.613364],
              [-114.521845, 33.612544],
              [-114.521911, 33.612171],
              [-114.522071, 33.611277],
              [-114.523994, 33.60999],
              [-114.526782, 33.608831],
              [-114.52788, 33.607834],
              [-114.528316, 33.607438],
              [-114.52874, 33.607053],
              [-114.529186, 33.60665],
              [-114.530723, 33.604774],
              [-114.530837, 33.604636],
              [-114.530866, 33.6046],
              [-114.531051, 33.604482],
              [-114.532131, 33.602933],
              [-114.532778, 33.602008],
              [-114.532961, 33.601746],
              [-114.53346, 33.601033],
              [-114.534714, 33.599102],
              [-114.535299, 33.598333],
              [-114.536777, 33.596394],
              [-114.536976, 33.595977],
              [-114.537393, 33.595105],
              [-114.537418, 33.595052],
              [-114.537493, 33.594895],
              [-114.537532, 33.594852],
              [-114.537681, 33.594699],
              [-114.538131, 33.594239],
              [-114.538282, 33.594087],
              [-114.538656, 33.593666],
              [-114.538724, 33.593589],
              [-114.53892, 33.593371],
              [-114.53893, 33.593358],
              [-114.538998, 33.59328],
              [-114.539228, 33.593013],
              [-114.53992, 33.592215],
              [-114.540151, 33.591949],
              [-114.540617, 33.591412],
              [-114.540639, 33.590621],
              [-114.540664, 33.589789],
              [-114.540596, 33.589639],
              [-114.540527, 33.589486],
              [-114.540308, 33.588777],
              [-114.540179, 33.588502],
              [-114.540111, 33.588354],
              [-114.540202, 33.587087],
              [-114.540419, 33.586373],
              [-114.540474, 33.585925],
              [-114.540559, 33.584388],
              [-114.540652, 33.582872],
              [-114.54061, 33.582322],
              [-114.5403, 33.580615],
              [-114.54022, 33.580301],
              [-114.539764, 33.57851],
              [-114.538983, 33.576792],
              [-114.538767, 33.576566],
              [-114.537801, 33.575555],
              [-114.537662, 33.57514],
              [-114.53753, 33.574744],
              [-114.53728, 33.573995],
              [-114.537223, 33.573822],
              [-114.537151, 33.573141],
              [-114.537136, 33.572994],
              [-114.537109, 33.57273],
              [-114.536921, 33.571436],
              [-114.536784, 33.570959],
              [-114.535965, 33.569154],
              [-114.535664, 33.568788],
              [-114.535214, 33.568275],
              [-114.534288, 33.567353],
              [-114.533908, 33.566841],
              [-114.533192, 33.565823],
              [-114.533182, 33.565795],
              [-114.532667, 33.564313],
              [-114.532636, 33.564222],
              [-114.532559, 33.563882],
              [-114.532333, 33.562879],
              [-114.531613, 33.561702],
              [-114.530637, 33.561046],
              [-114.530141, 33.560713],
              [-114.529405, 33.56004],
              [-114.529185, 33.560052],
              [-114.528869, 33.559921],
              [-114.52822, 33.559318],
              [-114.527622, 33.558522],
              [-114.526834, 33.557466],
              [-114.526763, 33.556916],
              [-114.52557, 33.555402],
              [-114.5255, 33.555314],
              [-114.525167, 33.554892],
              [-114.524391, 33.553683],
              [-114.524361, 33.553578],
              [-114.524337, 33.553494],
              [-114.524215, 33.553068],
              [-114.524296, 33.552892],
              [-114.524599, 33.552231],
              [-114.525997, 33.551457],
              [-114.529027, 33.550711],
              [-114.530401, 33.550099],
              [-114.530882, 33.549278],
              [-114.531802, 33.547862],
              [-114.542011, 33.542481],
              [-114.546237, 33.539884],
              [-114.558023, 33.532589],
              [-114.558898, 33.531819],
              [-114.559507, 33.530724],
              [-114.559794, 33.529054],
              [-114.560098, 33.526663],
              [-114.560835, 33.524334],
              [-114.560625, 33.522195],
              [-114.560522, 33.52114],
              [-114.560552, 33.518272],
              [-114.560742, 33.517659],
              [-114.560963, 33.516739],
              [-114.561967, 33.515599],
              [-114.562608, 33.515045],
              [-114.565392, 33.51298],
              [-114.567405, 33.511038],
              [-114.569533, 33.509219],
              [-114.571816, 33.508167],
              [-114.573757, 33.507543],
              [-114.576361, 33.507116],
              [-114.580468, 33.506465],
              [-114.583747, 33.504903],
              [-114.588239, 33.502453],
              [-114.589246, 33.501813],
              [-114.589742, 33.501283],
              [-114.590968, 33.500027],
              [-114.591554, 33.499443],
              [-114.592369, 33.498675],
              [-114.592759, 33.497799],
              [-114.593721, 33.495932],
              [-114.594534, 33.495058],
              [-114.597283, 33.490653],
              [-114.598758, 33.486804],
              [-114.599712, 33.484316],
              [-114.601694, 33.481396],
              [-114.602533, 33.480966],
              [-114.602628, 33.480928],
              [-114.602731, 33.480887],
              [-114.603044, 33.480766],
              [-114.603148, 33.480726],
              [-114.603396, 33.480631],
              [-114.604704, 33.478718],
              [-114.6049, 33.478435],
              [-114.607843, 33.474834],
              [-114.609909, 33.473019],
              [-114.611849, 33.471315],
              [-114.612472, 33.470768],
              [-114.612824, 33.470305],
              [-114.613096, 33.469948],
              [-114.613782, 33.469049],
              [-114.613846, 33.468843],
              [-114.613982, 33.468415],
              [-114.613995, 33.468372],
              [-114.614036, 33.468243],
              [-114.61405, 33.468201],
              [-114.614331, 33.467315],
              [-114.618354, 33.462708],
              [-114.622918, 33.456561],
              [-114.623369, 33.455257],
              [-114.623395, 33.45449],
              [-114.622519, 33.450879],
              [-114.622283, 33.447558],
              [-114.622889, 33.445524],
              [-114.623122, 33.444747],
              [-114.625789, 33.437173],
              [-114.626666, 33.435034],
              [-114.626826, 33.434405],
              [-114.627125, 33.433554],
              [-114.627167, 33.433436],
              [-114.627479, 33.432307],
              [-114.62964, 33.428137],
              [-114.630903, 33.426754],
              [-114.633262, 33.425024],
              [-114.635183, 33.422725],
              [-114.641012, 33.418432],
              [-114.643302, 33.416746],
              [-114.64954, 33.413633],
              [-114.652828, 33.412923],
              [-114.654262, 33.412899],
              [-114.656735, 33.412813],
              [-114.657806, 33.412958],
              [-114.658254, 33.413021],
              [-114.659062, 33.413371],
              [-114.660309, 33.414012],
              [-114.662208, 33.414747],
              [-114.663511, 33.415135],
              [-114.665063, 33.415616],
              [-114.665741, 33.41596],
              [-114.666142, 33.416164],
              [-114.666343, 33.416266],
              [-114.66661, 33.416404],
              [-114.666877, 33.416541],
              [-114.66743, 33.416777],
              [-114.668537, 33.417181],
              [-114.66923, 33.417434],
              [-114.669769, 33.417631],
              [-114.67307, 33.418875],
              [-114.673691, 33.419157],
              [-114.674792, 33.419141],
              [-114.675492, 33.419049],
              [-114.676826, 33.418823],
              [-114.676862, 33.418816],
              [-114.677519, 33.418743],
              [-114.684817, 33.418005],
              [-114.687053, 33.417918],
              [-114.68795, 33.417934],
              [-114.690987, 33.416874],
              [-114.693371, 33.416114],
              [-114.695658, 33.415128],
              [-114.696507, 33.414063],
              [-114.696543, 33.413809],
              [-114.696674, 33.412678],
              [-114.696805, 33.412087],
              [-114.696869, 33.412005],
              [-114.696933, 33.411924],
              [-114.697231, 33.411547],
              [-114.69734, 33.41141],
              [-114.697708, 33.410942],
              [-114.698224, 33.410446],
              [-114.699642, 33.409512],
              [-114.700759, 33.408911],
              [-114.701788, 33.408377],
              [-114.70244, 33.408244],
              [-114.702838, 33.408147],
              [-114.704255, 33.408023],
              [-114.704481, 33.408003],
              [-114.705936, 33.407919],
              [-114.708522, 33.407558],
              [-114.710878, 33.407254],
              [-114.7134, 33.407271],
              [-114.715819, 33.407691],
              [-114.717698, 33.407905],
              [-114.720065, 33.407891],
              [-114.720646, 33.407751],
              [-114.720838, 33.407705],
              [-114.721912, 33.407447],
              [-114.722025, 33.40742],
              [-114.722201, 33.407384],
              [-114.723829, 33.406531],
              [-114.725282, 33.405048],
              [-114.725535, 33.404055],
              [-114.725292, 33.402341],
              [-114.725147, 33.402141],
              [-114.72483, 33.401533],
              [-114.72425, 33.40042],
              [-114.722873, 33.398776],
              [-114.721994, 33.397774],
              [-114.721757, 33.397504],
              [-114.721232, 33.396911],
              [-114.720711, 33.396379],
              [-114.720463, 33.396125],
              [-114.719275, 33.394909],
              [-114.719183, 33.394791],
              [-114.718998, 33.394559],
              [-114.718073, 33.393396],
              [-114.717777, 33.393095],
              [-114.715619, 33.390718],
              [-114.714173, 33.388953],
              [-114.713602, 33.388256],
              [-114.713522, 33.388184],
              [-114.713192, 33.387895],
              [-114.712744, 33.387555],
              [-114.712225, 33.387127],
              [-114.711095, 33.386215],
              [-114.711031, 33.386166],
              [-114.710967, 33.386116],
              [-114.710881, 33.386046],
              [-114.710867, 33.386034],
              [-114.710344, 33.385605],
              [-114.71005, 33.385384],
              [-114.709201, 33.384744],
              [-114.708929, 33.384538],
              [-114.708407, 33.384142],
              [-114.707309, 33.38254],
              [-114.707213, 33.382146],
              [-114.707023, 33.381374],
              [-114.707009, 33.380633],
              [-114.707069, 33.379506],
              [-114.707317, 33.37883],
              [-114.707351, 33.378738],
              [-114.707403, 33.378597],
              [-114.707485, 33.378375],
              [-114.707537, 33.377561],
              [-114.707348, 33.376627],
              [-114.707035, 33.375784],
              [-114.706997, 33.375692],
              [-114.706722, 33.37503],
              [-114.705572, 33.373261],
              [-114.704201, 33.371238],
              [-114.701959, 33.367134],
              [-114.700356, 33.363705],
              [-114.699872, 33.362753],
              [-114.699056, 33.361148],
              [-114.69817, 33.356575],
              [-114.698035, 33.352442],
              [-114.699124, 33.349258],
              [-114.69935, 33.345692],
              [-114.700103, 33.341045],
              [-114.700938, 33.337014],
              [-114.701363, 33.336028],
              [-114.701709, 33.335225],
              [-114.702364, 33.333711],
              [-114.702443, 33.333548],
              [-114.702481, 33.333466],
              [-114.705186, 33.327709],
              [-114.70787, 33.323316],
              [-114.709801, 33.321139],
              [-114.710627, 33.3205],
              [-114.710679, 33.320455],
              [-114.713023, 33.31888],
              [-114.717056, 33.316664],
              [-114.71861, 33.315761],
              [-114.721015, 33.314068],
              [-114.722754, 33.312736],
              [-114.723623, 33.312109],
              [-114.724665, 33.310097],
              [-114.726484, 33.308273],
              [-114.729904, 33.305745],
              [-114.731222, 33.304039],
              [-114.731223, 33.302433],
              [-114.730417, 33.300711],
              [-114.728202, 33.296664],
              [-114.724999, 33.291097],
              [-114.723259, 33.288079],
              [-114.72167, 33.286982],
              [-114.717875, 33.285156],
              [-114.711197, 33.283341],
              [-114.702873, 33.281916],
              [-114.697175, 33.280475],
              [-114.694449, 33.279785],
              [-114.688599, 33.277861],
              [-114.684363, 33.276023],
              [-114.680507, 33.273576],
              [-114.677032, 33.270169],
              [-114.674595, 33.265453],
              [-114.672401, 33.260469],
              [-114.672088, 33.258499],
              [-114.672924, 33.257042],
              [-114.674491, 33.255597],
              [-114.67766, 33.254426],
              [-114.68076, 33.252212],
              [-114.683253, 33.250034],
              [-114.685415, 33.249056],
              [-114.688205, 33.247965],
              [-114.689541, 33.246428],
              [-114.689421, 33.24525],
              [-114.689383, 33.245186],
              [-114.687481, 33.241981],
              [-114.68424, 33.237479],
              [-114.682731, 33.234918],
              [-114.681566, 33.233758],
              [-114.678097, 33.2303],
              [-114.674479, 33.225504],
              [-114.673626, 33.223121],
              [-114.673715, 33.219245],
              [-114.675426, 33.213779],
              [-114.676072, 33.210835],
              [-114.677677, 33.206985],
              [-114.678749, 33.203448],
              [-114.678177, 33.199577],
              [-114.678163, 33.199488],
              [-114.678049, 33.199073],
              [-114.676667, 33.194001],
              [-114.676491, 33.193359],
              [-114.675676, 33.19037],
              [-114.675189, 33.188178],
              [-114.675359, 33.185488],
              [-114.67583, 33.18152],
              [-114.677856, 33.176803],
              [-114.679033, 33.174737],
              [-114.679085, 33.174651],
              [-114.679115, 33.174608],
              [-114.679849, 33.171499],
              [-114.680237, 33.169637],
              [-114.68089, 33.169074],
              [-114.680608, 33.167157],
              [-114.67935, 33.162433],
              [-114.679945, 33.159059],
              [-114.682759, 33.154808],
              [-114.684907, 33.147823],
              [-114.687405, 33.141983],
              [-114.690246, 33.137724],
              [-114.694858, 33.13346],
              [-114.696914, 33.131119],
              [-114.697828, 33.12865],
              [-114.699296, 33.125037],
              [-114.703682, 33.113768],
              [-114.706175, 33.105334],
              [-114.707028, 33.101784],
              [-114.707896, 33.097431],
              [-114.708133, 33.094022],
              [-114.707819, 33.091102],
              [-114.706488, 33.08816],
              [-114.70473, 33.087051],
              [-114.701165, 33.086368],
              [-114.697132, 33.086394],
              [-114.694628, 33.086226],
              [-114.692548, 33.085786],
              [-114.68902, 33.084035],
              [-114.688597, 33.082869],
              [-114.689307, 33.079179],
              [-114.68912, 33.076121],
              [-114.686991, 33.070968],
              [-114.682348, 33.065823],
              [-114.682097, 33.065545],
              [-114.681247, 33.064591],
              [-114.680899, 33.064184],
              [-114.680757, 33.064019],
              [-114.679114, 33.061966],
              [-114.676516, 33.05926],
              [-114.675303, 33.058444],
              [-114.674295, 33.057169],
              [-114.674434, 33.054204],
              [-114.675111, 33.048638],
              [-114.675103, 33.04753],
              [-114.675041, 33.047065],
              [-114.674872, 33.045816],
              [-114.67483, 33.045507],
              [-114.674762, 33.045301],
              [-114.673659, 33.041896],
              [-114.672224, 33.040173],
              [-114.670803, 33.037983],
              [-114.668209, 33.036003],
              [-114.66506, 33.033906],
              [-114.662317, 33.03267],
              [-114.659832, 33.032664],
              [-114.657827, 33.033824],
              [-114.655038, 33.037106],
              [-114.653356, 33.039832],
              [-114.653197, 33.040105],
              [-114.652811, 33.040771],
              [-114.652703, 33.041114],
              [-114.652684, 33.041148],
              [-114.650999, 33.044131],
              [-114.649001, 33.046762],
              [-114.647049, 33.048416],
              [-114.645979, 33.048902],
              [-114.64482, 33.048644],
              [-114.641621, 33.046894],
              [-114.639552, 33.04529],
              [-114.638808, 33.044384],
              [-114.638716, 33.04426],
              [-114.638014, 33.04331],
              [-114.637817, 33.04312],
              [-114.636443, 33.041796],
              [-114.635807, 33.041015],
              [-114.634257, 33.039107],
              [-114.63419, 33.039024],
              [-114.633887, 33.038535],
              [-114.63383, 33.038446],
              [-114.633697, 33.038235],
              [-114.631343, 33.03502],
              [-114.629732, 33.032546],
              [-114.628294, 33.03105],
              [-114.627416, 33.030382],
              [-114.625787, 33.029435],
              [-114.62387, 33.02872],
              [-114.618788, 33.027202],
              [-114.611584, 33.026221],
              [-114.606282, 33.025703],
              [-114.603369, 33.025546],
              [-114.60335, 33.025536],
              [-114.602713, 33.025499],
              [-114.602544, 33.025489],
              [-114.602117, 33.025464],
              [-114.601988, 33.025457],
              [-114.601655, 33.025439],
              [-114.601228, 33.02542],
              [-114.601014, 33.02541],
              [-114.598871, 33.025391],
              [-114.598825, 33.02539],
              [-114.598093, 33.025384],
              [-114.596109, 33.025657],
              [-114.595886, 33.025688],
              [-114.594725, 33.025848],
              [-114.589778, 33.026228],
              [-114.588203, 33.026631],
              [-114.588078, 33.026663],
              [-114.586982, 33.026944],
              [-114.585793, 33.027634],
              [-114.585598, 33.027747],
              [-114.584765, 33.02823],
              [-114.583029, 33.030149],
              [-114.581404, 33.032545],
              [-114.57937, 33.034563],
              [-114.579054, 33.0348],
              [-114.578287, 33.035375],
              [-114.577605, 33.035598],
              [-114.577449, 33.035649],
              [-114.575161, 33.036541],
              [-114.571653, 33.036624],
              [-114.569196, 33.036156],
              [-114.5648, 33.035077],
              [-114.56085, 33.035285],
              [-114.557597, 33.0349],
              [-114.553189, 33.033974],
              [-114.549933, 33.033823],
              [-114.543131, 33.03376],
              [-114.538459, 33.033422],
              [-114.531083, 33.032393],
              [-114.527555, 33.031731],
              [-114.523578, 33.03096],
              [-114.52013, 33.029984],
              [-114.517894, 33.028612],
              [-114.516639, 33.027746],
              [-114.516454, 33.027617],
              [-114.5149, 33.026524],
              [-114.511343, 33.023455],
              [-114.507956, 33.019708],
              [-114.507924, 33.019662],
              [-114.507817, 33.019508],
              [-114.506362, 33.017403],
              [-114.506138, 33.017006],
              [-114.506123, 33.016998],
              [-114.504981, 33.01506],
              [-114.504916, 33.014949],
              [-114.504696, 33.014578],
              [-114.504682, 33.014552],
              [-114.504652, 33.014502],
              [-114.50287, 33.011154],
              [-114.501226, 33.007556],
              [-114.500782, 33.006318],
              [-114.499797, 33.003905],
              [-114.499643, 33.002088],
              [-114.49941, 33.00004],
              [-114.498511, 32.99698],
              [-114.498032, 32.994446],
              [-114.497551, 32.992548],
              [-114.497315, 32.991473],
              [-114.497052, 32.990206],
              [-114.496934, 32.988649],
              [-114.496798, 32.986534],
              [-114.4965, 32.984508],
              [-114.496133, 32.982788],
              [-114.495946, 32.981473],
              [-114.495712, 32.980075],
              [-114.495429, 32.978937],
              [-114.495033, 32.977427],
              [-114.494759, 32.976312],
              [-114.494467, 32.975158],
              [-114.494212, 32.974262],
              [-114.493718, 32.973123],
              [-114.492938, 32.971781],
              [-114.492184, 32.971021],
              [-114.490932, 32.970235],
              [-114.490129, 32.969884],
              [-114.489526, 32.969819],
              [-114.488625, 32.969946],
              [-114.48779, 32.970225],
              [-114.486217, 32.970892],
              [-114.484806, 32.971339],
              [-114.483464, 32.97157],
              [-114.482486, 32.971753],
              [-114.481872, 32.971871],
              [-114.481315, 32.972064],
              [-114.48117, 32.972419],
              [-114.481092, 32.972911],
              [-114.480831, 32.973362],
              [-114.480303, 32.973748],
              [-114.479477, 32.974189],
              [-114.478383, 32.974606],
              [-114.477308, 32.975023],
              [-114.476532, 32.975174],
              [-114.475171, 32.975154],
              [-114.473745, 32.974891],
              [-114.472606, 32.974654],
              [-114.471334, 32.974207],
              [-114.470511, 32.973858],
              [-114.469948, 32.973469],
              [-114.46949, 32.972878],
              [-114.468995, 32.972239],
              [-114.468605, 32.971649],
              [-114.468379, 32.970745],
              [-114.468109, 32.96888],
              [-114.467695, 32.967015],
              [-114.467367, 32.965384],
              [-114.467315, 32.96406],
              [-114.46731, 32.962939],
              [-114.467268, 32.961591],
              [-114.467274, 32.960172],
              [-114.467337, 32.958954],
              [-114.467399, 32.957912],
              [-114.467624, 32.956663],
              [-114.468061, 32.95526],
              [-114.468536, 32.953922],
              [-114.469213, 32.952455],
              [-114.470082, 32.950778],
              [-114.470768, 32.949424],
              [-114.471692, 32.948129],
              [-114.472578, 32.947082],
              [-114.473262, 32.94614],
              [-114.474042, 32.94515],
              [-114.474907, 32.944362],
              [-114.475984, 32.943348],
              [-114.477311, 32.94198],
              [-114.478456, 32.940555],
              [-114.479362, 32.939217],
              [-114.48022, 32.937928],
              [-114.48074, 32.937027],
              [-114.480925, 32.936276],
              [-114.48092, 32.935252],
              [-114.480783, 32.933678],
              [-114.480348, 32.932353],
              [-114.479893, 32.931174],
              [-114.479571, 32.930237],
              [-114.479005, 32.928291],
              [-114.478493, 32.927014],
              [-114.477952, 32.925706],
              [-114.47664, 32.923628],
              [-114.475449, 32.922179],
              [-114.473713, 32.920594],
              [-114.471995, 32.919241],
              [-114.46939, 32.917119],
              [-114.467853, 32.916058],
              [-114.466794, 32.915185],
              [-114.465516, 32.914091],
              [-114.464448, 32.913128],
              [-114.463972, 32.912441],
              [-114.46365, 32.911682],
              [-114.463464, 32.910406],
              [-114.463182, 32.909211],
              [-114.462929, 32.907944],
              [-114.46289, 32.905797],
              [-114.462983, 32.904167],
              [-114.463127, 32.901884],
              [-114.463258, 32.900149],
              [-114.463302, 32.899226],
              [-114.463307, 32.899116],
              [-114.463776, 32.895494],
              [-114.464172, 32.892454],
              [-114.464284, 32.890833],
              [-114.46451, 32.889485],
              [-114.464714, 32.888464],
              [-114.464723, 32.88843],
              [-114.4648, 32.888098],
              [-114.465172, 32.885295],
              [-114.465312, 32.883083],
              [-114.465361, 32.882312],
              [-114.465377, 32.881692],
              [-114.46546, 32.879428],
              [-114.465476, 32.879229],
              [-114.465499, 32.878128],
              [-114.465504, 32.877963],
              [-114.465524, 32.876753],
              [-114.465588, 32.875295],
              [-114.46562, 32.874791],
              [-114.465678, 32.874075],
              [-114.465711, 32.873681],
              [-114.466031, 32.870605],
              [-114.468971, 32.845155],
              [-114.469025, 32.845103],
              [-114.475892, 32.838693],
              [-114.482546, 32.8331],
              [-114.48278, 32.832904],
              [-114.487795, 32.828691],
              [-114.488207, 32.828344],
              [-114.488563, 32.828045],
              [-114.489248, 32.827458],
              [-114.489797, 32.82699],
              [-114.490062, 32.826763],
              [-114.490091, 32.826738],
              [-114.490698, 32.826219],
              [-114.490955, 32.826],
              [-114.491451, 32.82557],
              [-114.491593, 32.825449],
              [-114.493268, 32.824022],
              [-114.493334, 32.823966],
              [-114.494116, 32.823287],
              [-114.494193, 32.823234],
              [-114.495588, 32.822628],
              [-114.496284, 32.822325],
              [-114.496827, 32.822119],
              [-114.49827, 32.821434],
              [-114.498544, 32.821302],
              [-114.500781, 32.820393],
              [-114.501616, 32.820029],
              [-114.506414, 32.817932],
              [-114.507273, 32.817589],
              [-114.507829, 32.817366],
              [-114.510279, 32.816516],
              [-114.510327, 32.816488],
              [-114.511886, 32.814961],
              [-114.51203, 32.814833],
              [-114.512834, 32.814182],
              [-114.513274, 32.813758],
              [-114.514509, 32.812357],
              [-114.515389, 32.811439],
              [-114.516338, 32.810076],
              [-114.516645, 32.809528],
              [-114.518504, 32.80706],
              [-114.519758, 32.805676],
              [-114.520363, 32.804385],
              [-114.520385, 32.803576],
              [-114.522031, 32.801675],
              [-114.525274, 32.798841],
              [-114.528849, 32.796307],
              [-114.529633, 32.795477],
              [-114.530755, 32.793484],
              [-114.531669, 32.791185],
              [-114.531587, 32.78726],
              [-114.531746, 32.782503],
              [-114.531851, 32.781906],
              [-114.532426, 32.778644],
              [-114.532432, 32.776922],
              [-114.531831, 32.774264],
              [-114.530525, 32.771571],
              [-114.529264, 32.769484],
              [-114.528443, 32.767276],
              [-114.52834, 32.766646],
              [-114.527608, 32.763114],
              [-114.52727, 32.760416],
              [-114.526856, 32.757094],
              [-114.527099, 32.757091],
              [-114.527523, 32.757086],
              [-114.531003, 32.757045],
              [-114.53394, 32.75701],
              [-114.53412, 32.757008],
              [-114.536409, 32.75698],
              [-114.537489, 32.756967],
              [-114.538443, 32.756956],
              [-114.538657, 32.756954],
              [-114.538788, 32.756952],
              [-114.538953, 32.75695],
              [-114.539092, 32.756949],
              [-114.539067, 32.753591],
              [-114.539046, 32.750904],
              [-114.539132, 32.750045],
              [-114.539224, 32.749812],
              [-114.548283, 32.749645],
              [-114.550235, 32.74966],
              [-114.551514, 32.74965],
              [-114.552113, 32.749646],
              [-114.564447, 32.749554],
              [-114.564508, 32.742274],
              [-114.564632, 32.742274],
              [-114.572997, 32.742306],
              [-114.573139, 32.742309],
              [-114.573404, 32.742313],
              [-114.578852, 32.742351],
              [-114.581736, 32.74232],
              [-114.581766, 32.7402],
              [-114.581766, 32.736607],
              [-114.581784, 32.734946],
              [-114.581803, 32.734946],
              [-114.583103, 32.73492],
              [-114.583166, 32.734919],
              [-114.584657, 32.73489],
              [-114.585763, 32.734868],
              [-114.586884, 32.734846],
              [-114.612655, 32.734516],
              [-114.612697, 32.734515],
              [-114.613109, 32.734515],
              [-114.613497, 32.734523],
              [-114.614007, 32.734517],
              [-114.614484, 32.734513],
              [-114.615112, 32.734515],
              [-114.615004, 32.73434],
              [-114.614787, 32.734076],
              [-114.614773, 32.733942],
              [-114.614775, 32.733713],
              [-114.614747, 32.733358],
              [-114.614783, 32.73305],
              [-114.614786, 32.732846],
              [-114.614833, 32.732761],
              [-114.614848, 32.73267],
              [-114.614924, 32.732525],
              [-114.614971, 32.73247],
              [-114.615117, 32.732291],
              [-114.615184, 32.732201],
              [-114.615276, 32.732031],
              [-114.615281, 32.731982],
              [-114.615291, 32.731899],
              [-114.615396, 32.731648],
              [-114.61543, 32.731584],
              [-114.615504, 32.731449],
              [-114.615507, 32.73119],
              [-114.61548, 32.730899],
              [-114.615466, 32.730707],
              [-114.615438, 32.73057],
              [-114.61544, 32.730386],
              [-114.615517, 32.730303],
              [-114.615501, 32.730044],
              [-114.615654, 32.730034],
              [-114.615718, 32.73003],
              [-114.615794, 32.729892],
              [-114.61587, 32.729717],
              [-114.61587, 32.729633],
              [-114.61587, 32.72958],
              [-114.61572, 32.729413],
              [-114.615716, 32.729339],
              [-114.615719, 32.729308],
              [-114.615723, 32.729295],
              [-114.615726, 32.729284],
              [-114.61571, 32.729262],
              [-114.615677, 32.729217],
              [-114.615665, 32.7292],
              [-114.615649, 32.729151],
              [-114.615641, 32.729124],
              [-114.61564, 32.729097],
              [-114.615639, 32.729034],
              [-114.615649, 32.728938],
              [-114.615654, 32.728851],
              [-114.615666, 32.728662],
              [-114.615674, 32.728518],
              [-114.61567, 32.728454],
              [-114.615705, 32.728458],
              [-114.615976, 32.728489],
              [-114.616316, 32.728412],
              [-114.616722, 32.728358],
              [-114.617186, 32.728291],
              [-114.617395, 32.728257],
              [-114.618373, 32.728353],
              [-114.619417, 32.72848],
              [-114.619609, 32.728504],
              [-114.619792, 32.728558],
              [-114.620036, 32.728603],
              [-114.620234, 32.728626],
              [-114.620385, 32.728644],
              [-114.620385, 32.728657],
              [-114.620677, 32.728726],
              [-114.620967, 32.728779],
              [-114.621016, 32.728787],
              [-114.621287, 32.728831],
              [-114.621852, 32.728924],
              [-114.622508, 32.729069],
              [-114.623149, 32.729076],
              [-114.623759, 32.729199],
              [-114.624248, 32.729298],
              [-114.624502, 32.729327],
              [-114.624535, 32.729331],
              [-114.624583, 32.729336],
              [-114.624644, 32.729342],
              [-114.626277, 32.729511],
              [-114.626719, 32.729549],
              [-114.626811, 32.729557],
              [-114.627299, 32.729626],
              [-114.627911, 32.729717],
              [-114.628307, 32.729785],
              [-114.628719, 32.729839],
              [-114.629299, 32.729908],
              [-114.629955, 32.730083],
              [-114.630395, 32.730188],
              [-114.630534, 32.730221],
              [-114.631419, 32.730426],
              [-114.632074, 32.730643],
              [-114.632686, 32.730846],
              [-114.633327, 32.731052],
              [-114.633967, 32.731167],
              [-114.634164, 32.731201],
              [-114.634274, 32.73122],
              [-114.634594, 32.731304],
              [-114.635006, 32.731372],
              [-114.635493, 32.731419],
              [-114.635647, 32.731434],
              [-114.636318, 32.73151],
              [-114.637142, 32.731571],
              [-114.638058, 32.731686],
              [-114.63876, 32.731738],
              [-114.639706, 32.7318],
              [-114.640438, 32.731854],
              [-114.642658, 32.731999],
              [-114.644157, 32.732039],
              [-114.645353, 32.732139],
              [-114.64601, 32.732282],
              [-114.646114, 32.732294],
              [-114.648006, 32.732525],
              [-114.648388, 32.732624],
              [-114.648937, 32.732761],
              [-114.649502, 32.732861],
              [-114.650295, 32.733013],
              [-114.650358, 32.733018],
              [-114.650558, 32.733032],
              [-114.651028, 32.733067],
              [-114.651867, 32.733173],
              [-114.65263, 32.733317],
              [-114.653515, 32.73344],
              [-114.654247, 32.73357],
              [-114.654835, 32.733598],
              [-114.654888, 32.733601],
              [-114.655773, 32.733607],
              [-114.656689, 32.733662],
              [-114.657558, 32.733738],
              [-114.65826, 32.733798],
              [-114.65852, 32.73379],
              [-114.65884, 32.733829],
              [-114.659176, 32.733814],
              [-114.659528, 32.733829],
              [-114.660184, 32.733829],
              [-114.660657, 32.733883],
              [-114.661283, 32.733959],
              [-114.661939, 32.73386],
              [-114.662717, 32.733952],
              [-114.663556, 32.733959],
              [-114.66435, 32.733936],
              [-114.665082, 32.73399],
              [-114.665494, 32.733997],
              [-114.665921, 32.734028],
              [-114.666623, 32.734135],
              [-114.667493, 32.734226],
              [-114.667687, 32.734251],
              [-114.667722, 32.734257],
              [-114.668195, 32.734325],
              [-114.668744, 32.734417],
              [-114.669278, 32.734538],
              [-114.66995, 32.734676],
              [-114.67059, 32.734806],
              [-114.671338, 32.734882],
              [-114.672025, 32.734951],
              [-114.672711, 32.735096],
              [-114.673368, 32.735225],
              [-114.673993, 32.735416],
              [-114.674787, 32.735546],
              [-114.675702, 32.735782],
              [-114.67648, 32.736033],
              [-114.677091, 32.736218],
              [-114.677945, 32.736446],
              [-114.678632, 32.736614],
              [-114.678876, 32.736614],
              [-114.679349, 32.736697],
              [-114.680097, 32.736874],
              [-114.680139, 32.736881],
              [-114.680738, 32.736981],
              [-114.681729, 32.737156],
              [-114.682614, 32.737348],
              [-114.68274, 32.737321],
              [-114.682902, 32.73725],
              [-114.683292, 32.737326],
              [-114.684164, 32.73752],
              [-114.685489, 32.737456],
              [-114.686713, 32.737374],
              [-114.686987, 32.737346],
              [-114.687284, 32.737392],
              [-114.687528, 32.737408],
              [-114.687863, 32.737461],
              [-114.68823, 32.73753],
              [-114.68852, 32.737591],
              [-114.688779, 32.737675],
              [-114.689038, 32.737789],
              [-114.689282, 32.737927],
              [-114.689618, 32.738171],
              [-114.689878, 32.738461],
              [-114.690244, 32.738757],
              [-114.690488, 32.738987],
              [-114.690717, 32.739224],
              [-114.690922, 32.739421],
              [-114.690962, 32.73946],
              [-114.69116, 32.739666],
              [-114.691511, 32.739894],
              [-114.691801, 32.740147],
              [-114.692137, 32.740344],
              [-114.692534, 32.740566],
              [-114.693052, 32.740872],
              [-114.693525, 32.741146],
              [-114.693968, 32.741413],
              [-114.69441, 32.74168],
              [-114.694883, 32.741932],
              [-114.695387, 32.742244],
              [-114.695799, 32.742558],
              [-114.696196, 32.742877],
              [-114.696562, 32.743145],
              [-114.696959, 32.743443],
              [-114.697401, 32.743831],
              [-114.697935, 32.744259],
              [-114.698378, 32.744557],
              [-114.69879, 32.744846],
              [-114.699141, 32.74503],
              [-114.699247, 32.745098],
              [-114.699507, 32.74522],
              [-114.699873, 32.745312],
              [-114.700285, 32.745434],
              [-114.700743, 32.745617],
              [-114.701155, 32.745663],
              [-114.701582, 32.745632],
              [-114.701918, 32.745548],
              [-114.702223, 32.74541],
              [-114.702604, 32.745106],
              [-114.702833, 32.744884],
              [-114.70294, 32.744793],
              [-114.703016, 32.744639],
              [-114.703306, 32.744282],
              [-114.703673, 32.743862],
              [-114.704039, 32.743481],
              [-114.704466, 32.743023],
              [-114.705015, 32.742344],
              [-114.705382, 32.741917],
              [-114.705717, 32.74158],
              [-114.706114, 32.740986],
              [-114.706419, 32.740414],
              [-114.70674, 32.739948],
              [-114.70706, 32.739483],
              [-114.707396, 32.738827],
              [-114.707548, 32.73827],
              [-114.707899, 32.737568],
              [-114.708128, 32.737125],
              [-114.708387, 32.736631],
              [-114.708647, 32.73615],
              [-114.708861, 32.735852],
              [-114.709074, 32.735456],
              [-114.709196, 32.735334],
              [-114.709379, 32.735112],
              [-114.709562, 32.734883],
              [-114.709807, 32.734662],
              [-114.710096, 32.734417],
              [-114.71031, 32.734204],
              [-114.710615, 32.733936],
              [-114.710875, 32.7338],
              [-114.711088, 32.733693],
              [-114.711424, 32.733503],
              [-114.711882, 32.733227],
              [-114.712217, 32.732976],
              [-114.712629, 32.732678],
              [-114.712904, 32.732411],
              [-114.713209, 32.732098],
              [-114.713377, 32.7319],
              [-114.71353, 32.731656],
              [-114.713835, 32.731282],
              [-114.714186, 32.730832],
              [-114.714522, 32.73039],
              [-114.714811, 32.729855],
              [-114.714986, 32.729517],
              [-114.715071, 32.729352],
              [-114.715267, 32.728945],
              [-114.71533, 32.728817],
              [-114.71562, 32.728208],
              [-114.715651, 32.728116],
              [-114.715788, 32.727758],
              [-114.71591, 32.727376],
              [-114.716017, 32.726979],
              [-114.716154, 32.726498],
              [-114.716276, 32.72607],
              [-114.716383, 32.725667],
              [-114.71649, 32.725285],
              [-114.716612, 32.72495],
              [-114.716749, 32.72453],
              [-114.716826, 32.724248],
              [-114.716963, 32.72392],
              [-114.717116, 32.723638],
              [-114.717299, 32.723142],
              [-114.717436, 32.722676],
              [-114.717543, 32.72218],
              [-114.717665, 32.721654],
              [-114.717695, 32.721547],
              [-114.717878, 32.721372],
              [-114.717955, 32.721142],
              [-114.718184, 32.720914],
              [-114.718443, 32.720624],
              [-114.718687, 32.720304],
              [-114.718977, 32.719869],
              [-114.719191, 32.719426],
              [-114.71945, 32.719029],
              [-114.719633, 32.718763],
              [-114.719723, 32.718753],
              [-114.719995, 32.718728],
              [-114.720086, 32.71872],
              [-114.720289, 32.718701],
              [-114.721309, 32.718626],
              [-114.72498, 32.718359],
              [-114.726166, 32.718274],
              [-114.726204, 32.71827],
              [-114.726269, 32.718263],
              [-114.726465, 32.718241],
              [-114.726531, 32.718236],
              [-114.726616, 32.718227],
              [-114.726871, 32.718201],
              [-114.726957, 32.718194],
              [-114.727004, 32.71819],
              [-114.727268, 32.718179],
              [-114.728203, 32.718141],
              [-114.728515, 32.718129],
              [-114.728809, 32.718092],
              [-114.729694, 32.717982],
              [-114.729989, 32.717947],
              [-114.731352, 32.717864],
              [-114.735442, 32.717616],
              [-114.736806, 32.717534],
              [-114.739602, 32.717364],
              [-114.747992, 32.716856],
              [-114.750789, 32.716688],
              [-114.7847, 32.714323],
              [-114.875796, 32.70797],
              [-114.886433, 32.707219],
              [-114.909229, 32.705611],
              [-114.920346, 32.704878],
              [-114.932465, 32.704079],
              [-114.955905, 32.702535],
              [-114.968824, 32.701712],
              [-114.980946, 32.70094],
              [-115.000802, 32.699676],
              [-115.051872, 32.696075],
              [-115.125808, 32.690865],
              [-115.250813, 32.682309],
              [-115.26464, 32.681297],
              [-115.335545, 32.676108],
              [-115.335756, 32.676091],
              [-115.336388, 32.676046],
              [-115.3366, 32.676031],
              [-115.341884, 32.675644],
              [-115.357737, 32.674481],
              [-115.363022, 32.674096],
              [-115.367623, 32.673758],
              [-115.37582, 32.673161],
              [-115.38143, 32.67278],
              [-115.386034, 32.672468],
              [-115.386476, 32.672437],
              [-115.387806, 32.672347],
              [-115.388249, 32.672318],
              [-115.403632, 32.671273],
              [-115.437119, 32.669003],
              [-115.449781, 32.668143],
              [-115.465164, 32.6671],
              [-115.466951, 32.666966],
              [-115.472313, 32.666566],
              [-115.473607, 32.66647],
              [-115.474101, 32.666434],
              [-115.474903, 32.666373],
              [-115.477311, 32.666193],
              [-115.478114, 32.666134],
              [-115.479267, 32.666048],
              [-115.480584, 32.665954],
              [-115.484534, 32.665675],
              [-115.485852, 32.665583],
              [-115.486759, 32.665518],
              [-115.489482, 32.665325],
              [-115.49039, 32.665262],
              [-115.491248, 32.665204],
              [-115.493824, 32.665031],
              [-115.494683, 32.664975],
              [-115.49488, 32.664963],
              [-115.495473, 32.664934],
              [-115.495671, 32.664925],
              [-115.495711, 32.664921],
              [-115.495833, 32.66491],
              [-115.495874, 32.664909],
              [-115.495905, 32.664906],
              [-115.495998, 32.664899],
              [-115.49603, 32.664897],
              [-115.496354, 32.664872],
              [-115.496529, 32.664858],
              [-115.496733, 32.664842],
              [-115.497076, 32.664817],
              [-115.497123, 32.664814],
              [-115.499169, 32.664668],
              [-115.499546, 32.66464],
              [-115.499591, 32.664636],
              [-115.500827, 32.664548],
              [-115.50086, 32.664544],
              [-115.501282, 32.664505],
              [-115.501373, 32.6645],
              [-115.502111, 32.664461],
              [-115.502345, 32.66444],
              [-115.503138, 32.664376],
              [-115.504431, 32.664271],
              [-115.505463, 32.664187],
              [-115.505532, 32.664181],
              [-115.505634, 32.664174],
              [-115.506595, 32.664105],
              [-115.506739, 32.664094],
              [-115.506958, 32.664079],
              [-115.507173, 32.664063],
              [-115.507318, 32.664053],
              [-115.508205, 32.663989],
              [-115.509009, 32.663932],
              [-115.50949, 32.663898],
              [-115.510768, 32.663809],
              [-115.512108, 32.663716],
              [-115.513385, 32.663628],
              [-115.513918, 32.663591],
              [-115.514084, 32.663576],
              [-115.514681, 32.66353],
              [-115.515775, 32.663445],
              [-115.516057, 32.663424],
              [-115.520081, 32.663117],
              [-115.533487, 32.662095],
              [-115.559231, 32.660092],
              [-115.625832, 32.654912],
              [-115.636468, 32.654159],
              [-115.662226, 32.652337],
              [-115.662363, 32.652328],
              [-115.668184, 32.651874],
              [-115.686059, 32.650487],
              [-115.692018, 32.650025],
              [-115.722267, 32.647675],
              [-115.750837, 32.645458],
              [-115.801995, 32.641633],
              [-115.813032, 32.640842],
              [-115.81842, 32.640458],
              [-115.843298, 32.63871],
              [-115.855574, 32.637847],
              [-115.875842, 32.636424],
              [-115.892386, 32.635036],
              [-115.90465, 32.63401],
              [-115.905307, 32.633954],
              [-115.907281, 32.633788],
              [-115.907939, 32.633734],
              [-115.922795, 32.63263],
              [-115.967363, 32.62932],
              [-115.968358, 32.629248],
              [-115.973705, 32.628851],
              [-115.982043, 32.628232],
              [-115.982219, 32.628217],
              [-115.992603, 32.627322],
              [-116.000846, 32.626615],
              [-116.022008, 32.625063],
              [-116.022792, 32.624992],
              [-116.02378, 32.624923],
              [-116.028333, 32.624606],
              [-116.033006, 32.624274],
              [-116.034053, 32.624185],
              [-116.034176, 32.62417],
              [-116.035654, 32.623991],
              [-116.04248, 32.623593],
              [-116.04662, 32.623353],
              [-116.05159, 32.622936],
              [-116.061547, 32.622099],
              [-116.066201, 32.6217],
              [-116.067382, 32.621606],
              [-116.073798, 32.621102],
              [-116.075686, 32.620985],
              [-116.075746, 32.620981],
              [-116.081255, 32.620533],
              [-116.095373, 32.619387],
              [-116.097249, 32.619249],
              [-116.097963, 32.619196],
              [-116.103385, 32.618799],
              [-116.103536, 32.618788],
              [-116.104064, 32.618747],
              [-116.105651, 32.618631],
              [-116.10618, 32.618592],
              [-116.115427, 32.61791],
              [-116.116432, 32.61779],
              [-116.125848, 32.616673],
              [-116.147157, 32.615029],
              [-116.157412, 32.614239],
              [-116.159148, 32.614103],
              [-116.164358, 32.613703],
              [-116.166095, 32.61357],
              [-116.166164, 32.613565],
              [-116.166925, 32.613509],
              [-116.169418, 32.613332],
              [-116.170249, 32.613275],
              [-116.17334, 32.613055],
              [-116.182617, 32.612395],
              [-116.185709, 32.612176],
              [-116.186009, 32.612153],
              [-116.186911, 32.61209],
              [-116.187212, 32.612069],
              [-116.189334, 32.611899],
              [-116.1957, 32.61139],
              [-116.197823, 32.611221],
              [-116.198991, 32.611119],
              [-116.202498, 32.610816],
              [-116.203129, 32.610762],
              [-116.203668, 32.610723],
              [-116.204749, 32.610644],
              [-116.207992, 32.610411],
              [-116.209074, 32.610335],
              [-116.209437, 32.610332],
              [-116.210527, 32.610326],
              [-116.210891, 32.610326],
              [-116.211034, 32.610326],
              [-116.222897, 32.60936],
              [-116.226679, 32.609052],
              [-116.244139, 32.607631],
              [-116.250851, 32.607086],
              [-116.258912, 32.606367],
              [-116.261194, 32.606164],
              [-116.270085, 32.60542],
              [-116.270254, 32.605406],
              [-116.270912, 32.605319],
              [-116.272665, 32.605182],
              [-116.277925, 32.604778],
              [-116.279679, 32.604643],
              [-116.280716, 32.604563],
              [-116.283962, 32.604306],
              [-116.296811, 32.60329],
              [-116.301095, 32.602952],
              [-116.302307, 32.602857],
              [-116.303757, 32.602744],
              [-116.305943, 32.602572],
              [-116.307155, 32.602478],
              [-116.315346, 32.601835],
              [-116.316367, 32.601755],
              [-116.333344, 32.600317],
              [-116.339913, 32.599787],
              [-116.348104, 32.59913],
              [-116.350978, 32.598898],
              [-116.354267, 32.598633],
              [-116.357903, 32.598342],
              [-116.372772, 32.597341],
              [-116.37585, 32.597136],
              [-116.378936, 32.596857],
              [-116.379129, 32.59684],
              [-116.379569, 32.596804],
              [-116.385619, 32.596353],
              [-116.389841, 32.596039],
              [-116.390091, 32.59602],
              [-116.39017, 32.595999],
              [-116.391139, 32.595754],
              [-116.391463, 32.595674],
              [-116.39591, 32.595354],
              [-116.396289, 32.595328],
              [-116.39737, 32.595251],
              [-116.405521, 32.594647],
              [-116.409252, 32.594372],
              [-116.411411, 32.594214],
              [-116.4137, 32.594045],
              [-116.414424, 32.593984],
              [-116.416597, 32.5938],
              [-116.417322, 32.59374],
              [-116.420915, 32.593437],
              [-116.428391, 32.592807],
              [-116.431696, 32.592534],
              [-116.43529, 32.592238],
              [-116.435426, 32.592227],
              [-116.439405, 32.59183],
              [-116.439476, 32.591822],
              [-116.439689, 32.5918],
              [-116.439761, 32.591795],
              [-116.455344, 32.590531],
              [-116.458242, 32.590297],
              [-116.46732, 32.589522],
              [-116.487119, 32.587834],
              [-116.499184, 32.586812],
              [-116.500849, 32.586818],
              [-116.503254, 32.586618],
              [-116.510832, 32.585991],
              [-116.51521, 32.585628],
              [-116.515233, 32.585626],
              [-116.519254, 32.585292],
              [-116.521323, 32.585121],
              [-116.531325, 32.584358],
              [-116.53535, 32.584052],
              [-116.535388, 32.584048],
              [-116.535504, 32.584037],
              [-116.535543, 32.584034],
              [-116.535698, 32.58402],
              [-116.536575, 32.583971],
              [-116.539675, 32.583799],
              [-116.540643, 32.583747],
              [-116.540708, 32.583741],
              [-116.545314, 32.583331],
              [-116.559131, 32.582102],
              [-116.563739, 32.581695],
              [-116.565883, 32.581504],
              [-116.568426, 32.581279],
              [-116.572318, 32.580948],
              [-116.574464, 32.580767],
              [-116.582863, 32.580053],
              [-116.590269, 32.579425],
              [-116.592128, 32.579271],
              [-116.596813, 32.578884],
              [-116.603965, 32.578292],
              [-116.60752, 32.577995],
              [-116.608063, 32.577948],
              [-116.616464, 32.577248],
              [-116.617442, 32.577166],
              [-116.618342, 32.577076],
              [-116.623973, 32.576514],
              [-116.62585, 32.576327],
              [-116.625896, 32.576372],
              [-116.625982, 32.576372],
              [-116.626159, 32.576372],
              [-116.627143, 32.576379],
              [-116.627471, 32.576382],
              [-116.631884, 32.576026],
              [-116.639854, 32.575381],
              [-116.642318, 32.575184],
              [-116.648378, 32.574695],
              [-116.656674, 32.574025],
              [-116.665968, 32.573211],
              [-116.676993, 32.572245],
              [-116.684074, 32.571626],
              [-116.685874, 32.571468],
              [-116.689371, 32.57117],
              [-116.690184, 32.571102],
              [-116.692623, 32.5709],
              [-116.693437, 32.570833],
              [-116.705869, 32.569803],
              [-116.70874, 32.569566],
              [-116.717251, 32.568861],
              [-116.735652, 32.56746],
              [-116.735794, 32.567449],
              [-116.743177, 32.566858],
              [-116.745652, 32.56666],
              [-116.746289, 32.566605],
              [-116.754048, 32.565942],
              [-116.755608, 32.565809],
              [-116.755956, 32.565778],
              [-116.757003, 32.565689],
              [-116.757352, 32.56566],
              [-116.762947, 32.565216],
              [-116.767452, 32.56486],
              [-116.779724, 32.563768],
              [-116.782232, 32.563545],
              [-116.782808, 32.563497],
              [-116.785317, 32.563288],
              [-116.78536, 32.563284],
              [-116.785492, 32.563273],
              [-116.785536, 32.56327],
              [-116.785652, 32.563254],
              [-116.787298, 32.563115],
              [-116.787611, 32.563087],
              [-116.793836, 32.56254],
              [-116.794753, 32.56246],
              [-116.795912, 32.562365],
              [-116.80046, 32.561991],
              [-116.805694, 32.561561],
              [-116.810948, 32.561129],
              [-116.814104, 32.560868],
              [-116.816653, 32.56066],
              [-116.818655, 32.56053],
              [-116.819369, 32.560482],
              [-116.821512, 32.560343],
              [-116.822227, 32.560297],
              [-116.822589, 32.560271],
              [-116.823678, 32.560201],
              [-116.824041, 32.560179],
              [-116.824353, 32.560159],
              [-116.825037, 32.560101],
              [-116.828027, 32.559852],
              [-116.829024, 32.55977],
              [-116.833843, 32.559368],
              [-116.84118, 32.558757],
              [-116.847154, 32.558259],
              [-116.848301, 32.558166],
              [-116.852971, 32.557794],
              [-116.853122, 32.557782],
              [-116.857154, 32.557459],
              [-116.857678, 32.557406],
              [-116.871324, 32.556033],
              [-116.873174, 32.555848],
              [-116.875054, 32.555659],
              [-116.875874, 32.555579],
              [-116.879263, 32.555251],
              [-116.889434, 32.55427],
              [-116.892824, 32.553943],
              [-116.89445, 32.553784],
              [-116.899328, 32.553314],
              [-116.900955, 32.553158],
              [-116.904315, 32.552858],
              [-116.914395, 32.551958],
              [-116.917755, 32.551658],
              [-116.919815, 32.551478],
              [-116.925995, 32.550938],
              [-116.928055, 32.550758],
              [-116.930103, 32.550677],
              [-116.936247, 32.550438],
              [-116.938296, 32.550358],
              [-116.938493, 32.550313],
              [-116.938992, 32.5502],
              [-116.939085, 32.550179],
              [-116.939283, 32.550135],
              [-116.939969, 32.550103],
              [-116.943919, 32.549922],
              [-116.946038, 32.549826],
              [-116.95778, 32.548631],
              [-116.962398, 32.548162],
              [-116.967141, 32.547776],
              [-116.967409, 32.547753],
              [-116.969709, 32.547572],
              [-116.982445, 32.546562],
              [-116.987458, 32.546165],
              [-116.989417, 32.545982],
              [-116.995297, 32.545437],
              [-116.997257, 32.545257],
              [-117.000097, 32.544976],
              [-117.008617, 32.544137],
              [-117.011457, 32.543857],
              [-117.014425, 32.543577],
              [-117.023332, 32.542741],
              [-117.026301, 32.542462],
              [-117.026359, 32.542457],
              [-117.026473, 32.542485],
              [-117.026986, 32.542614],
              [-117.027158, 32.542657],
              [-117.027515, 32.542628],
              [-117.028458, 32.542557],
              [-117.028588, 32.542541],
              [-117.028945, 32.542502],
              [-117.029028, 32.542491],
              [-117.029278, 32.542462],
              [-117.029362, 32.542453],
              [-117.029374, 32.542451],
              [-117.029395, 32.542417],
              [-117.029402, 32.542407],
              [-117.029409, 32.542395],
              [-117.029432, 32.542358],
              [-117.02944, 32.542348],
              [-117.029447, 32.542335],
              [-117.029469, 32.542299],
              [-117.029477, 32.542287],
              [-117.02949, 32.542265],
              [-117.029529, 32.542202],
              [-117.029543, 32.542181],
              [-117.029558, 32.542157],
              [-117.029724, 32.542157],
              [-117.03031, 32.542157],
              [-117.030506, 32.542157],
              [-117.030556, 32.542157],
              [-117.030707, 32.542157],
              [-117.030758, 32.542157],
              [-117.031843, 32.542059],
              [-117.035101, 32.541766],
              [-117.036187, 32.541669],
              [-117.036881, 32.541606],
              [-117.038963, 32.541418],
              [-117.039658, 32.541357],
              [-117.040118, 32.541337],
              [-117.041498, 32.541277],
              [-117.041958, 32.541257],
              [-117.046049, 32.540951],
              [-117.058324, 32.540041],
              [-117.062416, 32.539737],
              [-117.062453, 32.539729],
              [-117.062567, 32.539735],
              [-117.062605, 32.539735],
              [-117.062631, 32.539733],
              [-117.063452, 32.539686],
              [-117.065916, 32.539549],
              [-117.066738, 32.539504],
              [-117.073714, 32.538884],
              [-117.086342, 32.537762],
              [-117.094646, 32.537024],
              [-117.099466, 32.536597],
              [-117.101623, 32.536405],
              [-117.10511, 32.536094],
              [-117.115574, 32.535165],
              [-117.118883, 32.534871],
              [-117.119062, 32.534856],
              [-117.120187, 32.534719],
              [-117.123563, 32.534311],
              [-117.124322, 32.53422],
              [-117.124689, 32.534175],
              [-117.124856, 32.534156],
              [-117.124962, 32.534156],
              [-117.125787, 32.534156],
              [-117.126062, 32.534156],
              [-117.141832, 32.533091],
              [-117.189145, 32.529896],
              [-117.204917, 32.528832],
              [-117.204956, 32.529028],
              [-117.204995, 32.529224],
              [-117.207052, 32.539616],
              [-117.210025, 32.554634],
              [-117.210025, 32.572305],
              [-117.210025, 32.572883],
              [-117.211936, 32.583124],
              [-117.212072, 32.583857],
              [-117.212479, 32.586035],
              [-117.213334, 32.590615],
              [-117.214112, 32.594781],
              [-117.218196, 32.610292],
              [-117.21929, 32.612637],
              [-117.222513, 32.619546],
              [-117.22267, 32.619884],
              [-117.223144, 32.620899],
              [-117.223302, 32.621238],
              [-117.223952, 32.620889],
              [-117.225903, 32.619844],
              [-117.226554, 32.619496],
              [-117.228408, 32.618502],
              [-117.231136, 32.617527],
              [-117.233513, 32.616678],
              [-117.24066, 32.615766],
              [-117.245877, 32.614989],
              [-117.246786, 32.614854],
              [-117.250869, 32.614856],
              [-117.251994, 32.61486],
              [-117.255092, 32.615474],
              [-117.256561, 32.615849],
              [-117.25678, 32.615902],
              [-117.260771, 32.61687],
              [-117.263113, 32.61765],
              [-117.265018, 32.61816],
              [-117.267321, 32.618907],
              [-117.268789, 32.619415],
              [-117.271246, 32.6207],
              [-117.272592, 32.621612],
              [-117.273664, 32.622045],
              [-117.2741, 32.622221],
              [-117.275963, 32.623135],
              [-117.276916, 32.623474],
              [-117.278814, 32.625053],
              [-117.282605, 32.628435],
              [-117.285838, 32.631318],
              [-117.293975, 32.638587],
              [-117.297538, 32.641769],
              [-117.297728, 32.642007],
              [-117.307348, 32.654037],
              [-117.309239, 32.656401],
              [-117.320939, 32.689847],
              [-117.321801, 32.69755],
              [-117.323516, 32.712859],
              [-117.324107, 32.718144],
              [-117.32562, 32.731653],
              [-117.32577, 32.734009],
              [-117.32611, 32.739318],
              [-117.32626, 32.741667],
              [-117.326711, 32.748721],
              [-117.326862, 32.751072],
              [-117.326892, 32.751545],
              [-117.326982, 32.752964],
              [-117.327013, 32.753437],
              [-117.327385, 32.759263],
              [-117.327488, 32.760875],
              [-117.327758, 32.765089],
              [-117.328575, 32.777858],
              [-117.328916, 32.783189],
              [-117.329392, 32.790628],
              [-117.329516, 32.792574],
              [-117.32989, 32.798412],
              [-117.330015, 32.80036],
              [-117.330185, 32.80303],
              [-117.3303, 32.804817],
              [-117.332201, 32.810761],
              [-117.333017, 32.813311],
              [-117.333817, 32.815814],
              [-117.33498, 32.81945],
              [-117.336274, 32.823304],
              [-117.337111, 32.825797],
              [-117.337842, 32.827976],
              [-117.340038, 32.834517],
              [-117.340771, 32.836697],
              [-117.342, 32.840354],
              [-117.339811, 32.857948],
              [-117.33966, 32.859167],
              [-117.33264, 32.882161],
              [-117.332005, 32.883578],
              [-117.323279, 32.903064],
              [-117.328126, 32.91893],
              [-117.334434, 32.939576],
              [-117.336697, 32.946984],
              [-117.343486, 32.969209],
              [-117.34575, 32.976618],
              [-117.346187, 32.978049],
              [-117.347498, 32.982342],
              [-117.347936, 32.983773],
              [-117.348846, 32.986753],
              [-117.351577, 32.995694],
              [-117.352488, 32.998675],
              [-117.353444, 33.001806],
              [-117.356315, 33.011201],
              [-117.357272, 33.014333],
              [-117.35854, 33.018486],
              [-117.362347, 33.030948],
              [-117.363617, 33.035102],
              [-117.36364, 33.035179],
              [-117.363711, 33.035411],
              [-117.363735, 33.035489],
              [-117.366013, 33.042946],
              [-117.368313, 33.050475],
              [-117.369368, 33.053929],
              [-117.372849, 33.065323],
              [-117.375002, 33.072369],
              [-117.375066, 33.072576],
              [-117.375129, 33.072783],
              [-117.375259, 33.073209],
              [-117.375381, 33.07361],
              [-117.375749, 33.074815],
              [-117.375872, 33.075217],
              [-117.381676, 33.085217],
              [-117.392238, 33.103418],
              [-117.399147, 33.114931],
              [-117.399262, 33.115122],
              [-117.405192, 33.125003],
              [-117.405213, 33.125038],
              [-117.406193, 33.126733],
              [-117.409136, 33.131821],
              [-117.410117, 33.133517],
              [-117.415481, 33.142791],
              [-117.420426, 33.15134],
              [-117.430734, 33.169162],
              [-117.431574, 33.170614],
              [-117.435563, 33.177512],
              [-117.437426, 33.17953],
              [-117.449536, 33.192641],
              [-117.467871, 33.212494],
              [-117.487598, 33.230221],
              [-117.500001, 33.241366],
              [-117.500438, 33.241759],
              [-117.500875, 33.242151],
              [-117.502247, 33.243727],
              [-117.506364, 33.248457],
              [-117.507737, 33.250034],
              [-117.508607, 33.251269],
              [-117.511218, 33.254978],
              [-117.512089, 33.256215],
              [-117.520999, 33.268872],
              [-117.528433, 33.275512],
              [-117.549601, 33.294418],
              [-117.571531, 33.312302],
              [-117.588769, 33.321755],
              [-117.611081, 33.333992],
              [-117.61404, 33.335614],
              [-117.62292, 33.340485],
              [-117.62588, 33.342109],
              [-117.631566, 33.346157],
              [-117.634654, 33.348135],
              [-117.636201, 33.349128],
              [-117.638948, 33.352232],
              [-117.641171, 33.35574],
              [-117.644398, 33.359384],
              [-117.64786, 33.363635],
              [-117.649865, 33.364007],
              [-117.653795, 33.364818],
              [-117.658018, 33.368194],
              [-117.659744, 33.369575],
              [-117.666181, 33.375031],
              [-117.669031, 33.376944],
              [-117.671288, 33.378453],
              [-117.678059, 33.382984],
              [-117.680316, 33.384496],
              [-117.68129, 33.385147],
              [-117.684212, 33.387103],
              [-117.685187, 33.387755],
              [-117.687937, 33.389792],
              [-117.68862, 33.390298],
              [-117.690671, 33.391816],
              [-117.691355, 33.392323],
              [-117.694423, 33.394595],
              [-117.703631, 33.401414],
              [-117.7067, 33.403688],
              [-117.713571, 33.408777],
              [-117.715011, 33.408617],
              [-117.725719, 33.407436],
              [-117.735336, 33.407436],
              [-117.741409, 33.410118],
              [-117.74358, 33.412146],
              [-117.750887, 33.418975],
              [-117.755205, 33.422488],
              [-117.757411, 33.424283],
              [-117.76514, 33.431539],
              [-117.767216, 33.434046],
              [-117.770767, 33.438334],
              [-117.772342, 33.440237],
              [-117.773774, 33.442183],
              [-117.776062, 33.445291],
              [-117.78147, 33.450656],
              [-117.783555, 33.453061],
              [-117.786757, 33.456753],
              [-117.786995, 33.457027],
              [-117.789369, 33.459899],
              [-117.790478, 33.461241],
              [-117.792353, 33.465346],
              [-117.793262, 33.469782],
              [-117.794544, 33.470536],
              [-117.79807, 33.472609],
              [-117.798559, 33.472896],
              [-117.79859, 33.472915],
              [-117.800122, 33.473888],
              [-117.800633, 33.474213],
              [-117.804418, 33.476616],
              [-117.804626, 33.476779],
              [-117.811277, 33.482019],
              [-117.81455, 33.486784],
              [-117.81484, 33.487206],
              [-117.816356, 33.490872],
              [-117.816597, 33.490997],
              [-117.818158, 33.491811],
              [-117.822844, 33.494253],
              [-117.823504, 33.494598],
              [-117.824308, 33.495222],
              [-117.82481, 33.49561],
              [-117.826316, 33.49678],
              [-117.826593, 33.496995],
              [-117.826865, 33.497082],
              [-117.828699, 33.49767],
              [-117.834202, 33.499437],
              [-117.836037, 33.500026],
              [-117.836294, 33.500161],
              [-117.837067, 33.500566],
              [-117.837325, 33.500701],
              [-117.843842, 33.504118],
              [-117.863394, 33.514369],
              [-117.869912, 33.517786],
              [-117.870286, 33.517982],
              [-117.871409, 33.518571],
              [-117.871784, 33.518768],
              [-117.873695, 33.51977],
              [-117.875887, 33.520841],
              [-117.87853, 33.522132],
              [-117.890757, 33.528106],
              [-117.898852, 33.532061],
              [-117.905627, 33.535371],
              [-117.906935, 33.536009],
              [-117.910861, 33.537926],
              [-117.91217, 33.538566],
              [-117.921852, 33.543295],
              [-117.934046, 33.549254],
              [-117.949569, 33.559785],
              [-117.958488, 33.565835],
              [-117.964466, 33.569891],
              [-117.973807, 33.576229],
              [-117.982614, 33.581736],
              [-117.988741, 33.585567],
              [-117.988813, 33.585612],
              [-117.989029, 33.585747],
              [-117.989102, 33.585793],
              [-117.99067, 33.586774],
              [-117.995376, 33.589717],
              [-117.996945, 33.590698],
              [-117.9972, 33.590857],
              [-117.997965, 33.591335],
              [-117.998221, 33.591495],
              [-117.998621, 33.591745],
              [-117.999824, 33.592497],
              [-118.000225, 33.592748],
              [-118.000879, 33.593165],
              [-118.000902, 33.59317],
              [-118.000891, 33.593129],
              [-118.004996, 33.594629],
              [-118.015323, 33.599487],
              [-118.024968, 33.604676],
              [-118.033611, 33.609354],
              [-118.042008, 33.614904],
              [-118.049848, 33.620015],
              [-118.056121, 33.62502],
              [-118.056271, 33.625136],
              [-118.056724, 33.625484],
              [-118.056875, 33.6256],
              [-118.05773, 33.626258],
              [-118.060297, 33.628233],
              [-118.061153, 33.628892],
              [-118.06299, 33.630306],
              [-118.068502, 33.634548],
              [-118.07034, 33.635962],
              [-118.075074, 33.639606],
              [-118.082331, 33.644369],
              [-118.096473, 33.653651],
              [-118.107478, 33.668778],
              [-118.112022, 33.675773],
              [-118.120005, 33.688063],
              [-118.121184, 33.689878],
              [-118.124721, 33.695323],
              [-118.1259, 33.697139],
              [-118.1259, 33.697151],
              [-118.155793, 33.692964],
              [-118.16583, 33.691563],
              [-118.168654, 33.691459],
              [-118.172314, 33.691324],
              [-118.175975, 33.691189],
              [-118.192111, 33.690593],
              [-118.225276, 33.690595],
              [-118.226755, 33.690595],
              [-118.237008, 33.690595],
              [-118.243546, 33.684802],
              [-118.247226, 33.68154],
              [-118.250907, 33.678279],
              [-118.251092, 33.678116],
              [-118.251276, 33.677952],
              [-118.254528, 33.67507],
              [-118.274239, 33.663429],
              [-118.274294, 33.663424],
              [-118.293399, 33.661772],
              [-118.319135, 33.659547],
              [-118.33512, 33.661907],
              [-118.345415, 33.663427],
              [-118.352008, 33.666649],
              [-118.377171, 33.678953],
              [-118.385931, 33.686712],
              [-118.428637, 33.701266],
              [-118.466962, 33.725524],
              [-118.473702, 33.735714],
              [-118.482284, 33.748686],
              [-118.485577, 33.753664],
              [-118.487768, 33.779865],
              [-118.485276, 33.79753],
              [-118.484483, 33.803154],
              [-118.483207, 33.804445],
              [-118.471807, 33.81599],
              [-118.469153, 33.818679],
              [-118.457062, 33.827503],
              [-118.450538, 33.832265],
              [-118.446904, 33.836021],
              [-118.443968, 33.839057],
              [-118.445331, 33.843083],
              [-118.447254, 33.84876],
              [-118.448358, 33.850423],
              [-118.455633, 33.861383],
              [-118.458204, 33.865256],
              [-118.459633, 33.867119],
              [-118.475802, 33.888187],
              [-118.47682, 33.889513],
              [-118.484869, 33.899239],
              [-118.498989, 33.916299],
              [-118.500912, 33.918621],
              [-118.506476, 33.928052],
              [-118.508627, 33.932517],
              [-118.513605, 33.938958],
              [-118.513672, 33.939051],
              [-118.51814, 33.945265],
              [-118.520563, 33.94874],
              [-118.520854, 33.949157],
              [-118.523447, 33.953049],
              [-118.523571, 33.953175],
              [-118.525753, 33.955395],
              [-118.526416, 33.955944],
              [-118.533474, 33.961794],
              [-118.537486, 33.966674],
              [-118.541565, 33.971636],
              [-118.545438, 33.976347],
              [-118.547881, 33.979318],
              [-118.548417, 33.97997],
              [-118.55134, 33.982489],
              [-118.556864, 33.987249],
              [-118.557356, 33.987673],
              [-118.559153, 33.987713],
              [-118.562633, 33.98779],
              [-118.562718, 33.987792],
              [-118.566387, 33.988084],
              [-118.569238, 33.988712],
              [-118.569687, 33.988811],
              [-118.570099, 33.988727],
              [-118.576018, 33.987517],
              [-118.580976, 33.9873],
              [-118.586577, 33.987013],
              [-118.590971, 33.987235],
              [-118.594079, 33.988065],
              [-118.599715, 33.986668],
              [-118.604389, 33.986283],
              [-118.611321, 33.986193],
              [-118.615958, 33.986413],
              [-118.620072, 33.98697],
              [-118.620837, 33.986799],
              [-118.625916, 33.987233],
              [-118.62632, 33.98646],
              [-118.628862, 33.986153],
              [-118.633067, 33.985645],
              [-118.639736, 33.985736],
              [-118.64394, 33.985797],
              [-118.648348, 33.986729],
              [-118.650161, 33.985116],
              [-118.652867, 33.984408],
              [-118.656463, 33.983798],
              [-118.661387, 33.982147],
              [-118.666514, 33.980562],
              [-118.672412, 33.979914],
              [-118.677017, 33.979268],
              [-118.680815, 33.97916],
              [-118.684008, 33.979221],
              [-118.689144, 33.97995],
              [-118.693991, 33.979135],
              [-118.696696, 33.978568],
              [-118.698514, 33.978187],
              [-118.704574, 33.97784],
              [-118.709586, 33.978064],
              [-118.71026, 33.978123],
              [-118.716742, 33.978687],
              [-118.722605, 33.979412],
              [-118.727459, 33.980307],
              [-118.730726, 33.978857],
              [-118.735528, 33.977035],
              [-118.737756, 33.976775],
              [-118.73993, 33.976522],
              [-118.743443, 33.975877],
              [-118.745959, 33.975556],
              [-118.746391, 33.975501],
              [-118.749606, 33.971804],
              [-118.750919, 33.971071],
              [-118.754411, 33.967792],
              [-118.756866, 33.966402],
              [-118.760571, 33.964972],
              [-118.762587, 33.964357],
              [-118.764278, 33.963609],
              [-118.768137, 33.9608],
              [-118.77393, 33.95751],
              [-118.779039, 33.954527],
              [-118.786528, 33.95143],
              [-118.792126, 33.949519],
              [-118.797728, 33.948212],
              [-118.803613, 33.94724],
              [-118.805372, 33.947145],
              [-118.809827, 33.946905],
              [-118.814752, 33.947214],
              [-118.820932, 33.948189],
              [-118.824893, 33.949042],
              [-118.829381, 33.950395],
              [-118.834761, 33.952382],
              [-118.841116, 33.955371],
              [-118.847761, 33.959334],
              [-118.852787, 33.962565],
              [-118.856846, 33.96597],
              [-118.859282, 33.968242],
              [-118.863997, 33.97302],
              [-118.870461, 33.980063],
              [-118.870993, 33.980642],
              [-118.873699, 33.983048],
              [-118.873998, 33.983314],
              [-118.875925, 33.983823],
              [-118.881397, 33.984969],
              [-118.884833, 33.98606],
              [-118.887301, 33.987223],
              [-118.887712, 33.98724],
              [-118.892262, 33.98743],
              [-118.897265, 33.987805],
              [-118.902229, 33.988483],
              [-118.910463, 33.989411],
              [-118.91543, 33.990424],
              [-118.919261, 33.990503],
              [-118.926201, 33.990999],
              [-118.931653, 33.99211],
              [-118.932544, 33.992029],
              [-118.935239, 33.991786],
              [-118.940438, 33.991452],
              [-118.945644, 33.991992],
              [-118.951721, 33.992858],
              [-118.952426, 33.992959],
              [-118.958246, 33.994705],
              [-118.964029, 33.996754],
              [-118.96633, 33.997009],
              [-118.972425, 33.997744],
              [-118.979975, 33.998806],
              [-118.983206, 33.999457],
              [-118.985312, 34.000008],
              [-119.000421, 34.007467],
              [-119.027407, 34.014919],
              [-119.057767, 34.019389],
              [-119.057893, 34.019411],
              [-119.116797, 34.029823],
              [-119.158963, 34.040253],
              [-119.217993, 34.062491],
              [-119.226928, 34.074344],
              [-119.232924, 34.082299],
              [-119.233501, 34.083065],
              [-119.242495, 34.094995],
              [-119.245555, 34.099054],
              [-119.250942, 34.106201],
              [-119.255125, 34.109293],
              [-119.261105, 34.115218],
              [-119.267734, 34.121252],
              [-119.271198, 34.125004],
              [-119.285743, 34.140805],
              [-119.289149, 34.146425],
              [-119.297569, 34.160316],
              [-119.30146, 34.168912],
              [-119.303875, 34.174246],
              [-119.310182, 34.187481],
              [-119.310671, 34.188588],
              [-119.31116, 34.189694],
              [-119.315225, 34.198894],
              [-119.31683, 34.202525],
              [-119.328654, 34.226906],
              [-119.329216, 34.227838],
              [-119.329246, 34.227887],
              [-119.329302, 34.22798],
              [-119.329383, 34.228114],
              [-119.33001, 34.229154],
              [-119.330976, 34.230755],
              [-119.331841, 34.232188],
              [-119.333341, 34.234673],
              [-119.334462, 34.23653],
              [-119.33467, 34.236698],
              [-119.334784, 34.236778],
              [-119.334887, 34.236865],
              [-119.335676, 34.23734],
              [-119.335926, 34.237523],
              [-119.340069, 34.238255],
              [-119.350726, 34.240139],
              [-119.352313, 34.240716],
              [-119.364126, 34.245013],
              [-119.375935, 34.24988],
              [-119.375936, 34.250011],
              [-119.376164, 34.25001],
              [-119.386333, 34.257074],
              [-119.394946, 34.265357],
              [-119.401994, 34.272258],
              [-119.41139, 34.273638],
              [-119.42862, 34.277781],
              [-119.448982, 34.284685],
              [-119.466995, 34.29366],
              [-119.484224, 34.306779],
              [-119.500953, 34.326922],
              [-119.510797, 34.33085],
              [-119.519472, 34.336069],
              [-119.52291, 34.335919],
              [-119.526078, 34.337049],
              [-119.527918, 34.338771],
              [-119.530301, 34.33877],
              [-119.532654, 34.33877],
              [-119.534661, 34.339415],
              [-119.539275, 34.340897],
              [-119.53976, 34.341053],
              [-119.546017, 34.343381],
              [-119.54785, 34.344362],
              [-119.555829, 34.345019],
              [-119.560669, 34.347035],
              [-119.563311, 34.348135],
              [-119.574545, 34.353816],
              [-119.577223, 34.355865],
              [-119.584795, 34.36166],
              [-119.590294, 34.361209],
              [-119.602061, 34.363654],
              [-119.610022, 34.366883],
              [-119.610846, 34.367217],
              [-119.612338, 34.36741],
              [-119.614332, 34.367669],
              [-119.617462, 34.36873],
              [-119.622496, 34.36674],
              [-119.625962, 34.366216],
              [-119.636239, 34.365726],
              [-119.636269, 34.365722],
              [-119.642953, 34.365836],
              [-119.651357, 34.366708],
              [-119.659359, 34.360068],
              [-119.660021, 34.359518],
              [-119.66611, 34.354464],
              [-119.679633, 34.348207],
              [-119.684135, 34.346295],
              [-119.689314, 34.344095],
              [-119.689391, 34.344066],
              [-119.697783, 34.340867],
              [-119.707593, 34.337324],
              [-119.710476, 34.336707],
              [-119.713644, 34.336029],
              [-119.716129, 34.336108],
              [-119.718622, 34.336635],
              [-119.72193, 34.337334],
              [-119.732023, 34.339696],
              [-119.735376, 34.341118],
              [-119.739235, 34.342755],
              [-119.740486, 34.343217],
              [-119.750318, 34.346846],
              [-119.750969, 34.347086],
              [-119.755696, 34.34927],
              [-119.755999, 34.34941],
              [-119.763574, 34.35291],
              [-119.768929, 34.35451],
              [-119.777331, 34.357573],
              [-119.785525, 34.359903],
              [-119.787546, 34.360854],
              [-119.797067, 34.365331],
              [-119.801591, 34.36473],
              [-119.805805, 34.36534],
              [-119.810348, 34.362728],
              [-119.815897, 34.359766],
              [-119.819084, 34.358571],
              [-119.823148, 34.357047],
              [-119.83021, 34.355604],
              [-119.837921, 34.354318],
              [-119.839468, 34.354208],
              [-119.845639, 34.353769],
              [-119.853696, 34.354238],
              [-119.85527, 34.35433],
              [-119.857108, 34.354824],
              [-119.860229, 34.355662],
              [-119.865898, 34.357186],
              [-119.867631, 34.356691],
              [-119.872476, 34.355949],
              [-119.87597, 34.355641],
              [-119.883874, 34.356144],
              [-119.891521, 34.357457],
              [-119.898502, 34.360358],
              [-119.90542, 34.364371],
              [-119.912886, 34.369391],
              [-119.915631, 34.371817],
              [-119.916529, 34.37261],
              [-119.920325, 34.37276],
              [-119.923395, 34.373453],
              [-119.927899, 34.374995],
              [-119.952335, 34.383186],
              [-119.956851, 34.3847],
              [-120.000972, 34.39949],
              [-120.005381, 34.400347],
              [-120.009416, 34.401535],
              [-120.014585, 34.404115],
              [-120.018946, 34.406664],
              [-120.022346, 34.408347],
              [-120.024356, 34.409343],
              [-120.0273, 34.408971],
              [-120.031173, 34.409361],
              [-120.036136, 34.409948],
              [-120.041666, 34.411431],
              [-120.044814, 34.412454],
              [-120.048079, 34.411682],
              [-120.051223, 34.410745],
              [-120.056788, 34.409935],
              [-120.063282, 34.40962],
              [-120.070301, 34.409769],
              [-120.076435, 34.411116],
              [-120.079619, 34.410012],
              [-120.083086, 34.409372],
              [-120.09067, 34.409286],
              [-120.099023, 34.410691],
              [-120.108309, 34.412891],
              [-120.115456, 34.415097],
              [-120.120947, 34.416709],
              [-120.125977, 34.418346],
              [-120.127243, 34.418725],
              [-120.131183, 34.42051],
              [-120.13512, 34.422061],
              [-120.137145, 34.421854],
              [-120.139454, 34.42178],
              [-120.142739, 34.422301],
              [-120.14728, 34.422916],
              [-120.150799, 34.421872],
              [-120.154525, 34.421359],
              [-120.158372, 34.421012],
              [-120.163636, 34.420361],
              [-120.170159, 34.420337],
              [-120.176402, 34.420946],
              [-120.180452, 34.420498],
              [-120.184177, 34.419984],
              [-120.191754, 34.419988],
              [-120.19816, 34.420795],
              [-120.202828, 34.422208],
              [-120.205543, 34.422098],
              [-120.209957, 34.421647],
              [-120.21587, 34.42129],
              [-120.220321, 34.420339],
              [-120.22388, 34.419159],
              [-120.225491, 34.418988],
              [-120.227807, 34.418743],
              [-120.231613, 34.418195],
              [-120.236595, 34.417906],
              [-120.240604, 34.417456],
              [-120.242791, 34.417414],
              [-120.245831, 34.417567],
              [-120.248265, 34.418056],
              [-120.24944, 34.417984],
              [-120.250982, 34.417914],
              [-120.253985, 34.41758],
              [-120.259584, 34.417696],
              [-120.264735, 34.41768],
              [-120.269235, 34.4172],
              [-120.273532, 34.416886],
              [-120.280222, 34.416298],
              [-120.287125, 34.417609],
              [-120.290404, 34.416532],
              [-120.297047, 34.414976],
              [-120.306323, 34.413212],
              [-120.312283, 34.412791],
              [-120.31698, 34.411575],
              [-120.324511, 34.409748],
              [-120.332863, 34.409218],
              [-120.340003, 34.409424],
              [-120.341865, 34.408917],
              [-120.346851, 34.408432],
              [-120.348871, 34.407324],
              [-120.355482, 34.407265],
              [-120.360393, 34.407847],
              [-120.367335, 34.408486],
              [-120.369317, 34.407845],
              [-120.375989, 34.406672],
              [-120.377298, 34.406376],
              [-120.380855, 34.405922],
              [-120.386922, 34.405653],
              [-120.390592, 34.403297],
              [-120.397982, 34.401018],
              [-120.408528, 34.398653],
              [-120.417698, 34.397553],
              [-120.41819, 34.397494],
              [-120.426032, 34.396745],
              [-120.431036, 34.394646],
              [-120.436246, 34.393179],
              [-120.443641, 34.391965],
              [-120.450998, 34.391184],
              [-120.456015, 34.391485],
              [-120.462128, 34.392479],
              [-120.468689, 34.393937],
              [-120.475218, 34.396663],
              [-120.478818, 34.396706],
              [-120.488091, 34.398278],
              [-120.495188, 34.401133],
              [-120.500999, 34.403136],
              [-120.504043, 34.405526],
              [-120.508141, 34.408295],
              [-120.514162, 34.412048],
              [-120.519374, 34.41604],
              [-120.523646, 34.419505],
              [-120.526794, 34.424075],
              [-120.530189, 34.428877],
              [-120.532928, 34.433183],
              [-120.535572, 34.439521],
              [-120.535911, 34.446738],
              [-120.535664, 34.452695],
              [-120.538206, 34.454308],
              [-120.541495, 34.457081],
              [-120.544586, 34.460221],
              [-120.549042, 34.465148],
              [-120.553105, 34.47101],
              [-120.556068, 34.476678],
              [-120.558303, 34.482618],
              [-120.559439, 34.488466],
              [-120.561273, 34.488753],
              [-120.563884, 34.4893],
              [-120.566622, 34.49028],
              [-120.568883, 34.492195],
              [-120.57145, 34.492543],
              [-120.575415, 34.493979],
              [-120.58151, 34.496632],
              [-120.588165, 34.499987],
              [-120.594334, 34.504072],
              [-120.597364, 34.503317],
              [-120.600474, 34.502561],
              [-120.603998, 34.502067],
              [-120.609481, 34.50191],
              [-120.616441, 34.502366],
              [-120.619498, 34.502753],
              [-120.62105, 34.503097],
              [-120.621903, 34.503083],
              [-120.626, 34.502644],
              [-120.626, 34.502762],
              [-120.62782, 34.502766],
              [-120.632575, 34.502687],
              [-120.637588, 34.503141],
              [-120.64236, 34.503765],
              [-120.645437, 34.504853],
              [-120.648179, 34.505612],
              [-120.650556, 34.506377],
              [-120.652729, 34.507145],
              [-120.656058, 34.508598],
              [-120.658161, 34.509804],
              [-120.660803, 34.511402],
              [-120.663446, 34.513068],
              [-120.665846, 34.514805],
              [-120.668246, 34.516508],
              [-120.671312, 34.518837],
              [-120.672397, 34.519993],
              [-120.673752, 34.520506],
              [-120.675722, 34.521278],
              [-120.678239, 34.522744],
              [-120.682206, 34.525326],
              [-120.684529, 34.527131],
              [-120.686655, 34.529241],
              [-120.688781, 34.531351],
              [-120.690665, 34.533565],
              [-120.692354, 34.536085],
              [-120.694293, 34.538869],
              [-120.694987, 34.540634],
              [-120.696799, 34.541542],
              [-120.698831, 34.543117],
              [-120.701501, 34.545788],
              [-120.703968, 34.548495],
              [-120.706453, 34.551907],
              [-120.707951, 34.554898],
              [-120.708998, 34.557764],
              [-120.710379, 34.56096],
              [-120.711383, 34.563726],
              [-120.712182, 34.566429],
              [-120.712757, 34.568297],
              [-120.713095, 34.57208],
              [-120.713329, 34.57496],
              [-120.71315, 34.577545],
              [-120.712543, 34.581009],
              [-120.712134, 34.584202],
              [-120.710894, 34.588415],
              [-120.70902, 34.593276],
              [-120.707089, 34.597501],
              [-120.707395, 34.600045],
              [-120.707996, 34.602951],
              [-120.708562, 34.606094],
              [-120.70837, 34.609853],
              [-120.707841, 34.613182],
              [-120.707183, 34.616211],
              [-120.706302, 34.618473],
              [-120.705315, 34.621408],
              [-120.703836, 34.624983],
              [-120.702635, 34.627736],
              [-120.701283, 34.630007],
              [-120.699278, 34.632421],
              [-120.696913, 34.635276],
              [-120.693937, 34.63834],
              [-120.690594, 34.641441],
              [-120.68648, 34.645023],
              [-120.686088, 34.646037],
              [-120.685215, 34.647697],
              [-120.683671, 34.650777],
              [-120.68248, 34.652979],
              [-120.677911, 34.663772],
              [-120.672515, 34.676661],
              [-120.671839, 34.678532],
              [-120.668364, 34.688155],
              [-120.665367, 34.695158],
              [-120.664691, 34.696344],
              [-120.665845, 34.69943],
              [-120.667043, 34.700124],
              [-120.668908, 34.701584],
              [-120.671069, 34.703615],
              [-120.673561, 34.705878],
              [-120.677308, 34.709743],
              [-120.679105, 34.712047],
              [-120.680568, 34.713917],
              [-120.682159, 34.716088],
              [-120.683004, 34.717695],
              [-120.684014, 34.719435],
              [-120.684655, 34.721078],
              [-120.685776, 34.722042],
              [-120.687475, 34.723337],
              [-120.688431, 34.724202],
              [-120.690183, 34.726169],
              [-120.693234, 34.730042],
              [-120.697137, 34.735757],
              [-120.698276, 34.737832],
              [-120.699592, 34.74068],
              [-120.700698, 34.743193],
              [-120.701434, 34.745576],
              [-120.702093, 34.748329],
              [-120.702022, 34.74998],
              [-120.702089, 34.752353],
              [-120.702296, 34.754871],
              [-120.702305, 34.755375],
              [-120.702342, 34.757492],
              [-120.702226, 34.760215],
              [-120.701828, 34.763211],
              [-120.700987, 34.766547],
              [-120.699795, 34.770861],
              [-120.698179, 34.774273],
              [-120.696673, 34.776978],
              [-120.695555, 34.778559],
              [-120.694638, 34.779856],
              [-120.692889, 34.782664],
              [-120.692383, 34.784148],
              [-120.690747, 34.788837],
              [-120.688432, 34.79444],
              [-120.686308, 34.799369],
              [-120.68442, 34.803657],
              [-120.682507, 34.808953],
              [-120.680791, 34.813811],
              [-120.67924, 34.818733],
              [-120.673281, 34.838624],
              [-120.674683, 34.839315],
              [-120.67761, 34.840662],
              [-120.681121, 34.842675],
              [-120.684098, 34.844526],
              [-120.688002, 34.847979],
              [-120.69078, 34.850201],
              [-120.6956, 34.854585],
              [-120.697165, 34.855174],
              [-120.70054, 34.856381],
              [-120.704948, 34.858149],
              [-120.709494, 34.860788],
              [-120.712967, 34.862902],
              [-120.715954, 34.865256],
              [-120.718405, 34.867414],
              [-120.720319, 34.869241],
              [-120.722574, 34.871838],
              [-120.72412, 34.873703],
              [-120.724575, 34.874978],
              [-120.725616, 34.876668],
              [-120.72735, 34.879529],
              [-120.729095, 34.882927],
              [-120.730679, 34.886427],
              [-120.732019, 34.889996],
              [-120.732972, 34.892599],
              [-120.733333, 34.894237],
              [-120.73382, 34.896041],
              [-120.734081, 34.898787],
              [-120.734292, 34.900689],
              [-120.734302, 34.900778],
              [-120.734382, 34.901499],
              [-120.734327, 34.904751],
              [-120.733417, 34.910161],
              [-120.731949, 34.914303],
              [-120.730188, 34.918081],
              [-120.728826, 34.921384],
              [-120.72723, 34.923316],
              [-120.727764, 34.925421],
              [-120.728331, 34.928238],
              [-120.728356, 34.928364],
              [-120.728506, 34.931647],
              [-120.728497, 34.931945],
              [-120.72841, 34.934934],
              [-120.72811, 34.938257],
              [-120.727402, 34.941584],
              [-120.726753, 34.943805],
              [-120.725745, 34.9465],
              [-120.724256, 34.949671],
              [-120.722201, 34.95315],
              [-120.720262, 34.956293],
              [-120.717925, 34.959977],
              [-120.71483, 34.962631],
              [-120.71444, 34.963541],
              [-120.713933, 34.966766],
              [-120.712437, 34.971568],
              [-120.712065, 34.972926],
              [-120.70801, 34.984778],
              [-120.702795, 34.999975],
              [-120.700558, 35.009647],
              [-120.697407, 35.017967],
              [-120.695642, 35.025097],
              [-120.694715, 35.028839],
              [-120.694198, 35.034573],
              [-120.693425, 35.043134],
              [-120.693119, 35.046109],
              [-120.691656, 35.060356],
              [-120.690343, 35.069071],
              [-120.689058, 35.074859],
              [-120.687802, 35.077488],
              [-120.68919, 35.087164],
              [-120.689365, 35.090517],
              [-120.689678, 35.096508],
              [-120.689606, 35.098828],
              [-120.690175, 35.09899],
              [-120.695347, 35.100466],
              [-120.699107, 35.101539],
              [-120.701673, 35.102423],
              [-120.707095, 35.10429],
              [-120.707912, 35.104654],
              [-120.715374, 35.10798],
              [-120.717871, 35.10924],
              [-120.719041, 35.10983],
              [-120.722303, 35.111475],
              [-120.725503, 35.113576],
              [-120.730606, 35.111195],
              [-120.738496, 35.10964],
              [-120.746308, 35.109297],
              [-120.75101, 35.109433],
              [-120.752427, 35.109456],
              [-120.75661, 35.109963],
              [-120.760714, 35.111074],
              [-120.765173, 35.11047],
              [-120.772669, 35.110479],
              [-120.77269, 35.110482],
              [-120.782921, 35.111978],
              [-120.79113, 35.113862],
              [-120.800411, 35.116577],
              [-120.808391, 35.120545],
              [-120.814596, 35.124977],
              [-120.816226, 35.12595],
              [-120.818625, 35.127851],
              [-120.821991, 35.131441],
              [-120.825312, 35.131295],
              [-120.830594, 35.131869],
              [-120.83942, 35.134654],
              [-120.8473, 35.137869],
              [-120.856926, 35.142812],
              [-120.864872, 35.147844],
              [-120.871703, 35.153709],
              [-120.875546, 35.15932],
              [-120.876014, 35.159718],
              [-120.880498, 35.161635],
              [-120.887755, 35.165624],
              [-120.896839, 35.171343],
              [-120.905349, 35.177226],
              [-120.912142, 35.182525],
              [-120.917792, 35.187378],
              [-120.923998, 35.194019],
              [-120.927919, 35.199433],
              [-120.930202, 35.204635],
              [-120.932779, 35.205526],
              [-120.937349, 35.208047],
              [-120.943855, 35.21378],
              [-120.949032, 35.22102],
              [-120.953264, 35.228355],
              [-120.956721, 35.235348],
              [-120.960247, 35.243622],
              [-120.961253, 35.249975],
              [-120.961342, 35.252243],
              [-120.961364, 35.256143],
              [-120.960389, 35.260807],
              [-120.959971, 35.262714],
              [-120.959375, 35.265437],
              [-120.957104, 35.27167],
              [-120.955567, 35.275522],
              [-120.955111, 35.278476],
              [-120.953333, 35.284834],
              [-120.95333, 35.284847],
              [-120.951278, 35.29014],
              [-120.948159, 35.295458],
              [-120.944678, 35.300301],
              [-120.94076, 35.306753],
              [-120.940171, 35.307723],
              [-120.936229, 35.315687],
              [-120.932294, 35.323181],
              [-120.928602, 35.330711],
              [-120.927608, 35.333406],
              [-120.926414, 35.336641],
              [-120.924911, 35.343418],
              [-120.923435, 35.348413],
              [-120.925745, 35.354277],
              [-120.92596, 35.354823],
              [-120.928182, 35.360689],
              [-120.928285, 35.360962],
              [-120.929486, 35.367063],
              [-120.929882, 35.369078],
              [-120.930797, 35.374974],
              [-120.930949, 35.376602],
              [-120.930938, 35.378058],
              [-120.930926, 35.379728],
              [-120.930244, 35.383087],
              [-120.928286, 35.386843],
              [-120.931015, 35.389746],
              [-120.933292, 35.392748],
              [-120.940351, 35.393284],
              [-120.950404, 35.394471],
              [-120.95967, 35.396494],
              [-120.968066, 35.399587],
              [-120.976828, 35.403557],
              [-120.984111, 35.407635],
              [-120.985051, 35.408162],
              [-120.989632, 35.411139],
              [-120.992507, 35.410445],
              [-120.994865, 35.409864],
              [-120.997497, 35.409724],
              [-121.000958, 35.409653],
              [-121.005555, 35.409503],
              [-121.010303, 35.409931],
              [-121.015005, 35.410629],
              [-121.022317, 35.412561],
              [-121.023154, 35.412783],
              [-121.029552, 35.41485],
              [-121.031727, 35.415553],
              [-121.03697, 35.417775],
              [-121.041635, 35.41999],
              [-121.047907, 35.425055],
              [-121.052308, 35.428546],
              [-121.056646, 35.433385],
              [-121.057136, 35.434123],
              [-121.058696, 35.436474],
              [-121.059509, 35.4377],
              [-121.060929, 35.4392],
              [-121.065126, 35.442554],
              [-121.069057, 35.447252],
              [-121.070618, 35.447676],
              [-121.073806, 35.449568],
              [-121.082954, 35.455643],
              [-121.088721, 35.461779],
              [-121.092238, 35.466672],
              [-121.094122, 35.467605],
              [-121.100138, 35.470878],
              [-121.111208, 35.481322],
              [-121.118171, 35.490604],
              [-121.122048, 35.493615],
              [-121.126029, 35.494404],
              [-121.128266, 35.495609],
              [-121.132456, 35.499973],
              [-121.140497, 35.506375],
              [-121.149524, 35.514954],
              [-121.151056, 35.516412],
              [-121.159587, 35.529438],
              [-121.168401, 35.545612],
              [-121.177756, 35.561255],
              [-121.186643, 35.568242],
              [-121.187705, 35.569077],
              [-121.1879, 35.569282],
              [-121.201555, 35.58369],
              [-121.202299, 35.583818],
              [-121.220123, 35.586874],
              [-121.231811, 35.594039],
              [-121.235557, 35.59501],
              [-121.246589, 35.600363],
              [-121.251034, 35.60068],
              [-121.25981, 35.601756],
              [-121.270416, 35.606479],
              [-121.286036, 35.608436],
              [-121.295999, 35.611923],
              [-121.30998, 35.617095],
              [-121.323512, 35.624969],
              [-121.339645, 35.642428],
              [-121.345602, 35.655964],
              [-121.346056, 35.661408],
              [-121.347735, 35.663014],
              [-121.347721, 35.664984],
              [-121.353377, 35.669204],
              [-121.366362, 35.680419],
              [-121.371197, 35.690378],
              [-121.37604, 35.700509],
              [-121.37896, 35.706026],
              [-121.378732, 35.711534],
              [-121.37715, 35.71647],
              [-121.378127, 35.718643],
              [-121.37918, 35.722519],
              [-121.379327, 35.726959],
              [-121.385629, 35.733688],
              [-121.391715, 35.743888],
              [-121.393738, 35.749968],
              [-121.39346, 35.763401],
              [-121.399754, 35.765342],
              [-121.413833, 35.775503],
              [-121.428496, 35.785905],
              [-121.438176, 35.796655],
              [-121.445306, 35.804572],
              [-121.450627, 35.809784],
              [-121.456097, 35.817713],
              [-121.462351, 35.820187],
              [-121.481606, 35.829354],
              [-121.501047, 35.842745],
              [-121.519192, 35.8634],
              [-121.525467, 35.874963],
              [-121.532011, 35.884798],
              [-121.541828, 35.90166],
              [-121.554917, 35.92836],
              [-121.561461, 35.943818],
              [-121.569642, 35.949438],
              [-121.589277, 35.959274],
              [-121.605639, 35.964894],
              [-121.620365, 35.969109],
              [-121.631818, 35.978945],
              [-121.64818, 35.998617],
              [-121.657997, 36.021098],
              [-121.664541, 36.039365],
              [-121.674359, 36.064661],
              [-121.69072, 36.08293],
              [-121.708718, 36.1026],
              [-121.725175, 36.124963],
              [-121.726073, 36.125943],
              [-121.727127, 36.127614],
              [-121.727812, 36.129009],
              [-121.729211, 36.129564],
              [-121.731307, 36.130701],
              [-121.734049, 36.133098],
              [-121.73811, 36.135778],
              [-121.741376, 36.13923],
              [-121.746061, 36.144965],
              [-121.747331, 36.145993],
              [-121.748899, 36.146447],
              [-121.751064, 36.147446],
              [-121.75238, 36.148407],
              [-121.755827, 36.151086],
              [-121.759359, 36.153596],
              [-121.761199, 36.155719],
              [-121.763955, 36.15727],
              [-121.765966, 36.158918],
              [-121.770693, 36.162014],
              [-121.773419, 36.162442],
              [-121.776718, 36.163555],
              [-121.78195, 36.166009],
              [-121.787967, 36.168468],
              [-121.79303, 36.171226],
              [-121.796894, 36.173873],
              [-121.800795, 36.176962],
              [-121.802354, 36.1783],
              [-121.806235, 36.17928],
              [-121.808877, 36.180047],
              [-121.81164, 36.180985],
              [-121.813791, 36.181238],
              [-121.817388, 36.181672],
              [-121.822594, 36.182593],
              [-121.827056, 36.183474],
              [-121.831681, 36.184595],
              [-121.83659, 36.186295],
              [-121.841539, 36.188234],
              [-121.846404, 36.190342],
              [-121.850153, 36.19234],
              [-121.854142, 36.195292],
              [-121.855166, 36.196524],
              [-121.858464, 36.197975],
              [-121.865136, 36.201829],
              [-121.867936, 36.203515],
              [-121.869154, 36.204188],
              [-121.876067, 36.208007],
              [-121.87832, 36.209144],
              [-121.880193, 36.210314],
              [-121.882271, 36.212204],
              [-121.884849, 36.2143],
              [-121.887257, 36.216739],
              [-121.889333, 36.218834],
              [-121.89153, 36.221785],
              [-121.893521, 36.224291],
              [-121.895507, 36.227755],
              [-121.896748, 36.22995],
              [-121.898029, 36.232385],
              [-121.899477, 36.234991],
              [-121.900421, 36.237904],
              [-121.901164, 36.239653],
              [-121.90366, 36.241475],
              [-121.905699, 36.243022],
              [-121.907776, 36.245288],
              [-121.909938, 36.247246],
              [-121.911267, 36.248756],
              [-121.913507, 36.249964],
              [-121.914926, 36.250602],
              [-121.916304, 36.251478],
              [-121.917559, 36.252523],
              [-121.918899, 36.253906],
              [-121.920361, 36.255052],
              [-121.921817, 36.255218],
              [-121.924611, 36.256529],
              [-121.927409, 36.25828],
              [-121.929709, 36.259183],
              [-121.93066, 36.259557],
              [-121.931996, 36.260467],
              [-121.933083, 36.261207],
              [-121.935381, 36.262824],
              [-121.937306, 36.264612],
              [-121.939228, 36.265959],
              [-121.941652, 36.267677],
              [-121.944202, 36.269598],
              [-121.946838, 36.271789],
              [-121.948599, 36.27378],
              [-121.950905, 36.276377],
              [-121.952833, 36.278469],
              [-121.954347, 36.280662],
              [-121.956363, 36.283328],
              [-121.957666, 36.285117],
              [-121.959053, 36.287108],
              [-121.96049, 36.290047],
              [-121.961887, 36.293324],
              [-121.962364, 36.295859],
              [-121.963177, 36.298766],
              [-121.963572, 36.301403],
              [-121.964217, 36.304141],
              [-121.964613, 36.306981],
              [-121.964426, 36.30962],
              [-121.964449, 36.312562],
              [-121.964222, 36.315438],
              [-121.963659, 36.317875],
              [-121.963469, 36.320176],
              [-121.962873, 36.322733],
              [-121.963582, 36.323964],
              [-121.964342, 36.325416],
              [-121.964814, 36.327343],
              [-121.965492, 36.328897],
              [-121.966215, 36.330924],
              [-121.966936, 36.332613],
              [-121.967364, 36.334168],
              [-121.967417, 36.334616],
              [-121.967586, 36.336027],
              [-121.968142, 36.337954],
              [-121.968361, 36.339374],
              [-121.968872, 36.340759],
              [-121.969095, 36.34272],
              [-121.969108, 36.34431],
              [-121.969159, 36.345561],
              [-121.968915, 36.346306],
              [-121.969211, 36.346914],
              [-121.969593, 36.34786],
              [-121.969937, 36.349178],
              [-121.970452, 36.35124],
              [-121.970927, 36.353471],
              [-121.971401, 36.355431],
              [-121.971877, 36.357831],
              [-121.972061, 36.360029],
              [-121.972077, 36.361991],
              [-121.972133, 36.363885],
              [-121.972107, 36.366016],
              [-121.971998, 36.367945],
              [-121.97168, 36.369806],
              [-121.971851, 36.370482],
              [-121.97203, 36.37197],
              [-121.972286, 36.372848],
              [-121.971733, 36.374963],
              [-121.971864, 36.375343],
              [-121.97196, 36.376817],
              [-121.971808, 36.378562],
              [-121.971448, 36.380274],
              [-121.970967, 36.382491],
              [-121.970391, 36.383368],
              [-121.971233, 36.384399],
              [-121.972119, 36.385664],
              [-121.973139, 36.387968],
              [-121.974406, 36.389866],
              [-121.975638, 36.39257],
              [-121.97594, 36.393706],
              [-121.976541, 36.395712],
              [-121.976895, 36.398189],
              [-121.977246, 36.40013],
              [-121.977381, 36.401335],
              [-121.976847, 36.402245],
              [-121.977483, 36.403446],
              [-121.97808, 36.404982],
              [-121.978888, 36.406817],
              [-121.979406, 36.40889],
              [-121.980257, 36.410893],
              [-121.980316, 36.412837],
              [-121.980659, 36.413906],
              [-121.98084, 36.415614],
              [-121.980977, 36.416919],
              [-121.981032, 36.418494],
              [-121.981042, 36.419634],
              [-121.981428, 36.42087],
              [-121.981934, 36.421536],
              [-121.982733, 36.422266],
              [-121.983363, 36.42283],
              [-121.984625, 36.424125],
              [-121.98576, 36.425086],
              [-121.987399, 36.426613],
              [-121.988452, 36.427843],
              [-121.98997, 36.429706],
              [-121.991194, 36.431404],
              [-121.992921, 36.433398],
              [-121.994104, 36.435063],
              [-121.995039, 36.437032],
              [-121.996012, 36.438631],
              [-121.997118, 36.441001],
              [-121.99793, 36.443272],
              [-121.998703, 36.445745],
              [-121.999433, 36.448118],
              [-122.000128, 36.451128],
              [-122.000606, 36.453268],
              [-122.000836, 36.455679],
              [-122.000819, 36.458428],
              [-122.00055, 36.461045],
              [-122.000234, 36.462958],
              [-121.999756, 36.464706],
              [-121.999628, 36.465176],
              [-121.999472, 36.466451],
              [-122.00075, 36.467888],
              [-122.001068, 36.468245],
              [-122.005233, 36.470516],
              [-122.008199, 36.474337],
              [-122.008971, 36.475332],
              [-122.013333, 36.483895],
              [-122.015825, 36.491388],
              [-122.018313, 36.499962],
              [-122.019979, 36.503214],
              [-122.02077, 36.50535],
              [-122.021998, 36.508908],
              [-122.022701, 36.51197],
              [-122.023054, 36.515816],
              [-122.022793, 36.519306],
              [-122.022707, 36.522227],
              [-122.022007, 36.52529],
              [-122.021132, 36.528497],
              [-122.02052, 36.531916],
              [-122.018944, 36.535266],
              [-122.017455, 36.537405],
              [-122.017982, 36.538686],
              [-122.019385, 36.539539],
              [-122.021402, 36.541246],
              [-122.023595, 36.543379],
              [-122.025261, 36.545086],
              [-122.026578, 36.546208],
              [-122.026755, 36.546358],
              [-122.02681, 36.546405],
              [-122.027015, 36.54658],
              [-122.029033, 36.5485],
              [-122.031315, 36.551987],
              [-122.033508, 36.55469],
              [-122.035265, 36.557464],
              [-122.037459, 36.560381],
              [-122.039654, 36.564652],
              [-122.041148, 36.568211],
              [-122.042818, 36.572766],
              [-122.043434, 36.575828],
              [-122.04405, 36.578249],
              [-122.044402, 36.580456],
              [-122.044405, 36.583519],
              [-122.044319, 36.586226],
              [-122.044058, 36.588719],
              [-122.044148, 36.591354],
              [-122.043185, 36.594347],
              [-122.042748, 36.596627],
              [-122.040733, 36.60012],
              [-122.038542, 36.602829],
              [-122.036877, 36.60568],
              [-122.034248, 36.609673],
              [-122.031617, 36.612098],
              [-122.029863, 36.614238],
              [-122.027758, 36.616021],
              [-122.025127, 36.618589],
              [-122.023636, 36.620443],
              [-122.022057, 36.621656],
              [-122.020127, 36.622798],
              [-122.020406, 36.624959],
              [-122.019351, 36.627761],
              [-122.017418, 36.63204],
              [-122.015927, 36.634289],
              [-122.014173, 36.636682],
              [-122.012596, 36.638568],
              [-122.011019, 36.639946],
              [-122.008042, 36.642411],
              [-122.005765, 36.644369],
              [-122.003487, 36.646399],
              [-122.001124, 36.647704],
              [-122.03118, 36.749908],
              [-122.031194, 36.749956],
              [-122.045947, 36.799632],
              [-122.051878, 36.8196],
              [-122.057223, 36.837598],
              [-122.060098, 36.847276],
              [-122.068317, 36.874953],
              [-122.072197, 36.891874],
              [-122.072355, 36.892564],
              [-122.076393, 36.910176],
              [-122.076399, 36.910199],
              [-122.081727, 36.910956],
              [-122.082376, 36.911117],
              [-122.100059, 36.915514],
              [-122.10304, 36.916255],
              [-122.105892, 36.917196],
              [-122.116694, 36.92076],
              [-122.130569, 36.925338],
              [-122.148521, 36.93299],
              [-122.158986, 36.937451],
              [-122.172024, 36.942681],
              [-122.175291, 36.944228],
              [-122.188009, 36.950251],
              [-122.209322, 36.961606],
              [-122.222099, 36.969011],
              [-122.231523, 36.974474],
              [-122.237378, 36.977874],
              [-122.240118, 36.979465],
              [-122.248341, 36.984241],
              [-122.251082, 36.985833],
              [-122.254328, 36.988314],
              [-122.261049, 36.993452],
              [-122.263203, 36.996583],
              [-122.26552, 36.99995],
              [-122.265654, 37.00012],
              [-122.269943, 37.009415],
              [-122.271504, 37.01382],
              [-122.273159, 37.018485],
              [-122.276375, 37.024834],
              [-122.290313, 37.03209],
              [-122.303179, 37.043881],
              [-122.303283, 37.043987],
              [-122.313899, 37.054764],
              [-122.323549, 37.053858],
              [-122.326714, 37.053858],
              [-122.336414, 37.053858],
              [-122.348208, 37.055672],
              [-122.360003, 37.058393],
              [-122.36453, 37.060094],
              [-122.369651, 37.06202],
              [-122.376086, 37.065648],
              [-122.381709, 37.071481],
              [-122.385739, 37.075893],
              [-122.38631, 37.076681],
              [-122.389421, 37.080974],
              [-122.393127, 37.087164],
              [-122.395358, 37.093741],
              [-122.396875, 37.102764],
              [-122.397386, 37.107036],
              [-122.396916, 37.109888],
              [-122.404395, 37.113933],
              [-122.405063, 37.114455],
              [-122.409278, 37.117749],
              [-122.413362, 37.121791],
              [-122.415749, 37.124947],
              [-122.417743, 37.129156],
              [-122.419757, 37.131882],
              [-122.421347, 37.135077],
              [-122.428467, 37.137755],
              [-122.439341, 37.144664],
              [-122.445198, 37.149667],
              [-122.445632, 37.150037],
              [-122.451513, 37.158245],
              [-122.453353, 37.161678],
              [-122.455426, 37.166602],
              [-122.45865, 37.169779],
              [-122.461034, 37.17332],
              [-122.464117, 37.177848],
              [-122.46591, 37.181856],
              [-122.468053, 37.187964],
              [-122.468572, 37.19348],
              [-122.468501, 37.195813],
              [-122.469461, 37.19944],
              [-122.470516, 37.202156],
              [-122.471583, 37.207305],
              [-122.472235, 37.212214],
              [-122.475538, 37.216102],
              [-122.479071, 37.221682],
              [-122.481703, 37.229147],
              [-122.483142, 37.23474],
              [-122.483682, 37.242082],
              [-122.484046, 37.249944],
              [-122.483083, 37.2589],
              [-122.481774, 37.271088],
              [-122.476094, 37.294152],
              [-122.475288, 37.297864],
              [-122.474695, 37.300593],
              [-122.472915, 37.308782],
              [-122.472323, 37.311512],
              [-122.472315, 37.311551],
              [-122.472273, 37.311743],
              [-122.47225, 37.311848],
              [-122.471612, 37.314788],
              [-122.468878, 37.327378],
              [-122.465869, 37.34124],
              [-122.467005, 37.349889],
              [-122.471549, 37.358538],
              [-122.474362, 37.363808],
              [-122.480307, 37.374942],
              [-122.480369, 37.375058],
              [-122.480431, 37.375174],
              [-122.484105, 37.382056],
              [-122.487454, 37.388328],
              [-122.496676, 37.401761],
              [-122.501092, 37.408193],
              [-122.503083, 37.411206],
              [-122.503543, 37.411902],
              [-122.505895, 37.41709],
              [-122.507234, 37.42118],
              [-122.507734, 37.422706],
              [-122.508041, 37.424689],
              [-122.508415, 37.427102],
              [-122.509351, 37.4314],
              [-122.509972, 37.4368],
              [-122.509916, 37.439982],
              [-122.509889, 37.441554],
              [-122.514592, 37.442518],
              [-122.527596, 37.446096],
              [-122.537338, 37.450738],
              [-122.546996, 37.457857],
              [-122.549587, 37.460537],
              [-122.552961, 37.464026],
              [-122.559524, 37.469866],
              [-122.560804, 37.471162],
              [-122.562993, 37.473378],
              [-122.563877, 37.474273],
              [-122.568095, 37.479214],
              [-122.568856, 37.480612],
              [-122.570346, 37.483349],
              [-122.570958, 37.484472],
              [-122.572777, 37.489182],
              [-122.573419, 37.493644],
              [-122.574055, 37.498508],
              [-122.574963, 37.499943],
              [-122.575076, 37.501064],
              [-122.57784, 37.52838],
              [-122.579563, 37.545875],
              [-122.583774, 37.555676],
              [-122.586454, 37.561913],
              [-122.587608, 37.573636],
              [-122.587882, 37.576424],
              [-122.588177, 37.579408],
              [-122.587472, 37.58477],
              [-122.587108, 37.587549],
              [-122.586788, 37.589981],
              [-122.586454, 37.592529],
              [-122.584816, 37.597032],
              [-122.583978, 37.599338],
              [-122.583025, 37.601954],
              [-122.58017, 37.609804],
              [-122.57956, 37.611484],
              [-122.579166, 37.6124],
              [-122.578232, 37.61457],
              [-122.575433, 37.621083],
              [-122.574501, 37.623254],
              [-122.573887, 37.624681],
              [-122.572666, 37.627521],
              [-122.57203, 37.628955],
              [-122.571401, 37.630376],
              [-122.570158, 37.633179],
              [-122.567497, 37.639185],
              [-122.567794, 37.641799],
              [-122.568142, 37.644847],
              [-122.568402, 37.647132],
              [-122.569182, 37.653987],
              [-122.569443, 37.656273],
              [-122.569649, 37.658085],
              [-122.570267, 37.66352],
              [-122.570474, 37.665333],
              [-122.571257, 37.67221],
              [-122.573608, 37.692844],
              [-122.574392, 37.699722],
              [-122.574551, 37.701121],
              [-122.575029, 37.705321],
              [-122.575189, 37.706721],
              [-122.578294, 37.733988],
              [-122.5799, 37.748079],
              [-122.581281, 37.760203],
              [-122.581981, 37.76405],
              [-122.583293, 37.771266],
              [-122.584728, 37.779156],
              [-122.588174, 37.789362],
              [-122.600236, 37.802483],
              [-122.612285, 37.815224],
              [-122.626091, 37.821638],
              [-122.626092, 37.821593],
              [-122.626105, 37.821597],
              [-122.629583, 37.824367],
              [-122.632386, 37.827704],
              [-122.632472, 37.827807],
              [-122.641685, 37.830085],
              [-122.643163, 37.830451],
              [-122.65561, 37.835821],
              [-122.667425, 37.841726],
              [-122.66788, 37.842102],
              [-122.675305, 37.848229],
              [-122.675654, 37.848517],
              [-122.678018, 37.847891],
              [-122.691987, 37.845006],
              [-122.697, 37.844725],
              [-122.704679, 37.844296],
              [-122.715845, 37.845625],
              [-122.724311, 37.84768],
              [-122.730666, 37.85006],
              [-122.740258, 37.855174],
              [-122.741437, 37.855414],
              [-122.751098, 37.857348],
              [-122.752466, 37.857699],
              [-122.757895, 37.860482],
              [-122.760192, 37.861859],
              [-122.766771, 37.865804],
              [-122.773706, 37.870847],
              [-122.778019, 37.874928],
              [-122.779141, 37.876132],
              [-122.779914, 37.877164],
              [-122.78229, 37.880335],
              [-122.784914, 37.88441],
              [-122.785472, 37.885277],
              [-122.786391, 37.886947],
              [-122.787037, 37.88812],
              [-122.788221, 37.891027],
              [-122.789027, 37.893763],
              [-122.793105, 37.895005],
              [-122.80034, 37.897078],
              [-122.806389, 37.898803],
              [-122.80749, 37.899117],
              [-122.814134, 37.901084],
              [-122.818792, 37.903239],
              [-122.825371, 37.907093],
              [-122.829804, 37.910325],
              [-122.833226, 37.913415],
              [-122.838896, 37.918914],
              [-122.842134, 37.923317],
              [-122.844277, 37.927342],
              [-122.84608, 37.931601],
              [-122.847862, 37.937478],
              [-122.849453, 37.941803],
              [-122.85046, 37.945585],
              [-122.854574, 37.947634],
              [-122.857081, 37.949774],
              [-122.857222, 37.949913],
              [-122.859584, 37.95225],
              [-122.862503, 37.955269],
              [-122.863958, 37.957234],
              [-122.865133, 37.957881],
              [-122.868662, 37.959488],
              [-122.872722, 37.96248],
              [-122.875186, 37.964788],
              [-122.876104, 37.966342],
              [-122.879057, 37.966578],
              [-122.882555, 37.968876],
              [-122.889291, 37.974109],
              [-122.892764, 37.974686],
              [-122.897421, 37.975747],
              [-122.902284, 37.977386],
              [-122.905489, 37.970574],
              [-122.909236, 37.964644],
              [-122.912527, 37.960784],
              [-122.913397, 37.959765],
              [-122.91917, 37.954835],
              [-122.926207, 37.950626],
              [-122.931927, 37.946672],
              [-122.937969, 37.944443],
              [-122.945916, 37.94267],
              [-122.953224, 37.941093],
              [-122.959588, 37.940619],
              [-122.968073, 37.940267],
              [-122.976428, 37.940149],
              [-122.984735, 37.940536],
              [-122.993127, 37.941091],
              [-123.001109, 37.941358],
              [-123.010226, 37.941337],
              [-123.022507, 37.942065],
              [-123.03042, 37.943307],
              [-123.041121, 37.945465],
              [-123.050084, 37.948016],
              [-123.052209, 37.949162],
              [-123.059028, 37.952841],
              [-123.067965, 37.958669],
              [-123.074188, 37.964887],
              [-123.07939, 37.971937],
              [-123.081881, 37.978707],
              [-123.08388, 37.983366],
              [-123.083951, 37.983824],
              [-123.084443, 37.987027],
              [-123.084607, 37.988088],
              [-123.085102, 37.995385],
              [-123.085265, 37.996711],
              [-123.085661, 37.999928],
              [-123.08513, 38.008433],
              [-123.082947, 38.015333],
              [-123.0812, 38.018971],
              [-123.074701, 38.027199],
              [-123.067254, 38.034019],
              [-123.063797, 38.036487],
              [-123.023699, 38.124923],
              [-123.014817, 38.149132],
              [-123.019727, 38.154186],
              [-123.02306, 38.158172],
              [-123.027211, 38.16417],
              [-123.030483, 38.172458],
              [-123.031531, 38.1781],
              [-123.031336, 38.181462],
              [-123.032917, 38.182968],
              [-123.034803, 38.186018],
              [-123.037897, 38.192491],
              [-123.038943, 38.197528],
              [-123.040393, 38.198362],
              [-123.045825, 38.204926],
              [-123.048921, 38.211466],
              [-123.049617, 38.214286],
              [-123.051963, 38.215855],
              [-123.08863, 38.249918],
              [-123.100963, 38.261358],
              [-123.103441, 38.262322],
              [-123.110969, 38.267263],
              [-123.119591, 38.277103],
              [-123.123467, 38.284309],
              [-123.123774, 38.285787],
              [-123.126115, 38.287284],
              [-123.126116, 38.287286],
              [-123.134523, 38.296263],
              [-123.141329, 38.311338],
              [-123.141292, 38.313127],
              [-123.141001, 38.327454],
              [-123.138321, 38.337188],
              [-123.135064, 38.344168],
              [-123.134312, 38.345786],
              [-123.135853, 38.346856],
              [-123.140012, 38.350605],
              [-123.142724, 38.354158],
              [-123.142734, 38.355302],
              [-123.146286, 38.357607],
              [-123.148978, 38.360222],
              [-123.150492, 38.361692],
              [-123.155743, 38.368396],
              [-123.156952, 38.370274],
              [-123.159847, 38.374917],
              [-123.160931, 38.377741],
              [-123.161975, 38.380458],
              [-123.163878, 38.386323],
              [-123.164195, 38.387298],
              [-123.164757, 38.388337],
              [-123.165451, 38.388773],
              [-123.168101, 38.390436],
              [-123.170121, 38.39219],
              [-123.170677, 38.392673],
              [-123.171711, 38.394079],
              [-123.179974, 38.397463],
              [-123.185038, 38.400682],
              [-123.188465, 38.40286],
              [-123.189735, 38.404056],
              [-123.193371, 38.407481],
              [-123.196159, 38.410108],
              [-123.201896, 38.419676],
              [-123.202684, 38.42099],
              [-123.204071, 38.423903],
              [-123.204244, 38.424067],
              [-123.211382, 38.430817],
              [-123.218752, 38.439879],
              [-123.22022, 38.441954],
              [-123.228013, 38.444228],
              [-123.234752, 38.448422],
              [-123.236391, 38.450428],
              [-123.241989, 38.450531],
              [-123.242362, 38.450598],
              [-123.249604, 38.451898],
              [-123.251116, 38.452078],
              [-123.253522, 38.452705],
              [-123.269188, 38.457569],
              [-123.280298, 38.463461],
              [-123.281332, 38.464497],
              [-123.283172, 38.464959],
              [-123.289685, 38.46758],
              [-123.306742, 38.478681],
              [-123.317345, 38.488638],
              [-123.32381, 38.498614],
              [-123.324537, 38.498678],
              [-123.327403, 38.499267],
              [-123.329119, 38.499913],
              [-123.32944, 38.499913],
              [-123.330403, 38.499913],
              [-123.330725, 38.499913],
              [-123.330921, 38.502421],
              [-123.339655, 38.503323],
              [-123.348529, 38.504241],
              [-123.360929, 38.510202],
              [-123.368558, 38.518416],
              [-123.370856, 38.52089],
              [-123.37612, 38.526809],
              [-123.378567, 38.529598],
              [-123.384286, 38.536675],
              [-123.384567, 38.537022],
              [-123.3891, 38.546152],
              [-123.394157, 38.553942],
              [-123.404998, 38.562818],
              [-123.408257, 38.56591],
              [-123.41417, 38.571522],
              [-123.417449, 38.574831],
              [-123.425389, 38.582844],
              [-123.430222, 38.591209],
              [-123.43049, 38.591672],
              [-123.43715, 38.597888],
              [-123.448391, 38.606292],
              [-123.456159, 38.614788],
              [-123.460369, 38.622708],
              [-123.462496, 38.624913],
              [-123.465616, 38.628846],
              [-123.470379, 38.638297],
              [-123.470521, 38.638578],
              [-123.477373, 38.642226],
              [-123.487286, 38.650427],
              [-123.488543, 38.651466],
              [-123.495084, 38.660662],
              [-123.498669, 38.668943],
              [-123.50112, 38.670522],
              [-123.5039, 38.672915],
              [-123.505134, 38.674389],
              [-123.51058, 38.68089],
              [-123.52301, 38.687515],
              [-123.531889, 38.693421],
              [-123.549463, 38.700135],
              [-123.566089, 38.709021],
              [-123.578005, 38.717583],
              [-123.581741, 38.721982],
              [-123.586915, 38.728075],
              [-123.591962, 38.740227],
              [-123.600099, 38.74472],
              [-123.607061, 38.748565],
              [-123.614258, 38.749912],
              [-123.615205, 38.749912],
              [-123.626123, 38.754646],
              [-123.627397, 38.75534],
              [-123.631222, 38.757424],
              [-123.632497, 38.758119],
              [-123.632611, 38.758173],
              [-123.641253, 38.764394],
              [-123.652651, 38.774967],
              [-123.660036, 38.784258],
              [-123.663964, 38.785537],
              [-123.682698, 38.797015],
              [-123.691926, 38.809419],
              [-123.692595, 38.810319],
              [-123.706514, 38.817724],
              [-123.717807, 38.827921],
              [-123.7253, 38.844117],
              [-123.737921, 38.857441],
              [-123.751129, 38.867392],
              [-123.76203, 38.87491],
              [-123.767128, 38.877896],
              [-123.784676, 38.893728],
              [-123.792035, 38.912066],
              [-123.798451, 38.927467],
              [-123.806505, 38.940457],
              [-123.80986, 38.961219],
              [-123.806068, 38.977042],
              [-123.797217, 38.989863],
              [-123.795188, 38.991591],
              [-123.785468, 38.999869],
              [-123.785423, 38.999908],
              [-123.779909, 39.002937],
              [-123.779882, 39.002967],
              [-123.779867, 39.002985],
              [-123.776609, 39.005018],
              [-123.769177, 39.007883],
              [-123.763148, 39.00869],
              [-123.762692, 39.010707],
              [-123.762021, 39.012926],
              [-123.761178, 39.015046],
              [-123.761574, 39.015984],
              [-123.761387, 39.019308],
              [-123.761156, 39.022499],
              [-123.761615, 39.025652],
              [-123.761469, 39.028674],
              [-123.760926, 39.030624],
              [-123.761411, 39.031897],
              [-123.761733, 39.034245],
              [-123.76207, 39.038372],
              [-123.761965, 39.041091],
              [-123.764577, 39.043896],
              [-123.766375, 39.047276],
              [-123.768343, 39.050286],
              [-123.769187, 39.053033],
              [-123.770422, 39.056182],
              [-123.77131, 39.058996],
              [-123.772642, 39.063185],
              [-123.774686, 39.065053],
              [-123.776473, 39.067023],
              [-123.778309, 39.06973],
              [-123.779501, 39.072744],
              [-123.78095, 39.075555],
              [-123.782271, 39.078501],
              [-123.783423, 39.081784],
              [-123.78458, 39.085604],
              [-123.784992, 39.088287],
              [-123.786106, 39.092141],
              [-123.786544, 39.092944],
              [-123.788472, 39.096054],
              [-123.789825, 39.09769],
              [-123.791445, 39.100232],
              [-123.79232, 39.101435],
              [-123.792812, 39.102313],
              [-123.793856, 39.104178],
              [-123.7947, 39.106792],
              [-123.7964, 39.10856],
              [-123.798761, 39.111769],
              [-123.801606, 39.115914],
              [-123.803055, 39.118523],
              [-123.804724, 39.121601],
              [-123.805532, 39.124903],
              [-123.806333, 39.124903],
              [-123.807457, 39.127231],
              [-123.80789, 39.129757],
              [-123.808128, 39.133566],
              [-123.808635, 39.137811],
              [-123.80921, 39.141043],
              [-123.811466, 39.143823],
              [-123.814333, 39.146867],
              [-123.815981, 39.149483],
              [-123.821234, 39.157732],
              [-123.823457, 39.161051],
              [-123.82582, 39.164908],
              [-123.827157, 39.167055],
              [-123.828574, 39.16883],
              [-123.831056, 39.172045],
              [-123.834081, 39.176537],
              [-123.836879, 39.180423],
              [-123.83888, 39.183339],
              [-123.840771, 39.187335],
              [-123.840128, 39.190208],
              [-123.841317, 39.191276],
              [-123.842523, 39.193289],
              [-123.843946, 39.195367],
              [-123.845471, 39.198254],
              [-123.846018, 39.1998],
              [-123.845842, 39.202061],
              [-123.845798, 39.204456],
              [-123.846429, 39.205832],
              [-123.846817, 39.208122],
              [-123.847143, 39.211828],
              [-123.847125, 39.215673],
              [-123.848393, 39.218731],
              [-123.849189, 39.222062],
              [-123.849682, 39.225464],
              [-123.850218, 39.228764],
              [-123.850092, 39.231396],
              [-123.851784, 39.233875],
              [-123.852999, 39.236393],
              [-123.854249, 39.238405],
              [-123.85485, 39.240457],
              [-123.855431, 39.241396],
              [-123.856855, 39.243474],
              [-123.859734, 39.24682],
              [-123.860768, 39.249899],
              [-123.86341, 39.254763],
              [-123.864482, 39.257963],
              [-123.86523, 39.262539],
              [-123.867365, 39.266757],
              [-123.868192, 39.269869],
              [-123.869037, 39.273054],
              [-123.869079, 39.275878],
              [-123.870212, 39.280658],
              [-123.874968, 39.288591],
              [-123.876128, 39.290448],
              [-123.876128, 39.291589],
              [-123.879278, 39.301383],
              [-123.880183, 39.304198],
              [-123.8816, 39.310208],
              [-123.881034, 39.319078],
              [-123.881017, 39.319346],
              [-123.886417, 39.325027],
              [-123.890125, 39.332283],
              [-123.893085, 39.337494],
              [-123.894936, 39.348349],
              [-123.895218, 39.352421],
              [-123.89434, 39.356915],
              [-123.894994, 39.360274],
              [-123.896046, 39.363931],
              [-123.896323, 39.367185],
              [-123.896551, 39.369853],
              [-123.896809, 39.374893],
              [-123.896649, 39.375949],
              [-123.896431, 39.377381],
              [-123.895872, 39.380097],
              [-123.894565, 39.383103],
              [-123.892889, 39.386843],
              [-123.891468, 39.389308],
              [-123.890446, 39.390098],
              [-123.890423, 39.392286],
              [-123.890644, 39.395017],
              [-123.891541, 39.398333],
              [-123.892098, 39.401441],
              [-123.892358, 39.404341],
              [-123.892449, 39.407037],
              [-123.892323, 39.409762],
              [-123.891305, 39.413244],
              [-123.890046, 39.416117],
              [-123.889569, 39.417144],
              [-123.888512, 39.419421],
              [-123.887852, 39.42254],
              [-123.887103, 39.425758],
              [-123.887635, 39.427883],
              [-123.888187, 39.430088],
              [-123.889266, 39.43462],
              [-123.889082, 39.437815],
              [-123.888895, 39.443939],
              [-123.887943, 39.448097],
              [-123.887402, 39.453036],
              [-123.885027, 39.458244],
              [-123.882581, 39.46436],
              [-123.881131, 39.467733],
              [-123.879602, 39.470836],
              [-123.879461, 39.471095],
              [-123.878219, 39.473369],
              [-123.877697, 39.476221],
              [-123.876128, 39.478987],
              [-123.876128, 39.479963],
              [-123.874384, 39.482562],
              [-123.875159, 39.485321],
              [-123.87537, 39.490356],
              [-123.875238, 39.49243],
              [-123.875126, 39.494179],
              [-123.874434, 39.496353],
              [-123.873836, 39.498158],
              [-123.873284, 39.499887],
              [-123.872515, 39.502386],
              [-123.871207, 39.504629],
              [-123.867826, 39.508305],
              [-123.863573, 39.511268],
              [-123.857139, 39.514141],
              [-123.852648, 39.514849],
              [-123.84722, 39.523876],
              [-123.846623, 39.524869],
              [-123.842331, 39.533258],
              [-123.837006, 39.543259],
              [-123.834649, 39.549194],
              [-123.836565, 39.552601],
              [-123.838411, 39.554916],
              [-123.839652, 39.556471],
              [-123.840835, 39.56035],
              [-123.843584, 39.566063],
              [-123.846375, 39.571674],
              [-123.849387, 39.57829],
              [-123.850624, 39.584478],
              [-123.852516, 39.592371],
              [-123.853057, 39.597557],
              [-123.853427, 39.603549],
              [-123.853146, 39.608974],
              [-123.853083, 39.614599],
              [-123.85263, 39.620426],
              [-123.851862, 39.624881],
              [-123.851638, 39.627589],
              [-123.851518, 39.631745],
              [-123.851854, 39.637705],
              [-123.851919, 39.642763],
              [-123.852355, 39.646343],
              [-123.854308, 39.650003],
              [-123.85526, 39.653308],
              [-123.856001, 39.657051],
              [-123.856536, 39.661534],
              [-123.856876, 39.664378],
              [-123.856929, 39.668464],
              [-123.857325, 39.672312],
              [-123.857183, 39.674693],
              [-123.859453, 39.675937],
              [-123.861804, 39.676844],
              [-123.864559, 39.678751],
              [-123.867444, 39.68069],
              [-123.871243, 39.682885],
              [-123.873908, 39.684525],
              [-123.876131, 39.686103],
              [-123.876354, 39.686235],
              [-123.880306, 39.689086],
              [-123.885337, 39.694648],
              [-123.890367, 39.700009],
              [-123.893216, 39.704784],
              [-123.895741, 39.711406],
              [-123.897271, 39.718407],
              [-123.898361, 39.72501],
              [-123.89923, 39.731378],
              [-123.899885, 39.734762],
              [-123.900068, 39.736784],
              [-123.900295, 39.739289],
              [-123.901617, 39.743775],
              [-123.903228, 39.749876],
              [-123.904018, 39.757083],
              [-123.903225, 39.763072],
              [-123.902641, 39.76832],
              [-123.901088, 39.772699],
              [-123.90097, 39.780466],
              [-123.90238, 39.78187],
              [-123.905813, 39.788271],
              [-123.90855, 39.79461],
              [-123.910529, 39.799575],
              [-123.915221, 39.802238],
              [-123.920479, 39.804864],
              [-123.923958, 39.807903],
              [-123.93197, 39.811352],
              [-123.938591, 39.814944],
              [-123.94391, 39.818745],
              [-123.949187, 39.822613],
              [-123.9536, 39.826991],
              [-123.957497, 39.83174],
              [-123.95972, 39.835055],
              [-123.963275, 39.840211],
              [-123.966252, 39.844496],
              [-123.968104, 39.846132],
              [-123.970063, 39.849247],
              [-123.973477, 39.853562],
              [-123.975978, 39.857984],
              [-123.97854, 39.863616],
              [-123.979433, 39.86825],
              [-123.980753, 39.872276],
              [-123.981898, 39.874871],
              [-123.982946, 39.876778],
              [-123.985171, 39.877824],
              [-123.986786, 39.879171],
              [-123.988966, 39.879814],
              [-123.991757, 39.881097],
              [-123.991778, 39.881114],
              [-124.001136, 39.886172],
              [-124.005454, 39.889286],
              [-124.009637, 39.893749],
              [-124.012176, 39.899372],
              [-124.016198, 39.907401],
              [-124.019271, 39.910731],
              [-124.023463, 39.915664],
              [-124.028933, 39.923778],
              [-124.035355, 39.931073],
              [-124.041394, 39.936354],
              [-124.047685, 39.94096],
              [-124.051134, 39.945665],
              [-124.057047, 39.951114],
              [-124.063575, 39.956624],
              [-124.068213, 39.96155],
              [-124.074278, 39.968108],
              [-124.078596, 39.972263],
              [-124.086319, 39.973083],
              [-124.096383, 39.975018],
              [-124.108581, 39.978845],
              [-124.115214, 39.9824],
              [-124.122772, 39.988736],
              [-124.126142, 39.993565],
              [-124.133255, 39.999865],
              [-124.132307, 39.999865],
              [-124.134889, 40.00247],
              [-124.137109, 40.00471],
              [-124.141086, 40.010971],
              [-124.144105, 40.018172],
              [-124.145333, 40.024171],
              [-124.145426, 40.028998],
              [-124.145214, 40.034698],
              [-124.145091, 40.040901],
              [-124.144312, 40.04764],
              [-124.143968, 40.051765],
              [-124.144099, 40.052401],
              [-124.146286, 40.055783],
              [-124.153762, 40.063544],
              [-124.156646, 40.065885],
              [-124.168923, 40.070382],
              [-124.186269, 40.075603],
              [-124.203288, 40.081021],
              [-124.204345, 40.081358],
              [-124.204752, 40.081488],
              [-124.217953, 40.086447],
              [-124.231204, 40.093382],
              [-124.239964, 40.102911],
              [-124.247238, 40.1109],
              [-124.251151, 40.114618],
              [-124.252029, 40.115523],
              [-124.254082, 40.117665],
              [-124.255306, 40.119037],
              [-124.257708, 40.121514],
              [-124.260328, 40.123654],
              [-124.262302, 40.124862],
              [-124.272099, 40.13365],
              [-124.277461, 40.134726],
              [-124.283039, 40.136205],
              [-124.287833, 40.137683],
              [-124.294326, 40.142353],
              [-124.302256, 40.148232],
              [-124.309928, 40.153607],
              [-124.321872, 40.16019],
              [-124.333689, 40.167175],
              [-124.348256, 40.178255],
              [-124.359905, 40.186783],
              [-124.368675, 40.193228],
              [-124.376159, 40.200526],
              [-124.379252, 40.20287],
              [-124.385311, 40.206051],
              [-124.392777, 40.209799],
              [-124.403151, 40.218005],
              [-124.411026, 40.225275],
              [-124.421287, 40.237102],
              [-124.426725, 40.249858],
              [-124.428222, 40.258121],
              [-124.42915, 40.266915],
              [-124.428677, 40.275644],
              [-124.428027, 40.284104],
              [-124.427899, 40.288804],
              [-124.426413, 40.2928],
              [-124.424619, 40.296964],
              [-124.421949, 40.302135],
              [-124.419499, 40.308816],
              [-124.418276, 40.314289],
              [-124.418542, 40.318753],
              [-124.420213, 40.32258],
              [-124.422979, 40.327479],
              [-124.425439, 40.331607],
              [-124.427109, 40.335702],
              [-124.427945, 40.338823],
              [-124.428038, 40.344396],
              [-124.42787, 40.351512],
              [-124.427744, 40.357588],
              [-124.428055, 40.361785],
              [-124.429462, 40.364906],
              [-124.432711, 40.367623],
              [-124.438641, 40.371815],
              [-124.442505, 40.37423],
              [-124.443164, 40.374855],
              [-124.444708, 40.376412],
              [-124.450003, 40.383319],
              [-124.455744, 40.391365],
              [-124.463471, 40.401645],
              [-124.472435, 40.41282],
              [-124.478625, 40.422102],
              [-124.480543, 40.428493],
              [-124.481367, 40.435763],
              [-124.482003, 40.440318],
              [-124.479158, 40.452636],
              [-124.475894, 40.459762],
              [-124.470646, 40.465927],
              [-124.470153, 40.466341],
              [-124.465785, 40.470012],
              [-124.463248, 40.47311],
              [-124.458922, 40.479203],
              [-124.455952, 40.484047],
              [-124.454648, 40.487675],
              [-124.453516, 40.49043],
              [-124.45256, 40.493084],
              [-124.45258, 40.497977],
              [-124.452895, 40.499851],
              [-124.451874, 40.51561],
              [-124.447677, 40.529709],
              [-124.443579, 40.535642],
              [-124.434816, 40.548648],
              [-124.427191, 40.562085],
              [-124.419831, 40.577127],
              [-124.412003, 40.597022],
              [-124.403367, 40.61317],
              [-124.396452, 40.624849],
              [-124.392902, 40.630369],
              [-124.38409, 40.644873],
              [-124.376173, 40.657329],
              [-124.368878, 40.669706],
              [-124.368584, 40.670116],
              [-124.367038, 40.67227],
              [-124.360802, 40.680963],
              [-124.352814, 40.693491],
              [-124.352276, 40.694324],
              [-124.3429, 40.708832],
              [-124.334114, 40.718982],
              [-124.327425, 40.727724],
              [-124.31995, 40.738877],
              [-124.311445, 40.749846],
              [-124.299333, 40.768481],
              [-124.299031, 40.771072],
              [-124.298338, 40.775479],
              [-124.29699, 40.780963],
              [-124.292662, 40.788094],
              [-124.28658, 40.795897],
              [-124.281397, 40.799149],
              [-124.280181, 40.799932],
              [-124.272302, 40.810795],
              [-124.265778, 40.820078],
              [-124.256931, 40.83316],
              [-124.251175, 40.840929],
              [-124.246315, 40.846964],
              [-124.243044, 40.85289],
              [-124.23763, 40.861327],
              [-124.236337, 40.863388],
              [-124.232622, 40.869286],
              [-124.230473, 40.874846],
              [-124.230442, 40.874901],
              [-124.225497, 40.88359],
              [-124.221106, 40.892429],
              [-124.214964, 40.906404],
              [-124.210622, 40.917506],
              [-124.204832, 40.932078],
              [-124.204071, 40.934028],
              [-124.203357, 40.935852],
              [-124.197619, 40.950529],
              [-124.195885, 40.956746],
              [-124.194502, 40.961703],
              [-124.193762, 40.963644],
              [-124.193727, 40.963735],
              [-124.189793, 40.974048],
              [-124.186191, 40.987289],
              [-124.182895, 40.999846],
              [-124.182694, 41.001313],
              [-124.183886, 41.001926],
              [-124.185833, 41.002545],
              [-124.188087, 41.003503],
              [-124.190784, 41.00453],
              [-124.194139, 41.006302],
              [-124.19639, 41.007528],
              [-124.198288, 41.008583],
              [-124.200051, 41.009772],
              [-124.201682, 41.01096],
              [-124.203224, 41.012113],
              [-124.204984, 41.013638],
              [-124.206608, 41.015498],
              [-124.208012, 41.017121],
              [-124.20946, 41.018912],
              [-124.21042, 41.020531],
              [-124.211598, 41.022555],
              [-124.21234, 41.023804],
              [-124.213037, 41.025186],
              [-124.213245, 41.025731],
              [-124.213273, 41.025804],
              [-124.213642, 41.02677],
              [-124.213812, 41.027374],
              [-124.213985, 41.027982],
              [-124.214463, 41.029094],
              [-124.214628, 41.030305],
              [-124.215105, 41.031417],
              [-124.216073, 41.032298],
              [-124.218141, 41.034261],
              [-124.220424, 41.036864],
              [-124.222268, 41.039028],
              [-124.224022, 41.041291],
              [-124.225549, 41.044024],
              [-124.227079, 41.046622],
              [-124.22821, 41.049015],
              [-124.229115, 41.051844],
              [-124.229932, 41.054605],
              [-124.230222, 41.05669],
              [-124.230309, 41.056926],
              [-124.230514, 41.058641],
              [-124.230798, 41.061331],
              [-124.23078, 41.063246],
              [-124.231202, 41.065501],
              [-124.232213, 41.066549],
              [-124.233179, 41.067799],
              [-124.234716, 41.069591],
              [-124.236162, 41.071684],
              [-124.237207, 41.073842],
              [-124.237943, 41.075863],
              [-124.238502, 41.077782],
              [-124.238973, 41.079633],
              [-124.239356, 41.08145],
              [-124.239565, 41.082863],
              [-124.240271, 41.083405],
              [-124.241679, 41.08486],
              [-124.242908, 41.086414],
              [-124.244448, 41.088071],
              [-124.246117, 41.090098],
              [-124.24743, 41.092224],
              [-124.2479, 41.094175],
              [-124.24894, 41.096971],
              [-124.249588, 41.099025],
              [-124.250015, 41.100842],
              [-124.250348, 41.10323],
              [-124.250508, 41.105012],
              [-124.25058, 41.106827],
              [-124.250564, 41.10854],
              [-124.250636, 41.110288],
              [-124.250582, 41.111296],
              [-124.250435, 41.11274],
              [-124.25033, 41.114419],
              [-124.250048, 41.116097],
              [-124.249722, 41.117674],
              [-124.249532, 41.11895],
              [-124.249164, 41.120157],
              [-124.249681, 41.121908],
              [-124.250288, 41.123592],
              [-124.250635, 41.124468],
              [-124.250776, 41.124844],
              [-124.250999, 41.12533],
              [-124.251192, 41.126104],
              [-124.251191, 41.127393],
              [-124.251679, 41.128267],
              [-124.251902, 41.12867],
              [-124.251902, 41.12951],
              [-124.252123, 41.130181],
              [-124.252166, 41.13139],
              [-124.252343, 41.132531],
              [-124.252474, 41.134075],
              [-124.251984, 41.136054],
              [-124.251189, 41.137563],
              [-124.250972, 41.138812],
              [-124.250604, 41.140693],
              [-124.250147, 41.142675],
              [-124.249644, 41.144925],
              [-124.249185, 41.147176],
              [-124.248052, 41.150567],
              [-124.247236, 41.152817],
              [-124.246019, 41.1553],
              [-124.244308, 41.158319],
              [-124.243365, 41.15956],
              [-124.242511, 41.160834],
              [-124.241389, 41.162208],
              [-124.240268, 41.163515],
              [-124.239013, 41.16472],
              [-124.237625, 41.165959],
              [-124.236326, 41.167097],
              [-124.234625, 41.168469],
              [-124.233282, 41.169506],
              [-124.231491, 41.170944],
              [-124.23015, 41.171645],
              [-124.228986, 41.172481],
              [-124.227913, 41.173082],
              [-124.226214, 41.174017],
              [-124.22456, 41.174951],
              [-124.223308, 41.17572],
              [-124.221922, 41.176386],
              [-124.220625, 41.177087],
              [-124.219418, 41.177687],
              [-124.217632, 41.178386],
              [-124.215889, 41.179052],
              [-124.213521, 41.180051],
              [-124.211421, 41.180916],
              [-124.209546, 41.18148],
              [-124.208251, 41.181945],
              [-124.206688, 41.182409],
              [-124.203116, 41.183437],
              [-124.200171, 41.183962],
              [-124.197851, 41.18439],
              [-124.195532, 41.184582],
              [-124.195031, 41.18626],
              [-124.193524, 41.1917],
              [-124.190652, 41.201404],
              [-124.18779, 41.209562],
              [-124.186065, 41.214228],
              [-124.18484, 41.217383],
              [-124.181486, 41.225437],
              [-124.179131, 41.230772],
              [-124.178131, 41.233525],
              [-124.177271, 41.235404],
              [-124.176367, 41.237148],
              [-124.176406, 41.237955],
              [-124.176174, 41.239366],
              [-124.175717, 41.240978],
              [-124.175126, 41.242555],
              [-124.175337, 41.244338],
              [-124.175457, 41.246356],
              [-124.175541, 41.247197],
              [-124.175489, 41.248339],
              [-124.175439, 41.249044],
              [-124.175481, 41.249485],
              [-124.175523, 41.249845],
              [-124.175155, 41.253175],
              [-124.174691, 41.255491],
              [-124.17435, 41.257047],
              [-124.173953, 41.258881],
              [-124.173316, 41.260995],
              [-124.171324, 41.266092],
              [-124.17024, 41.268473],
              [-124.169245, 41.270753],
              [-124.168208, 41.272731],
              [-124.166585, 41.275646],
              [-124.165991, 41.277928],
              [-124.165176, 41.280007],
              [-124.164405, 41.282154],
              [-124.162374, 41.286042],
              [-124.160704, 41.289293],
              [-124.161457, 41.290036],
              [-124.161943, 41.290777],
              [-124.162471, 41.291788],
              [-124.163, 41.292596],
              [-124.163398, 41.293169],
              [-124.16447, 41.292838],
              [-124.16612, 41.292611],
              [-124.167771, 41.292249],
              [-124.1696, 41.291955],
              [-124.171741, 41.291629],
              [-124.174104, 41.291539],
              [-124.176111, 41.291346],
              [-124.177983, 41.291221],
              [-124.180256, 41.29113],
              [-124.182841, 41.291242],
              [-124.184445, 41.291283],
              [-124.186628, 41.291293],
              [-124.188899, 41.291606],
              [-124.190949, 41.291715],
              [-124.192328, 41.29199],
              [-124.194064, 41.292435],
              [-124.195265, 41.292843],
              [-124.196378, 41.293083],
              [-124.197936, 41.29346],
              [-124.199538, 41.293902],
              [-124.200916, 41.294379],
              [-124.202385, 41.294721],
              [-124.203452, 41.295129],
              [-124.20492, 41.295572],
              [-124.205853, 41.29608],
              [-124.207721, 41.296827],
              [-124.208788, 41.297268],
              [-124.210077, 41.297811],
              [-124.211366, 41.298422],
              [-124.212256, 41.298828],
              [-124.213722, 41.299607],
              [-124.215055, 41.300319],
              [-124.216076, 41.300995],
              [-124.217232, 41.301571],
              [-124.218565, 41.302282],
              [-124.220032, 41.302994],
              [-124.221852, 41.304278],
              [-124.223673, 41.305562],
              [-124.225093, 41.306677],
              [-124.226113, 41.307588],
              [-124.227977, 41.309309],
              [-124.229441, 41.310491],
              [-124.230329, 41.311234],
              [-124.231393, 41.312313],
              [-124.232545, 41.313595],
              [-124.233432, 41.314505],
              [-124.23454, 41.315753],
              [-124.235248, 41.31673],
              [-124.236177, 41.317977],
              [-124.23684, 41.319021],
              [-124.237502, 41.3203],
              [-124.23821, 41.321446],
              [-124.238827, 41.322658],
              [-124.239577, 41.32434],
              [-124.240192, 41.325956],
              [-124.240851, 41.327907],
              [-124.241334, 41.329286],
              [-124.241639, 41.330732],
              [-124.242164, 41.332616],
              [-124.242291, 41.333893],
              [-124.24259, 41.336447],
              [-124.242626, 41.337959],
              [-124.242665, 41.339169],
              [-124.242479, 41.340613],
              [-124.242293, 41.34199],
              [-124.24242, 41.3433],
              [-124.242101, 41.344509],
              [-124.241917, 41.345751],
              [-124.241777, 41.346792],
              [-124.241548, 41.348001],
              [-124.241094, 41.349544],
              [-124.24073, 41.350887],
              [-124.240363, 41.3528],
              [-124.239863, 41.354545],
              [-124.239004, 41.356793],
              [-124.238191, 41.358705],
              [-124.237154, 41.360716],
              [-124.235805, 41.362693],
              [-124.235174, 41.363833],
              [-124.234276, 41.364972],
              [-124.233513, 41.365842],
              [-124.232794, 41.366746],
              [-124.231941, 41.367717],
              [-124.231087, 41.368856],
              [-124.23028, 41.369625],
              [-124.229159, 41.370595],
              [-124.228218, 41.371465],
              [-124.226828, 41.372601],
              [-124.225618, 41.373705],
              [-124.224371, 41.374843],
              [-124.21922, 41.378298],
              [-124.215819, 41.380263],
              [-124.212014, 41.382394],
              [-124.209689, 41.383423],
              [-124.206515, 41.384718],
              [-124.202851, 41.385876],
              [-124.197803, 41.387195],
              [-124.19298, 41.388347],
              [-124.188604, 41.389267],
              [-124.185301, 41.389554],
              [-124.181239, 41.390139],
              [-124.176556, 41.390352],
              [-124.170896, 41.390057],
              [-124.165906, 41.389597],
              [-124.161407, 41.389039],
              [-124.157623, 41.388316],
              [-124.154195, 41.387628],
              [-124.150858, 41.386706],
              [-124.148411, 41.385989],
              [-124.1461, 41.385005],
              [-124.144056, 41.384123],
              [-124.142414, 41.383276],
              [-124.139973, 41.381821],
              [-124.13958, 41.386415],
              [-124.138639, 41.392416],
              [-124.136905, 41.402874],
              [-124.136229, 41.409212],
              [-124.135697, 41.414275],
              [-124.135301, 41.419171],
              [-124.134776, 41.423362],
              [-124.134206, 41.42762],
              [-124.13383, 41.430067],
              [-124.133231, 41.432311],
              [-124.133804, 41.433187],
              [-124.134372, 41.434699],
              [-124.135024, 41.436816],
              [-124.135632, 41.438832],
              [-124.136322, 41.441821],
              [-124.136398, 41.443466],
              [-124.136906, 41.446823],
              [-124.136782, 41.456551],
              [-124.136497, 41.464265],
              [-124.136561, 41.464452]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "REGION": "4",
        "DIVISION": "8",
        "STATEFP": "32",
        "STATENS": "01779793",
        "GEOID": "32",
        "STUSPS": "NV",
        "NAME": "Nevada",
        "LSAD": "00",
        "MTFCC": "G4000",
        "FUNCSTAT": "A",
        "ALAND": 284329327067,
        "AWATER": 2047350803,
        "INTPTLAT": "+39.3310928",
        "INTPTLON": "-116.6151469"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [-119.324184, 41.993923],
              [-119.323622, 41.993917],
              [-119.322117, 41.993917],
              [-119.321043, 41.993926],
              [-119.316972, 41.993905],
              [-119.314316, 41.993911],
              [-119.308155, 41.993921],
              [-119.306243, 41.993919],
              [-119.30478, 41.993927],
              [-119.300815, 41.993934],
              [-119.300135, 41.993935],
              [-119.29953, 41.993936],
              [-119.297315, 41.993937],
              [-119.295133, 41.993934],
              [-119.290608, 41.993914],
              [-119.289847, 41.993911],
              [-119.286579, 41.9939],
              [-119.280682, 41.99388],
              [-119.275874, 41.993864],
              [-119.275011, 41.993862],
              [-119.272506, 41.993855],
              [-119.270841, 41.993851],
              [-119.269308, 41.993846],
              [-119.265743, 41.993836],
              [-119.264231, 41.993831],
              [-119.261607, 41.993824],
              [-119.257872, 41.993817],
              [-119.25482, 41.99383],
              [-119.252301, 41.993841],
              [-119.251669, 41.993846],
              [-119.25145, 41.993845],
              [-119.251033, 41.993843],
              [-119.250852, 41.993848],
              [-119.246979, 41.993865],
              [-119.241802, 41.993887],
              [-119.238096, 41.993898],
              [-119.233654, 41.993861],
              [-119.231767, 41.993826],
              [-119.230272, 41.993805],
              [-119.22451, 41.993699],
              [-119.222922, 41.993672],
              [-119.218597, 41.99363],
              [-119.208993, 41.993551],
              [-119.205014, 41.99351],
              [-119.204632, 41.993511],
              [-119.203995, 41.993498],
              [-119.201398, 41.993478],
              [-119.192748, 41.993445],
              [-119.190239, 41.99344],
              [-119.184822, 41.993438],
              [-119.181176, 41.993425],
              [-119.178671, 41.993414],
              [-119.176935, 41.993406],
              [-119.172885, 41.993392],
              [-119.170874, 41.993387],
              [-119.166512, 41.993376],
              [-119.164153, 41.99337],
              [-119.15987, 41.993354],
              [-119.159565, 41.993354],
              [-119.157627, 41.993354],
              [-119.154684, 41.993354],
              [-119.14981, 41.993369],
              [-119.144936, 41.993384],
              [-119.140125, 41.993396],
              [-119.140099, 41.993396],
              [-119.140073, 41.993396],
              [-119.138234, 41.993398],
              [-119.135208, 41.993403],
              [-119.130349, 41.993434],
              [-119.125704, 41.993464],
              [-119.120595, 41.993475],
              [-119.115069, 41.993492],
              [-119.108591, 41.993545],
              [-119.103926, 41.993562],
              [-119.088957, 41.993676],
              [-119.082125, 41.993715],
              [-119.076789, 41.993714],
              [-119.061797, 41.993626],
              [-119.054762, 41.993629],
              [-119.051872, 41.993608],
              [-119.045009, 41.993582],
              [-119.042597, 41.99358],
              [-119.041568, 41.993584],
              [-119.036462, 41.993585],
              [-119.035573, 41.993586],
              [-119.031362, 41.993583],
              [-119.025717, 41.993575],
              [-119.01683, 41.99357],
              [-119.015885, 41.993569],
              [-119.013131, 41.99357],
              [-119.010293, 41.993564],
              [-119.008114, 41.99356],
              [-119.004074, 41.99356],
              [-119.00106, 41.993551],
              [-118.995945, 41.99351],
              [-118.99475, 41.993494],
              [-118.991082, 41.993461],
              [-118.986226, 41.993416],
              [-118.982525, 41.993385],
              [-118.981375, 41.993383],
              [-118.976519, 41.993361],
              [-118.975273, 41.993359],
              [-118.972561, 41.993352],
              [-118.971672, 41.99335],
              [-118.970123, 41.993341],
              [-118.969548, 41.993338],
              [-118.963185, 41.993302],
              [-118.955457, 41.993263],
              [-118.950553, 41.99324],
              [-118.948403, 41.993228],
              [-118.945483, 41.993211],
              [-118.940589, 41.993184],
              [-118.934234, 41.993158],
              [-118.931466, 41.993119],
              [-118.929034, 41.993084],
              [-118.906596, 41.992769],
              [-118.903244, 41.992725],
              [-118.892427, 41.992751],
              [-118.88785, 41.992762],
              [-118.883832, 41.992772],
              [-118.876266, 41.992759],
              [-118.873659, 41.992754],
              [-118.870936, 41.992748],
              [-118.870284, 41.992749],
              [-118.868311, 41.992742],
              [-118.862286, 41.992728],
              [-118.857373, 41.992716],
              [-118.849546, 41.992698],
              [-118.845498, 41.992689],
              [-118.842813, 41.992683],
              [-118.841046, 41.992679],
              [-118.839734, 41.992677],
              [-118.837955, 41.992673],
              [-118.837468, 41.992672],
              [-118.83531, 41.992667],
              [-118.82963, 41.992656],
              [-118.825588, 41.992647],
              [-118.814045, 41.992619],
              [-118.809336, 41.992613],
              [-118.808347, 41.992613],
              [-118.806662, 41.992612],
              [-118.802807, 41.992607],
              [-118.799789, 41.992605],
              [-118.798566, 41.992614],
              [-118.796413, 41.992603],
              [-118.793338, 41.992605],
              [-118.792745, 41.992603],
              [-118.788774, 41.992604],
              [-118.785256, 41.992601],
              [-118.783891, 41.992595],
              [-118.783039, 41.992591],
              [-118.782658, 41.992589],
              [-118.782418, 41.992588],
              [-118.777629, 41.992565],
              [-118.776939, 41.992562],
              [-118.776512, 41.99256],
              [-118.77598, 41.992557],
              [-118.775391, 41.992554],
              [-118.772633, 41.992541],
              [-118.771605, 41.992536],
              [-118.766675, 41.992513],
              [-118.765792, 41.992509],
              [-118.761902, 41.992554],
              [-118.75895, 41.992589],
              [-118.757159, 41.99261],
              [-118.75507, 41.992585],
              [-118.754101, 41.992579],
              [-118.751829, 41.992556],
              [-118.75103, 41.992548],
              [-118.75085, 41.992546],
              [-118.747627, 41.992516],
              [-118.745862, 41.992499],
              [-118.742861, 41.992435],
              [-118.738096, 41.992332],
              [-118.736037, 41.992309],
              [-118.733093, 41.992287],
              [-118.728091, 41.992245],
              [-118.726844, 41.992235],
              [-118.726107, 41.992229],
              [-118.724909, 41.992221],
              [-118.722589, 41.992205],
              [-118.714985, 41.99214],
              [-118.714342, 41.992132],
              [-118.713299, 41.992127],
              [-118.70851, 41.992089],
              [-118.707888, 41.992082],
              [-118.703555, 41.992096],
              [-118.7014, 41.992104],
              [-118.697637, 41.992117],
              [-118.696124, 41.992121],
              [-118.695723, 41.992123],
              [-118.695246, 41.992125],
              [-118.69407, 41.992128],
              [-118.6927, 41.992129],
              [-118.692074, 41.992135],
              [-118.690105, 41.992142],
              [-118.684565, 41.99215],
              [-118.681124, 41.992152],
              [-118.679576, 41.992152],
              [-118.679081, 41.992153],
              [-118.676499, 41.992154],
              [-118.674737, 41.992199],
              [-118.674633, 41.992201],
              [-118.671648, 41.992277],
              [-118.670185, 41.992314],
              [-118.6699, 41.992321],
              [-118.665739, 41.992427],
              [-118.665064, 41.992444],
              [-118.661294, 41.99254],
              [-118.660229, 41.992567],
              [-118.656569, 41.992659],
              [-118.651844, 41.992779],
              [-118.650524, 41.992812],
              [-118.647119, 41.992898],
              [-118.644699, 41.992962],
              [-118.643773, 41.992983],
              [-118.641863, 41.993031],
              [-118.641567, 41.993039],
              [-118.640052, 41.993077],
              [-118.639151, 41.993108],
              [-118.638755, 41.993118],
              [-118.634858, 41.99324],
              [-118.633533, 41.993282],
              [-118.632015, 41.99333],
              [-118.631245, 41.993349],
              [-118.630471, 41.993366],
              [-118.628816, 41.993396],
              [-118.626001, 41.993447],
              [-118.62467, 41.993471],
              [-118.624508, 41.993474],
              [-118.623336, 41.993495],
              [-118.621137, 41.993535],
              [-118.618566, 41.993582],
              [-118.614992, 41.993647],
              [-118.612272, 41.993696],
              [-118.6093, 41.993751],
              [-118.608464, 41.993766],
              [-118.607471, 41.993784],
              [-118.606326, 41.993805],
              [-118.601816, 41.993887],
              [-118.601663, 41.99389],
              [-118.601318, 41.993896],
              [-118.600798, 41.993905],
              [-118.600464, 41.993911],
              [-118.599747, 41.993924],
              [-118.583827, 41.994213],
              [-118.569725, 41.994457],
              [-118.559852, 41.994546],
              [-118.556403, 41.994582],
              [-118.551038, 41.994636],
              [-118.542702, 41.994731],
              [-118.54201, 41.99474],
              [-118.541414, 41.994749],
              [-118.540753, 41.994754],
              [-118.538863, 41.994777],
              [-118.538507, 41.99478],
              [-118.53799, 41.994786],
              [-118.53713, 41.994797],
              [-118.534309, 41.99483],
              [-118.533072, 41.994844],
              [-118.532838, 41.994846],
              [-118.527877, 41.994903],
              [-118.520783, 41.994987],
              [-118.514277, 41.995062],
              [-118.506899, 41.995141],
              [-118.504408, 41.995169],
              [-118.501008, 41.995207],
              [-118.497286, 41.995247],
              [-118.494249, 41.995279],
              [-118.491887, 41.995303],
              [-118.490601, 41.995316],
              [-118.489357, 41.995328],
              [-118.488725, 41.995335],
              [-118.488391, 41.995339],
              [-118.488232, 41.99534],
              [-118.488085, 41.995342],
              [-118.486816, 41.995355],
              [-118.483366, 41.995392],
              [-118.481791, 41.995408],
              [-118.480766, 41.995419],
              [-118.477372, 41.995454],
              [-118.474072, 41.995489],
              [-118.470074, 41.995528],
              [-118.467845, 41.99555],
              [-118.463505, 41.995594],
              [-118.46061, 41.995622],
              [-118.458699, 41.995642],
              [-118.45714, 41.995656],
              [-118.455256, 41.995675],
              [-118.452399, 41.995701],
              [-118.451596, 41.995708],
              [-118.450372, 41.995719],
              [-118.449221, 41.995729],
              [-118.447834, 41.995741],
              [-118.446278, 41.995755],
              [-118.44457, 41.99577],
              [-118.443068, 41.995784],
              [-118.442582, 41.995788],
              [-118.442376, 41.99579],
              [-118.441883, 41.995794],
              [-118.441506, 41.995798],
              [-118.441257, 41.9958],
              [-118.440365, 41.995808],
              [-118.435279, 41.995853],
              [-118.434816, 41.995857],
              [-118.433372, 41.995857],
              [-118.433054, 41.995856],
              [-118.431851, 41.995861],
              [-118.429638, 41.995871],
              [-118.426818, 41.995882],
              [-118.42651, 41.995882],
              [-118.426195, 41.995883],
              [-118.424547, 41.995888],
              [-118.423732, 41.995887],
              [-118.421181, 41.995895],
              [-118.418652, 41.995901],
              [-118.418168, 41.995902],
              [-118.416488, 41.995906],
              [-118.416254, 41.995907],
              [-118.415296, 41.99591],
              [-118.414024, 41.995914],
              [-118.413113, 41.995913],
              [-118.412686, 41.995912],
              [-118.412495, 41.995912],
              [-118.412244, 41.995912],
              [-118.399229, 41.995887],
              [-118.397503, 41.995887],
              [-118.396869, 41.995888],
              [-118.394855, 41.995925],
              [-118.394481, 41.995931],
              [-118.392489, 41.995967],
              [-118.392354, 41.99597],
              [-118.391923, 41.995977],
              [-118.391453, 41.995986],
              [-118.391181, 41.995991],
              [-118.390984, 41.995994],
              [-118.390803, 41.995997],
              [-118.385292, 41.996097],
              [-118.382918, 41.99614],
              [-118.382232, 41.996155],
              [-118.381826, 41.996162],
              [-118.381635, 41.996163],
              [-118.380954, 41.996175],
              [-118.377414, 41.996239],
              [-118.376669, 41.996244],
              [-118.376079, 41.996247],
              [-118.374104, 41.996259],
              [-118.370582, 41.996281],
              [-118.360496, 41.996343],
              [-118.356833, 41.996368],
              [-118.355309, 41.996381],
              [-118.350496, 41.996421],
              [-118.348218, 41.996444],
              [-118.347756, 41.996444],
              [-118.34711, 41.99645],
              [-118.343982, 41.996476],
              [-118.342354, 41.996489],
              [-118.339143, 41.996516],
              [-118.337599, 41.996519],
              [-118.334265, 41.996526],
              [-118.332844, 41.996528],
              [-118.329327, 41.996536],
              [-118.327906, 41.996539],
              [-118.327192, 41.99654],
              [-118.325557, 41.996543],
              [-118.324553, 41.996545],
              [-118.319946, 41.996554],
              [-118.30371, 41.996722],
              [-118.295453, 41.996786],
              [-118.287995, 41.996826],
              [-118.285709, 41.996839],
              [-118.282429, 41.996857],
              [-118.277618, 41.996886],
              [-118.265963, 41.996958],
              [-118.264728, 41.996965],
              [-118.259775, 41.99699],
              [-118.257383, 41.99701],
              [-118.252982, 41.997037],
              [-118.249222, 41.99706],
              [-118.246077, 41.99708],
              [-118.245121, 41.997085],
              [-118.242249, 41.997103],
              [-118.241122, 41.997111],
              [-118.240275, 41.997118],
              [-118.236173, 41.99715],
              [-118.235428, 41.997155],
              [-118.231316, 41.997187],
              [-118.230584, 41.997192],
              [-118.226461, 41.997224],
              [-118.225739, 41.997229],
              [-118.223189, 41.997249],
              [-118.221601, 41.997254],
              [-118.22089, 41.997256],
              [-118.216743, 41.99727],
              [-118.216455, 41.997271],
              [-118.211983, 41.997287],
              [-118.211901, 41.997288],
              [-118.207146, 41.997305],
              [-118.20706, 41.997305],
              [-118.203204, 41.997319],
              [-118.202306, 41.997323],
              [-118.202214, 41.997323],
              [-118.197371, 41.997344],
              [-118.195869, 41.997001],
              [-118.191913, 41.997022],
              [-118.190594, 41.997029],
              [-118.186671, 41.997049],
              [-118.186638, 41.99705],
              [-118.177543, 41.9971],
              [-118.174904, 41.997114],
              [-118.170982, 41.997135],
              [-118.168432, 41.997146],
              [-118.160782, 41.997181],
              [-118.158233, 41.997193],
              [-118.157782, 41.997195],
              [-118.150808, 41.997231],
              [-118.143112, 41.99727],
              [-118.135211, 41.99731],
              [-118.129544, 41.997339],
              [-118.127267, 41.99735],
              [-118.125847, 41.997357],
              [-118.12404, 41.997367],
              [-118.124006, 41.997367],
              [-118.123819, 41.997368],
              [-118.106748, 41.997457],
              [-118.105761, 41.997462],
              [-118.10557, 41.997462],
              [-118.104998, 41.997466],
              [-118.104808, 41.997467],
              [-118.103682, 41.997472],
              [-118.100307, 41.997491],
              [-118.099182, 41.997497],
              [-118.098808, 41.997498],
              [-118.097686, 41.997505],
              [-118.097313, 41.997507],
              [-118.097045, 41.997508],
              [-118.096244, 41.997513],
              [-118.095977, 41.997515],
              [-118.094112, 41.997524],
              [-118.088519, 41.997554],
              [-118.086655, 41.997564],
              [-118.083341, 41.997581],
              [-118.0734, 41.997633],
              [-118.071396, 41.997644],
              [-118.071113, 41.997646],
              [-118.070087, 41.997651],
              [-118.069983, 41.997651],
              [-118.069673, 41.997653],
              [-118.06957, 41.997654],
              [-118.069425, 41.997654],
              [-118.068994, 41.997657],
              [-118.06885, 41.997658],
              [-118.068666, 41.997659],
              [-118.068118, 41.997662],
              [-118.067935, 41.997663],
              [-118.067262, 41.997663],
              [-118.065243, 41.997667],
              [-118.064571, 41.997669],
              [-118.064322, 41.99767],
              [-118.064106, 41.997672],
              [-118.063862, 41.997673],
              [-118.063575, 41.997674],
              [-118.063326, 41.997676],
              [-118.062838, 41.997678],
              [-118.061377, 41.997685],
              [-118.060891, 41.997688],
              [-118.058509, 41.9977],
              [-118.054612, 41.99772],
              [-118.054112, 41.997722],
              [-118.048154, 41.997752],
              [-118.046412, 41.997761],
              [-118.038814, 41.997799],
              [-118.033774, 41.997824],
              [-118.026996, 41.997859],
              [-118.022797, 41.99788],
              [-118.017772, 41.997906],
              [-118.01564, 41.997917],
              [-118.010202, 41.997945],
              [-118.006004, 41.997967],
              [-118.005207, 41.99797],
              [-118.002818, 41.997983],
              [-118.00232, 41.997985],
              [-118.002022, 41.997987],
              [-118.00181, 41.997988],
              [-118.001192, 41.998041],
              [-118.000985, 41.998059],
              [-117.999499, 41.998056],
              [-117.995041, 41.998048],
              [-117.993556, 41.998046],
              [-117.992783, 41.998044],
              [-117.990467, 41.99804],
              [-117.989695, 41.998039],
              [-117.988302, 41.998036],
              [-117.984123, 41.998029],
              [-117.98273, 41.998027],
              [-117.980689, 41.998023],
              [-117.974566, 41.998011],
              [-117.972526, 41.998008],
              [-117.968201, 41.998],
              [-117.960382, 41.997988],
              [-117.958038, 41.998001],
              [-117.955229, 41.998015],
              [-117.950906, 41.998039],
              [-117.948425, 41.998052],
              [-117.945723, 41.998068],
              [-117.942741, 41.998084],
              [-117.940982, 41.998093],
              [-117.938502, 41.998108],
              [-117.937016, 41.998115],
              [-117.932558, 41.998138],
              [-117.931073, 41.998146],
              [-117.929194, 41.998156],
              [-117.923558, 41.998188],
              [-117.92168, 41.9982],
              [-117.918799, 41.998217],
              [-117.918061, 41.998221],
              [-117.914375, 41.998242],
              [-117.907207, 41.998193],
              [-117.903589, 41.998169],
              [-117.901915, 41.998158],
              [-117.900898, 41.998155],
              [-117.892825, 41.998137],
              [-117.890613, 41.998133],
              [-117.890135, 41.998141],
              [-117.888913, 41.99816],
              [-117.887299, 41.998186],
              [-117.885249, 41.99818],
              [-117.884028, 41.998178],
              [-117.881786, 41.998172],
              [-117.87598, 41.998159],
              [-117.875063, 41.998223],
              [-117.873467, 41.998335],
              [-117.872826, 41.998333],
              [-117.843576, 41.998246],
              [-117.838883, 41.998234],
              [-117.835757, 41.998223],
              [-117.835658, 41.998224],
              [-117.82928, 41.998277],
              [-117.812415, 41.998417],
              [-117.810253, 41.998428],
              [-117.804475, 41.998414],
              [-117.803929, 41.998413],
              [-117.803877, 41.998413],
              [-117.7944, 41.998419],
              [-117.793555, 41.99842],
              [-117.792182, 41.998421],
              [-117.790139, 41.998424],
              [-117.790035, 41.998421],
              [-117.789235, 41.998426],
              [-117.788649, 41.998426],
              [-117.780419, 41.998352],
              [-117.780363, 41.998331],
              [-117.780281, 41.998331],
              [-117.779965, 41.998331],
              [-117.77863, 41.998331],
              [-117.773679, 41.998333],
              [-117.772029, 41.998334],
              [-117.77024, 41.998323],
              [-117.764874, 41.998292],
              [-117.763086, 41.998282],
              [-117.761839, 41.998283],
              [-117.758098, 41.998287],
              [-117.756852, 41.998289],
              [-117.755676, 41.998287],
              [-117.75215, 41.998281],
              [-117.750975, 41.99828],
              [-117.748326, 41.998267],
              [-117.740383, 41.998231],
              [-117.737735, 41.998219],
              [-117.735924, 41.998211],
              [-117.7295, 41.998184],
              [-117.725418, 41.99818],
              [-117.722496, 41.998177],
              [-117.721819, 41.998177],
              [-117.720229, 41.998189],
              [-117.719842, 41.998189],
              [-117.719432, 41.998189],
              [-117.718943, 41.998189],
              [-117.718549, 41.998189],
              [-117.717823, 41.998186],
              [-117.717087, 41.998184],
              [-117.708566, 41.998163],
              [-117.706713, 41.998161],
              [-117.705781, 41.998161],
              [-117.686195, 41.998147],
              [-117.658624, 41.998128],
              [-117.65823, 41.998128],
              [-117.650799, 41.998122],
              [-117.650514, 41.998122],
              [-117.649501, 41.99812],
              [-117.64773, 41.998117],
              [-117.645667, 41.998158],
              [-117.641114, 41.998144],
              [-117.640726, 41.998143],
              [-117.639929, 41.998141],
              [-117.639851, 41.99814],
              [-117.635023, 41.998128],
              [-117.634711, 41.998127],
              [-117.63462, 41.998125],
              [-117.632928, 41.998095],
              [-117.632266, 41.998082],
              [-117.632151, 41.998083],
              [-117.628952, 41.99809],
              [-117.626018, 41.998096],
              [-117.624409, 41.998357],
              [-117.623734, 41.998466],
              [-117.620092, 41.998488],
              [-117.617188, 41.998505],
              [-117.616391, 41.998509],
              [-117.614003, 41.998523],
              [-117.613208, 41.998529],
              [-117.612798, 41.998532],
              [-117.610714, 41.998542],
              [-117.603248, 41.998588],
              [-117.57658, 41.998746],
              [-117.574055, 41.998761],
              [-117.566479, 41.998806],
              [-117.563955, 41.998822],
              [-117.55913, 41.99885],
              [-117.556422, 41.998867],
              [-117.552416, 41.99889],
              [-117.544655, 41.998936],
              [-117.539831, 41.998965],
              [-117.537984, 41.998976],
              [-117.532443, 41.999009],
              [-117.530597, 41.999021],
              [-117.530197, 41.999024],
              [-117.522306, 41.99911],
              [-117.521675, 41.999117],
              [-117.521402, 41.99912],
              [-117.521065, 41.999124],
              [-117.520396, 41.999131],
              [-117.518391, 41.999152],
              [-117.516896, 41.999169],
              [-117.515958, 41.999179],
              [-117.515112, 41.999189],
              [-117.513476, 41.999207],
              [-117.513308, 41.999209],
              [-117.51312, 41.999211],
              [-117.511962, 41.999224],
              [-117.506682, 41.999268],
              [-117.506428, 41.999271],
              [-117.504408, 41.999342],
              [-117.500967, 41.999464],
              [-117.495677, 41.999354],
              [-117.495655, 41.999354],
              [-117.4935, 41.999357],
              [-117.492286, 41.999358],
              [-117.490332, 41.999358],
              [-117.49007, 41.999358],
              [-117.4814, 41.9994],
              [-117.481112, 41.999399],
              [-117.479949, 41.99939],
              [-117.474132, 41.999413],
              [-117.473266, 41.999393],
              [-117.473037, 41.999394],
              [-117.47199, 41.999425],
              [-117.471413, 41.999418],
              [-117.470528, 41.999407],
              [-117.467281, 41.999367],
              [-117.464832, 41.999396],
              [-117.46235, 41.999426],
              [-117.455887, 41.999503],
              [-117.443062, 41.999659],
              [-117.438269, 41.999577],
              [-117.421264, 41.999436],
              [-117.41824, 41.99941],
              [-117.414986, 41.999383],
              [-117.407772, 41.999324],
              [-117.407112, 41.999318],
              [-117.405134, 41.999302],
              [-117.404475, 41.999297],
              [-117.403613, 41.99929],
              [-117.395124, 41.999325],
              [-117.393572, 41.999331],
              [-117.37694, 41.999402],
              [-117.376183, 41.999405],
              [-117.375967, 41.999407],
              [-117.36841, 41.9995],
              [-117.366074, 41.999504],
              [-117.365753, 41.999504],
              [-117.35825, 41.999516],
              [-117.349198, 41.999542],
              [-117.345604, 41.999553],
              [-117.334822, 41.999584],
              [-117.331379, 41.999595],
              [-117.331228, 41.999595],
              [-117.330551, 41.999596],
              [-117.328524, 41.999601],
              [-117.327848, 41.999603],
              [-117.323897, 41.999612],
              [-117.312047, 41.99964],
              [-117.308097, 41.99965],
              [-117.295456, 41.99968],
              [-117.292696, 41.999687],
              [-117.284777, 41.999706],
              [-117.277189, 41.999725],
              [-117.274415, 41.999731],
              [-117.266094, 41.999751],
              [-117.263321, 41.999758],
              [-117.260845, 41.999763],
              [-117.25342, 41.999781],
              [-117.251232, 41.999787],
              [-117.250964, 41.999887],
              [-117.246229, 41.999887],
              [-117.232027, 41.999888],
              [-117.227293, 41.999889],
              [-117.217551, 41.999887],
              [-117.2172, 42.000124],
              [-117.208612, 42.000213],
              [-117.207457, 42.000223],
              [-117.198024, 42.000383],
              [-117.197867, 42.000381],
              [-117.197703, 42.00038],
              [-117.197615, 42.000379],
              [-117.182044, 42.000378],
              [-117.178631, 42.000351],
              [-117.161823, 42.000383],
              [-117.161397, 42.000385],
              [-117.133667, 42.000439],
              [-117.125961, 42.000371],
              [-117.124757, 42.000364],
              [-117.113663, 42.0003],
              [-117.096808, 42.000201],
              [-117.0966, 42.000199],
              [-117.09657, 42.000199],
              [-117.079226, 42.000098],
              [-117.068398, 42.000035],
              [-117.065553, 42.00003],
              [-117.057019, 42.000015],
              [-117.055181, 42.000012],
              [-117.054469, 42.000007],
              [-117.052419, 42.000053],
              [-117.049639, 41.999971],
              [-117.048412, 41.999976],
              [-117.046559, 41.999984],
              [-117.042623, 42.000002],
              [-117.026639, 41.999893],
              [-117.026197, 41.99989],
              [-117.0262, 42.000195],
              [-117.025645, 42.000166],
              [-117.024546, 42.000076],
              [-117.0227, 41.999904],
              [-117.022544, 41.99989],
              [-117.021855, 41.999826],
              [-117.019095, 41.999598],
              [-117.018204, 41.999715],
              [-117.015534, 41.999317],
              [-117.014908, 41.999269],
              [-117.013952, 41.999193],
              [-117.012825, 41.999075],
              [-117.011811, 41.998968],
              [-117.009646, 41.998821],
              [-117.008908, 41.998756],
              [-117.008065, 41.998742],
              [-117.006827, 41.998739],
              [-117.000958, 41.998761],
              [-116.998623, 41.998769],
              [-116.998006, 41.99877],
              [-116.997639, 41.99877],
              [-116.989423, 41.998779],
              [-116.988742, 41.998782],
              [-116.978995, 41.998825],
              [-116.977258, 41.998825],
              [-116.972643, 41.998825],
              [-116.969334, 41.998826],
              [-116.960301, 41.998831],
              [-116.959213, 41.998831],
              [-116.949105, 41.998759],
              [-116.944675, 41.998715],
              [-116.942168, 41.998752],
              [-116.940305, 41.99878],
              [-116.93561, 41.998849],
              [-116.932562, 41.998896],
              [-116.932188, 41.998895],
              [-116.931345, 41.998893],
              [-116.930455, 41.998891],
              [-116.926644, 41.998877],
              [-116.923871, 41.998867],
              [-116.923848, 41.998867],
              [-116.919437, 41.998851],
              [-116.914282, 41.998854],
              [-116.901095, 41.998851],
              [-116.899199, 41.99884],
              [-116.891632, 41.998796],
              [-116.88783, 41.998774],
              [-116.887683, 41.998776],
              [-116.88139, 41.998822],
              [-116.877246, 41.998778],
              [-116.875964, 41.998765],
              [-116.873293, 41.998755],
              [-116.866789, 41.99873],
              [-116.862274, 41.998712],
              [-116.862234, 41.998712],
              [-116.862144, 41.998712],
              [-116.861028, 41.998707],
              [-116.850985, 41.998662],
              [-116.846421, 41.998638],
              [-116.84184, 41.998613],
              [-116.838223, 41.998594],
              [-116.831317, 41.998568],
              [-116.827843, 41.998557],
              [-116.825105, 41.998547],
              [-116.823079, 41.998524],
              [-116.822206, 41.998522],
              [-116.820466, 41.99852],
              [-116.819749, 41.998518],
              [-116.819468, 41.998515],
              [-116.817614, 41.9985],
              [-116.815682, 41.99845],
              [-116.805773, 41.998434],
              [-116.805638, 41.998434],
              [-116.804127, 41.99843],
              [-116.803236, 41.998429],
              [-116.799811, 41.998423],
              [-116.79965, 41.998423],
              [-116.78877, 41.998375],
              [-116.783772, 41.998341],
              [-116.781382, 41.998325],
              [-116.777317, 41.998297],
              [-116.772448, 41.998292],
              [-116.769769, 41.99829],
              [-116.764555, 41.998231],
              [-116.763575, 41.99822],
              [-116.759685, 41.998201],
              [-116.750664, 41.998154],
              [-116.744425, 41.998116],
              [-116.742585, 41.998099],
              [-116.737456, 41.998055],
              [-116.735838, 41.998039],
              [-116.733721, 41.998058],
              [-116.731084, 41.998083],
              [-116.726271, 41.998007],
              [-116.725869, 41.998001],
              [-116.725602, 41.997997],
              [-116.72452, 41.99798],
              [-116.71345, 41.997984],
              [-116.709505, 41.997954],
              [-116.7094, 41.997953],
              [-116.7093, 41.997953],
              [-116.70527, 41.997923],
              [-116.697532, 41.997864],
              [-116.68588, 41.99784],
              [-116.666327, 41.997825],
              [-116.664486, 41.997817],
              [-116.647082, 41.997782],
              [-116.638496, 41.997687],
              [-116.63526, 41.997678],
              [-116.635231, 41.997676],
              [-116.631288, 41.997664],
              [-116.627568, 41.997614],
              [-116.626801, 41.997603],
              [-116.625692, 41.997588],
              [-116.622574, 41.99759],
              [-116.617863, 41.997591],
              [-116.617382, 41.997592],
              [-116.610635, 41.997621],
              [-116.608077, 41.997623],
              [-116.606257, 41.997555],
              [-116.606057, 41.997546],
              [-116.604651, 41.997575],
              [-116.602973, 41.99756],
              [-116.601502, 41.997588],
              [-116.600831, 41.997599],
              [-116.59418, 41.997549],
              [-116.588226, 41.997534],
              [-116.586948, 41.997531],
              [-116.585966, 41.997528],
              [-116.583677, 41.997522],
              [-116.580993, 41.997491],
              [-116.57845, 41.997448],
              [-116.576917, 41.997416],
              [-116.576393, 41.997413],
              [-116.575641, 41.997412],
              [-116.575363, 41.997411],
              [-116.568701, 41.997397],
              [-116.566733, 41.997393],
              [-116.56367, 41.997387],
              [-116.556382, 41.99737],
              [-116.549444, 41.997356],
              [-116.545955, 41.997349],
              [-116.543038, 41.99734],
              [-116.533535, 41.997315],
              [-116.530452, 41.997309],
              [-116.529979, 41.997307],
              [-116.528659, 41.997303],
              [-116.523951, 41.997292],
              [-116.51909, 41.997291],
              [-116.518175, 41.99729],
              [-116.517959, 41.99729],
              [-116.517475, 41.99729],
              [-116.516605, 41.99729],
              [-116.511248, 41.997288],
              [-116.510537, 41.997276],
              [-116.510517, 41.997276],
              [-116.509795, 41.997264],
              [-116.509048, 41.99728],
              [-116.507987, 41.997279],
              [-116.506224, 41.997291],
              [-116.503885, 41.99729],
              [-116.50335, 41.997298],
              [-116.501741, 41.997334],
              [-116.500906, 41.997353],
              [-116.498188, 41.997443],
              [-116.493737, 41.997365],
              [-116.490485, 41.997311],
              [-116.487018, 41.997255],
              [-116.484389, 41.99721],
              [-116.481682, 41.997187],
              [-116.481446, 41.997184],
              [-116.477231, 41.997148],
              [-116.473695, 41.997105],
              [-116.471093, 41.997095],
              [-116.469397, 41.997041],
              [-116.467134, 41.996991],
              [-116.464899, 41.996959],
              [-116.461986, 41.996927],
              [-116.458695, 41.996913],
              [-116.457658, 41.996908],
              [-116.452228, 41.996816],
              [-116.451524, 41.996813],
              [-116.449145, 41.996805],
              [-116.447902, 41.996784],
              [-116.445957, 41.996749],
              [-116.445553, 41.996742],
              [-116.442328, 41.996706],
              [-116.440954, 41.996683],
              [-116.440679, 41.996679],
              [-116.438793, 41.996645],
              [-116.433502, 41.996702],
              [-116.432186, 41.996696],
              [-116.430391, 41.996688],
              [-116.427657, 41.99671],
              [-116.424705, 41.996746],
              [-116.421424, 41.996731],
              [-116.414038, 41.996738],
              [-116.412809, 41.996711],
              [-116.409882, 41.996648],
              [-116.409555, 41.996641],
              [-116.408774, 41.996624],
              [-116.407672, 41.9966],
              [-116.404964, 41.996541],
              [-116.397503, 41.996447],
              [-116.397298, 41.996444],
              [-116.393121, 41.996461],
              [-116.38921, 41.996477],
              [-116.388322, 41.996481],
              [-116.385261, 41.996493],
              [-116.382638, 41.996485],
              [-116.378176, 41.996472],
              [-116.377923, 41.996324],
              [-116.37773, 41.996324],
              [-116.375941, 41.996321],
              [-116.37405, 41.996311],
              [-116.368477, 41.99628],
              [-116.366445, 41.996365],
              [-116.366429, 41.996373],
              [-116.36214, 41.996566],
              [-116.356823, 41.996744],
              [-116.352973, 41.996874],
              [-116.352077, 41.996904],
              [-116.346625, 41.997045],
              [-116.339276, 41.997237],
              [-116.335028, 41.997267],
              [-116.334654, 41.997268],
              [-116.317339, 41.997147],
              [-116.317179, 41.997143],
              [-116.314496, 41.997069],
              [-116.303994, 41.997216],
              [-116.302417, 41.997238],
              [-116.301112, 41.997244],
              [-116.28294, 41.997332],
              [-116.279251, 41.997351],
              [-116.274259, 41.99738],
              [-116.27247, 41.997391],
              [-116.261793, 41.99733],
              [-116.260303, 41.997322],
              [-116.25764, 41.997361],
              [-116.243962, 41.997651],
              [-116.231194, 41.997663],
              [-116.227373, 41.997667],
              [-116.227152, 41.997667],
              [-116.225462, 41.997669],
              [-116.225413, 41.997669],
              [-116.225245, 41.997669],
              [-116.223856, 41.99767],
              [-116.206229, 41.997684],
              [-116.205381, 41.997685],
              [-116.204533, 41.997686],
              [-116.203778, 41.997687],
              [-116.201028, 41.997683],
              [-116.191003, 41.997698],
              [-116.190932, 41.997698],
              [-116.190857, 41.997698],
              [-116.190456, 41.997698],
              [-116.190061, 41.997698],
              [-116.189584, 41.997699],
              [-116.189482, 41.997699],
              [-116.189435, 41.997699],
              [-116.18042, 41.997693],
              [-116.180262, 41.997693],
              [-116.178215, 41.99769],
              [-116.174866, 41.997688],
              [-116.174799, 41.997687],
              [-116.170362, 41.997633],
              [-116.163931, 41.997555],
              [-116.160833, 41.997508],
              [-116.160685, 41.997511],
              [-116.157931, 41.997547],
              [-116.152535, 41.997616],
              [-116.148149, 41.997673],
              [-116.146857, 41.997667],
              [-116.146759, 41.997667],
              [-116.146665, 41.997667],
              [-116.144359, 41.997665],
              [-116.140363, 41.997662],
              [-116.137904, 41.99766],
              [-116.133749, 41.997656],
              [-116.133287, 41.997656],
              [-116.133245, 41.997656],
              [-116.126421, 41.99765],
              [-116.125933, 41.99765],
              [-116.125274, 41.997648],
              [-116.117684, 41.99762],
              [-116.117621, 41.99762],
              [-116.11501, 41.99761],
              [-116.114905, 41.99761],
              [-116.113208, 41.997604],
              [-116.107056, 41.997582],
              [-116.092622, 41.997529],
              [-116.084526, 41.997499],
              [-116.078596, 41.9975],
              [-116.072666, 41.997501],
              [-116.066625, 41.9975],
              [-116.065991, 41.997462],
              [-116.062612, 41.997262],
              [-116.062577, 41.997262],
              [-116.062528, 41.997263],
              [-116.061298, 41.997182],
              [-116.060695, 41.997145],
              [-116.053878, 41.996748],
              [-116.048515, 41.996471],
              [-116.047136, 41.996344],
              [-116.045373, 41.996331],
              [-116.041936, 41.996339],
              [-116.037949, 41.996348],
              [-116.037746, 41.996349],
              [-116.037744, 41.996363],
              [-116.037724, 41.996349],
              [-116.031551, 41.996362],
              [-116.029502, 41.996367],
              [-116.022168, 41.99642],
              [-116.018139, 41.99642],
              [-116.018111, 41.996422],
              [-116.015164, 41.99642],
              [-116.01273, 41.996418],
              [-116.012649, 41.996416],
              [-116.008034, 41.996413],
              [-116.005045, 41.996444],
              [-116.002974, 41.996465],
              [-116.001823, 41.996477],
              [-116.000929, 41.996485],
              [-115.999132, 41.9965],
              [-115.998198, 41.996507],
              [-115.995767, 41.99653],
              [-115.986799, 41.996523],
              [-115.986335, 41.996513],
              [-115.981709, 41.996474],
              [-115.980203, 41.996461],
              [-115.974682, 41.996462],
              [-115.965867, 41.996385],
              [-115.959042, 41.996438],
              [-115.952587, 41.996423],
              [-115.950455, 41.996418],
              [-115.948664, 41.996478],
              [-115.948653, 41.99649],
              [-115.948631, 41.996479],
              [-115.948305, 41.99649],
              [-115.947691, 41.99651],
              [-115.94639, 41.996553],
              [-115.942748, 41.996571],
              [-115.938492, 41.996592],
              [-115.92968, 41.996674],
              [-115.926375, 41.996705],
              [-115.923187, 41.996771],
              [-115.920471, 41.996828],
              [-115.915976, 41.996928],
              [-115.915255, 41.996948],
              [-115.914058, 41.996957],
              [-115.912457, 41.996971],
              [-115.911652, 41.996973],
              [-115.909272, 41.997119],
              [-115.903708, 41.997166],
              [-115.900612, 41.997144],
              [-115.897006, 41.997118],
              [-115.890261, 41.997172],
              [-115.884211, 41.997111],
              [-115.875921, 41.997081],
              [-115.871723, 41.997042],
              [-115.871325, 41.997038],
              [-115.870572, 41.997031],
              [-115.869727, 41.997023],
              [-115.865956, 41.996988],
              [-115.8649, 41.996977],
              [-115.86059, 41.996932],
              [-115.853463, 41.996807],
              [-115.849404, 41.996789],
              [-115.849128, 41.996788],
              [-115.846137, 41.996776],
              [-115.843125, 41.996779],
              [-115.84278, 41.996779],
              [-115.838972, 41.99683],
              [-115.834935, 41.996865],
              [-115.834082, 41.996872],
              [-115.829846, 41.996909],
              [-115.826097, 41.996903],
              [-115.825624, 41.996902],
              [-115.822947, 41.996898],
              [-115.819056, 41.996896],
              [-115.817473, 41.996895],
              [-115.817044, 41.996895],
              [-115.812059, 41.99696],
              [-115.806586, 41.996949],
              [-115.805618, 41.996947],
              [-115.80345, 41.996923],
              [-115.799563, 41.996881],
              [-115.793658, 41.99694],
              [-115.787935, 41.996911],
              [-115.786664, 41.996904],
              [-115.784535, 41.996893],
              [-115.779704, 41.99696],
              [-115.769353, 41.996964],
              [-115.768613, 41.996961],
              [-115.767271, 41.996958],
              [-115.761994, 41.996942],
              [-115.757542, 41.996934],
              [-115.755639, 41.996932],
              [-115.755324, 41.996931],
              [-115.751015, 41.996852],
              [-115.749891, 41.996831],
              [-115.748045, 41.996797],
              [-115.74057, 41.996853],
              [-115.738995, 41.996865],
              [-115.733245, 41.996859],
              [-115.731127, 41.996867],
              [-115.728607, 41.996876],
              [-115.728244, 41.996877],
              [-115.72573, 41.996886],
              [-115.724543, 41.996891],
              [-115.721055, 41.996905],
              [-115.717986, 41.996917],
              [-115.713189, 41.996893],
              [-115.712477, 41.99689],
              [-115.711034, 41.996882],
              [-115.708821, 41.996871],
              [-115.701886, 41.996908],
              [-115.699477, 41.996882],
              [-115.695907, 41.996843],
              [-115.695417, 41.996841],
              [-115.69192, 41.996823],
              [-115.689873, 41.996813],
              [-115.689457, 41.996811],
              [-115.689393, 41.99681],
              [-115.687821, 41.996802],
              [-115.68099, 41.996822],
              [-115.67973, 41.996825],
              [-115.678588, 41.996841],
              [-115.672752, 41.996917],
              [-115.669416, 41.996917],
              [-115.664969, 41.996917],
              [-115.660792, 41.996918],
              [-115.654968, 41.996919],
              [-115.653264, 41.99692],
              [-115.652991, 41.99692],
              [-115.652728, 41.99692],
              [-115.650621, 41.99692],
              [-115.650289, 41.99692],
              [-115.649963, 41.996921],
              [-115.649599, 41.996921],
              [-115.649367, 41.996921],
              [-115.649188, 41.996921],
              [-115.649086, 41.996921],
              [-115.648524, 41.996921],
              [-115.640747, 41.99692],
              [-115.636719, 41.996922],
              [-115.635732, 41.99692],
              [-115.631617, 41.996922],
              [-115.630567, 41.996922],
              [-115.629842, 41.996922],
              [-115.627742, 41.996922],
              [-115.624883, 41.996922],
              [-115.62339, 41.996922],
              [-115.610998, 41.99692],
              [-115.59172, 41.996915],
              [-115.59114, 41.996915],
              [-115.59087, 41.996914],
              [-115.589666, 41.996906],
              [-115.587902, 41.996896],
              [-115.586629, 41.996884],
              [-115.586405, 41.996884],
              [-115.582441, 41.996856],
              [-115.582125, 41.996854],
              [-115.580904, 41.996845],
              [-115.578272, 41.996866],
              [-115.576911, 41.996877],
              [-115.573194, 41.996906],
              [-115.572206, 41.996905],
              [-115.572149, 41.996905],
              [-115.571205, 41.996904],
              [-115.569975, 41.9969],
              [-115.567304, 41.996893],
              [-115.564152, 41.996888],
              [-115.561191, 41.996898],
              [-115.559425, 41.996887],
              [-115.557053, 41.996896],
              [-115.553923, 41.996888],
              [-115.553662, 41.996887],
              [-115.551992, 41.996867],
              [-115.551052, 41.996856],
              [-115.548075, 41.996861],
              [-115.546037, 41.996875],
              [-115.543363, 41.996877],
              [-115.541404, 41.996887],
              [-115.539937, 41.996892],
              [-115.539176, 41.996895],
              [-115.536973, 41.996865],
              [-115.535403, 41.996882],
              [-115.533768, 41.996826],
              [-115.532811, 41.996854],
              [-115.531901, 41.996881],
              [-115.529978, 41.99687],
              [-115.529603, 41.996868],
              [-115.527044, 41.99687],
              [-115.526752, 41.99687],
              [-115.526193, 41.99687],
              [-115.524893, 41.996874],
              [-115.523814, 41.996877],
              [-115.523241, 41.996879],
              [-115.522134, 41.996882],
              [-115.521775, 41.996882],
              [-115.521744, 41.996881],
              [-115.521252, 41.996882],
              [-115.520105, 41.996879],
              [-115.518354, 41.996858],
              [-115.516887, 41.996839],
              [-115.516582, 41.996849],
              [-115.515327, 41.996877],
              [-115.51345, 41.996884],
              [-115.513261, 41.996885],
              [-115.512623, 41.996886],
              [-115.510251, 41.996889],
              [-115.507305, 41.996911],
              [-115.506247, 41.99692],
              [-115.504598, 41.996936],
              [-115.504198, 41.996931],
              [-115.500929, 41.996889],
              [-115.497644, 41.996831],
              [-115.493859, 41.996815],
              [-115.492117, 41.996808],
              [-115.487027, 41.996787],
              [-115.48654, 41.996785],
              [-115.486433, 41.996784],
              [-115.486326, 41.996784],
              [-115.485887, 41.996782],
              [-115.482705, 41.996768],
              [-115.47439, 41.996772],
              [-115.473781, 41.996768],
              [-115.472822, 41.996764],
              [-115.471633, 41.996757],
              [-115.466913, 41.996732],
              [-115.46124, 41.99671],
              [-115.458266, 41.996725],
              [-115.456693, 41.996733],
              [-115.455023, 41.99674],
              [-115.454789, 41.996739],
              [-115.446289, 41.996693],
              [-115.445939, 41.996689],
              [-115.445139, 41.996679],
              [-115.443533, 41.996665],
              [-115.442156, 41.996662],
              [-115.437211, 41.99663],
              [-115.432513, 41.996601],
              [-115.428963, 41.99663],
              [-115.426731, 41.996599],
              [-115.425412, 41.996602],
              [-115.424982, 41.996604],
              [-115.424865, 41.996604],
              [-115.422829, 41.996579],
              [-115.421533, 41.996561],
              [-115.42015, 41.996565],
              [-115.41902, 41.996564],
              [-115.413707, 41.99653],
              [-115.411906, 41.99652],
              [-115.411606, 41.996518],
              [-115.407377, 41.996495],
              [-115.403131, 41.996511],
              [-115.402546, 41.996514],
              [-115.401393, 41.996513],
              [-115.396785, 41.996513],
              [-115.396582, 41.996513],
              [-115.393715, 41.996492],
              [-115.392448, 41.996483],
              [-115.389368, 41.996476],
              [-115.387984, 41.996474],
              [-115.385906, 41.996467],
              [-115.383737, 41.996459],
              [-115.383118, 41.996457],
              [-115.380717, 41.996449],
              [-115.377162, 41.99643],
              [-115.37461, 41.996428],
              [-115.372946, 41.996423],
              [-115.371033, 41.996411],
              [-115.367341, 41.996388],
              [-115.365958, 41.996379],
              [-115.365369, 41.99638],
              [-115.364279, 41.99638],
              [-115.362116, 41.996381],
              [-115.357726, 41.996318],
              [-115.352449, 41.996317],
              [-115.351872, 41.996316],
              [-115.346858, 41.996326],
              [-115.344882, 41.996313],
              [-115.344376, 41.99631],
              [-115.343822, 41.996306],
              [-115.338332, 41.996366],
              [-115.338158, 41.996367],
              [-115.334782, 41.996365],
              [-115.330976, 41.996396],
              [-115.329989, 41.996384],
              [-115.329615, 41.996384],
              [-115.325926, 41.996368],
              [-115.325517, 41.996367],
              [-115.325086, 41.996367],
              [-115.323684, 41.996395],
              [-115.322779, 41.996413],
              [-115.320203, 41.996411],
              [-115.319047, 41.99641],
              [-115.318498, 41.996424],
              [-115.317546, 41.996444],
              [-115.31376, 41.996395],
              [-115.313485, 41.996385],
              [-115.312767, 41.996394],
              [-115.311655, 41.996409],
              [-115.310705, 41.996368],
              [-115.310062, 41.996367],
              [-115.308951, 41.996366],
              [-115.305789, 41.996364],
              [-115.305105, 41.996362],
              [-115.304933, 41.996365],
              [-115.304222, 41.996361],
              [-115.300387, 41.996355],
              [-115.29863, 41.996358],
              [-115.296745, 41.996331],
              [-115.296216, 41.996323],
              [-115.293143, 41.996332],
              [-115.291642, 41.996337],
              [-115.289743, 41.996335],
              [-115.289279, 41.996335],
              [-115.287982, 41.996334],
              [-115.287716, 41.996331],
              [-115.286556, 41.996315],
              [-115.28459, 41.996321],
              [-115.280151, 41.996334],
              [-115.279448, 41.996338],
              [-115.277303, 41.996346],
              [-115.275907, 41.996371],
              [-115.27514, 41.996333],
              [-115.274473, 41.996345],
              [-115.273639, 41.996339],
              [-115.271334, 41.99633],
              [-115.269248, 41.996322],
              [-115.267124, 41.996326],
              [-115.267032, 41.996326],
              [-115.265411, 41.996298],
              [-115.262369, 41.996335],
              [-115.261708, 41.996338],
              [-115.261005, 41.996336],
              [-115.259772, 41.996293],
              [-115.257576, 41.996315],
              [-115.255783, 41.996314],
              [-115.254908, 41.996271],
              [-115.254796, 41.99627],
              [-115.251027, 41.996297],
              [-115.25003, 41.996307],
              [-115.247952, 41.99633],
              [-115.246613, 41.996331],
              [-115.246195, 41.996331],
              [-115.241813, 41.996323],
              [-115.240268, 41.996322],
              [-115.238186, 41.996331],
              [-115.233209, 41.996347],
              [-115.233022, 41.996347],
              [-115.229947, 41.996358],
              [-115.226585, 41.996373],
              [-115.226033, 41.996376],
              [-115.224258, 41.996379],
              [-115.220763, 41.996376],
              [-115.219421, 41.996371],
              [-115.213994, 41.996351],
              [-115.20997, 41.996346],
              [-115.206268, 41.996335],
              [-115.205406, 41.996332],
              [-115.202067, 41.9963],
              [-115.201373, 41.996292],
              [-115.198363, 41.996292],
              [-115.197713, 41.996291],
              [-115.196829, 41.996291],
              [-115.191944, 41.996281],
              [-115.190948, 41.996279],
              [-115.189956, 41.996278],
              [-115.18584, 41.996265],
              [-115.181883, 41.996258],
              [-115.177589, 41.996248],
              [-115.173273, 41.996235],
              [-115.172725, 41.996239],
              [-115.166842, 41.996268],
              [-115.166719, 41.996267],
              [-115.165309, 41.996253],
              [-115.161893, 41.996216],
              [-115.160351, 41.996224],
              [-115.156504, 41.99624],
              [-115.155483, 41.99624],
              [-115.154169, 41.996239],
              [-115.14978, 41.996236],
              [-115.149424, 41.996237],
              [-115.147743, 41.996246],
              [-115.147305, 41.996247],
              [-115.143376, 41.996265],
              [-115.142717, 41.996263],
              [-115.13856, 41.996247],
              [-115.136038, 41.996235],
              [-115.134733, 41.996231],
              [-115.130808, 41.996206],
              [-115.127805, 41.996187],
              [-115.126706, 41.99618],
              [-115.122565, 41.996154],
              [-115.121667, 41.996148],
              [-115.118774, 41.99613],
              [-115.117677, 41.996129],
              [-115.114199, 41.996128],
              [-115.110322, 41.996129],
              [-115.109808, 41.996128],
              [-115.109464, 41.996131],
              [-115.108277, 41.996135],
              [-115.103541, 41.996159],
              [-115.100418, 41.996141],
              [-115.097185, 41.996121],
              [-115.092428, 41.996115],
              [-115.086936, 41.996158],
              [-115.080992, 41.996163],
              [-115.076313, 41.996142],
              [-115.075093, 41.996134],
              [-115.069421, 41.996137],
              [-115.065127, 41.996177],
              [-115.062879, 41.996198],
              [-115.057343, 41.996205],
              [-115.055721, 41.99621],
              [-115.051992, 41.996219],
              [-115.048376, 41.996293],
              [-115.047323, 41.996276],
              [-115.045313, 41.996239],
              [-115.041837, 41.996249],
              [-115.04041, 41.996259],
              [-115.038255, 41.996279],
              [-115.038157, 41.996279],
              [-115.037492, 41.996245],
              [-115.036784, 41.996245],
              [-115.034003, 41.996245],
              [-115.033002, 41.996245],
              [-115.032007, 41.99626],
              [-115.031718, 41.996264],
              [-115.025748, 41.996352],
              [-115.022967, 41.996432],
              [-115.020737, 41.996496],
              [-115.015773, 41.996552],
              [-115.015594, 41.996554],
              [-115.015418, 41.996556],
              [-115.015109, 41.996559],
              [-115.014887, 41.996562],
              [-115.014605, 41.996571],
              [-115.014066, 41.99659],
              [-115.013765, 41.9966],
              [-115.013385, 41.996613],
              [-115.012399, 41.996647],
              [-115.011507, 41.996678],
              [-115.009858, 41.996734],
              [-115.007937, 41.9968],
              [-115.003053, 41.996976],
              [-115.000992, 41.997136],
              [-115.000973, 41.997138],
              [-115.000052, 41.99721],
              [-114.999925, 41.99722],
              [-114.993499, 41.997565],
              [-114.984142, 41.997505],
              [-114.984117, 41.997505],
              [-114.982996, 41.997498],
              [-114.982817, 41.997497],
              [-114.982538, 41.997495],
              [-114.982153, 41.997493],
              [-114.981515, 41.997489],
              [-114.980912, 41.997485],
              [-114.979529, 41.997476],
              [-114.978955, 41.997473],
              [-114.977357, 41.997473],
              [-114.976005, 41.997474],
              [-114.974713, 41.997475],
              [-114.97355, 41.997476],
              [-114.972414, 41.997518],
              [-114.964083, 41.997829],
              [-114.954842, 41.998166],
              [-114.948793, 41.998446],
              [-114.946468, 41.998557],
              [-114.944367, 41.998656],
              [-114.94165, 41.998785],
              [-114.936234, 41.999042],
              [-114.930444, 41.999197],
              [-114.92728, 41.999297],
              [-114.924093, 41.9994],
              [-114.921639, 41.999455],
              [-114.921326, 41.999462],
              [-114.918159, 41.999533],
              [-114.917681, 41.999543],
              [-114.914384, 41.999619],
              [-114.910759, 41.999701],
              [-114.907029, 41.999789],
              [-114.900304, 41.999882],
              [-114.89921, 41.999907],
              [-114.898822, 41.999916],
              [-114.898513, 41.999923],
              [-114.897615, 41.999952],
              [-114.894235, 42.00006],
              [-114.891964, 42.000141],
              [-114.886751, 42.000339],
              [-114.881721, 42.000537],
              [-114.875618, 42.000784],
              [-114.871632, 42.000987],
              [-114.870379, 42.001051],
              [-114.864125, 42.001339],
              [-114.862441, 42.001414],
              [-114.85625, 42.001702],
              [-114.851754, 42.001716],
              [-114.847029, 42.001727],
              [-114.846538, 42.001729],
              [-114.846467, 42.001729],
              [-114.831119, 42.001793],
              [-114.823491, 42.001842],
              [-114.816425, 42.001791],
              [-114.808517, 42.001468],
              [-114.807563, 42.001434],
              [-114.804401, 42.001311],
              [-114.797689, 42.001043],
              [-114.797309, 42.001028],
              [-114.7972, 42.001023],
              [-114.797116, 42.001018],
              [-114.796685, 42.001002],
              [-114.792441, 42.000829],
              [-114.778711, 42.000347],
              [-114.768463, 41.999993],
              [-114.763825, 41.999867],
              [-114.760987, 41.999794],
              [-114.760372, 41.999776],
              [-114.754741, 41.999582],
              [-114.754651, 41.999579],
              [-114.754503, 41.999574],
              [-114.751566, 41.999452],
              [-114.750943, 41.999425],
              [-114.749115, 41.999367],
              [-114.745638, 41.999256],
              [-114.744469, 41.999219],
              [-114.743608, 41.999191],
              [-114.742638, 41.999159],
              [-114.740643, 41.99909],
              [-114.739813, 41.999061],
              [-114.737674, 41.998987],
              [-114.736445, 41.998945],
              [-114.735288, 41.998905],
              [-114.734172, 41.998866],
              [-114.731208, 41.998757],
              [-114.729224, 41.998672],
              [-114.724917, 41.998486],
              [-114.72104, 41.998394],
              [-114.720699, 41.998382],
              [-114.719038, 41.998321],
              [-114.717381, 41.99826],
              [-114.715705, 41.998198],
              [-114.713657, 41.998122],
              [-114.711472, 41.998036],
              [-114.709904, 41.99797],
              [-114.706681, 41.997835],
              [-114.704773, 41.997754],
              [-114.703741, 41.997711],
              [-114.701749, 41.99763],
              [-114.694063, 41.997409],
              [-114.688486, 41.997278],
              [-114.682694, 41.997082],
              [-114.680175, 41.997044],
              [-114.677124, 41.996999],
              [-114.674773, 41.99696],
              [-114.672663, 41.996918],
              [-114.669336, 41.996854],
              [-114.668119, 41.99683],
              [-114.667879, 41.996825],
              [-114.664099, 41.996764],
              [-114.66062, 41.996703],
              [-114.654812, 41.996586],
              [-114.652795, 41.996544],
              [-114.651524, 41.996516],
              [-114.650875, 41.996503],
              [-114.648854, 41.996468],
              [-114.643159, 41.996326],
              [-114.640258, 41.996248],
              [-114.638692, 41.99619],
              [-114.636737, 41.996118],
              [-114.635389, 41.99607],
              [-114.625861, 41.995712],
              [-114.62234, 41.99558],
              [-114.621123, 41.995534],
              [-114.620836, 41.995523],
              [-114.617774, 41.995359],
              [-114.613668, 41.99514],
              [-114.612139, 41.995076],
              [-114.61085, 41.995021],
              [-114.607334, 41.994873],
              [-114.603156, 41.994697],
              [-114.600415, 41.994639],
              [-114.598281, 41.994594],
              [-114.594299, 41.994578],
              [-114.593148, 41.994572],
              [-114.592926, 41.994571],
              [-114.590133, 41.994557],
              [-114.589585, 41.994554],
              [-114.588893, 41.99455],
              [-114.587148, 41.994542],
              [-114.586903, 41.99454],
              [-114.586156, 41.994537],
              [-114.584398, 41.994511],
              [-114.584165, 41.994508],
              [-114.578247, 41.994437],
              [-114.576094, 41.994418],
              [-114.56869, 41.994377],
              [-114.563103, 41.994388],
              [-114.562125, 41.99439],
              [-114.555347, 41.99431],
              [-114.54886, 41.994238],
              [-114.546928, 41.994244],
              [-114.542268, 41.994258],
              [-114.540564, 41.994292],
              [-114.540313, 41.994297],
              [-114.53915, 41.99432],
              [-114.538746, 41.994328],
              [-114.538465, 41.994334],
              [-114.538118, 41.994341],
              [-114.537737, 41.994349],
              [-114.537142, 41.994361],
              [-114.536838, 41.994367],
              [-114.535718, 41.994387],
              [-114.534792, 41.994397],
              [-114.533782, 41.994409],
              [-114.532461, 41.994423],
              [-114.531339, 41.994436],
              [-114.530232, 41.994448],
              [-114.526836, 41.994486],
              [-114.524348, 41.994492],
              [-114.521387, 41.9945],
              [-114.520261, 41.99451],
              [-114.518014, 41.994531],
              [-114.517759, 41.994533],
              [-114.517043, 41.994539],
              [-114.515965, 41.994549],
              [-114.515109, 41.994557],
              [-114.514212, 41.994565],
              [-114.509653, 41.994577],
              [-114.504158, 41.994587],
              [-114.501807, 41.99463],
              [-114.500904, 41.994646],
              [-114.49852, 41.994632],
              [-114.494622, 41.994608],
              [-114.49216, 41.994622],
              [-114.489677, 41.994633],
              [-114.482517, 41.994538],
              [-114.480299, 41.994537],
              [-114.475216, 41.994537],
              [-114.467662, 41.994531],
              [-114.46523, 41.994529],
              [-114.45774, 41.994523],
              [-114.446696, 41.994522],
              [-114.441769, 41.99452],
              [-114.44149, 41.994522],
              [-114.43807, 41.99453],
              [-114.434364, 41.994509],
              [-114.42734, 41.994499],
              [-114.421694, 41.994476],
              [-114.420755, 41.994484],
              [-114.42065, 41.99449],
              [-114.417574, 41.994462],
              [-114.414827, 41.994464],
              [-114.408311, 41.994484],
              [-114.40548, 41.994493],
              [-114.405162, 41.994495],
              [-114.404569, 41.994493],
              [-114.403057, 41.994488],
              [-114.398179, 41.994474],
              [-114.397627, 41.994482],
              [-114.396128, 41.99449],
              [-114.37886, 41.994457],
              [-114.377188, 41.994466],
              [-114.369427, 41.994542],
              [-114.367508, 41.994532],
              [-114.361462, 41.994481],
              [-114.361055, 41.994477],
              [-114.36, 41.994468],
              [-114.359435, 41.994464],
              [-114.358553, 41.994472],
              [-114.351448, 41.99453],
              [-114.349749, 41.994538],
              [-114.347846, 41.994531],
              [-114.345331, 41.994523],
              [-114.339858, 41.994504],
              [-114.336741, 41.994485],
              [-114.336151, 41.99448],
              [-114.333018, 41.994464],
              [-114.33275, 41.994461],
              [-114.323748, 41.994427],
              [-114.323595, 41.994427],
              [-114.322557, 41.994423],
              [-114.321438, 41.994418],
              [-114.320482, 41.994415],
              [-114.319509, 41.994412],
              [-114.318904, 41.994412],
              [-114.318113, 41.99441],
              [-114.317387, 41.994409],
              [-114.316764, 41.994408],
              [-114.316193, 41.994407],
              [-114.315596, 41.994406],
              [-114.314364, 41.994403],
              [-114.313108, 41.994401],
              [-114.311257, 41.994397],
              [-114.309965, 41.994395],
              [-114.30867, 41.994393],
              [-114.307958, 41.994392],
              [-114.303677, 41.994389],
              [-114.300401, 41.994385],
              [-114.300026, 41.994384],
              [-114.298742, 41.99438],
              [-114.294816, 41.994364],
              [-114.290348, 41.994352],
              [-114.284504, 41.994349],
              [-114.282023, 41.994431],
              [-114.281799, 41.994441],
              [-114.281855, 41.994214],
              [-114.280635, 41.994218],
              [-114.280243, 41.994219],
              [-114.280189, 41.994218],
              [-114.279594, 41.994221],
              [-114.279525, 41.994221],
              [-114.279374, 41.994221],
              [-114.277971, 41.994226],
              [-114.277939, 41.994226],
              [-114.2778, 41.994226],
              [-114.277618, 41.994227],
              [-114.274121, 41.994238],
              [-114.273878, 41.994239],
              [-114.264852, 41.994268],
              [-114.264519, 41.994269],
              [-114.264228, 41.99427],
              [-114.26394, 41.99427],
              [-114.250855, 41.994314],
              [-114.248444, 41.994284],
              [-114.241471, 41.994198],
              [-114.234654, 41.994114],
              [-114.217721, 41.994114],
              [-114.20174, 41.994108],
              [-114.2015, 41.994107],
              [-114.20126, 41.994107],
              [-114.196906, 41.994098],
              [-114.196501, 41.994097],
              [-114.195156, 41.994095],
              [-114.184918, 41.994075],
              [-114.184822, 41.994076],
              [-114.160877, 41.994029],
              [-114.160405, 41.994028],
              [-114.156811, 41.994021],
              [-114.156788, 41.994021],
              [-114.152952, 41.994014],
              [-114.145338, 41.994008],
              [-114.145182, 41.994008],
              [-114.145053, 41.994007],
              [-114.144467, 41.994006],
              [-114.143208, 41.994005],
              [-114.143087, 41.994005],
              [-114.138896, 41.994001],
              [-114.125958, 41.993989],
              [-114.125851, 41.993989],
              [-114.125851, 41.993936],
              [-114.121603, 41.993943],
              [-114.107781, 41.993965],
              [-114.107728, 41.993965],
              [-114.10765, 41.993904],
              [-114.107637, 41.993894],
              [-114.107528, 41.993831],
              [-114.08585, 41.993948],
              [-114.06191, 41.993939],
              [-114.061895, 41.993797],
              [-114.061622, 41.993797],
              [-114.056529, 41.993803],
              [-114.053581, 41.993807],
              [-114.048257, 41.993814],
              [-114.045295, 41.993802],
              [-114.041477, 41.993873],
              [-114.041441, 41.988116],
              [-114.04144, 41.987914],
              [-114.041401, 41.981953],
              [-114.041387, 41.980414],
              [-114.041383, 41.979889],
              [-114.041384, 41.979828],
              [-114.041382, 41.979705],
              [-114.041383, 41.979623],
              [-114.041382, 41.979532],
              [-114.041381, 41.979261],
              [-114.04138, 41.979058],
              [-114.041378, 41.978836],
              [-114.041376, 41.978144],
              [-114.041374, 41.977717],
              [-114.041362, 41.977437],
              [-114.041243, 41.974598],
              [-114.041123, 41.971664],
              [-114.041038, 41.969657],
              [-114.040935, 41.967202],
              [-114.040808, 41.964158],
              [-114.040805, 41.963913],
              [-114.040218, 41.949878],
              [-114.040196, 41.949364],
              [-114.040177, 41.948805],
              [-114.040246, 41.944461],
              [-114.040268, 41.943092],
              [-114.040275, 41.942647],
              [-114.040331, 41.938668],
              [-114.040384, 41.935825],
              [-114.040387, 41.932634],
              [-114.040305, 41.926635],
              [-114.040304, 41.926547],
              [-114.040302, 41.926415],
              [-114.040293, 41.925388],
              [-114.040293, 41.925371],
              [-114.040285, 41.924906],
              [-114.040261, 41.923514],
              [-114.040253, 41.92305],
              [-114.040198, 41.919613],
              [-114.040036, 41.909303],
              [-114.039982, 41.905867],
              [-114.039951, 41.903939],
              [-114.039964, 41.895995],
              [-114.039978, 41.891345],
              [-114.040147, 41.88583],
              [-114.040227, 41.883092],
              [-114.040318, 41.880225],
              [-114.04042, 41.876987],
              [-114.04041, 41.876086],
              [-114.040396, 41.874776],
              [-114.040363, 41.8717],
              [-114.040289, 41.864656],
              [-114.0403, 41.862478],
              [-114.040315, 41.859407],
              [-114.040323, 41.857532],
              [-114.040351, 41.851912],
              [-114.04036, 41.850038],
              [-114.040365, 41.849363],
              [-114.040368, 41.848918],
              [-114.040375, 41.84801],
              [-114.040298, 41.84575],
              [-114.039932, 41.834933],
              [-114.039888, 41.833632],
              [-114.039891, 41.829319],
              [-114.039897, 41.829151],
              [-114.040177, 41.775203],
              [-114.040119, 41.77411],
              [-114.040057, 41.76887],
              [-114.040037, 41.767292],
              [-114.040037, 41.767226],
              [-114.03999, 41.763241],
              [-114.039962, 41.760339],
              [-114.039957, 41.759875],
              [-114.039879, 41.753266],
              [-114.039868, 41.752683],
              [-114.039868, 41.752653],
              [-114.039854, 41.751619],
              [-114.039726, 41.741913],
              [-114.039707, 41.740817],
              [-114.039717, 41.740275],
              [-114.039784, 41.737197],
              [-114.039841, 41.73459],
              [-114.039863, 41.733129],
              [-114.039852, 41.731701],
              [-114.039791, 41.726755],
              [-114.039783, 41.725998],
              [-114.03971, 41.724541],
              [-114.039623, 41.722687],
              [-114.039623, 41.722495],
              [-114.039463, 41.719178],
              [-114.039461, 41.718874],
              [-114.039578, 41.715513],
              [-114.039619, 41.714313],
              [-114.03972, 41.711839],
              [-114.039752, 41.709423],
              [-114.039768, 41.708671],
              [-114.039824, 41.702422],
              [-114.039834, 41.701193],
              [-114.039852, 41.695261],
              [-114.039842, 41.693579],
              [-114.039829, 41.691173],
              [-114.039802, 41.661217],
              [-114.039805, 41.660824],
              [-114.039804, 41.660558],
              [-114.039766, 41.653034],
              [-114.039764, 41.651667],
              [-114.039753, 41.648917],
              [-114.039799, 41.633212],
              [-114.039813, 41.631709],
              [-114.039861, 41.628069],
              [-114.039908, 41.62442],
              [-114.039936, 41.622295],
              [-114.040016, 41.615224],
              [-114.040025, 41.614895],
              [-114.040121, 41.606945],
              [-114.040164, 41.602529],
              [-114.040168, 41.602387],
              [-114.040244, 41.591789],
              [-114.040246, 41.591603],
              [-114.040246, 41.591376],
              [-114.040348, 41.581797],
              [-114.040467, 41.575051],
              [-114.040492, 41.572147],
              [-114.040486, 41.570273],
              [-114.040493, 41.569092],
              [-114.040493, 41.568376],
              [-114.04054, 41.560563],
              [-114.040663, 41.54133],
              [-114.040715, 41.534082],
              [-114.040715, 41.534048],
              [-114.040735, 41.526208],
              [-114.04073, 41.525963],
              [-114.040727, 41.525021],
              [-114.040359, 41.495807],
              [-114.040342, 41.495437],
              [-114.040317, 41.494257],
              [-114.040298, 41.493404],
              [-114.040295, 41.49246],
              [-114.040293, 41.491971],
              [-114.04029, 41.491199],
              [-114.040289, 41.486183],
              [-114.0403, 41.484539],
              [-114.040324, 41.480083],
              [-114.040335, 41.47932],
              [-114.04039, 41.47167],
              [-114.040415, 41.464801],
              [-114.040413, 41.464654],
              [-114.040415, 41.464238],
              [-114.040454, 41.457869],
              [-114.040437, 41.454936],
              [-114.040446, 41.452926],
              [-114.040442, 41.4466],
              [-114.040469, 41.426338],
              [-114.04047, 41.425736],
              [-114.040467, 41.425014],
              [-114.040488, 41.41042],
              [-114.040488, 41.409845],
              [-114.040491, 41.408632],
              [-114.040495, 41.407568],
              [-114.040479, 41.405845],
              [-114.040488, 41.400672],
              [-114.040519, 41.398965],
              [-114.040534, 41.394877],
              [-114.040524, 41.393576],
              [-114.04054, 41.392251],
              [-114.040546, 41.391346],
              [-114.04059, 41.376598],
              [-114.040586, 41.375664],
              [-114.040604, 41.36892],
              [-114.040598, 41.368202],
              [-114.040584, 41.364873],
              [-114.040581, 41.362773],
              [-114.040569, 41.361303],
              [-114.04057, 41.360009],
              [-114.040528, 41.34197],
              [-114.040566, 41.334139],
              [-114.040589, 41.329845],
              [-114.040616, 41.321329],
              [-114.040742, 41.303121],
              [-114.040781, 41.29924],
              [-114.040854, 41.292756],
              [-114.040854, 41.291989],
              [-114.040923, 41.28635],
              [-114.040959, 41.280005],
              [-114.040959, 41.278664],
              [-114.040978, 41.275676],
              [-114.04099, 41.270791],
              [-114.041035, 41.265544],
              [-114.041102, 41.260205],
              [-114.041166, 41.254145],
              [-114.041165, 41.253774],
              [-114.041189, 41.249171],
              [-114.041188, 41.245437],
              [-114.041189, 41.244603],
              [-114.041189, 41.24453],
              [-114.041192, 41.244381],
              [-114.04123, 41.23577],
              [-114.041242, 41.231601],
              [-114.041297, 41.222508],
              [-114.041299, 41.220117],
              [-114.041317, 41.21289],
              [-114.041318, 41.211539],
              [-114.041319, 41.211481],
              [-114.041323, 41.20878],
              [-114.04133, 41.206692],
              [-114.041326, 41.203331],
              [-114.041355, 41.195401],
              [-114.041369, 41.191271],
              [-114.041364, 41.190989],
              [-114.041376, 41.181575],
              [-114.041317, 41.176286],
              [-114.041295, 41.170272],
              [-114.041267, 41.164361],
              [-114.041229, 41.157223],
              [-114.041229, 41.153683],
              [-114.041295, 41.150112],
              [-114.041362, 41.146436],
              [-114.041425, 41.143102],
              [-114.041502, 41.136496],
              [-114.041499, 41.136396],
              [-114.041528, 41.131396],
              [-114.041547, 41.127492],
              [-114.04156, 41.125226],
              [-114.04157, 41.123425],
              [-114.041556, 41.122504],
              [-114.041555, 41.119425],
              [-114.041542, 41.116701],
              [-114.041547, 41.114831],
              [-114.041521, 41.103928],
              [-114.041695, 41.055791],
              [-114.041695, 41.055588],
              [-114.041809, 41.037304],
              [-114.041815, 41.030705],
              [-114.041846, 41.026027],
              [-114.041851, 41.024789],
              [-114.041861, 41.023749],
              [-114.041794, 41.020902],
              [-114.041819, 41.019505],
              [-114.041829, 41.017874],
              [-114.041948, 41.010251],
              [-114.042016, 41.001562],
              [-114.042029, 40.999896],
              [-114.042043, 40.998326],
              [-114.04206, 40.995644],
              [-114.042062, 40.995003],
              [-114.042095, 40.991534],
              [-114.04209, 40.991312],
              [-114.042144, 40.983517],
              [-114.042153, 40.982138],
              [-114.042175, 40.976978],
              [-114.042199, 40.972192],
              [-114.042203, 40.971213],
              [-114.042204, 40.971132],
              [-114.042219, 40.968065],
              [-114.042222, 40.966817],
              [-114.042231, 40.964428],
              [-114.042232, 40.963912],
              [-114.042245, 40.960793],
              [-114.042246, 40.960294],
              [-114.04226, 40.957159],
              [-114.04226, 40.956675],
              [-114.04227, 40.954665],
              [-114.042274, 40.953524],
              [-114.042275, 40.953057],
              [-114.042276, 40.952297],
              [-114.042281, 40.94989],
              [-114.042292, 40.946259],
              [-114.042293, 40.946018],
              [-114.042292, 40.945671],
              [-114.042302, 40.942092],
              [-114.042307, 40.937574],
              [-114.042317, 40.935177],
              [-114.042318, 40.931829],
              [-114.042321, 40.928888],
              [-114.042329, 40.922686],
              [-114.042373, 40.917578],
              [-114.042379, 40.91693],
              [-114.042477, 40.897577],
              [-114.042482, 40.895605],
              [-114.042487, 40.894343],
              [-114.042492, 40.892582],
              [-114.042494, 40.891977],
              [-114.042494, 40.891819],
              [-114.042502, 40.888352],
              [-114.042511, 40.884725],
              [-114.042519, 40.881098],
              [-114.042523, 40.879827],
              [-114.04254, 40.878084],
              [-114.042546, 40.877479],
              [-114.042573, 40.874458],
              [-114.042637, 40.867649],
              [-114.042674, 40.845903],
              [-114.042779, 40.829422],
              [-114.042779, 40.829358],
              [-114.042798, 40.826958],
              [-114.042792, 40.824501],
              [-114.042794, 40.820231],
              [-114.042821, 40.81663],
              [-114.042838, 40.814396],
              [-114.042847, 40.813193],
              [-114.04288, 40.807395],
              [-114.042886, 40.806129],
              [-114.04288, 40.79954],
              [-114.042876, 40.795858],
              [-114.042876, 40.795614],
              [-114.042876, 40.795593],
              [-114.042875, 40.79535],
              [-114.042874, 40.795071],
              [-114.043046, 40.780326],
              [-114.043047, 40.780264],
              [-114.043066, 40.778729],
              [-114.043093, 40.776894],
              [-114.043094, 40.776844],
              [-114.043147, 40.773089],
              [-114.043195, 40.769673],
              [-114.043246, 40.766057],
              [-114.043249, 40.765834],
              [-114.043271, 40.764267],
              [-114.043277, 40.763889],
              [-114.043279, 40.763749],
              [-114.043283, 40.763438],
              [-114.043287, 40.763203],
              [-114.043298, 40.762431],
              [-114.043302, 40.762207],
              [-114.043352, 40.75881],
              [-114.043356, 40.758583],
              [-114.043374, 40.757355],
              [-114.043385, 40.756592],
              [-114.043391, 40.756197],
              [-114.043401, 40.755552],
              [-114.043402, 40.755466],
              [-114.043406, 40.755189],
              [-114.043409, 40.755069],
              [-114.04341, 40.755013],
              [-114.04341, 40.754984],
              [-114.043411, 40.754934],
              [-114.043484, 40.749823],
              [-114.043512, 40.74794],
              [-114.043515, 40.747712],
              [-114.043533, 40.746507],
              [-114.043544, 40.745715],
              [-114.043565, 40.744428],
              [-114.043615, 40.740827],
              [-114.043618, 40.74069],
              [-114.043622, 40.740389],
              [-114.043624, 40.740263],
              [-114.043626, 40.740097],
              [-114.043627, 40.740044],
              [-114.043635, 40.73961],
              [-114.043654, 40.738142],
              [-114.043657, 40.73799],
              [-114.043686, 40.735861],
              [-114.043689, 40.735776],
              [-114.043692, 40.735586],
              [-114.04371, 40.733125],
              [-114.043718, 40.732067],
              [-114.043718, 40.731965],
              [-114.043722, 40.731488],
              [-114.043722, 40.731403],
              [-114.043728, 40.730467],
              [-114.043737, 40.729113],
              [-114.043746, 40.727847],
              [-114.043752, 40.726855],
              [-114.043762, 40.726034],
              [-114.043793, 40.720918],
              [-114.043806, 40.718956],
              [-114.043808, 40.71873],
              [-114.043815, 40.717894],
              [-114.043834, 40.714941],
              [-114.043856, 40.711711],
              [-114.043857, 40.711483],
              [-114.043869, 40.709714],
              [-114.043874, 40.709185],
              [-114.043881, 40.707908],
              [-114.04389, 40.707004],
              [-114.043898, 40.705562],
              [-114.043907, 40.704236],
              [-114.04393, 40.700844],
              [-114.043931, 40.700614],
              [-114.043943, 40.698904],
              [-114.043946, 40.698407],
              [-114.043954, 40.697221],
              [-114.043956, 40.696992],
              [-114.043964, 40.695034],
              [-114.043978, 40.693599],
              [-114.043982, 40.693162],
              [-114.043989, 40.691975],
              [-114.044002, 40.69012],
              [-114.044003, 40.689977],
              [-114.044004, 40.689755],
              [-114.044007, 40.689277],
              [-114.04401, 40.688856],
              [-114.044027, 40.686081],
              [-114.04405, 40.682461],
              [-114.04409, 40.677496],
              [-114.044108, 40.675222],
              [-114.044139, 40.671599],
              [-114.044161, 40.668958],
              [-114.044393, 40.640812],
              [-114.044394, 40.64075],
              [-114.044404, 40.639578],
              [-114.044441, 40.635102],
              [-114.044449, 40.634058],
              [-114.044465, 40.631984],
              [-114.044467, 40.631739],
              [-114.044496, 40.62836],
              [-114.044497, 40.628289],
              [-114.04452, 40.625583],
              [-114.044529, 40.62474],
              [-114.04467, 40.60742],
              [-114.044792, 40.588853],
              [-114.04485, 40.578953],
              [-114.045283, 40.518228],
              [-114.04536, 40.50703],
              [-114.045361, 40.506763],
              [-114.045374, 40.504983],
              [-114.045376, 40.504601],
              [-114.045396, 40.502153],
              [-114.045393, 40.501883],
              [-114.045397, 40.501629],
              [-114.045398, 40.50149],
              [-114.045401, 40.501087],
              [-114.045401, 40.500984],
              [-114.045405, 40.500393],
              [-114.045407, 40.500112],
              [-114.045408, 40.499907],
              [-114.045409, 40.499759],
              [-114.045413, 40.499291],
              [-114.045415, 40.498865],
              [-114.045423, 40.497978],
              [-114.045443, 40.494848],
              [-114.045444, 40.494745],
              [-114.045458, 40.492809],
              [-114.045467, 40.491525],
              [-114.045477, 40.49008],
              [-114.04545, 40.487245],
              [-114.045451, 40.487066],
              [-114.045458, 40.484398],
              [-114.045332, 40.457793],
              [-114.045327, 40.457386],
              [-114.045256, 40.452658],
              [-114.045245, 40.451932],
              [-114.045227, 40.450646],
              [-114.045218, 40.450077],
              [-114.045215, 40.449892],
              [-114.045202, 40.448956],
              [-114.0452, 40.448821],
              [-114.045171, 40.446869],
              [-114.045117, 40.443229],
              [-114.04507, 40.43992],
              [-114.04507, 40.439605],
              [-114.045069, 40.438861],
              [-114.04507, 40.437818],
              [-114.04507, 40.431611],
              [-114.04507, 40.43082],
              [-114.045246, 40.42898],
              [-114.04534, 40.427994],
              [-114.045622, 40.425058],
              [-114.045671, 40.424544],
              [-114.045689, 40.423157],
              [-114.045723, 40.420754],
              [-114.045764, 40.417698],
              [-114.04578, 40.416483],
              [-114.045833, 40.414018],
              [-114.045856, 40.412999],
              [-114.045913, 40.410395],
              [-114.045922, 40.409955],
              [-114.045992, 40.406773],
              [-114.046001, 40.406333],
              [-114.046081, 40.402709],
              [-114.046099, 40.401848],
              [-114.046088, 40.399528],
              [-114.046088, 40.398834],
              [-114.046083, 40.398551],
              [-114.046088, 40.398274],
              [-114.046038, 40.388999],
              [-114.046037, 40.388967],
              [-114.046036, 40.388796],
              [-114.046036, 40.388713],
              [-114.046035, 40.388453],
              [-114.046075, 40.376816],
              [-114.046091, 40.374168],
              [-114.0461, 40.372736],
              [-114.046078, 40.372506],
              [-114.046051, 40.37114],
              [-114.046053, 40.37037],
              [-114.046073, 40.363958],
              [-114.046081, 40.361821],
              [-114.046081, 40.361803],
              [-114.046081, 40.361752],
              [-114.046082, 40.361735],
              [-114.046082, 40.361599],
              [-114.046086, 40.360501],
              [-114.046086, 40.360392],
              [-114.046087, 40.36025],
              [-114.04609, 40.359348],
              [-114.046095, 40.358114],
              [-114.046097, 40.357383],
              [-114.046099, 40.356908],
              [-114.046103, 40.355192],
              [-114.046105, 40.354462],
              [-114.046106, 40.354038],
              [-114.046108, 40.352767],
              [-114.04611, 40.352344],
              [-114.046112, 40.351351],
              [-114.046118, 40.348375],
              [-114.046121, 40.347383],
              [-114.046131, 40.342747],
              [-114.046136, 40.341009],
              [-114.046155, 40.332189],
              [-114.046156, 40.332159],
              [-114.046162, 40.329419],
              [-114.046178, 40.322537],
              [-114.046178, 40.3212],
              [-114.046181, 40.318461],
              [-114.046182, 40.315483],
              [-114.046187, 40.306551],
              [-114.04619, 40.303574],
              [-114.04619, 40.303498],
              [-114.04619, 40.30327],
              [-114.04619, 40.303195],
              [-114.04619, 40.303166],
              [-114.04619, 40.303079],
              [-114.04619, 40.30305],
              [-114.04619, 40.303021],
              [-114.04619, 40.302934],
              [-114.04619, 40.302905],
              [-114.046191, 40.300422],
              [-114.046197, 40.293363],
              [-114.046193, 40.292974],
              [-114.046172, 40.290588],
              [-114.046172, 40.290492],
              [-114.046179, 40.289246],
              [-114.046208, 40.284081],
              [-114.046208, 40.284052],
              [-114.046217, 40.282866],
              [-114.046245, 40.277838],
              [-114.046274, 40.272631],
              [-114.046314, 40.265625],
              [-114.046314, 40.265494],
              [-114.046317, 40.264989],
              [-114.046303, 40.26166],
              [-114.046273, 40.254928],
              [-114.046334, 40.249933],
              [-114.046299, 40.244493],
              [-114.046256, 40.243216],
              [-114.046257, 40.24295],
              [-114.04626, 40.242755],
              [-114.04626, 40.242636],
              [-114.046368, 40.235104],
              [-114.046371, 40.234942],
              [-114.046372, 40.234864],
              [-114.046374, 40.234656],
              [-114.046396, 40.233138],
              [-114.046418, 40.231738],
              [-114.04648, 40.225447],
              [-114.046492, 40.223231],
              [-114.046508, 40.219312],
              [-114.046543, 40.211388],
              [-114.046558, 40.208878],
              [-114.046563, 40.207325],
              [-114.046573, 40.2037],
              [-114.046584, 40.200076],
              [-114.046522, 40.183899],
              [-114.046434, 40.179152],
              [-114.046433, 40.179095],
              [-114.046432, 40.179036],
              [-114.046409, 40.176511],
              [-114.04646, 40.157602],
              [-114.046462, 40.156732],
              [-114.046461, 40.1555],
              [-114.046473, 40.155333],
              [-114.046475, 40.155306],
              [-114.046564, 40.154056],
              [-114.046456, 40.143255],
              [-114.046484, 40.137606],
              [-114.046489, 40.136693],
              [-114.046489, 40.136641],
              [-114.04649, 40.136573],
              [-114.046496, 40.135218],
              [-114.046517, 40.131154],
              [-114.046524, 40.1298],
              [-114.046527, 40.12914],
              [-114.046537, 40.127163],
              [-114.046541, 40.126556],
              [-114.04654, 40.126505],
              [-114.046502, 40.124935],
              [-114.046585, 40.122456],
              [-114.046602, 40.119201],
              [-114.046614, 40.116936],
              [-114.046619, 40.115829],
              [-114.046645, 40.110047],
              [-114.046656, 40.106209],
              [-114.046671, 40.103],
              [-114.046679, 40.101188],
              [-114.046685, 40.099672],
              [-114.04669, 40.096168],
              [-114.046709, 40.087656],
              [-114.04671, 40.081025],
              [-114.046708, 40.080178],
              [-114.046703, 40.078375],
              [-114.046702, 40.076407],
              [-114.046695, 40.073203],
              [-114.04669, 40.070185],
              [-114.046684, 40.067039],
              [-114.046691, 40.064273],
              [-114.046697, 40.06337],
              [-114.046679, 40.061668],
              [-114.04668, 40.060512],
              [-114.046673, 40.057745],
              [-114.046671, 40.055922],
              [-114.04667, 40.054416],
              [-114.046689, 40.04986],
              [-114.046694, 40.048685],
              [-114.046729, 40.037741],
              [-114.04673, 40.037394],
              [-114.046753, 40.030325],
              [-114.046754, 40.027129],
              [-114.046768, 40.020572],
              [-114.046782, 40.013861],
              [-114.046783, 40.013664],
              [-114.046794, 40.008913],
              [-114.046795, 40.007106],
              [-114.046803, 40.005262],
              [-114.046802, 40.00473],
              [-114.046806, 40.002926],
              [-114.046812, 40.001761],
              [-114.046829, 39.997108],
              [-114.046843, 39.99379],
              [-114.046896, 39.980589],
              [-114.046936, 39.971929],
              [-114.046954, 39.963133],
              [-114.04698, 39.947234],
              [-114.046981, 39.94151],
              [-114.046985, 39.930173],
              [-114.046987, 39.923293],
              [-114.046994, 39.911513],
              [-114.04702, 39.906808],
              [-114.047026, 39.906097],
              [-114.047025, 39.906037],
              [-114.047026, 39.905923],
              [-114.047042, 39.904061],
              [-114.047114, 39.89552],
              [-114.04712, 39.881093],
              [-114.047164, 39.873347],
              [-114.047165, 39.872785],
              [-114.047165, 39.87253],
              [-114.047167, 39.870079],
              [-114.047169, 39.869263],
              [-114.047169, 39.869197],
              [-114.04717, 39.866984],
              [-114.04717, 39.86675],
              [-114.047174, 39.85333],
              [-114.047176, 39.85207],
              [-114.047181, 39.849739],
              [-114.047217, 39.846973],
              [-114.04744, 39.831285],
              [-114.047598, 39.821013],
              [-114.047685, 39.815645],
              [-114.047766, 39.808439],
              [-114.047835, 39.786968],
              [-114.047839, 39.785508],
              [-114.047839, 39.785438],
              [-114.047839, 39.785375],
              [-114.047855, 39.779402],
              [-114.047839, 39.776029],
              [-114.047792, 39.7699],
              [-114.047726, 39.763588],
              [-114.047633, 39.759521],
              [-114.047292, 39.744414],
              [-114.047224, 39.741213],
              [-114.047148, 39.737494],
              [-114.047084, 39.731848],
              [-114.047005, 39.724798],
              [-114.04691, 39.71652],
              [-114.046839, 39.709111],
              [-114.046963, 39.700436],
              [-114.046968, 39.699396],
              [-114.046999, 39.697503],
              [-114.047041, 39.694787],
              [-114.047055, 39.693562],
              [-114.047082, 39.691455],
              [-114.047133, 39.68799],
              [-114.047173, 39.685041],
              [-114.047198, 39.683244],
              [-114.047209, 39.682211],
              [-114.047238, 39.679905],
              [-114.047266, 39.674467],
              [-114.047273, 39.672215],
              [-114.047294, 39.668856],
              [-114.047301, 39.667585],
              [-114.0473, 39.667529],
              [-114.0473, 39.666928],
              [-114.047338, 39.659504],
              [-114.047373, 39.651088],
              [-114.047392, 39.648816],
              [-114.047413, 39.646414],
              [-114.047444, 39.642882],
              [-114.04748, 39.638332],
              [-114.047544, 39.626775],
              [-114.047547, 39.626085],
              [-114.047551, 39.624941],
              [-114.047551, 39.62483],
              [-114.047554, 39.618996],
              [-114.047556, 39.617052],
              [-114.047549, 39.604405],
              [-114.047542, 39.598031],
              [-114.047546, 39.593456],
              [-114.047553, 39.590822],
              [-114.047565, 39.584147],
              [-114.04758, 39.577217],
              [-114.047581, 39.57689],
              [-114.047587, 39.574207],
              [-114.047594, 39.569251],
              [-114.047601, 39.566235],
              [-114.047606, 39.563575],
              [-114.047614, 39.559873],
              [-114.047617, 39.557731],
              [-114.04762, 39.555511],
              [-114.047622, 39.554455],
              [-114.047627, 39.552383],
              [-114.047629, 39.551459],
              [-114.047638, 39.547724],
              [-114.047641, 39.546952],
              [-114.047647, 39.544005],
              [-114.047649, 39.542742],
              [-114.047654, 39.540223],
              [-114.047649, 39.537524],
              [-114.047678, 39.5331],
              [-114.047682, 39.53205],
              [-114.047696, 39.528477],
              [-114.047759, 39.50977],
              [-114.04777, 39.506391],
              [-114.047783, 39.502266],
              [-114.047823, 39.492326],
              [-114.047824, 39.488768],
              [-114.047827, 39.487553],
              [-114.04783, 39.486457],
              [-114.047831, 39.485541],
              [-114.047836, 39.484024],
              [-114.0479, 39.462327],
              [-114.047894, 39.461852],
              [-114.047905, 39.460422],
              [-114.047916, 39.457628],
              [-114.047919, 39.456167],
              [-114.047923, 39.455766],
              [-114.047934, 39.45218],
              [-114.047943, 39.447707],
              [-114.047946, 39.446768],
              [-114.04795, 39.444929],
              [-114.047975, 39.432197],
              [-114.047979, 39.42911],
              [-114.047984, 39.425016],
              [-114.047988, 39.42267],
              [-114.047989, 39.422267],
              [-114.048068, 39.407269],
              [-114.048073, 39.406717],
              [-114.048089, 39.403303],
              [-114.048099, 39.401322],
              [-114.048102, 39.400128],
              [-114.048103, 39.399686],
              [-114.048119, 39.396639],
              [-114.048134, 39.393674],
              [-114.048138, 39.392805],
              [-114.04816, 39.38871],
              [-114.048174, 39.386006],
              [-114.048181, 39.384432],
              [-114.048189, 39.383595],
              [-114.048193, 39.381587],
              [-114.048208, 39.379423],
              [-114.048215, 39.378363],
              [-114.048221, 39.3753],
              [-114.048239, 39.368532],
              [-114.048244, 39.36649],
              [-114.048247, 39.365608],
              [-114.048253, 39.363699],
              [-114.048269, 39.360113],
              [-114.048277, 39.355577],
              [-114.048285, 39.3526],
              [-114.048287, 39.351895],
              [-114.048319, 39.342812],
              [-114.048317, 39.342457],
              [-114.048318, 39.341875],
              [-114.048321, 39.340522],
              [-114.048325, 39.340243],
              [-114.048343, 39.332135],
              [-114.048344, 39.329585],
              [-114.048354, 39.322627],
              [-114.048361, 39.318427],
              [-114.04837, 39.313603],
              [-114.048372, 39.312428],
              [-114.048375, 39.309588],
              [-114.048377, 39.308579],
              [-114.048385, 39.30547],
              [-114.048393, 39.301256],
              [-114.048398, 39.299375],
              [-114.048401, 39.298487],
              [-114.048407, 39.295728],
              [-114.048417, 39.292272],
              [-114.04842, 39.290465],
              [-114.048425, 39.288224],
              [-114.048427, 39.287221],
              [-114.048431, 39.286435],
              [-114.04844, 39.281006],
              [-114.048456, 39.27519],
              [-114.04846, 39.273574],
              [-114.04847, 39.270682],
              [-114.04847, 39.270592],
              [-114.048477, 39.268817],
              [-114.048481, 39.267226],
              [-114.048492, 39.261384],
              [-114.048495, 39.260169],
              [-114.048496, 39.255682],
              [-114.048496, 39.253236],
              [-114.048496, 39.251839],
              [-114.048496, 39.250266],
              [-114.048497, 39.2474],
              [-114.048499, 39.243754],
              [-114.0485, 39.242508],
              [-114.0485, 39.241932],
              [-114.048502, 39.239588],
              [-114.048503, 39.238487],
              [-114.048512, 39.231379],
              [-114.048511, 39.230564],
              [-114.048511, 39.229826],
              [-114.048518, 39.224797],
              [-114.04853, 39.216661],
              [-114.048533, 39.214642],
              [-114.048539, 39.211145],
              [-114.048542, 39.20935],
              [-114.048544, 39.207935],
              [-114.048545, 39.207336],
              [-114.048547, 39.206378],
              [-114.048551, 39.204357],
              [-114.048558, 39.201447],
              [-114.048531, 39.197855],
              [-114.048542, 39.194931],
              [-114.048538, 39.194539],
              [-114.048557, 39.188111],
              [-114.048557, 39.188074],
              [-114.048558, 39.187967],
              [-114.048562, 39.187649],
              [-114.048564, 39.187543],
              [-114.048553, 39.187432],
              [-114.04856, 39.187102],
              [-114.048563, 39.186992],
              [-114.048564, 39.186335],
              [-114.048569, 39.184364],
              [-114.048572, 39.183708],
              [-114.048573, 39.183407],
              [-114.048576, 39.182506],
              [-114.048578, 39.182206],
              [-114.048601, 39.173715],
              [-114.04861, 39.170689],
              [-114.048625, 39.166643],
              [-114.04863, 39.164417],
              [-114.048633, 39.163396],
              [-114.048641, 39.160336],
              [-114.048645, 39.159317],
              [-114.048647, 39.158697],
              [-114.048653, 39.156838],
              [-114.048656, 39.156219],
              [-114.048658, 39.155484],
              [-114.048666, 39.15328],
              [-114.048669, 39.152546],
              [-114.048672, 39.151672],
              [-114.04868, 39.14905],
              [-114.048684, 39.148177],
              [-114.048685, 39.147649],
              [-114.04869, 39.146065],
              [-114.048693, 39.145538],
              [-114.048693, 39.145416],
              [-114.048694, 39.145049],
              [-114.048695, 39.144928],
              [-114.048697, 39.144162],
              [-114.048705, 39.141864],
              [-114.048708, 39.141099],
              [-114.048707, 39.141024],
              [-114.048704, 39.1408],
              [-114.048703, 39.140726],
              [-114.048719, 39.135115],
              [-114.048723, 39.133734],
              [-114.048726, 39.132436],
              [-114.048739, 39.1271],
              [-114.048744, 39.124956],
              [-114.048751, 39.124443],
              [-114.048764, 39.112374],
              [-114.048808, 39.099956],
              [-114.048823, 39.098521],
              [-114.048813, 39.098447],
              [-114.04881, 39.087848],
              [-114.048811, 39.08777],
              [-114.048844, 39.079152],
              [-114.048846, 39.078851],
              [-114.048855, 39.076398],
              [-114.048864, 39.072841],
              [-114.048881, 39.064228],
              [-114.048888, 39.060495],
              [-114.04889, 39.058927],
              [-114.048891, 39.057112],
              [-114.048897, 39.050635],
              [-114.048899, 39.048286],
              [-114.048903, 39.043592],
              [-114.048903, 39.042389],
              [-114.048919, 39.030504],
              [-114.048931, 39.027526],
              [-114.048933, 39.027207],
              [-114.048946, 39.024169],
              [-114.048949, 39.023634],
              [-114.04895, 39.023463],
              [-114.048956, 39.021905],
              [-114.04896, 39.020928],
              [-114.048963, 39.020295],
              [-114.048967, 39.019567],
              [-114.048967, 39.019403],
              [-114.048968, 39.019265],
              [-114.048978, 39.016958],
              [-114.048997, 39.012521],
              [-114.049025, 39.006552],
              [-114.049026, 39.006008],
              [-114.04903, 39.005383],
              [-114.04906, 38.997156],
              [-114.049094, 38.986258],
              [-114.049141, 38.970483],
              [-114.049171, 38.959577],
              [-114.049254, 38.949597],
              [-114.049273, 38.94725],
              [-114.049298, 38.940575],
              [-114.049297, 38.940259],
              [-114.049317, 38.93936],
              [-114.049331, 38.938576],
              [-114.04932, 38.936323],
              [-114.049358, 38.934748],
              [-114.049323, 38.933906],
              [-114.049325, 38.932641],
              [-114.04933, 38.931576],
              [-114.04934, 38.929449],
              [-114.04934, 38.929334],
              [-114.04939, 38.920789],
              [-114.049387, 38.920149],
              [-114.049394, 38.919845],
              [-114.049402, 38.916007],
              [-114.049453, 38.906641],
              [-114.049476, 38.902754],
              [-114.049493, 38.90043],
              [-114.049571, 38.885122],
              [-114.049579, 38.88348],
              [-114.049579, 38.88202],
              [-114.049579, 38.880184],
              [-114.049587, 38.877776],
              [-114.049594, 38.874649],
              [-114.0496, 38.87206],
              [-114.04962, 38.866562],
              [-114.049638, 38.862051],
              [-114.049646, 38.859779],
              [-114.049651, 38.858978],
              [-114.049655, 38.854002],
              [-114.049655, 38.85221],
              [-114.049653, 38.842255],
              [-114.049649, 38.83811],
              [-114.04963, 38.832056],
              [-114.049632, 38.831923],
              [-114.049629, 38.831224],
              [-114.049625, 38.828408],
              [-114.049628, 38.820017],
              [-114.049613, 38.808369],
              [-114.049612, 38.808182],
              [-114.049603, 38.804425],
              [-114.049593, 38.799973],
              [-114.049596, 38.796626],
              [-114.049613, 38.78767],
              [-114.049621, 38.780105],
              [-114.04962, 38.777365],
              [-114.049618, 38.773157],
              [-114.049614, 38.770179],
              [-114.049618, 38.769106],
              [-114.049623, 38.767872],
              [-114.049626, 38.767117],
              [-114.049692, 38.731192],
              [-114.049692, 38.731162],
              [-114.049695, 38.729873],
              [-114.049699, 38.728164],
              [-114.049721, 38.72019],
              [-114.04973, 38.716713],
              [-114.049751, 38.708261],
              [-114.049756, 38.706232],
              [-114.049767, 38.701615],
              [-114.049773, 38.69922],
              [-114.0498, 38.697835],
              [-114.049815, 38.691097],
              [-114.049829, 38.684097],
              [-114.049829, 38.683833],
              [-114.049831, 38.683042],
              [-114.049832, 38.682779],
              [-114.049807, 38.677365],
              [-114.049804, 38.675302],
              [-114.049801, 38.672507],
              [-114.049799, 38.67102],
              [-114.049798, 38.670582],
              [-114.0498, 38.668693],
              [-114.049816, 38.662904],
              [-114.049823, 38.660248],
              [-114.049826, 38.659218],
              [-114.049851, 38.651844],
              [-114.049853, 38.651359],
              [-114.049894, 38.647844],
              [-114.0499, 38.64382],
              [-114.049911, 38.637589],
              [-114.049919, 38.633306],
              [-114.049921, 38.632497],
              [-114.049926, 38.629291],
              [-114.049904, 38.624119],
              [-114.049906, 38.621466],
              [-114.049906, 38.617289],
              [-114.049907, 38.614977],
              [-114.049908, 38.614413],
              [-114.049907, 38.611744],
              [-114.049908, 38.608891],
              [-114.049911, 38.602832],
              [-114.049912, 38.598377],
              [-114.049924, 38.592843],
              [-114.049926, 38.592128],
              [-114.049928, 38.590819],
              [-114.049929, 38.590697],
              [-114.049948, 38.583275],
              [-114.04995, 38.582428],
              [-114.049973, 38.574058],
              [-114.049977, 38.572974],
              [-114.049983, 38.570436],
              [-114.050005, 38.563933],
              [-114.050014, 38.561585],
              [-114.05003, 38.556322],
              [-114.050031, 38.556141],
              [-114.050057, 38.551206],
              [-114.050055, 38.55056],
              [-114.050061, 38.54771],
              [-114.050061, 38.547539],
              [-114.050064, 38.547132],
              [-114.050069, 38.546188],
              [-114.050071, 38.544147],
              [-114.050073, 38.54265],
              [-114.050076, 38.542635],
              [-114.05009, 38.536872],
              [-114.050094, 38.53572],
              [-114.050154, 38.53385],
              [-114.050118, 38.527424],
              [-114.050119, 38.525945],
              [-114.050129, 38.524524],
              [-114.050178, 38.520647],
              [-114.050193, 38.519856],
              [-114.050229, 38.517084],
              [-114.050256, 38.515307],
              [-114.050269, 38.514896],
              [-114.050472, 38.499658],
              [-114.050515, 38.496528],
              [-114.050521, 38.496117],
              [-114.050424, 38.491917],
              [-114.050366, 38.489357],
              [-114.050153, 38.4698],
              [-114.050184, 38.461991],
              [-114.050264, 38.455725],
              [-114.050284, 38.454575],
              [-114.050278, 38.44712],
              [-114.050273, 38.443849],
              [-114.050283, 38.441067],
              [-114.050279, 38.437892],
              [-114.050291, 38.433735],
              [-114.050296, 38.430973],
              [-114.050303, 38.427945],
              [-114.050301, 38.426066],
              [-114.050298, 38.424306],
              [-114.050282, 38.423158],
              [-114.050273, 38.42245],
              [-114.050178, 38.414955],
              [-114.050075, 38.404762],
              [-114.050075, 38.404723],
              [-114.050076, 38.404555],
              [-114.050069, 38.403578],
              [-114.049982, 38.392856],
              [-114.050111, 38.390471],
              [-114.049948, 38.384895],
              [-114.049923, 38.381588],
              [-114.049917, 38.380608],
              [-114.049935, 38.379611],
              [-114.04989, 38.376992],
              [-114.049863, 38.373386],
              [-114.049836, 38.369761],
              [-114.049817, 38.367125],
              [-114.049813, 38.366326],
              [-114.049808, 38.365623],
              [-114.049802, 38.364653],
              [-114.049787, 38.362517],
              [-114.049764, 38.358897],
              [-114.049736, 38.354776],
              [-114.049702, 38.349135],
              [-114.049695, 38.348015],
              [-114.049672, 38.344405],
              [-114.049659, 38.342467],
              [-114.049629, 38.337073],
              [-114.049603, 38.333524],
              [-114.049581, 38.329896],
              [-114.049559, 38.326267],
              [-114.049546, 38.322638],
              [-114.049549, 38.320188],
              [-114.049556, 38.318663],
              [-114.049562, 38.315597],
              [-114.049573, 38.304719],
              [-114.049574, 38.303435],
              [-114.049519, 38.290039],
              [-114.049508, 38.287211],
              [-114.049474, 38.281797],
              [-114.049474, 38.281774],
              [-114.049471, 38.280957],
              [-114.049455, 38.276126],
              [-114.049443, 38.273022],
              [-114.049422, 38.271715],
              [-114.049363, 38.268343],
              [-114.049325, 38.26606],
              [-114.049347, 38.265654],
              [-114.049396, 38.264721],
              [-114.049586, 38.261058],
              [-114.049681, 38.258939],
              [-114.049905, 38.255396],
              [-114.049906, 38.25537],
              [-114.050141, 38.249916],
              [-114.050131, 38.248429],
              [-114.050221, 38.244261],
              [-114.050346, 38.237104],
              [-114.050299, 38.231016],
              [-114.050291, 38.229283],
              [-114.050291, 38.229233],
              [-114.050275, 38.226416],
              [-114.050248, 38.222279],
              [-114.050149, 38.212789],
              [-114.050085, 38.205005],
              [-114.050091, 38.200741],
              [-114.050076, 38.195089],
              [-114.050068, 38.192284],
              [-114.050061, 38.188663],
              [-114.050056, 38.185377],
              [-114.050053, 38.185042],
              [-114.050052, 38.18471],
              [-114.05005, 38.183831],
              [-114.050029, 38.173978],
              [-114.050022, 38.170542],
              [-114.050002, 38.163283],
              [-114.049989, 38.159657],
              [-114.049988, 38.1594],
              [-114.049976, 38.156031],
              [-114.049971, 38.154433],
              [-114.04997, 38.154365],
              [-114.049964, 38.152398],
              [-114.049962, 38.151916],
              [-114.049975, 38.14924],
              [-114.049978, 38.148763],
              [-114.049979, 38.148598],
              [-114.049992, 38.145845],
              [-114.049998, 38.142687],
              [-114.050013, 38.141539],
              [-114.050014, 38.141407],
              [-114.050019, 38.140459],
              [-114.05002, 38.140158],
              [-114.05002, 38.140091],
              [-114.050022, 38.139623],
              [-114.050023, 38.139451],
              [-114.050024, 38.139343],
              [-114.050028, 38.138449],
              [-114.050032, 38.137716],
              [-114.050032, 38.137606],
              [-114.050033, 38.137459],
              [-114.050034, 38.137051],
              [-114.050038, 38.136852],
              [-114.050036, 38.136569],
              [-114.050062, 38.12961],
              [-114.050063, 38.129453],
              [-114.050063, 38.129427],
              [-114.050063, 38.1294],
              [-114.050063, 38.129373],
              [-114.050063, 38.129356],
              [-114.050063, 38.129319],
              [-114.050076, 38.127673],
              [-114.050096, 38.125374],
              [-114.0501, 38.124937],
              [-114.050103, 38.123983],
              [-114.050119, 38.11981],
              [-114.050125, 38.11842],
              [-114.050125, 38.118407],
              [-114.050122, 38.117597],
              [-114.050113, 38.115128],
              [-114.050111, 38.114306],
              [-114.050111, 38.114279],
              [-114.050107, 38.112327],
              [-114.050096, 38.10639],
              [-114.050093, 38.104412],
              [-114.050088, 38.104238],
              [-114.050084, 38.103746],
              [-114.050081, 38.103591],
              [-114.05008, 38.103112],
              [-114.050065, 38.100845],
              [-114.050061, 38.100214],
              [-114.050043, 38.097528],
              [-114.050064, 38.094673],
              [-114.050091, 38.092554],
              [-114.050118, 38.090421],
              [-114.050125, 38.089861],
              [-114.050138, 38.088811],
              [-114.05014, 38.08863],
              [-114.050143, 38.088421],
              [-114.050153, 38.087692],
              [-114.050153, 38.087618],
              [-114.05016, 38.087093],
              [-114.050168, 38.086494],
              [-114.050168, 38.086396],
              [-114.050176, 38.085766],
              [-114.050179, 38.08559],
              [-114.050179, 38.085539],
              [-114.050181, 38.08534],
              [-114.050182, 38.085315],
              [-114.050184, 38.085149],
              [-114.050186, 38.084972],
              [-114.05019, 38.084767],
              [-114.05019, 38.084703],
              [-114.050196, 38.084009],
              [-114.050199, 38.083936],
              [-114.050199, 38.083916],
              [-114.0502, 38.083866],
              [-114.050201, 38.083787],
              [-114.050206, 38.083383],
              [-114.050215, 38.082746],
              [-114.050217, 38.082507],
              [-114.050229, 38.081414],
              [-114.050241, 38.080371],
              [-114.050257, 38.078906],
              [-114.05026, 38.078601],
              [-114.050282, 38.076661],
              [-114.050291, 38.075963],
              [-114.050297, 38.075289],
              [-114.050306, 38.074423],
              [-114.050312, 38.073928],
              [-114.050335, 38.071873],
              [-114.05035, 38.070449],
              [-114.05038, 38.0673],
              [-114.050398, 38.063735],
              [-114.050408, 38.061659],
              [-114.050411, 38.06102],
              [-114.050414, 38.060527],
              [-114.050419, 38.059982],
              [-114.05042, 38.059273],
              [-114.050425, 38.058266],
              [-114.050428, 38.057679],
              [-114.050426, 38.056957],
              [-114.050445, 38.05425],
              [-114.050428, 38.052155],
              [-114.050423, 38.051734],
              [-114.050418, 38.051266],
              [-114.050398, 38.049833],
              [-114.050376, 38.047254],
              [-114.050357, 38.045478],
              [-114.05033, 38.042956],
              [-114.050319, 38.041955],
              [-114.050293, 38.039821],
              [-114.050291, 38.03962],
              [-114.050294, 38.038962],
              [-114.050293, 38.038652],
              [-114.050294, 38.038584],
              [-114.050294, 38.038383],
              [-114.050295, 38.038284],
              [-114.050295, 38.038241],
              [-114.050295, 38.038177],
              [-114.050295, 38.038059],
              [-114.050295, 38.037747],
              [-114.050296, 38.037418],
              [-114.050296, 38.037127],
              [-114.050297, 38.036488],
              [-114.050297, 38.03647],
              [-114.050297, 38.035856],
              [-114.050297, 38.035834],
              [-114.050299, 38.034572],
              [-114.050301, 38.032236],
              [-114.050302, 38.031945],
              [-114.050304, 38.029492],
              [-114.050304, 38.02939],
              [-114.050309, 38.024485],
              [-114.050309, 38.024273],
              [-114.05031, 38.022883],
              [-114.050311, 38.02137],
              [-114.050313, 38.019908],
              [-114.050314, 38.018403],
              [-114.050315, 38.016878],
              [-114.050316, 38.015357],
              [-114.050317, 38.01392],
              [-114.050318, 38.01363],
              [-114.050319, 38.012992],
              [-114.050317, 38.01284],
              [-114.050317, 38.012507],
              [-114.050305, 38.008259],
              [-114.050294, 38.00411],
              [-114.050294, 38.004033],
              [-114.050294, 38.003925],
              [-114.050287, 38.001514],
              [-114.050287, 38.001416],
              [-114.050287, 38.001346],
              [-114.050287, 38.001321],
              [-114.050286, 38.001146],
              [-114.050286, 38.001129],
              [-114.050286, 38.001111],
              [-114.050284, 38.000397],
              [-114.050283, 37.999781],
              [-114.050276, 37.997507],
              [-114.050275, 37.996989],
              [-114.050274, 37.996688],
              [-114.050274, 37.996611],
              [-114.050268, 37.996581],
              [-114.050276, 37.996565],
              [-114.050276, 37.996472],
              [-114.050272, 37.992202],
              [-114.050267, 37.98559],
              [-114.050268, 37.979468],
              [-114.050269, 37.979381],
              [-114.05027, 37.979251],
              [-114.05027, 37.979183],
              [-114.050277, 37.978499],
              [-114.050278, 37.978358],
              [-114.050279, 37.978254],
              [-114.050279, 37.978199],
              [-114.05028, 37.978122],
              [-114.050281, 37.978037],
              [-114.050281, 37.978025],
              [-114.050281, 37.977965],
              [-114.050282, 37.977819],
              [-114.050283, 37.977736],
              [-114.050286, 37.977399],
              [-114.050291, 37.976863],
              [-114.050295, 37.976324],
              [-114.0503, 37.975764],
              [-114.050304, 37.975248],
              [-114.050309, 37.974719],
              [-114.050313, 37.974235],
              [-114.050322, 37.973217],
              [-114.050341, 37.971023],
              [-114.05037, 37.967549],
              [-114.050446, 37.96045],
              [-114.050453, 37.959881],
              [-114.050455, 37.959703],
              [-114.050459, 37.959278],
              [-114.050484, 37.957],
              [-114.050501, 37.954682],
              [-114.0505, 37.953341],
              [-114.050449, 37.942541],
              [-114.050451, 37.941802],
              [-114.050443, 37.941394],
              [-114.050288, 37.925182],
              [-114.05028, 37.924431],
              [-114.050273, 37.923695],
              [-114.050256, 37.912237],
              [-114.050255, 37.911465],
              [-114.050254, 37.910402],
              [-114.050187, 37.901238],
              [-114.050182, 37.901211],
              [-114.050178, 37.900031],
              [-114.050174, 37.899587],
              [-114.050171, 37.899175],
              [-114.050168, 37.898851],
              [-114.050137, 37.894806],
              [-114.050056, 37.892347],
              [-114.050033, 37.891685],
              [-114.050007, 37.890995],
              [-114.049887, 37.88775],
              [-114.049869, 37.88708],
              [-114.0498, 37.885387],
              [-114.049657, 37.881504],
              [-114.049656, 37.881385],
              [-114.04971, 37.879245],
              [-114.049821, 37.874967],
              [-114.049973, 37.867851],
              [-114.049945, 37.867471],
              [-114.049931, 37.858819],
              [-114.049931, 37.858799],
              [-114.049931, 37.858782],
              [-114.049931, 37.858769],
              [-114.049931, 37.858752],
              [-114.04993, 37.858723],
              [-114.04993, 37.858702],
              [-114.04993, 37.858678],
              [-114.04993, 37.858618],
              [-114.049929, 37.858553],
              [-114.049928, 37.858409],
              [-114.049927, 37.858262],
              [-114.049927, 37.858228],
              [-114.049926, 37.85802],
              [-114.049925, 37.857852],
              [-114.049924, 37.85765],
              [-114.049922, 37.857445],
              [-114.049921, 37.857241],
              [-114.04992, 37.857038],
              [-114.049918, 37.856824],
              [-114.049917, 37.856614],
              [-114.049914, 37.856178],
              [-114.049911, 37.85576],
              [-114.049909, 37.855342],
              [-114.049906, 37.854955],
              [-114.049901, 37.85411],
              [-114.049899, 37.853892],
              [-114.049895, 37.853201],
              [-114.049893, 37.852868],
              [-114.049892, 37.852653],
              [-114.04989, 37.85258],
              [-114.049879, 37.852119],
              [-114.049874, 37.851878],
              [-114.049871, 37.851746],
              [-114.04987, 37.851708],
              [-114.049851, 37.851047],
              [-114.049847, 37.850919],
              [-114.049844, 37.850824],
              [-114.049813, 37.849762],
              [-114.049809, 37.849642],
              [-114.049805, 37.849468],
              [-114.049795, 37.849163],
              [-114.049786, 37.848834],
              [-114.049776, 37.848494],
              [-114.04977, 37.848288],
              [-114.049764, 37.848109],
              [-114.049764, 37.848077],
              [-114.049755, 37.847792],
              [-114.049737, 37.84716],
              [-114.049685, 37.845371],
              [-114.049615, 37.842999],
              [-114.049543, 37.840537],
              [-114.04951, 37.83939],
              [-114.049484, 37.838484],
              [-114.049469, 37.838363],
              [-114.049472, 37.838112],
              [-114.04947, 37.838036],
              [-114.049468, 37.837954],
              [-114.049474, 37.837368],
              [-114.04948, 37.836798],
              [-114.049481, 37.836743],
              [-114.049481, 37.836693],
              [-114.049482, 37.836614],
              [-114.049484, 37.836443],
              [-114.049487, 37.836131],
              [-114.04949, 37.835822],
              [-114.049494, 37.835439],
              [-114.049496, 37.835148],
              [-114.049501, 37.834656],
              [-114.049508, 37.833962],
              [-114.049509, 37.833941],
              [-114.049509, 37.833915],
              [-114.049514, 37.833408],
              [-114.04952, 37.832787],
              [-114.049544, 37.830378],
              [-114.049545, 37.83026],
              [-114.049546, 37.830159],
              [-114.049549, 37.829989],
              [-114.049549, 37.829906],
              [-114.049549, 37.829883],
              [-114.049552, 37.829763],
              [-114.049553, 37.829503],
              [-114.049559, 37.828818],
              [-114.049577, 37.82706],
              [-114.049579, 37.826858],
              [-114.049584, 37.826383],
              [-114.049587, 37.82613],
              [-114.049589, 37.825949],
              [-114.04959, 37.825801],
              [-114.04959, 37.825692],
              [-114.04959, 37.825565],
              [-114.049595, 37.825285],
              [-114.049595, 37.825082],
              [-114.049597, 37.824952],
              [-114.049611, 37.823691],
              [-114.049612, 37.82364],
              [-114.049612, 37.823589],
              [-114.049613, 37.823541],
              [-114.049596, 37.82336],
              [-114.04956, 37.822968],
              [-114.049501, 37.822334],
              [-114.0493, 37.820174],
              [-114.049234, 37.819463],
              [-114.049192, 37.818981],
              [-114.049144, 37.818497],
              [-114.049117, 37.818207],
              [-114.049088, 37.817885],
              [-114.049052, 37.8175],
              [-114.049003, 37.816925],
              [-114.048923, 37.816114],
              [-114.0489, 37.815868],
              [-114.048869, 37.815535],
              [-114.048761, 37.814374],
              [-114.048388, 37.810357],
              [-114.048342, 37.809861],
              [-114.04832, 37.809623],
              [-114.048302, 37.809424],
              [-114.048304, 37.809385],
              [-114.048305, 37.809371],
              [-114.048307, 37.809334],
              [-114.048317, 37.809155],
              [-114.048345, 37.808681],
              [-114.048432, 37.807184],
              [-114.048445, 37.806967],
              [-114.048459, 37.806725],
              [-114.048581, 37.804623],
              [-114.048589, 37.804486],
              [-114.048611, 37.804106],
              [-114.048643, 37.803577],
              [-114.04866, 37.803312],
              [-114.048707, 37.802469],
              [-114.048816, 37.800584],
              [-114.048819, 37.80054],
              [-114.048827, 37.800408],
              [-114.048834, 37.80029],
              [-114.048856, 37.799921],
              [-114.048874, 37.799597],
              [-114.048966, 37.798021],
              [-114.048985, 37.797692],
              [-114.049011, 37.797244],
              [-114.049096, 37.795771],
              [-114.049105, 37.795631],
              [-114.04912, 37.795371],
              [-114.049142, 37.795],
              [-114.049144, 37.794959],
              [-114.049145, 37.794949],
              [-114.049147, 37.794908],
              [-114.049147, 37.794873],
              [-114.049148, 37.794782],
              [-114.049149, 37.794669],
              [-114.04915, 37.794553],
              [-114.049153, 37.794162],
              [-114.049159, 37.793387],
              [-114.049164, 37.792658],
              [-114.049171, 37.791866],
              [-114.049176, 37.791194],
              [-114.049176, 37.791163],
              [-114.049181, 37.790497],
              [-114.049206, 37.78736],
              [-114.04923, 37.784326],
              [-114.049233, 37.783973],
              [-114.049238, 37.782951],
              [-114.049246, 37.782505],
              [-114.049248, 37.782033],
              [-114.049252, 37.781473],
              [-114.049258, 37.780643],
              [-114.049265, 37.780405],
              [-114.04929, 37.779789],
              [-114.049295, 37.779711],
              [-114.049298, 37.779617],
              [-114.0493, 37.779548],
              [-114.049308, 37.779369],
              [-114.049337, 37.778686],
              [-114.049347, 37.778534],
              [-114.049421, 37.776673],
              [-114.049498, 37.774817],
              [-114.049554, 37.773558],
              [-114.049559, 37.773361],
              [-114.049563, 37.773264],
              [-114.049569, 37.773145],
              [-114.049599, 37.772507],
              [-114.049719, 37.769549],
              [-114.049861, 37.766142],
              [-114.049914, 37.765725],
              [-114.049919, 37.765586],
              [-114.050092, 37.76423],
              [-114.050615, 37.760163],
              [-114.050789, 37.758808],
              [-114.050859, 37.758216],
              [-114.051071, 37.75644],
              [-114.051142, 37.755849],
              [-114.051143, 37.755836],
              [-114.051147, 37.755798],
              [-114.051149, 37.755786],
              [-114.051153, 37.755747],
              [-114.051167, 37.755634],
              [-114.051172, 37.755596],
              [-114.051438, 37.753314],
              [-114.051613, 37.752016],
              [-114.051651, 37.75165],
              [-114.051654, 37.751498],
              [-114.051655, 37.751053],
              [-114.05166, 37.748924],
              [-114.051675, 37.746755],
              [-114.051666, 37.746286],
              [-114.051666, 37.746185],
              [-114.051666, 37.746148],
              [-114.051667, 37.746085],
              [-114.051671, 37.744438],
              [-114.051678, 37.742073],
              [-114.051677, 37.741891],
              [-114.051679, 37.741684],
              [-114.051679, 37.741251],
              [-114.051679, 37.741148],
              [-114.051679, 37.740986],
              [-114.05168, 37.740798],
              [-114.05168, 37.740663],
              [-114.05168, 37.740639],
              [-114.051681, 37.740524],
              [-114.051681, 37.740473],
              [-114.051681, 37.740412],
              [-114.051681, 37.740385],
              [-114.051681, 37.740339],
              [-114.051681, 37.740326],
              [-114.051681, 37.740315],
              [-114.051681, 37.740297],
              [-114.051681, 37.740284],
              [-114.051681, 37.740272],
              [-114.051681, 37.74026],
              [-114.051681, 37.740249],
              [-114.051681, 37.740215],
              [-114.051681, 37.740203],
              [-114.051682, 37.74015],
              [-114.051682, 37.740122],
              [-114.051682, 37.740025],
              [-114.051682, 37.739955],
              [-114.051682, 37.739924],
              [-114.051683, 37.739753],
              [-114.051683, 37.739616],
              [-114.051683, 37.73955],
              [-114.051683, 37.739532],
              [-114.051684, 37.73912],
              [-114.051684, 37.739082],
              [-114.051684, 37.739063],
              [-114.051684, 37.738928],
              [-114.051685, 37.73879],
              [-114.051685, 37.738779],
              [-114.051685, 37.738647],
              [-114.051685, 37.738546],
              [-114.051685, 37.738494],
              [-114.051687, 37.737525],
              [-114.051687, 37.737396],
              [-114.051688, 37.737176],
              [-114.051689, 37.737079],
              [-114.051691, 37.736782],
              [-114.051692, 37.736533],
              [-114.051692, 37.736486],
              [-114.051693, 37.736363],
              [-114.051695, 37.73594],
              [-114.051704, 37.734488],
              [-114.051704, 37.73439],
              [-114.051707, 37.734023],
              [-114.051708, 37.733807],
              [-114.05171, 37.733625],
              [-114.051713, 37.73332],
              [-114.051715, 37.732512],
              [-114.051716, 37.7324],
              [-114.051719, 37.731815],
              [-114.051721, 37.731605],
              [-114.051723, 37.731239],
              [-114.051725, 37.731008],
              [-114.051728, 37.730348],
              [-114.051736, 37.728864],
              [-114.051738, 37.728616],
              [-114.051739, 37.728453],
              [-114.05174, 37.728181],
              [-114.051741, 37.728096],
              [-114.051741, 37.728067],
              [-114.051741, 37.728034],
              [-114.051741, 37.727957],
              [-114.051744, 37.727501],
              [-114.05175, 37.726556],
              [-114.05175, 37.726424],
              [-114.051762, 37.724483],
              [-114.051763, 37.724038],
              [-114.051765, 37.723923],
              [-114.05177, 37.722748],
              [-114.051775, 37.722122],
              [-114.051775, 37.722022],
              [-114.051776, 37.721916],
              [-114.051793, 37.718809],
              [-114.051814, 37.715162],
              [-114.051816, 37.714706],
              [-114.051821, 37.713914],
              [-114.051821, 37.713813],
              [-114.051822, 37.713563],
              [-114.051823, 37.713524],
              [-114.051823, 37.713431],
              [-114.051826, 37.712749],
              [-114.051837, 37.711027],
              [-114.051846, 37.709822],
              [-114.051847, 37.709241],
              [-114.051848, 37.709066],
              [-114.051849, 37.708959],
              [-114.05185, 37.708811],
              [-114.051855, 37.708758],
              [-114.051862, 37.707868],
              [-114.051863, 37.707425],
              [-114.051865, 37.706401],
              [-114.051897, 37.701585],
              [-114.0519, 37.701056],
              [-114.051907, 37.700599],
              [-114.051909, 37.700399],
              [-114.051926, 37.697205],
              [-114.051969, 37.689028],
              [-114.051972, 37.688442],
              [-114.051985, 37.685487],
              [-114.052003, 37.681408],
              [-114.052005, 37.680845],
              [-114.052004, 37.680777],
              [-114.052006, 37.68061],
              [-114.052007, 37.680439],
              [-114.052009, 37.679989],
              [-114.05201, 37.679604],
              [-114.052011, 37.679591],
              [-114.052003, 37.678345],
              [-114.052001, 37.678327],
              [-114.051998, 37.678144],
              [-114.051997, 37.678071],
              [-114.051996, 37.677931],
              [-114.051994, 37.677823],
              [-114.051993, 37.677728],
              [-114.051991, 37.67758],
              [-114.051989, 37.677447],
              [-114.051989, 37.677405],
              [-114.051977, 37.676476],
              [-114.05196, 37.67512],
              [-114.051907, 37.670884],
              [-114.051839, 37.664243],
              [-114.051849, 37.6642],
              [-114.052001, 37.656452],
              [-114.052009, 37.656038],
              [-114.052054, 37.654232],
              [-114.052109, 37.651233],
              [-114.052111, 37.650775],
              [-114.052128, 37.650035],
              [-114.052146, 37.647764],
              [-114.052148, 37.646753],
              [-114.052157, 37.645225],
              [-114.052163, 37.644255],
              [-114.052176, 37.64138],
              [-114.052193, 37.639411],
              [-114.052193, 37.638086],
              [-114.052205, 37.637341],
              [-114.052211, 37.63632],
              [-114.052228, 37.63395],
              [-114.052236, 37.632643],
              [-114.052242, 37.632309],
              [-114.052241, 37.6321],
              [-114.052242, 37.632005],
              [-114.052243, 37.631782],
              [-114.052282, 37.625867],
              [-114.052304, 37.618878],
              [-114.05227, 37.608274],
              [-114.05227, 37.607552],
              [-114.052266, 37.607299],
              [-114.052264, 37.607046],
              [-114.052299, 37.60603],
              [-114.052344, 37.604926],
              [-114.052346, 37.604894],
              [-114.05235, 37.60479],
              [-114.05235, 37.604776],
              [-114.052353, 37.60471],
              [-114.052362, 37.604497],
              [-114.052397, 37.603635],
              [-114.052414, 37.603263],
              [-114.052806, 37.593913],
              [-114.052867, 37.58827],
              [-114.052831, 37.585238],
              [-114.052824, 37.574252],
              [-114.052851, 37.569775],
              [-114.052878, 37.565238],
              [-114.052885, 37.560855],
              [-114.052885, 37.5561],
              [-114.052885, 37.554117],
              [-114.052885, 37.553137],
              [-114.052885, 37.549262],
              [-114.052855, 37.541767],
              [-114.052833, 37.538396],
              [-114.052835, 37.53701],
              [-114.052823, 37.534437],
              [-114.05283, 37.532328],
              [-114.052818, 37.531031],
              [-114.05269, 37.518005],
              [-114.052729, 37.517525],
              [-114.052695, 37.516264],
              [-114.052685, 37.51584],
              [-114.052682, 37.509312],
              [-114.052685, 37.502513],
              [-114.052636, 37.500561],
              [-114.052635, 37.499974],
              [-114.052656, 37.497468],
              [-114.052701, 37.492014],
              [-114.052662, 37.489456],
              [-114.052662, 37.489347],
              [-114.052662, 37.482886],
              [-114.052749, 37.480556],
              [-114.052756, 37.479922],
              [-114.052782, 37.47361],
              [-114.052794, 37.47234],
              [-114.052779, 37.47192],
              [-114.052773, 37.470474],
              [-114.05275, 37.469464],
              [-114.05269, 37.466892],
              [-114.052677, 37.466315],
              [-114.052676, 37.466205],
              [-114.05265, 37.464823],
              [-114.052635, 37.464071],
              [-114.052642, 37.462539],
              [-114.052654, 37.460865],
              [-114.052658, 37.460398],
              [-114.05267, 37.459563],
              [-114.052675, 37.458043],
              [-114.052678, 37.456053],
              [-114.052712, 37.45315],
              [-114.052737, 37.449742],
              [-114.05273, 37.449243],
              [-114.052721, 37.448669],
              [-114.052753, 37.447593],
              [-114.052813, 37.442905],
              [-114.052819, 37.439041],
              [-114.05283, 37.437276],
              [-114.052807, 37.434149],
              [-114.052546, 37.429345],
              [-114.052494, 37.427967],
              [-114.052416, 37.426214],
              [-114.0524, 37.425771],
              [-114.052266, 37.424664],
              [-114.052283, 37.424449],
              [-114.0522, 37.422905],
              [-114.052025, 37.419672],
              [-114.051945, 37.418218],
              [-114.051916, 37.416672],
              [-114.051913, 37.414478],
              [-114.051909, 37.411544],
              [-114.05191, 37.408948],
              [-114.05191, 37.408004],
              [-114.051901, 37.405447],
              [-114.051891, 37.399188],
              [-114.051882, 37.391821],
              [-114.051883, 37.391304],
              [-114.051885, 37.390787],
              [-114.05188, 37.390346],
              [-114.051877, 37.38852],
              [-114.051876, 37.38772],
              [-114.051878, 37.386326],
              [-114.051877, 37.386157],
              [-114.051872, 37.384457],
              [-114.051868, 37.381042],
              [-114.051859, 37.379832],
              [-114.051852, 37.377513],
              [-114.051849, 37.376548],
              [-114.051844, 37.374906],
              [-114.051832, 37.373249],
              [-114.051825, 37.372322],
              [-114.051838, 37.370904],
              [-114.051843, 37.370537],
              [-114.051838, 37.369294],
              [-114.051837, 37.368115],
              [-114.051848, 37.365455],
              [-114.051864, 37.361298],
              [-114.051862, 37.358595],
              [-114.051831, 37.35647],
              [-114.051818, 37.355531],
              [-114.051824, 37.354949],
              [-114.051835, 37.354249],
              [-114.051822, 37.352496],
              [-114.051821, 37.350871],
              [-114.05182, 37.349367],
              [-114.051817, 37.347662],
              [-114.051818, 37.347264],
              [-114.051816, 37.346874],
              [-114.051814, 37.345732],
              [-114.051813, 37.345041],
              [-114.051812, 37.34457],
              [-114.051812, 37.344439],
              [-114.051811, 37.343518],
              [-114.051797, 37.340517],
              [-114.051794, 37.340217],
              [-114.051803, 37.339875],
              [-114.051789, 37.33832],
              [-114.05177, 37.334287],
              [-114.051758, 37.331743],
              [-114.051754, 37.331284],
              [-114.051802, 37.32692],
              [-114.0518, 37.326634],
              [-114.051882, 37.320855],
              [-114.05189, 37.31995],
              [-114.051767, 37.312615],
              [-114.051722, 37.309438],
              [-114.051709, 37.309052],
              [-114.05168, 37.307289],
              [-114.051658, 37.305951],
              [-114.051655, 37.30482],
              [-114.051626, 37.303905],
              [-114.051656, 37.303146],
              [-114.051665, 37.302972],
              [-114.051715, 37.300592],
              [-114.051868, 37.296336],
              [-114.051873, 37.293737],
              [-114.051888, 37.292955],
              [-114.05196, 37.284484],
              [-114.051957, 37.284334],
              [-114.051953, 37.284143],
              [-114.051848, 37.276459],
              [-114.051833, 37.2754],
              [-114.051801, 37.273137],
              [-114.051801, 37.273119],
              [-114.051783, 37.272055],
              [-114.051676, 37.257753],
              [-114.051741, 37.250035],
              [-114.051704, 37.23392],
              [-114.051705, 37.229756],
              [-114.051712, 37.208151],
              [-114.051615, 37.184152],
              [-114.051628, 37.180114],
              [-114.051674, 37.172467],
              [-114.051655, 37.171964],
              [-114.051852, 37.165449],
              [-114.051852, 37.165433],
              [-114.051864, 37.165074],
              [-114.051889, 37.162478],
              [-114.051889, 37.162463],
              [-114.051891, 37.16234],
              [-114.051922, 37.160556],
              [-114.051884, 37.153462],
              [-114.051908, 37.14776],
              [-114.051891, 37.141797],
              [-114.051889, 37.140589],
              [-114.051887, 37.139895],
              [-114.051895, 37.136925],
              [-114.05187, 37.134743],
              [-114.051866, 37.134387],
              [-114.051878, 37.130306],
              [-114.051912, 37.126491],
              [-114.051895, 37.124947],
              [-114.051914, 37.122766],
              [-114.052423, 37.1179],
              [-114.052541, 37.112881],
              [-114.052407, 37.104242],
              [-114.052327, 37.099116],
              [-114.052317, 37.098469],
              [-114.052297, 37.098243],
              [-114.052238, 37.097587],
              [-114.0522, 37.097166],
              [-114.051969, 37.093409],
              [-114.051816, 37.091821],
              [-114.05179, 37.091772],
              [-114.051687, 37.091432],
              [-114.051692, 37.091328],
              [-114.051694, 37.091294],
              [-114.051625, 37.090959],
              [-114.051536, 37.090729],
              [-114.051536, 37.090628],
              [-114.051536, 37.090575],
              [-114.051571, 37.090449],
              [-114.051578, 37.090328],
              [-114.051523, 37.090108],
              [-114.0514, 37.089834],
              [-114.051399, 37.089713],
              [-114.051469, 37.089586],
              [-114.051475, 37.089576],
              [-114.051489, 37.089422],
              [-114.051441, 37.089224],
              [-114.05142, 37.089087],
              [-114.051448, 37.088867],
              [-114.051448, 37.088565],
              [-114.051633, 37.088591],
              [-114.051502, 37.085889],
              [-114.051377, 37.08485],
              [-114.051344, 37.084106],
              [-114.05118, 37.080443],
              [-114.050881, 37.074976],
              [-114.050834, 37.071577],
              [-114.050756, 37.066019],
              [-114.050674, 37.060035],
              [-114.050485, 37.046246],
              [-114.050327, 37.035002],
              [-114.050326, 37.034916],
              [-114.050262, 37.030302],
              [-114.05016, 37.023164],
              [-114.05015, 37.022228],
              [-114.050072, 37.014095],
              [-114.050055, 37.001716],
              [-114.050052, 37.000191],
              [-114.050616, 37.000167],
              [-114.050585, 36.999103],
              [-114.05049, 36.992462],
              [-114.050205, 36.972539],
              [-114.05011, 36.965899],
              [-114.049998, 36.958022],
              [-114.049995, 36.957769],
              [-114.050016, 36.95596],
              [-114.050065, 36.951867],
              [-114.050079, 36.950649],
              [-114.050113, 36.947621],
              [-114.050161, 36.940348],
              [-114.050175, 36.939491],
              [-114.05018, 36.938204],
              [-114.050184, 36.937401],
              [-114.050218, 36.933092],
              [-114.050223, 36.932513],
              [-114.050401, 36.906345],
              [-114.050402, 36.90633],
              [-114.050423, 36.903154],
              [-114.050502, 36.895232],
              [-114.050464, 36.89262],
              [-114.050411, 36.88656],
              [-114.050402, 36.885467],
              [-114.050394, 36.884653],
              [-114.050373, 36.882214],
              [-114.050366, 36.881401],
              [-114.050359, 36.880709],
              [-114.050341, 36.878636],
              [-114.050335, 36.877945],
              [-114.050309, 36.87498],
              [-114.050314, 36.870984],
              [-114.050343, 36.850101],
              [-114.050354, 36.843141],
              [-114.050302, 36.839998],
              [-114.050147, 36.830569],
              [-114.050112, 36.828378],
              [-114.050114, 36.827427],
              [-114.050115, 36.826914],
              [-114.050118, 36.825375],
              [-114.05012, 36.824977],
              [-114.05012, 36.824862],
              [-114.05012, 36.824761],
              [-114.05012, 36.824716],
              [-114.05012, 36.824279],
              [-114.05012, 36.824224],
              [-114.050122, 36.824135],
              [-114.050122, 36.823926],
              [-114.050123, 36.823564],
              [-114.050124, 36.823389],
              [-114.050123, 36.823299],
              [-114.050124, 36.823091],
              [-114.050125, 36.822432],
              [-114.050129, 36.820457],
              [-114.050131, 36.819799],
              [-114.050131, 36.819637],
              [-114.050132, 36.819152],
              [-114.050133, 36.818991],
              [-114.050133, 36.818769],
              [-114.050134, 36.818102],
              [-114.050135, 36.817881],
              [-114.050135, 36.8178],
              [-114.050137, 36.817607],
              [-114.050137, 36.817562],
              [-114.050137, 36.817476],
              [-114.050137, 36.817381],
              [-114.050137, 36.817095],
              [-114.050138, 36.817001],
              [-114.05014, 36.816963],
              [-114.050147, 36.816849],
              [-114.05015, 36.816812],
              [-114.050148, 36.81658],
              [-114.050142, 36.815884],
              [-114.050141, 36.815652],
              [-114.050145, 36.813919],
              [-114.050145, 36.813891],
              [-114.050111, 36.809901],
              [-114.050108, 36.809637],
              [-114.050106, 36.809428],
              [-114.050087, 36.807811],
              [-114.050067, 36.806138],
              [-114.050044, 36.804144],
              [-114.05001, 36.80112],
              [-114.049994, 36.799682],
              [-114.049992, 36.799448],
              [-114.049991, 36.799435],
              [-114.049991, 36.799399],
              [-114.049992, 36.799388],
              [-114.049991, 36.799364],
              [-114.04999, 36.799324],
              [-114.04999, 36.799308],
              [-114.049989, 36.799285],
              [-114.049989, 36.799264],
              [-114.049989, 36.799244],
              [-114.049989, 36.799216],
              [-114.049989, 36.799194],
              [-114.049988, 36.799182],
              [-114.049988, 36.799147],
              [-114.049988, 36.799136],
              [-114.049984, 36.798802],
              [-114.049983, 36.798793],
              [-114.049972, 36.797767],
              [-114.049969, 36.797453],
              [-114.049968, 36.797426],
              [-114.049957, 36.796427],
              [-114.049935, 36.792472],
              [-114.049911, 36.787804],
              [-114.049893, 36.784428],
              [-114.049879, 36.781909],
              [-114.049902, 36.780388],
              [-114.049944, 36.777612],
              [-114.050004, 36.773731],
              [-114.05002, 36.77266],
              [-114.050087, 36.768315],
              [-114.050245, 36.758177],
              [-114.050289, 36.75528],
              [-114.05029, 36.755251],
              [-114.050327, 36.752899],
              [-114.050324, 36.752762],
              [-114.050305, 36.752037],
              [-114.050278, 36.750936],
              [-114.050262, 36.750319],
              [-114.050217, 36.74847],
              [-114.050202, 36.747854],
              [-114.050169, 36.746622],
              [-114.050072, 36.742928],
              [-114.05004, 36.741697],
              [-114.049973, 36.738672],
              [-114.049989, 36.733942],
              [-114.050024, 36.724223],
              [-114.050018, 36.723272],
              [-114.049941, 36.710677],
              [-114.049935, 36.709521],
              [-114.04975, 36.702925],
              [-114.049635, 36.698786],
              [-114.049528, 36.69491],
              [-114.049342, 36.686369],
              [-114.049253, 36.68223],
              [-114.049209, 36.680295],
              [-114.049142, 36.677694],
              [-114.048795, 36.664086],
              [-114.04868, 36.659551],
              [-114.048656, 36.658591],
              [-114.048584, 36.655713],
              [-114.048561, 36.654754],
              [-114.048383, 36.647803],
              [-114.048112, 36.637216],
              [-114.04798, 36.631208],
              [-114.047886, 36.626953],
              [-114.047793, 36.622732],
              [-114.04774, 36.620005],
              [-114.047695, 36.617856],
              [-114.047568, 36.611415],
              [-114.047526, 36.609268],
              [-114.047518, 36.608923],
              [-114.047494, 36.607891],
              [-114.047488, 36.607626],
              [-114.047486, 36.607548],
              [-114.047363, 36.603215],
              [-114.047281, 36.600322],
              [-114.047188, 36.597053],
              [-114.046994, 36.590219],
              [-114.046872, 36.585887],
              [-114.046829, 36.584214],
              [-114.046708, 36.57938],
              [-114.046703, 36.579195],
              [-114.046673, 36.578038],
              [-114.046659, 36.577523],
              [-114.04665, 36.577193],
              [-114.046625, 36.576204],
              [-114.046617, 36.575875],
              [-114.046553, 36.573473],
              [-114.046363, 36.566268],
              [-114.04632, 36.564615],
              [-114.046303, 36.563867],
              [-114.046266, 36.562239],
              [-114.046158, 36.557359],
              [-114.046123, 36.555732],
              [-114.046044, 36.552143],
              [-114.045898, 36.545416],
              [-114.045809, 36.541382],
              [-114.045732, 36.537796],
              [-114.045725, 36.537551],
              [-114.045707, 36.536819],
              [-114.045703, 36.536648],
              [-114.045701, 36.536575],
              [-114.045691, 36.53497],
              [-114.045678, 36.533059],
              [-114.045677, 36.532838],
              [-114.045672, 36.53082],
              [-114.045654, 36.523679],
              [-114.04565, 36.522514],
              [-114.045647, 36.521095],
              [-114.045568, 36.519001],
              [-114.04555, 36.518568],
              [-114.045535, 36.518168],
              [-114.045502, 36.517269],
              [-114.045487, 36.516837],
              [-114.04534, 36.513157],
              [-114.045279, 36.511616],
              [-114.045072, 36.506381],
              [-114.045, 36.502124],
              [-114.044949, 36.499107],
              [-114.044939, 36.498447],
              [-114.044897, 36.496078],
              [-114.044816, 36.491343],
              [-114.04479, 36.491013],
              [-114.04463, 36.488983],
              [-114.044446, 36.486626],
              [-114.04425, 36.48416],
              [-114.044036, 36.481464],
              [-114.043667, 36.476819],
              [-114.043662, 36.476762],
              [-114.043614, 36.476122],
              [-114.04348, 36.474296],
              [-114.043133, 36.469716],
              [-114.043086, 36.467101],
              [-114.043074, 36.466444],
              [-114.043004, 36.462462],
              [-114.042861, 36.449494],
              [-114.042843, 36.448175],
              [-114.042851, 36.44803],
              [-114.042855, 36.447919],
              [-114.042845, 36.445483],
              [-114.042842, 36.444702],
              [-114.042819, 36.438277],
              [-114.04303, 36.390187],
              [-114.043042, 36.387429],
              [-114.043036, 36.386383],
              [-114.043034, 36.38587],
              [-114.043135, 36.380478],
              [-114.043196, 36.379405],
              [-114.04338, 36.376186],
              [-114.043418, 36.375528],
              [-114.043435, 36.375113],
              [-114.04346, 36.374448],
              [-114.043538, 36.372457],
              [-114.043568, 36.371797],
              [-114.043667, 36.369283],
              [-114.043868, 36.36428],
              [-114.043948, 36.362345],
              [-114.043999, 36.361082],
              [-114.044702, 36.346298],
              [-114.044726, 36.34188],
              [-114.044726, 36.341743],
              [-114.044766, 36.334225],
              [-114.044768, 36.333717],
              [-114.044774, 36.332196],
              [-114.044776, 36.331969],
              [-114.044763, 36.33169],
              [-114.044676, 36.32997],
              [-114.044628, 36.329023],
              [-114.044223, 36.321023],
              [-114.044089, 36.318357],
              [-114.044079, 36.318187],
              [-114.044051, 36.317628],
              [-114.044054, 36.317492],
              [-114.044108, 36.3154],
              [-114.04416, 36.314251],
              [-114.044345, 36.310234],
              [-114.045033, 36.30305],
              [-114.045072, 36.301966],
              [-114.045141, 36.300113],
              [-114.045226, 36.297862],
              [-114.045238, 36.297508],
              [-114.045277, 36.29645],
              [-114.045291, 36.296098],
              [-114.045295, 36.295956],
              [-114.045304, 36.295728],
              [-114.04531, 36.295538],
              [-114.045317, 36.2954],
              [-114.045331, 36.295031],
              [-114.045342, 36.294722],
              [-114.045416, 36.292693],
              [-114.045442, 36.292019],
              [-114.045453, 36.291713],
              [-114.045504, 36.290326],
              [-114.045559, 36.288837],
              [-114.045553, 36.286651],
              [-114.045548, 36.28525],
              [-114.045544, 36.283559],
              [-114.045543, 36.283199],
              [-114.045536, 36.280989],
              [-114.045518, 36.27439],
              [-114.045459, 36.273284],
              [-114.045392, 36.271991],
              [-114.045325, 36.270719],
              [-114.045115, 36.266681],
              [-114.045062, 36.265655],
              [-114.044744, 36.259522],
              [-114.044696, 36.258744],
              [-114.044457, 36.254883],
              [-114.044437, 36.254574],
              [-114.044197, 36.250705],
              [-114.044185, 36.250541],
              [-114.04414, 36.24983],
              [-114.044084, 36.248915],
              [-114.044024, 36.247952],
              [-114.044008, 36.247698],
              [-114.043965, 36.246989],
              [-114.043953, 36.246795],
              [-114.043917, 36.246215],
              [-114.043905, 36.246022],
              [-114.043849, 36.245114],
              [-114.043864, 36.243725],
              [-114.04387, 36.243075],
              [-114.043879, 36.2423],
              [-114.043927, 36.23787],
              [-114.043939, 36.236803],
              [-114.043959, 36.23486],
              [-114.043965, 36.234231],
              [-114.043998, 36.231284],
              [-114.044001, 36.23099],
              [-114.044007, 36.229682],
              [-114.044008, 36.229345],
              [-114.044034, 36.223528],
              [-114.044043, 36.22159],
              [-114.044043, 36.221471],
              [-114.044042, 36.219312],
              [-114.044042, 36.218868],
              [-114.044042, 36.217157],
              [-114.044041, 36.214635],
              [-114.04404, 36.212478],
              [-114.04404, 36.210201],
              [-114.044028, 36.209675],
              [-114.043992, 36.208099],
              [-114.043981, 36.207574],
              [-114.043981, 36.205387],
              [-114.043983, 36.202273],
              [-114.043983, 36.201366],
              [-114.043975, 36.198832],
              [-114.04397, 36.196647],
              [-114.044013, 36.196603],
              [-114.043999, 36.195952],
              [-114.043984, 36.19523],
              [-114.043958, 36.194],
              [-114.043955, 36.193856],
              [-114.043954, 36.193796],
              [-114.043952, 36.193737],
              [-114.043948, 36.193544],
              [-114.043944, 36.19335],
              [-114.044132, 36.193308],
              [-114.04432, 36.193267],
              [-114.045051, 36.193106],
              [-114.046902, 36.1927],
              [-114.048375, 36.192376],
              [-114.049484, 36.192134],
              [-114.052743, 36.190919],
              [-114.053345, 36.190471],
              [-114.0536, 36.190281],
              [-114.056345, 36.188988],
              [-114.058662, 36.187835],
              [-114.061305, 36.185054],
              [-114.062361, 36.184001],
              [-114.063192, 36.18308],
              [-114.063255, 36.18301],
              [-114.064024, 36.18216],
              [-114.064424, 36.181718],
              [-114.064823, 36.181275],
              [-114.066798, 36.179087],
              [-114.068821, 36.175683],
              [-114.070002, 36.173696],
              [-114.071184, 36.171709],
              [-114.0713, 36.171512],
              [-114.071417, 36.171316],
              [-114.071652, 36.170921],
              [-114.072822, 36.168458],
              [-114.075641, 36.162523],
              [-114.077779, 36.159479],
              [-114.078832, 36.157434],
              [-114.07945, 36.154625],
              [-114.081234, 36.150208],
              [-114.087899, 36.142923],
              [-114.089249, 36.140382],
              [-114.089279, 36.140326],
              [-114.091701, 36.137303],
              [-114.092366, 36.135331],
              [-114.092753, 36.132356],
              [-114.093488, 36.13028],
              [-114.094164, 36.12837],
              [-114.094841, 36.126459],
              [-114.095101, 36.125725],
              [-114.095361, 36.124991],
              [-114.095681, 36.124202],
              [-114.096994, 36.120823],
              [-114.097707, 36.120213],
              [-114.097773, 36.12019],
              [-114.099217, 36.119685],
              [-114.100433, 36.119359],
              [-114.104177, 36.119381],
              [-114.107419, 36.119401],
              [-114.108381, 36.119154],
              [-114.116061, 36.115471],
              [-114.117025, 36.11485],
              [-114.118497, 36.1139],
              [-114.118698, 36.113641],
              [-114.118932, 36.11334],
              [-114.119165, 36.113039],
              [-114.120865, 36.11085],
              [-114.121065, 36.110214],
              [-114.121174, 36.109866],
              [-114.121284, 36.109519],
              [-114.12167, 36.108294],
              [-114.121779, 36.105699],
              [-114.121291, 36.104513],
              [-114.121033, 36.103885],
              [-114.119329, 36.10193],
              [-114.117978, 36.101277],
              [-114.11707, 36.101177],
              [-114.115208, 36.099878],
              [-114.1139, 36.098329],
              [-114.112452, 36.096615],
              [-114.1119, 36.095845],
              [-114.111998, 36.09491],
              [-114.112297, 36.09405],
              [-114.112991, 36.093276],
              [-114.113905, 36.092327],
              [-114.115311, 36.090866],
              [-114.115661, 36.090474],
              [-114.11635, 36.089704],
              [-114.117038, 36.088935],
              [-114.118324, 36.087489],
              [-114.119648, 36.085822],
              [-114.121186, 36.082755],
              [-114.124019, 36.075563],
              [-114.125845, 36.072999],
              [-114.125891, 36.072935],
              [-114.127871, 36.070662],
              [-114.129768, 36.068484],
              [-114.132705, 36.062953],
              [-114.133389, 36.061665],
              [-114.1352, 36.056946],
              [-114.136206, 36.053232],
              [-114.135927, 36.050358],
              [-114.135199, 36.04716],
              [-114.134824, 36.045343],
              [-114.134841, 36.043873],
              [-114.135721, 36.041238],
              [-114.136181, 36.04033],
              [-114.137112, 36.038491],
              [-114.13826, 36.03719],
              [-114.14048, 36.036218],
              [-114.143153, 36.035295],
              [-114.144666, 36.034272],
              [-114.145672, 36.03297],
              [-114.145757, 36.03049],
              [-114.145637, 36.028559],
              [-114.145907, 36.027229],
              [-114.146468, 36.026624],
              [-114.146539, 36.026546],
              [-114.146989, 36.026061],
              [-114.148336, 36.025185],
              [-114.150225, 36.023515],
              [-114.150827, 36.023317],
              [-114.15139, 36.023133],
              [-114.1534, 36.02317],
              [-114.154696, 36.023776],
              [-114.157344, 36.024966],
              [-114.1596, 36.025727],
              [-114.161237, 36.026279],
              [-114.16311, 36.026618],
              [-114.164402, 36.026852],
              [-114.164425, 36.026853],
              [-114.166638, 36.026982],
              [-114.167118, 36.026954],
              [-114.170889, 36.026734],
              [-114.171937, 36.026674],
              [-114.173267, 36.026752],
              [-114.174683, 36.02667],
              [-114.176304, 36.026129],
              [-114.179397, 36.024337],
              [-114.179438, 36.024313],
              [-114.182752, 36.023274],
              [-114.18586, 36.022266],
              [-114.185978, 36.02222],
              [-114.190088, 36.020628],
              [-114.190731, 36.020282],
              [-114.191221, 36.020019],
              [-114.19291, 36.019584],
              [-114.194671, 36.019131],
              [-114.19671, 36.018607],
              [-114.198748, 36.018082],
              [-114.200066, 36.017743],
              [-114.200489, 36.017746],
              [-114.201227, 36.017751],
              [-114.204156, 36.016575],
              [-114.206052, 36.016634],
              [-114.207857, 36.016152],
              [-114.208232, 36.016053],
              [-114.210595, 36.015423],
              [-114.211932, 36.014834],
              [-114.213549, 36.014615],
              [-114.214679, 36.014806],
              [-114.216232, 36.014428],
              [-114.216609, 36.014336],
              [-114.218759, 36.014511],
              [-114.221453, 36.01416],
              [-114.22423, 36.013903],
              [-114.224798, 36.013699],
              [-114.226459, 36.014606],
              [-114.228015, 36.014731],
              [-114.22856, 36.014548],
              [-114.229493, 36.014235],
              [-114.230076, 36.013966],
              [-114.231854, 36.013147],
              [-114.233443, 36.012835],
              [-114.235164, 36.01299],
              [-114.235366, 36.013008],
              [-114.236892, 36.013247],
              [-114.237328, 36.01367],
              [-114.237398, 36.013738],
              [-114.237468, 36.013806],
              [-114.237572, 36.013908],
              [-114.237677, 36.014009],
              [-114.238154, 36.014473],
              [-114.239169, 36.01487],
              [-114.239949, 36.0151],
              [-114.240439, 36.015245],
              [-114.242139, 36.015187],
              [-114.243865, 36.015266],
              [-114.244794, 36.015823],
              [-114.246111, 36.017164],
              [-114.246159, 36.017193],
              [-114.248051, 36.018334],
              [-114.248419, 36.018556],
              [-114.250846, 36.01956],
              [-114.251633, 36.019886],
              [-114.259518, 36.024206],
              [-114.262388, 36.026107],
              [-114.26438, 36.027911],
              [-114.265001, 36.028848],
              [-114.265971, 36.03031],
              [-114.267446, 36.032845],
              [-114.268586, 36.035034],
              [-114.26922, 36.036807],
              [-114.268896, 36.04094],
              [-114.269548, 36.043769],
              [-114.270862, 36.045523],
              [-114.272299, 36.046289],
              [-114.273911, 36.046529],
              [-114.276153, 36.046083],
              [-114.278166, 36.045819],
              [-114.279637, 36.046103],
              [-114.284006, 36.048242],
              [-114.286006, 36.048657],
              [-114.287992, 36.04907],
              [-114.289507, 36.049829],
              [-114.290867, 36.050511],
              [-114.291201, 36.051063],
              [-114.291927, 36.052263],
              [-114.293435, 36.0545],
              [-114.295941, 36.056168],
              [-114.298593, 36.057263],
              [-114.299755, 36.057359],
              [-114.300971, 36.05746],
              [-114.308476, 36.056985],
              [-114.308624, 36.056976],
              [-114.311904, 36.057661],
              [-114.313591, 36.059048],
              [-114.314427, 36.060523],
              [-114.314362, 36.061501],
              [-114.314328, 36.062016],
              [-114.31387, 36.063356],
              [-114.3136, 36.064148],
              [-114.31242, 36.066117],
              [-114.310281, 36.067552],
              [-114.307485, 36.069672],
              [-114.306517, 36.070743],
              [-114.305853, 36.071478],
              [-114.304384, 36.074019],
              [-114.304171, 36.07558],
              [-114.304784, 36.076709],
              [-114.305393, 36.078183],
              [-114.306245, 36.080967],
              [-114.306681, 36.081921],
              [-114.306939, 36.082487],
              [-114.31244, 36.088151],
              [-114.313086, 36.088816],
              [-114.315159, 36.091469],
              [-114.316983, 36.093409],
              [-114.317249, 36.093592],
              [-114.317424, 36.093713],
              [-114.3176, 36.093834],
              [-114.319067, 36.094845],
              [-114.320866, 36.096463],
              [-114.322094, 36.098514],
              [-114.322518, 36.09957],
              [-114.322648, 36.099895],
              [-114.323458, 36.101186],
              [-114.324613, 36.101997],
              [-114.325539, 36.102989],
              [-114.325814, 36.103933],
              [-114.326996, 36.104904],
              [-114.328801, 36.105902],
              [-114.33101, 36.106052],
              [-114.333587, 36.106342],
              [-114.334632, 36.106784],
              [-114.335473, 36.107776],
              [-114.336284, 36.108998],
              [-114.337264, 36.110428],
              [-114.338815, 36.111309],
              [-114.34095, 36.113457],
              [-114.342601, 36.115878],
              [-114.345022, 36.118472],
              [-114.3451, 36.118556],
              [-114.348592, 36.121147],
              [-114.351915, 36.123299],
              [-114.354817, 36.125124],
              [-114.357276, 36.12666],
              [-114.358968, 36.127795],
              [-114.360626, 36.129412],
              [-114.364499, 36.134072],
              [-114.365529, 36.136306],
              [-114.367381, 36.13852],
              [-114.368551, 36.140892],
              [-114.370181, 36.142624],
              [-114.371736, 36.143206],
              [-114.373745, 36.143722],
              [-114.375278, 36.143592],
              [-114.375356, 36.143556],
              [-114.376645, 36.142956],
              [-114.378098, 36.14209],
              [-114.379976, 36.141388],
              [-114.381479, 36.141349],
              [-114.382365, 36.141556],
              [-114.388301, 36.142946],
              [-114.394328, 36.144561],
              [-114.398373, 36.145799],
              [-114.402281, 36.146507],
              [-114.40432, 36.146797],
              [-114.405624, 36.146983],
              [-114.40914, 36.147],
              [-114.411811, 36.14661],
              [-114.412491, 36.146511],
              [-114.415253, 36.145123],
              [-114.418193, 36.142771],
              [-114.420083, 36.140529],
              [-114.423114, 36.13735],
              [-114.425936, 36.135618],
              [-114.430893, 36.132655],
              [-114.435507, 36.130057],
              [-114.439521, 36.127962],
              [-114.443502, 36.125724],
              [-114.443736, 36.125593],
              [-114.445042, 36.125346],
              [-114.446202, 36.125673],
              [-114.447135, 36.126022],
              [-114.448795, 36.127752],
              [-114.451331, 36.129831],
              [-114.453798, 36.133586],
              [-114.45511, 36.136372],
              [-114.456487, 36.138032],
              [-114.458945, 36.139214],
              [-114.4626, 36.139644],
              [-114.465579, 36.139496],
              [-114.468674, 36.138889],
              [-114.478248, 36.132683],
              [-114.483827, 36.12972],
              [-114.487635, 36.128656],
              [-114.491836, 36.128031],
              [-114.498849, 36.126612],
              [-114.501798, 36.126556],
              [-114.504715, 36.127188],
              [-114.507201, 36.128484],
              [-114.508467, 36.129913],
              [-114.509022, 36.131616],
              [-114.50921, 36.133247],
              [-114.508407, 36.134576],
              [-114.507175, 36.13634],
              [-114.50515, 36.138078],
              [-114.503213, 36.139242],
              [-114.50108, 36.14006],
              [-114.500339, 36.1407],
              [-114.499992, 36.141594],
              [-114.500236, 36.143226],
              [-114.501049, 36.144516],
              [-114.508104, 36.149713],
              [-114.511218, 36.150576],
              [-114.51428, 36.150795],
              [-114.516864, 36.150277],
              [-114.519197, 36.149752],
              [-114.52621, 36.148177],
              [-114.52885, 36.147751],
              [-114.531091, 36.147644],
              [-114.532308, 36.14804],
              [-114.532924, 36.149282],
              [-114.534478, 36.15023],
              [-114.537562, 36.151413],
              [-114.539233, 36.151764],
              [-114.543232, 36.151871],
              [-114.54485, 36.151601],
              [-114.546533, 36.151133],
              [-114.546725, 36.15108],
              [-114.548742, 36.150697],
              [-114.550445, 36.15106],
              [-114.552254, 36.151445],
              [-114.553155, 36.151777],
              [-114.554461, 36.152257],
              [-114.555134, 36.152341],
              [-114.555373, 36.152371],
              [-114.555612, 36.152401],
              [-114.556162, 36.15247],
              [-114.556983, 36.152161],
              [-114.557612, 36.151924],
              [-114.561173, 36.150921],
              [-114.564709, 36.150824],
              [-114.569047, 36.151184],
              [-114.57109, 36.151099],
              [-114.572474, 36.150648],
              [-114.574301, 36.150053],
              [-114.57706, 36.148845],
              [-114.578828, 36.147175],
              [-114.579578, 36.1467],
              [-114.580707, 36.145987],
              [-114.583614, 36.145574],
              [-114.583716, 36.14556],
              [-114.584967, 36.145059],
              [-114.58764, 36.143897],
              [-114.589828, 36.143192],
              [-114.593035, 36.142674],
              [-114.594222, 36.142282],
              [-114.59442, 36.142216],
              [-114.594618, 36.142151],
              [-114.596474, 36.141537],
              [-114.597512, 36.140896],
              [-114.598067, 36.140554],
              [-114.604192, 36.135773],
              [-114.609288, 36.132229],
              [-114.60959, 36.132079],
              [-114.610639, 36.131558],
              [-114.611688, 36.131037],
              [-114.61324, 36.130266],
              [-114.615455, 36.129653],
              [-114.616901, 36.129795],
              [-114.618429, 36.130328],
              [-114.620605, 36.131759],
              [-114.622264, 36.134268],
              [-114.623837, 36.137144],
              [-114.627079, 36.140761],
              [-114.628462, 36.141822],
              [-114.630474, 36.142218],
              [-114.631414, 36.141348],
              [-114.632528, 36.139858],
              [-114.635809, 36.13617],
              [-114.636862, 36.135552],
              [-114.63828, 36.135442],
              [-114.640125, 36.135126],
              [-114.641098, 36.134392],
              [-114.641976, 36.13373],
              [-114.642385, 36.13354],
              [-114.645728, 36.131995],
              [-114.649057, 36.129845],
              [-114.6517, 36.128589],
              [-114.652904, 36.12783],
              [-114.655512, 36.126187],
              [-114.658131, 36.124127],
              [-114.658763, 36.123001],
              [-114.658846, 36.122852],
              [-114.65893, 36.122704],
              [-114.660448, 36.119999],
              [-114.661833, 36.118158],
              [-114.662144, 36.117742],
              [-114.664343, 36.1163],
              [-114.665716, 36.116098],
              [-114.669166, 36.115995],
              [-114.671867, 36.115964],
              [-114.672903, 36.11546],
              [-114.673055, 36.115378],
              [-114.673144, 36.115347],
              [-114.673866, 36.114897],
              [-114.674447, 36.114493],
              [-114.675106, 36.114111],
              [-114.675719, 36.113393],
              [-114.676629, 36.112437],
              [-114.677357, 36.111741],
              [-114.677841, 36.111247],
              [-114.678375, 36.110815],
              [-114.678991, 36.11044],
              [-114.679775, 36.109874],
              [-114.680512, 36.109532],
              [-114.68128, 36.109326],
              [-114.681847, 36.109192],
              [-114.68267, 36.109146],
              [-114.682754, 36.109162],
              [-114.684426, 36.109472],
              [-114.685079, 36.109771],
              [-114.685812, 36.110257],
              [-114.687044, 36.110953],
              [-114.688074, 36.111457],
              [-114.689551, 36.111935],
              [-114.690089, 36.11209],
              [-114.691631, 36.112535],
              [-114.692605, 36.112571],
              [-114.693573, 36.112488],
              [-114.693655, 36.112482],
              [-114.694152, 36.112291],
              [-114.694552, 36.111943],
              [-114.695475, 36.111223],
              [-114.695823, 36.110902],
              [-114.695899, 36.110857],
              [-114.696527, 36.11049],
              [-114.696981, 36.110297],
              [-114.697759, 36.110124],
              [-114.701617, 36.108986],
              [-114.703046, 36.108488],
              [-114.703737, 36.108348],
              [-114.704667, 36.108237],
              [-114.705566, 36.108273],
              [-114.706091, 36.108239],
              [-114.707332, 36.107994],
              [-114.707964, 36.107784],
              [-114.708524, 36.107565],
              [-114.709269, 36.107396],
              [-114.710253, 36.107373],
              [-114.711334, 36.107264],
              [-114.714337, 36.107322],
              [-114.715364, 36.107342],
              [-114.715702, 36.107321],
              [-114.717198, 36.107292],
              [-114.718257, 36.107164],
              [-114.725141, 36.106073],
              [-114.725972, 36.105941],
              [-114.726063, 36.105927],
              [-114.731589, 36.105103],
              [-114.734085, 36.104672],
              [-114.734857, 36.104426],
              [-114.73576, 36.104053],
              [-114.736754, 36.103552],
              [-114.737497, 36.103102],
              [-114.739202, 36.101895],
              [-114.745083, 36.097712],
              [-114.748913, 36.095183],
              [-114.749147, 36.094894],
              [-114.750095, 36.092275],
              [-114.751443, 36.090764],
              [-114.752836, 36.089393],
              [-114.753784, 36.087807],
              [-114.754508, 36.086171],
              [-114.754681, 36.085052],
              [-114.754032, 36.083093],
              [-114.75057, 36.08033],
              [-114.748891, 36.074981],
              [-114.747725, 36.073894],
              [-114.745236, 36.072511],
              [-114.743542, 36.071037],
              [-114.742331, 36.069151],
              [-114.742138, 36.068676],
              [-114.7422, 36.067833],
              [-114.741129, 36.065454],
              [-114.74006, 36.062437],
              [-114.739301, 36.061811],
              [-114.736594, 36.059541],
              [-114.736023, 36.059063],
              [-114.735636, 36.057797],
              [-114.735285, 36.056648],
              [-114.735126, 36.054931],
              [-114.73508, 36.054435],
              [-114.735701, 36.053393],
              [-114.735836, 36.05324],
              [-114.736322, 36.052686],
              [-114.737678, 36.051517],
              [-114.739458, 36.050193],
              [-114.741677, 36.047877],
              [-114.742479, 36.045697],
              [-114.742487, 36.045558],
              [-114.742544, 36.044573],
              [-114.742661, 36.042573],
              [-114.742537, 36.041954],
              [-114.742105, 36.039792],
              [-114.741262, 36.038044],
              [-114.740018, 36.037467],
              [-114.738885, 36.037465],
              [-114.737383, 36.037829],
              [-114.735739, 36.038033],
              [-114.734521, 36.038053],
              [-114.733417, 36.037913],
              [-114.732484, 36.037428],
              [-114.730563, 36.036207],
              [-114.729873, 36.035481],
              [-114.727602, 36.033099],
              [-114.72512, 36.031918],
              [-114.723673, 36.03123],
              [-114.722742, 36.030286],
              [-114.722485, 36.029755],
              [-114.722096, 36.028952],
              [-114.722214, 36.027964],
              [-114.723324, 36.026588],
              [-114.72557, 36.024388],
              [-114.728874, 36.021387],
              [-114.729069, 36.021264],
              [-114.729906, 36.020736],
              [-114.730744, 36.020207],
              [-114.731696, 36.019606],
              [-114.733361, 36.018556],
              [-114.735211, 36.017437],
              [-114.735642, 36.017177],
              [-114.736072, 36.016917],
              [-114.736458, 36.016683],
              [-114.736845, 36.016449],
              [-114.736877, 36.016429],
              [-114.736972, 36.016371],
              [-114.737005, 36.016352],
              [-114.737062, 36.016317],
              [-114.737233, 36.016214],
              [-114.737245, 36.016208],
              [-114.73729, 36.016179],
              [-114.73804, 36.015689],
              [-114.738555, 36.015223],
              [-114.73865, 36.015124],
              [-114.738745, 36.015025],
              [-114.739924, 36.013799],
              [-114.740866, 36.012928],
              [-114.741099, 36.012465],
              [-114.741138, 36.012387],
              [-114.741177, 36.01231],
              [-114.741234, 36.012195],
              [-114.741712, 36.011249],
              [-114.741734, 36.011214],
              [-114.741798, 36.011114],
              [-114.741863, 36.011014],
              [-114.742301, 36.010335],
              [-114.742624, 36.009307],
              [-114.743005, 36.00845],
              [-114.743163, 36.006722],
              [-114.742758, 36.005564],
              [-114.742316, 36.00428],
              [-114.741679, 36.002283],
              [-114.741553, 35.999998],
              [-114.741552, 35.999987],
              [-114.741544, 35.999838],
              [-114.741536, 35.99969],
              [-114.7413, 35.998963],
              [-114.741065, 35.998236],
              [-114.740815, 35.997464],
              [-114.740819, 35.997079],
              [-114.740829, 35.99616],
              [-114.740544, 35.994853],
              [-114.740157, 35.993871],
              [-114.739869, 35.993138],
              [-114.739481, 35.992542],
              [-114.739318, 35.991804],
              [-114.739413, 35.991363],
              [-114.740043, 35.990534],
              [-114.743064, 35.987441],
              [-114.743117, 35.987387],
              [-114.743169, 35.987258],
              [-114.743357, 35.986786],
              [-114.743638, 35.985785],
              [-114.743652, 35.985416],
              [-114.743671, 35.984917],
              [-114.743606, 35.984416],
              [-114.743494, 35.983553],
              [-114.740536, 35.975545],
              [-114.736195, 35.969421],
              [-114.734709, 35.968047],
              [-114.732456, 35.965891],
              [-114.731391, 35.964464],
              [-114.731123, 35.964105],
              [-114.730611, 35.963378],
              [-114.730553, 35.963295],
              [-114.73009, 35.962691],
              [-114.729712, 35.962464],
              [-114.729328, 35.962428],
              [-114.728666, 35.961757],
              [-114.728496, 35.960395],
              [-114.729762, 35.959895],
              [-114.72959, 35.959622],
              [-114.729545, 35.959549],
              [-114.729326, 35.959204],
              [-114.728705, 35.958211],
              [-114.728587, 35.958021],
              [-114.728372, 35.957681],
              [-114.728045, 35.957159],
              [-114.727818, 35.956796],
              [-114.727398, 35.95612],
              [-114.726832, 35.955209],
              [-114.72668, 35.954963],
              [-114.726566, 35.954781],
              [-114.726206, 35.954199],
              [-114.724699, 35.951753],
              [-114.72461, 35.951612],
              [-114.724411, 35.951296],
              [-114.723899, 35.950463],
              [-114.723849, 35.95038],
              [-114.723728, 35.950181],
              [-114.723606, 35.949986],
              [-114.723242, 35.949392],
              [-114.722942, 35.948913],
              [-114.722666, 35.948472],
              [-114.722602, 35.948371],
              [-114.722493, 35.948197],
              [-114.722383, 35.948025],
              [-114.722313, 35.947914],
              [-114.72224, 35.947798],
              [-114.722027, 35.947462],
              [-114.721569, 35.946738],
              [-114.721205, 35.946169],
              [-114.720893, 35.945681],
              [-114.720708, 35.945394],
              [-114.720259, 35.944692],
              [-114.719975, 35.944251],
              [-114.717974, 35.941138],
              [-114.717886, 35.941],
              [-114.717798, 35.940863],
              [-114.717558, 35.940491],
              [-114.717317, 35.940115],
              [-114.717018, 35.939649],
              [-114.716815, 35.939334],
              [-114.714614, 35.93591],
              [-114.714023, 35.934995],
              [-114.713784, 35.934625],
              [-114.713312, 35.933844],
              [-114.713235, 35.933035],
              [-114.713413, 35.9319],
              [-114.71285, 35.932533],
              [-114.712756, 35.932639],
              [-114.712825, 35.932483],
              [-114.712888, 35.932342],
              [-114.712965, 35.932159],
              [-114.712544, 35.931861],
              [-114.711001, 35.930767],
              [-114.710787, 35.930601],
              [-114.708578, 35.928885],
              [-114.708548, 35.928864],
              [-114.708501, 35.928834],
              [-114.708469, 35.928814],
              [-114.708397, 35.928738],
              [-114.708, 35.928344],
              [-114.707603, 35.92795],
              [-114.707329, 35.926177],
              [-114.707363, 35.925301],
              [-114.707523, 35.921645],
              [-114.707554, 35.921484],
              [-114.707799, 35.920275],
              [-114.707853, 35.919605],
              [-114.70788, 35.919207],
              [-114.707805, 35.918797],
              [-114.707398, 35.918057],
              [-114.707784, 35.916993],
              [-114.70883, 35.916697],
              [-114.709187, 35.916827],
              [-114.709038, 35.916024],
              [-114.709001, 35.915775],
              [-114.708949, 35.915438],
              [-114.708608, 35.913234],
              [-114.708527, 35.912716],
              [-114.708323, 35.911416],
              [-114.708112, 35.909933],
              [-114.706767, 35.90895],
              [-114.705714, 35.909316],
              [-114.705882, 35.90888],
              [-114.705991, 35.908598],
              [-114.705119, 35.907637],
              [-114.704569, 35.907418],
              [-114.704479, 35.907383],
              [-114.704289, 35.907308],
              [-114.703538, 35.906707],
              [-114.702949, 35.905738],
              [-114.702451, 35.905132],
              [-114.702152, 35.904768],
              [-114.700769, 35.903064],
              [-114.700258, 35.901757],
              [-114.70017, 35.901781],
              [-114.699343, 35.900828],
              [-114.698746, 35.900336],
              [-114.698332, 35.899996],
              [-114.697558, 35.89936],
              [-114.696132, 35.898662],
              [-114.695872, 35.898431],
              [-114.695095, 35.897741],
              [-114.694928, 35.897594],
              [-114.695586, 35.896939],
              [-114.696064, 35.896464],
              [-114.69454, 35.896587],
              [-114.694107, 35.895998],
              [-114.693602, 35.895311],
              [-114.69087, 35.893489],
              [-114.68964, 35.892701],
              [-114.684878, 35.88965],
              [-114.684554, 35.889442],
              [-114.680966, 35.886885],
              [-114.680747, 35.886729],
              [-114.67928, 35.885721],
              [-114.678972, 35.88551],
              [-114.678014, 35.884752],
              [-114.677984, 35.884707],
              [-114.677365, 35.883787],
              [-114.676021, 35.882339],
              [-114.672962, 35.879044],
              [-114.672009, 35.878018],
              [-114.669077, 35.875834],
              [-114.668145, 35.875201],
              [-114.66711, 35.874984],
              [-114.667309, 35.874884],
              [-114.667209, 35.874884],
              [-114.667194, 35.874787],
              [-114.666428, 35.874522],
              [-114.6648, 35.874265],
              [-114.664229, 35.874175],
              [-114.664129, 35.874127],
              [-114.663214, 35.873692],
              [-114.662763, 35.873163],
              [-114.662387, 35.872519],
              [-114.662309, 35.872386],
              [-114.662232, 35.872254],
              [-114.661636, 35.871233],
              [-114.661636, 35.871067],
              [-114.661636, 35.870545],
              [-114.66198, 35.86985],
              [-114.662623, 35.869213],
              [-114.663617, 35.868772],
              [-114.664028, 35.868589],
              [-114.664924, 35.868191],
              [-114.666636, 35.867431],
              [-114.667205, 35.867179],
              [-114.667471, 35.867061],
              [-114.669107, 35.865771],
              [-114.669399, 35.86528],
              [-114.66968, 35.865036],
              [-114.669689, 35.865052],
              [-114.669684, 35.865067],
              [-114.669687, 35.865084],
              [-114.670772, 35.865054],
              [-114.671858, 35.865023],
              [-114.672289, 35.865011],
              [-114.672588, 35.864925],
              [-114.67356, 35.864647],
              [-114.674893, 35.864357],
              [-114.675427, 35.86418],
              [-114.67549, 35.86416],
              [-114.67603, 35.863911],
              [-114.677573, 35.863482],
              [-114.678186, 35.863311],
              [-114.678356, 35.863305],
              [-114.679121, 35.863281],
              [-114.68016, 35.863248],
              [-114.681523, 35.863033],
              [-114.68186, 35.86298],
              [-114.68205, 35.86295],
              [-114.683404, 35.862426],
              [-114.684631, 35.861952],
              [-114.685654, 35.861556],
              [-114.687798, 35.860728],
              [-114.689581, 35.859916],
              [-114.691456, 35.858661],
              [-114.691845, 35.858047],
              [-114.69239, 35.857184],
              [-114.69287, 35.856346],
              [-114.692946, 35.856214],
              [-114.693446, 35.855125],
              [-114.69437, 35.854463],
              [-114.69498, 35.854147],
              [-114.695291, 35.853987],
              [-114.69606, 35.853832],
              [-114.696581, 35.853727],
              [-114.699985, 35.853591],
              [-114.701904, 35.853223],
              [-114.703599, 35.852595],
              [-114.704403, 35.851986],
              [-114.704868, 35.851635],
              [-114.705856, 35.850508],
              [-114.706532, 35.849027],
              [-114.706528, 35.848073],
              [-114.706525, 35.847349],
              [-114.706288, 35.846218],
              [-114.706191, 35.846058],
              [-114.706076, 35.845873],
              [-114.704523, 35.844406],
              [-114.704203, 35.844274],
              [-114.704173, 35.842669],
              [-114.703939, 35.842402],
              [-114.703781, 35.842222],
              [-114.703527, 35.841845],
              [-114.702339, 35.842151],
              [-114.702363, 35.841916],
              [-114.702405, 35.841525],
              [-114.702368, 35.84128],
              [-114.702293, 35.840792],
              [-114.702085, 35.840239],
              [-114.702076, 35.840225],
              [-114.701757, 35.83974],
              [-114.701716, 35.839678],
              [-114.701675, 35.839616],
              [-114.701478, 35.839316],
              [-114.700208, 35.837999],
              [-114.69692, 35.834592],
              [-114.695757, 35.833387],
              [-114.695557, 35.832953],
              [-114.695506, 35.832843],
              [-114.6955, 35.83283],
              [-114.695443, 35.832707],
              [-114.695249, 35.832285],
              [-114.695265, 35.831586],
              [-114.695277, 35.831091],
              [-114.695337, 35.830805],
              [-114.695361, 35.83069],
              [-114.695386, 35.830575],
              [-114.69553, 35.829897],
              [-114.696616, 35.827956],
              [-114.696711, 35.827786],
              [-114.696806, 35.827616],
              [-114.697276, 35.826776],
              [-114.698881, 35.824576],
              [-114.699134, 35.82423],
              [-114.700271, 35.822565],
              [-114.700654, 35.822004],
              [-114.700859, 35.821473],
              [-114.701324, 35.820268],
              [-114.701559, 35.819662],
              [-114.702259, 35.817852],
              [-114.702726, 35.816643],
              [-114.703193, 35.815434],
              [-114.703271, 35.815233],
              [-114.703519, 35.814844],
              [-114.703665, 35.814614],
              [-114.704152, 35.814117],
              [-114.704228, 35.81404],
              [-114.705518, 35.812807],
              [-114.70634, 35.812022],
              [-114.707653, 35.811257],
              [-114.708818, 35.810577],
              [-114.709324, 35.81005],
              [-114.709334, 35.810035],
              [-114.709718, 35.809453],
              [-114.710037, 35.808673],
              [-114.710084, 35.808558],
              [-114.710534, 35.807525],
              [-114.711294, 35.806676],
              [-114.711934, 35.805674],
              [-114.712026, 35.805529],
              [-114.711913, 35.804955],
              [-114.71149, 35.80438],
              [-114.709206, 35.802061],
              [-114.708616, 35.801462],
              [-114.705827, 35.798889],
              [-114.704348, 35.79678],
              [-114.704248, 35.796637],
              [-114.703178, 35.794685],
              [-114.701459, 35.792732],
              [-114.701179, 35.792446],
              [-114.699882, 35.791123],
              [-114.699318, 35.79048],
              [-114.699092, 35.789469],
              [-114.699036, 35.788046],
              [-114.699343, 35.785607],
              [-114.699393, 35.785212],
              [-114.699443, 35.784816],
              [-114.699681, 35.782927],
              [-114.699874, 35.781547],
              [-114.699892, 35.78142],
              [-114.69991, 35.781294],
              [-114.700074, 35.780126],
              [-114.700664, 35.777257],
              [-114.70117, 35.774112],
              [-114.701177, 35.773543],
              [-114.701177, 35.773518],
              [-114.701185, 35.77284],
              [-114.701193, 35.772138],
              [-114.701197, 35.771793],
              [-114.701139, 35.771003],
              [-114.701027, 35.76968],
              [-114.700689, 35.76828],
              [-114.700266, 35.766879],
              [-114.700256, 35.766858],
              [-114.69742, 35.760677],
              [-114.696829, 35.75985],
              [-114.696266, 35.759253],
              [-114.695459, 35.758701],
              [-114.695224, 35.75854],
              [-114.694717, 35.757897],
              [-114.694706, 35.757854],
              [-114.694548, 35.757231],
              [-114.694267, 35.756633],
              [-114.695026, 35.755831],
              [-114.695675, 35.755529],
              [-114.696011, 35.755372],
              [-114.696181, 35.755139],
              [-114.696546, 35.754638],
              [-114.69677, 35.753812],
              [-114.696838, 35.752946],
              [-114.696854, 35.752756],
              [-114.697726, 35.750966],
              [-114.697726, 35.749795],
              [-114.697585, 35.748417],
              [-114.696937, 35.747245],
              [-114.696655, 35.746143],
              [-114.696606, 35.745472],
              [-114.696551, 35.744729],
              [-114.696504, 35.744072],
              [-114.696401, 35.742673],
              [-114.6964, 35.742653],
              [-114.696405, 35.742501],
              [-114.696434, 35.741757],
              [-114.696438, 35.741657],
              [-114.696441, 35.741556],
              [-114.696462, 35.740991],
              [-114.69647, 35.74079],
              [-114.696499, 35.740025],
              [-114.69654, 35.738934],
              [-114.696577, 35.738697],
              [-114.696699, 35.737941],
              [-114.696836, 35.737085],
              [-114.696872, 35.736866],
              [-114.697044, 35.735792],
              [-114.697129, 35.735261],
              [-114.697277, 35.734525],
              [-114.69745, 35.733677],
              [-114.697484, 35.733508],
              [-114.697518, 35.733339],
              [-114.697831, 35.73179],
              [-114.697859, 35.731657],
              [-114.698405, 35.729988],
              [-114.699405, 35.726929],
              [-114.699464, 35.726785],
              [-114.699516, 35.72666],
              [-114.699567, 35.726535],
              [-114.699657, 35.726315],
              [-114.699978, 35.725536],
              [-114.70039, 35.724536],
              [-114.700683, 35.723821],
              [-114.70106, 35.722907],
              [-114.701565, 35.721677],
              [-114.70173, 35.721278],
              [-114.701794, 35.72112],
              [-114.701859, 35.720963],
              [-114.701927, 35.720795],
              [-114.702131, 35.720294],
              [-114.7022, 35.720127],
              [-114.702484, 35.719432],
              [-114.702606, 35.719132],
              [-114.702767, 35.718738],
              [-114.703255, 35.717541],
              [-114.703787, 35.716148],
              [-114.703792, 35.716134],
              [-114.703981, 35.715639],
              [-114.704175, 35.71513],
              [-114.704442, 35.714424],
              [-114.704708, 35.713717],
              [-114.705423, 35.71182],
              [-114.705447, 35.711757],
              [-114.705559, 35.710403],
              [-114.705474, 35.709025],
              [-114.705347, 35.708344],
              [-114.705597, 35.708274],
              [-114.705261, 35.707612],
              [-114.705032, 35.707163],
              [-114.704842, 35.706744],
              [-114.704959, 35.706366],
              [-114.704501, 35.705993],
              [-114.703608, 35.703922],
              [-114.703109, 35.703314],
              [-114.702409, 35.702443],
              [-114.702213, 35.702199],
              [-114.701416, 35.701084],
              [-114.70052, 35.700272],
              [-114.700021, 35.699866],
              [-114.699478, 35.699331],
              [-114.699262, 35.699147],
              [-114.696876, 35.697114],
              [-114.696214, 35.69655],
              [-114.69607, 35.696451],
              [-114.695194, 35.695845],
              [-114.692566, 35.694027],
              [-114.691691, 35.693421],
              [-114.691263, 35.693125],
              [-114.690096, 35.692469],
              [-114.688113, 35.691354],
              [-114.685694, 35.690341],
              [-114.685097, 35.690022],
              [-114.683904, 35.689386],
              [-114.683754, 35.689306],
              [-114.683475, 35.689119],
              [-114.682657, 35.688571],
              [-114.681897, 35.68756],
              [-114.681622, 35.687061],
              [-114.680997, 35.685929],
              [-114.680907, 35.685465],
              [-114.680631, 35.684046],
              [-114.680827, 35.682255],
              [-114.68153, 35.67996],
              [-114.681892, 35.678979],
              [-114.682317, 35.677825],
              [-114.68256, 35.677359],
              [-114.682804, 35.67689],
              [-114.683048, 35.67642],
              [-114.683448, 35.675649],
              [-114.684651, 35.673338],
              [-114.685053, 35.672568],
              [-114.685396, 35.671906],
              [-114.686055, 35.670642],
              [-114.686558, 35.670007],
              [-114.687021, 35.669423],
              [-114.687853, 35.668371],
              [-114.688096, 35.668087],
              [-114.688502, 35.667611],
              [-114.688893, 35.667155],
              [-114.689568, 35.666261],
              [-114.690214, 35.665159],
              [-114.690467, 35.663782],
              [-114.690494, 35.662657],
              [-114.690227, 35.661182],
              [-114.690016, 35.660016],
              [-114.6899, 35.658867],
              [-114.689845, 35.658325],
              [-114.68968, 35.656701],
              [-114.689625, 35.65616],
              [-114.689595, 35.655864],
              [-114.689565, 35.655568],
              [-114.689526, 35.655195],
              [-114.68948, 35.654735],
              [-114.689398, 35.654142],
              [-114.689226, 35.652898],
              [-114.689336, 35.652317],
              [-114.689507, 35.651429],
              [-114.689481, 35.65137],
              [-114.6894, 35.651186],
              [-114.689319, 35.651003],
              [-114.689001, 35.65028],
              [-114.688737, 35.650007],
              [-114.688045, 35.649292],
              [-114.686133, 35.647522],
              [-114.685155, 35.647],
              [-114.683771, 35.646261],
              [-114.683462, 35.646096],
              [-114.683156, 35.645894],
              [-114.682598, 35.645527],
              [-114.68219, 35.645258],
              [-114.681783, 35.644989],
              [-114.681445, 35.644766],
              [-114.681361, 35.644711],
              [-114.681107, 35.644544],
              [-114.680935, 35.64443],
              [-114.680763, 35.644317],
              [-114.679415, 35.643429],
              [-114.67889, 35.642946],
              [-114.677955, 35.642086],
              [-114.677615, 35.641774],
              [-114.676491, 35.640464],
              [-114.675001, 35.638304],
              [-114.674543, 35.637531],
              [-114.673358, 35.635529],
              [-114.673287, 35.635409],
              [-114.672444, 35.633915],
              [-114.672134, 35.633365],
              [-114.670279, 35.630768],
              [-114.669981, 35.630319],
              [-114.669317, 35.629317],
              [-114.669015, 35.628861],
              [-114.668587, 35.628055],
              [-114.668495, 35.627881],
              [-114.668402, 35.627707],
              [-114.668087, 35.627115],
              [-114.667314, 35.624989],
              [-114.666935, 35.623946],
              [-114.666738, 35.623265],
              [-114.666682, 35.623073],
              [-114.665855, 35.622102],
              [-114.665389, 35.621556],
              [-114.665134, 35.621441],
              [-114.663647, 35.620773],
              [-114.663294, 35.620758],
              [-114.663131, 35.620751],
              [-114.662969, 35.620744],
              [-114.662018, 35.620703],
              [-114.660641, 35.620334],
              [-114.659461, 35.619552],
              [-114.658125, 35.618044],
              [-114.657241, 35.617046],
              [-114.655281, 35.614151],
              [-114.655219, 35.614059],
              [-114.654525, 35.612813],
              [-114.653927, 35.611739],
              [-114.653559, 35.609806],
              [-114.653534, 35.609672],
              [-114.653478, 35.608225],
              [-114.653505, 35.608029],
              [-114.653618, 35.607192],
              [-114.654133, 35.60611],
              [-114.654208, 35.605953],
              [-114.654489, 35.605173],
              [-114.65443, 35.604717],
              [-114.654377, 35.6043],
              [-114.654284, 35.603995],
              [-114.654255, 35.6039],
              [-114.654226, 35.603805],
              [-114.653984, 35.603014],
              [-114.653979, 35.602968],
              [-114.653731, 35.600373],
              [-114.653732, 35.60036],
              [-114.653806, 35.59949],
              [-114.653814, 35.599409],
              [-114.653821, 35.599328],
              [-114.6539, 35.598491],
              [-114.654289, 35.597305],
              [-114.654518, 35.596609],
              [-114.655586, 35.594819],
              [-114.656597, 35.592846],
              [-114.657355, 35.591303],
              [-114.657868, 35.590262],
              [-114.658108, 35.589773],
              [-114.658349, 35.589284],
              [-114.658629, 35.588713],
              [-114.658824, 35.588319],
              [-114.659238, 35.587477],
              [-114.659618, 35.587107],
              [-114.659687, 35.587041],
              [-114.660165, 35.58679],
              [-114.660558, 35.586583],
              [-114.660796, 35.586528],
              [-114.66185, 35.586286],
              [-114.662452, 35.586199],
              [-114.662826, 35.586144],
              [-114.66298, 35.586122],
              [-114.663509, 35.586045],
              [-114.664209, 35.585944],
              [-114.665291, 35.58549],
              [-114.666175, 35.58512],
              [-114.667635, 35.584318],
              [-114.668927, 35.583791],
              [-114.670191, 35.583471],
              [-114.670467, 35.583308],
              [-114.67209, 35.582356],
              [-114.672666, 35.582017],
              [-114.674396, 35.581002],
              [-114.674973, 35.580664],
              [-114.674993, 35.580652],
              [-114.675667, 35.580033],
              [-114.675751, 35.579459],
              [-114.675414, 35.578908],
              [-114.674881, 35.578379],
              [-114.674158, 35.577889],
              [-114.673898, 35.577712],
              [-114.671567, 35.576217],
              [-114.670022, 35.575596],
              [-114.668393, 35.574331],
              [-114.666231, 35.571642],
              [-114.665693, 35.57068],
              [-114.66472, 35.56894],
              [-114.664433, 35.568426],
              [-114.664141, 35.567257],
              [-114.663952, 35.566501],
              [-114.6639, 35.56629],
              [-114.663226, 35.565164],
              [-114.662882, 35.564433],
              [-114.662805, 35.564268],
              [-114.662824, 35.563672],
              [-114.662833, 35.563373],
              [-114.663114, 35.562593],
              [-114.663535, 35.560963],
              [-114.663451, 35.559884],
              [-114.66344, 35.559858],
              [-114.662454, 35.557551],
              [-114.66244, 35.557518],
              [-114.661963, 35.555887],
              [-114.661986, 35.554842],
              [-114.661991, 35.554601],
              [-114.661963, 35.552604],
              [-114.661738, 35.551524],
              [-114.661401, 35.550238],
              [-114.66112, 35.549021],
              [-114.661133, 35.548465],
              [-114.661149, 35.547781],
              [-114.66157, 35.545692],
              [-114.661494, 35.544588],
              [-114.661457, 35.544062],
              [-114.66133, 35.543672],
              [-114.661092, 35.542937],
              [-114.660756, 35.541696],
              [-114.660517, 35.540981],
              [-114.660335, 35.540433],
              [-114.660138, 35.540133],
              [-114.659988, 35.539905],
              [-114.659549, 35.539238],
              [-114.659536, 35.539222],
              [-114.659369, 35.539007],
              [-114.658707, 35.538158],
              [-114.657915, 35.537104],
              [-114.657809, 35.536963],
              [-114.657135, 35.53586],
              [-114.65677, 35.534964],
              [-114.656826, 35.533771],
              [-114.657163, 35.532301],
              [-114.657753, 35.530741],
              [-114.657967, 35.530433],
              [-114.658875, 35.529135],
              [-114.659393, 35.528512],
              [-114.659886, 35.527919],
              [-114.659971, 35.527859],
              [-114.661682, 35.526682],
              [-114.661943, 35.526198],
              [-114.66236, 35.525429],
              [-114.662664, 35.524869],
              [-114.66345, 35.523194],
              [-114.663662, 35.522782],
              [-114.663983, 35.522161],
              [-114.664601, 35.521519],
              [-114.664863, 35.521401],
              [-114.665414, 35.521152],
              [-114.665872, 35.521089],
              [-114.666565, 35.520993],
              [-114.668586, 35.521225],
              [-114.668824, 35.521116],
              [-114.66954, 35.52079],
              [-114.669602, 35.520743],
              [-114.670438, 35.520102],
              [-114.671244, 35.519522],
              [-114.672767, 35.518428],
              [-114.673048, 35.518188],
              [-114.673316, 35.517959],
              [-114.673585, 35.51773],
              [-114.674076, 35.517311],
              [-114.674085, 35.517304],
              [-114.674591, 35.516775],
              [-114.675414, 35.515913],
              [-114.675685, 35.51563],
              [-114.675803, 35.515406],
              [-114.676247, 35.514563],
              [-114.676302, 35.51446],
              [-114.677143, 35.512945],
              [-114.677156, 35.51287],
              [-114.67731, 35.511951],
              [-114.677312, 35.511941],
              [-114.677469, 35.511012],
              [-114.67748, 35.510948],
              [-114.677542, 35.509837],
              [-114.677579, 35.509185],
              [-114.677591, 35.508973],
              [-114.67762, 35.508773],
              [-114.677969, 35.506334],
              [-114.678068, 35.505645],
              [-114.678193, 35.505181],
              [-114.678375, 35.5045],
              [-114.678432, 35.50429],
              [-114.678684, 35.502867],
              [-114.678742, 35.502418],
              [-114.678776, 35.502164],
              [-114.678834, 35.50172],
              [-114.678879, 35.50137],
              [-114.678892, 35.501276],
              [-114.678855, 35.500567],
              [-114.678789, 35.50039],
              [-114.678768, 35.500331],
              [-114.678655, 35.499998],
              [-114.678587, 35.499846],
              [-114.678644, 35.499362],
              [-114.678715, 35.49875],
              [-114.678726, 35.498657],
              [-114.678642, 35.497628],
              [-114.678164, 35.496279],
              [-114.677791, 35.495306],
              [-114.677743, 35.495182],
              [-114.676818, 35.494177],
              [-114.676257, 35.493103],
              [-114.676396, 35.492531],
              [-114.676704, 35.491845],
              [-114.676804, 35.491248],
              [-114.676956, 35.490336],
              [-114.676903, 35.490131],
              [-114.676815, 35.489787],
              [-114.676507, 35.489284],
              [-114.675816, 35.488528],
              [-114.675609, 35.488302],
              [-114.67493, 35.487442],
              [-114.67488, 35.487379],
              [-114.673534, 35.485675],
              [-114.671907, 35.482087],
              [-114.671794, 35.480806],
              [-114.672074, 35.479709],
              [-114.672453, 35.479054],
              [-114.673081, 35.47797],
              [-114.673473, 35.476849],
              [-114.673585, 35.475843],
              [-114.673164, 35.474814],
              [-114.67235, 35.47374],
              [-114.670503, 35.472365],
              [-114.670094, 35.47206],
              [-114.668864, 35.471144],
              [-114.668763, 35.471069],
              [-114.667389, 35.469904],
              [-114.665988, 35.467985],
              [-114.665651, 35.466911],
              [-114.66557, 35.466296],
              [-114.66551, 35.465836],
              [-114.665594, 35.46483],
              [-114.66579, 35.463915],
              [-114.666048, 35.463454],
              [-114.666265, 35.463069],
              [-114.666769, 35.462085],
              [-114.667049, 35.461284],
              [-114.667217, 35.46037],
              [-114.666936, 35.459478],
              [-114.666151, 35.458198],
              [-114.665142, 35.457331],
              [-114.664217, 35.455845],
              [-114.66388, 35.454657],
              [-114.663935, 35.454077],
              [-114.663992, 35.453468],
              [-114.664215, 35.451707],
              [-114.663934, 35.449466],
              [-114.663541, 35.447797],
              [-114.662896, 35.446449],
              [-114.661747, 35.444735],
              [-114.660178, 35.443571],
              [-114.659577, 35.443067],
              [-114.658105, 35.441835],
              [-114.655331, 35.438362],
              [-114.654295, 35.436854],
              [-114.654014, 35.435871],
              [-114.653874, 35.434636],
              [-114.653817, 35.432853],
              [-114.653397, 35.431939],
              [-114.652752, 35.430819],
              [-114.65208, 35.430134],
              [-114.650735, 35.428877],
              [-114.640708, 35.420833],
              [-114.634649, 35.415972],
              [-114.631973, 35.413825],
              [-114.630433, 35.412408],
              [-114.629061, 35.411175],
              [-114.627325, 35.410193],
              [-114.626765, 35.409644],
              [-114.625702, 35.407976],
              [-114.625484, 35.407494],
              [-114.62447, 35.405256],
              [-114.623793, 35.403709],
              [-114.62363, 35.403336],
              [-114.622801, 35.401592],
              [-114.622712, 35.401405],
              [-114.621783, 35.39945],
              [-114.621505, 35.39865],
              [-114.6214, 35.398348],
              [-114.621296, 35.398047],
              [-114.620887, 35.396867],
              [-114.618984, 35.389391],
              [-114.618313, 35.384498],
              [-114.618261, 35.382808],
              [-114.618257, 35.382646],
              [-114.618173, 35.382131],
              [-114.618033, 35.381274],
              [-114.617698, 35.380131],
              [-114.617054, 35.37908],
              [-114.615625, 35.377021],
              [-114.613137, 35.373389],
              [-114.611206, 35.370119],
              [-114.609501, 35.366417],
              [-114.607487, 35.362417],
              [-114.607081, 35.361562],
              [-114.606173, 35.359651],
              [-114.605748, 35.358454],
              [-114.604607, 35.355239],
              [-114.604465, 35.354825],
              [-114.603698, 35.352582],
              [-114.60293, 35.35034],
              [-114.602086, 35.347873],
              [-114.601242, 35.345406],
              [-114.600901, 35.344411],
              [-114.60056, 35.343416],
              [-114.599771, 35.34111],
              [-114.599381, 35.339736],
              [-114.59845, 35.336455],
              [-114.59752, 35.333174],
              [-114.597508, 35.333131],
              [-114.595553, 35.326547],
              [-114.595162, 35.324238],
              [-114.595163, 35.321883],
              [-114.596454, 35.308346],
              [-114.596874, 35.305761],
              [-114.597075, 35.304243],
              [-114.59721, 35.303223],
              [-114.597268, 35.299565],
              [-114.597186, 35.298862],
              [-114.59715, 35.298552],
              [-114.597113, 35.298243],
              [-114.597046, 35.297668],
              [-114.596979, 35.297094],
              [-114.596963, 35.296965],
              [-114.596682, 35.294557],
              [-114.596264, 35.291928],
              [-114.595705, 35.289939],
              [-114.595119, 35.288201],
              [-114.593778, 35.285916],
              [-114.593247, 35.284361],
              [-114.593158, 35.283974],
              [-114.592661, 35.2818],
              [-114.591445, 35.276766],
              [-114.591371, 35.276459],
              [-114.591297, 35.276152],
              [-114.590987, 35.274872],
              [-114.590513, 35.272334],
              [-114.590163, 35.271319],
              [-114.589843, 35.270389],
              [-114.589416, 35.269428],
              [-114.588727, 35.267875],
              [-114.587855, 35.266172],
              [-114.587497, 35.265473],
              [-114.587163, 35.264101],
              [-114.586604, 35.262386],
              [-114.586402, 35.261005],
              [-114.58627, 35.260098],
              [-114.586074, 35.259181],
              [-114.585837, 35.258066],
              [-114.585768, 35.257743],
              [-114.585721, 35.255919],
              [-114.585713, 35.255616],
              [-114.585714, 35.253145],
              [-114.585884, 35.251956],
              [-114.585992, 35.249998],
              [-114.586053, 35.248891],
              [-114.586042, 35.24885],
              [-114.586008, 35.248724],
              [-114.585975, 35.248598],
              [-114.585522, 35.246902],
              [-114.585216, 35.24402],
              [-114.584993, 35.242717],
              [-114.584511, 35.241713],
              [-114.583707, 35.240285],
              [-114.583039, 35.239355],
              [-114.582842, 35.238703],
              [-114.58243, 35.234537],
              [-114.58248, 35.233173],
              [-114.582845, 35.232287],
              [-114.582926, 35.232102],
              [-114.583121, 35.231667],
              [-114.583438, 35.230994],
              [-114.583523, 35.230348],
              [-114.583428, 35.229737],
              [-114.583121, 35.228689],
              [-114.582866, 35.22783],
              [-114.581735, 35.224072],
              [-114.58044, 35.220431],
              [-114.580312, 35.220095],
              [-114.580314, 35.218113],
              [-114.580192, 35.217297],
              [-114.579921, 35.215521],
              [-114.579905, 35.212571],
              [-114.579897, 35.21097],
              [-114.579811, 35.210382],
              [-114.579731, 35.209887],
              [-114.579535, 35.208911],
              [-114.578581, 35.208113],
              [-114.577774, 35.207766],
              [-114.57649, 35.207331],
              [-114.574958, 35.206714],
              [-114.57451, 35.206123],
              [-114.574496, 35.206089],
              [-114.574233, 35.205481],
              [-114.574307, 35.204912],
              [-114.574037, 35.20379],
              [-114.573633, 35.20317],
              [-114.572084, 35.200794],
              [-114.571861, 35.2002],
              [-114.571821, 35.199755],
              [-114.571816, 35.199705],
              [-114.571804, 35.199555],
              [-114.571802, 35.199507],
              [-114.571804, 35.199376],
              [-114.571811, 35.198986],
              [-114.571814, 35.198857],
              [-114.571845, 35.19853],
              [-114.571864, 35.198334],
              [-114.571824, 35.197937],
              [-114.571799, 35.197682],
              [-114.571785, 35.197553],
              [-114.571753, 35.197229],
              [-114.571723, 35.196903],
              [-114.571693, 35.196578],
              [-114.571679, 35.196441],
              [-114.571596, 35.19553],
              [-114.5715, 35.194482],
              [-114.571463, 35.194083],
              [-114.571412, 35.193525],
              [-114.571412, 35.193297],
              [-114.571394, 35.192122],
              [-114.571402, 35.191075],
              [-114.571404, 35.191026],
              [-114.571014, 35.189913],
              [-114.57037, 35.18852],
              [-114.570305, 35.188346],
              [-114.569653, 35.186267],
              [-114.569489, 35.185085],
              [-114.569442, 35.184746],
              [-114.569384, 35.184329],
              [-114.569279, 35.183573],
              [-114.569258, 35.183424],
              [-114.569245, 35.182539],
              [-114.569242, 35.18162],
              [-114.569238, 35.178872],
              [-114.569242, 35.17796],
              [-114.569252, 35.177639],
              [-114.569161, 35.176776],
              [-114.568989, 35.175085],
              [-114.569177, 35.173239],
              [-114.569214, 35.17289],
              [-114.56876, 35.172195],
              [-114.568787, 35.171444],
              [-114.568869, 35.169193],
              [-114.568879, 35.169128],
              [-114.568874, 35.16908],
              [-114.568867, 35.169018],
              [-114.568886, 35.168443],
              [-114.568957, 35.167731],
              [-114.569172, 35.165595],
              [-114.569244, 35.164884],
              [-114.5693, 35.16437],
              [-114.569345, 35.163974],
              [-114.569446, 35.163063],
              [-114.569472, 35.16283],
              [-114.569488, 35.16269],
              [-114.569529, 35.162317],
              [-114.569625, 35.161918],
              [-114.570205, 35.159504],
              [-114.570301, 35.159105],
              [-114.570626, 35.157888],
              [-114.571171, 35.155845],
              [-114.571209, 35.155707],
              [-114.571626, 35.154139],
              [-114.573553, 35.146902],
              [-114.573879, 35.145351],
              [-114.573972, 35.14404],
              [-114.573943, 35.143892],
              [-114.573706, 35.142698],
              [-114.573536, 35.142306],
              [-114.573472, 35.142158],
              [-114.573299, 35.141699],
              [-114.572965, 35.140812],
              [-114.572597, 35.139557],
              [-114.572953, 35.138485],
              [-114.572958, 35.138472],
              [-114.573503, 35.137048],
              [-114.573703, 35.136583],
              [-114.574411, 35.13495],
              [-114.575985, 35.132665],
              [-114.5771, 35.131048],
              [-114.577146, 35.130982],
              [-114.577182, 35.130913],
              [-114.577324, 35.130638],
              [-114.577465, 35.130362],
              [-114.5778, 35.12971],
              [-114.578263, 35.12881],
              [-114.579882, 35.127506],
              [-114.580769, 35.127116],
              [-114.5815, 35.126796],
              [-114.581917, 35.126607],
              [-114.582006, 35.126566],
              [-114.583063, 35.126086],
              [-114.584877, 35.125194],
              [-114.585317, 35.125037],
              [-114.586186, 35.124729],
              [-114.588364, 35.123958],
              [-114.589787, 35.123522],
              [-114.594105, 35.122558],
              [-114.597794, 35.121735],
              [-114.599504, 35.121662],
              [-114.600694, 35.121611],
              [-114.601058, 35.121596],
              [-114.602007, 35.121641],
              [-114.60274, 35.121666],
              [-114.602958, 35.121595],
              [-114.603482, 35.121424],
              [-114.604007, 35.121252],
              [-114.605799, 35.121338],
              [-114.606039, 35.12135],
              [-114.608071, 35.121447],
              [-114.611176, 35.121596],
              [-114.611556, 35.121615],
              [-114.61297, 35.121622],
              [-114.613034, 35.121624],
              [-114.613227, 35.121635],
              [-114.613293, 35.121639],
              [-114.613377, 35.121643],
              [-114.613638, 35.121659],
              [-114.613725, 35.121666],
              [-114.614139, 35.121694],
              [-114.615384, 35.121781],
              [-114.615799, 35.121811],
              [-114.616083, 35.121808],
              [-114.616112, 35.121802],
              [-114.616424, 35.121742],
              [-114.616551, 35.121734],
              [-114.616749, 35.121723],
              [-114.61702, 35.121708],
              [-114.617047, 35.121709],
              [-114.617108, 35.121714],
              [-114.617254, 35.121725],
              [-114.617358, 35.121751],
              [-114.61836, 35.121749],
              [-114.618697, 35.121749],
              [-114.619802, 35.121655],
              [-114.621341, 35.121383],
              [-114.622329, 35.12121],
              [-114.624954, 35.120742],
              [-114.625807, 35.120514],
              [-114.626316, 35.120423],
              [-114.628993, 35.119411],
              [-114.627946, 35.119653],
              [-114.624808, 35.120366],
              [-114.623761, 35.120602],
              [-114.624671, 35.120229],
              [-114.62513, 35.12004],
              [-114.625581, 35.119856],
              [-114.625799, 35.119766],
              [-114.626329, 35.119637],
              [-114.626891, 35.119501],
              [-114.628427, 35.118943],
              [-114.629304, 35.118552],
              [-114.629377, 35.11852],
              [-114.629934, 35.118272],
              [-114.630441, 35.118009],
              [-114.630538, 35.117958],
              [-114.630636, 35.117908],
              [-114.630708, 35.11787],
              [-114.630926, 35.117757],
              [-114.630999, 35.11772],
              [-114.631949, 35.117251],
              [-114.632282, 35.117088],
              [-114.6334, 35.116032],
              [-114.633678, 35.11577],
              [-114.634331, 35.115169],
              [-114.635111, 35.114453],
              [-114.635182, 35.114385],
              [-114.635395, 35.11418],
              [-114.635467, 35.114113],
              [-114.635945, 35.113718],
              [-114.636423, 35.113323],
              [-114.636709, 35.113086],
              [-114.637432, 35.112489],
              [-114.639008, 35.110915],
              [-114.64008, 35.109635],
              [-114.641116, 35.108401],
              [-114.641231, 35.108302],
              [-114.641577, 35.108007],
              [-114.641693, 35.107909],
              [-114.643006, 35.106791],
              [-114.643247, 35.106631],
              [-114.644354, 35.105903],
              [-114.645152, 35.104995],
              [-114.646078, 35.103107],
              [-114.646758, 35.101877],
              [-114.646764, 35.101868],
              [-114.646588, 35.100868],
              [-114.646579, 35.10082],
              [-114.646003, 35.100079],
              [-114.645729, 35.099709],
              [-114.645642, 35.09959],
              [-114.644815, 35.098684],
              [-114.644507, 35.098346],
              [-114.644351, 35.098173],
              [-114.643885, 35.097657],
              [-114.643833, 35.0976],
              [-114.643729, 35.097487],
              [-114.643576, 35.09732],
              [-114.643368, 35.097092],
              [-114.643119, 35.096819],
              [-114.643008, 35.096697],
              [-114.642967, 35.096652],
              [-114.642831, 35.096503],
              [-114.642528, 35.096377],
              [-114.642337, 35.096297],
              [-114.641649, 35.096011],
              [-114.640346, 35.095341],
              [-114.639691, 35.095005],
              [-114.639677, 35.094975],
              [-114.639633, 35.094921],
              [-114.639597, 35.094876],
              [-114.639457, 35.094771],
              [-114.639389, 35.094741],
              [-114.63937, 35.094733],
              [-114.639291, 35.094713],
              [-114.639164, 35.09468],
              [-114.639129, 35.094672],
              [-114.638962, 35.094645],
              [-114.638776, 35.09463],
              [-114.638646, 35.09462],
              [-114.638034, 35.094409],
              [-114.636198, 35.093776],
              [-114.635586, 35.093566],
              [-114.634913, 35.093374],
              [-114.632895, 35.092798],
              [-114.632223, 35.092607],
              [-114.632189, 35.092597],
              [-114.632087, 35.092568],
              [-114.632053, 35.092559],
              [-114.628171, 35.090831],
              [-114.627354, 35.090468],
              [-114.625799, 35.089833],
              [-114.622517, 35.088703],
              [-114.618718, 35.086696],
              [-114.61865, 35.08666],
              [-114.618582, 35.086624],
              [-114.61842, 35.086539],
              [-114.616658, 35.085461],
              [-114.615507, 35.084757],
              [-114.613132, 35.083097],
              [-114.612603, 35.082681],
              [-114.612556, 35.082644],
              [-114.612084, 35.082263],
              [-114.609508, 35.080183],
              [-114.608987, 35.079707],
              [-114.607999, 35.078805],
              [-114.607701, 35.078533],
              [-114.607168, 35.077912],
              [-114.607075, 35.077804],
              [-114.606637, 35.077295],
              [-114.605545, 35.075961],
              [-114.605407, 35.075768],
              [-114.604891, 35.075047],
              [-114.604736, 35.07483],
              [-114.604401, 35.074156],
              [-114.604356, 35.074066],
              [-114.604311, 35.073976],
              [-114.604285, 35.073923],
              [-114.604207, 35.073767],
              [-114.604182, 35.073715],
              [-114.603175, 35.070445],
              [-114.603016, 35.069339],
              [-114.602908, 35.068588],
              [-114.602929, 35.068261],
              [-114.603042, 35.06654],
              [-114.603098, 35.066316],
              [-114.603619, 35.064226],
              [-114.604668, 35.06185],
              [-114.604715, 35.061744],
              [-114.604863, 35.061534],
              [-114.606694, 35.058941],
              [-114.609138, 35.05659],
              [-114.609278, 35.056455],
              [-114.610701, 35.055458],
              [-114.611692, 35.055001],
              [-114.611871, 35.054904],
              [-114.614342, 35.05358],
              [-114.615902, 35.05272],
              [-114.616776, 35.052157],
              [-114.616918, 35.052066],
              [-114.617111, 35.051944],
              [-114.617347, 35.051793],
              [-114.617446, 35.051731],
              [-114.617491, 35.051703],
              [-114.617606, 35.051626],
              [-114.617952, 35.051396],
              [-114.618068, 35.05132],
              [-114.619664, 35.050146],
              [-114.620805, 35.049343],
              [-114.62156, 35.048814],
              [-114.625799, 35.045834],
              [-114.627124, 35.044721],
              [-114.627968, 35.043724],
              [-114.628624, 35.042983],
              [-114.629027, 35.042531],
              [-114.629434, 35.041816],
              [-114.62977, 35.041228],
              [-114.630133, 35.040848],
              [-114.63039, 35.040578],
              [-114.630647, 35.040308],
              [-114.630657, 35.040297],
              [-114.630687, 35.040265],
              [-114.630698, 35.040255],
              [-114.631052, 35.039881],
              [-114.631892, 35.038407],
              [-114.631982, 35.038248],
              [-114.63284, 35.036977],
              [-114.633715, 35.035602],
              [-114.634328, 35.034476],
              [-114.634732, 35.033734],
              [-114.635753, 35.031343],
              [-114.635901, 35.030881],
              [-114.636329, 35.029542],
              [-114.636928, 35.028295],
              [-114.637524, 35.027053],
              [-114.637619, 35.025948],
              [-114.637712, 35.024771],
              [-114.63819, 35.022069],
              [-114.638057, 35.020866],
              [-114.637903, 35.019476],
              [-114.637924, 35.018514],
              [-114.637953, 35.017147],
              [-114.637769, 35.014948],
              [-114.637644, 35.013882],
              [-114.63764, 35.013851],
              [-114.637582, 35.013492],
              [-114.637523, 35.013134],
              [-114.637507, 35.013037],
              [-114.637261, 35.011534],
              [-114.637071, 35.010371],
              [-114.636821, 35.009359],
              [-114.636712, 35.008905],
              [-114.636289, 35.007804],
              [-114.63557, 35.005933],
              [-114.634235, 35.003322],
              [-114.633487, 35.001857],
              [-114.633607, 35.001953],
              [-114.63397, 35.002241],
              [-114.634091, 35.002338],
              [-114.63416, 35.002394],
              [-114.63437, 35.002562],
              [-114.63444, 35.002618],
              [-114.63612, 35.00398],
              [-114.64116, 35.008066],
              [-114.642841, 35.009428],
              [-114.642995, 35.009553],
              [-114.643375, 35.009861],
              [-114.64346, 35.009929],
              [-114.643615, 35.010055],
              [-114.645645, 35.011699],
              [-114.651735, 35.016633],
              [-114.653716, 35.018239],
              [-114.653765, 35.018279],
              [-114.654149, 35.018593],
              [-114.654446, 35.018836],
              [-114.656489, 35.020507],
              [-114.657171, 35.021064],
              [-114.657877, 35.021641],
              [-114.658436, 35.022098],
              [-114.662231, 35.0252],
              [-114.663407, 35.026162],
              [-114.663496, 35.026235],
              [-114.664554, 35.0271],
              [-114.667729, 35.029696],
              [-114.668748, 35.030529],
              [-114.668788, 35.030562],
              [-114.670093, 35.031629],
              [-114.674009, 35.03483],
              [-114.675315, 35.035897],
              [-114.675956, 35.036421],
              [-114.677882, 35.037993],
              [-114.678524, 35.038517],
              [-114.679003, 35.038904],
              [-114.680443, 35.040068],
              [-114.680541, 35.040147],
              [-114.680923, 35.040456],
              [-114.681441, 35.040874],
              [-114.682995, 35.04213],
              [-114.683438, 35.042489],
              [-114.683513, 35.04255],
              [-114.683978, 35.042926],
              [-114.685374, 35.044054],
              [-114.68584, 35.04443],
              [-114.690173, 35.047931],
              [-114.693499, 35.050619],
              [-114.695759, 35.052491],
              [-114.702737, 35.058119],
              [-114.703155, 35.058456],
              [-114.706796, 35.061393],
              [-114.707492, 35.061954],
              [-114.707665, 35.062094],
              [-114.708186, 35.062514],
              [-114.70836, 35.062654],
              [-114.70932, 35.063428],
              [-114.711354, 35.065068],
              [-114.712211, 35.065741],
              [-114.713182, 35.066503],
              [-114.714117, 35.067237],
              [-114.715534, 35.068394],
              [-114.722548, 35.07412],
              [-114.724887, 35.076029],
              [-114.725037, 35.076152],
              [-114.725489, 35.076521],
              [-114.72564, 35.076644],
              [-114.726275, 35.077162],
              [-114.72818, 35.078717],
              [-114.728816, 35.079236],
              [-114.729802, 35.08004],
              [-114.729901, 35.080116],
              [-114.731561, 35.081386],
              [-114.733186, 35.082719],
              [-114.734267, 35.083606],
              [-114.734614, 35.08389],
              [-114.735655, 35.084745],
              [-114.736003, 35.08503],
              [-114.736445, 35.085392],
              [-114.737773, 35.086481],
              [-114.738216, 35.086844],
              [-114.738825, 35.087343],
              [-114.739546, 35.087934],
              [-114.743536, 35.091206],
              [-114.744733, 35.092187],
              [-114.744867, 35.092297],
              [-114.74519, 35.092562],
              [-114.74616, 35.093357],
              [-114.746484, 35.093622],
              [-114.746618, 35.093732],
              [-114.747022, 35.094063],
              [-114.747157, 35.094174],
              [-114.749318, 35.095945],
              [-114.749572, 35.096154],
              [-114.756809, 35.102106],
              [-114.759222, 35.104091],
              [-114.761801, 35.106212],
              [-114.765601, 35.109337],
              [-114.769568, 35.112544],
              [-114.772166, 35.114645],
              [-114.772397, 35.114832],
              [-114.773092, 35.115393],
              [-114.773324, 35.115581],
              [-114.776247, 35.117944],
              [-114.779626, 35.120675],
              [-114.780264, 35.121175],
              [-114.783733, 35.12389],
              [-114.784631, 35.124619],
              [-114.785068, 35.124973],
              [-114.787878, 35.127253],
              [-114.787988, 35.127342],
              [-114.788697, 35.12792],
              [-114.788982, 35.128151],
              [-114.791245, 35.129984],
              [-114.791971, 35.130572],
              [-114.792967, 35.13138],
              [-114.797341, 35.134927],
              [-114.801313, 35.13803],
              [-114.803864, 35.140082],
              [-114.804571, 35.14065],
              [-114.804902, 35.140916],
              [-114.805128, 35.141098],
              [-114.805807, 35.141644],
              [-114.806034, 35.141827],
              [-114.807185, 35.142753],
              [-114.807793, 35.143242],
              [-114.81064, 35.145531],
              [-114.811591, 35.146296],
              [-114.811792, 35.146458],
              [-114.813531, 35.147856],
              [-114.815122, 35.149135],
              [-114.81878, 35.152013],
              [-114.81915, 35.152304],
              [-114.820525, 35.153405],
              [-114.823562, 35.155835],
              [-114.824408, 35.156513],
              [-114.826118, 35.157882],
              [-114.827555, 35.159031],
              [-114.827811, 35.159237],
              [-114.832673, 35.163128],
              [-114.835711, 35.165559],
              [-114.838426, 35.167615],
              [-114.840297, 35.169031],
              [-114.846574, 35.173783],
              [-114.84929, 35.17584],
              [-114.850209, 35.176534],
              [-114.852802, 35.178494],
              [-114.852973, 35.178608],
              [-114.853021, 35.17864],
              [-114.853932, 35.179247],
              [-114.860131, 35.184343],
              [-114.862223, 35.186063],
              [-114.863701, 35.187278],
              [-114.874283, 35.195977],
              [-114.874884, 35.196471],
              [-114.87904, 35.199888],
              [-114.885376, 35.205096],
              [-114.887097, 35.206511],
              [-114.88862, 35.207763],
              [-114.893899, 35.212103],
              [-114.895389, 35.213328],
              [-114.895987, 35.213803],
              [-114.897611, 35.215094],
              [-114.901487, 35.218174],
              [-114.90286, 35.219265],
              [-114.904106, 35.220256],
              [-114.90415, 35.220291],
              [-114.90428, 35.220394],
              [-114.905134, 35.221073],
              [-114.905548, 35.221402],
              [-114.905816, 35.221615],
              [-114.906503, 35.222161],
              [-114.907137, 35.222665],
              [-114.909042, 35.22418],
              [-114.90922, 35.224321],
              [-114.909678, 35.224685],
              [-114.912292, 35.226762],
              [-114.913444, 35.227677],
              [-114.915422, 35.229248],
              [-114.917835, 35.231166],
              [-114.920137, 35.232994],
              [-114.922752, 35.235072],
              [-114.922919, 35.235205],
              [-114.923255, 35.235472],
              [-114.924167, 35.236197],
              [-114.924767, 35.236674],
              [-114.925272, 35.237075],
              [-114.925305, 35.237097],
              [-114.92537, 35.237143],
              [-114.925402, 35.237168],
              [-114.925434, 35.237194],
              [-114.92987, 35.240719],
              [-114.931459, 35.241981],
              [-114.936449, 35.245946],
              [-114.943181, 35.251294],
              [-114.946779, 35.254153],
              [-114.947619, 35.254819],
              [-114.947725, 35.254903],
              [-114.948046, 35.255158],
              [-114.948153, 35.255243],
              [-114.949364, 35.256204],
              [-114.952997, 35.259088],
              [-114.954209, 35.26005],
              [-114.954791, 35.260512],
              [-114.95654, 35.261899],
              [-114.957123, 35.262362],
              [-114.959992, 35.264639],
              [-114.968602, 35.27147],
              [-114.971472, 35.273747],
              [-114.981551, 35.28174],
              [-114.982801, 35.282732],
              [-114.984368, 35.283975],
              [-114.990233, 35.288836],
              [-114.994666, 35.292511],
              [-115.01168, 35.305852],
              [-115.015928, 35.309182],
              [-115.021804, 35.31379],
              [-115.025175, 35.316431],
              [-115.032207, 35.321941],
              [-115.035288, 35.324355],
              [-115.038384, 35.326781],
              [-115.03866, 35.326997],
              [-115.039811, 35.327899],
              [-115.040194, 35.328199],
              [-115.043266, 35.330606],
              [-115.044111, 35.331268],
              [-115.044418, 35.331508],
              [-115.045225, 35.33214],
              [-115.047152, 35.333649],
              [-115.04903, 35.335119],
              [-115.051726, 35.33723],
              [-115.053432, 35.338565],
              [-115.057051, 35.341399],
              [-115.062866, 35.345952],
              [-115.067479, 35.349564],
              [-115.067699, 35.349736],
              [-115.070788, 35.352324],
              [-115.080698, 35.360626],
              [-115.084002, 35.363394],
              [-115.084274, 35.363581],
              [-115.085092, 35.364144],
              [-115.085365, 35.364332],
              [-115.086318, 35.365007],
              [-115.087927, 35.366147],
              [-115.08883, 35.366786],
              [-115.089231, 35.36707],
              [-115.089293, 35.367114],
              [-115.09206, 35.369074],
              [-115.092842, 35.369628],
              [-115.093274, 35.369934],
              [-115.094438, 35.370758],
              [-115.095891, 35.371787],
              [-115.09651, 35.372263],
              [-115.097341, 35.372904],
              [-115.098367, 35.373694],
              [-115.098986, 35.374172],
              [-115.099454, 35.37453],
              [-115.100066, 35.374998],
              [-115.100957, 35.375681],
              [-115.103264, 35.377534],
              [-115.104325, 35.378387],
              [-115.106704, 35.380301],
              [-115.112692, 35.385118],
              [-115.113844, 35.386044],
              [-115.116225, 35.387958],
              [-115.119579, 35.390654],
              [-115.124157, 35.394335],
              [-115.129645, 35.398742],
              [-115.133001, 35.401438],
              [-115.13574, 35.403637],
              [-115.142198, 35.408822],
              [-115.143957, 35.410234],
              [-115.146697, 35.412434],
              [-115.147151, 35.412798],
              [-115.148515, 35.413893],
              [-115.14897, 35.414258],
              [-115.151164, 35.416019],
              [-115.157749, 35.421303],
              [-115.159944, 35.423065],
              [-115.160652, 35.423632],
              [-115.161057, 35.423958],
              [-115.161281, 35.424184],
              [-115.162067, 35.424983],
              [-115.164904, 35.427867],
              [-115.165377, 35.428239],
              [-115.166796, 35.429358],
              [-115.166846, 35.429398],
              [-115.167269, 35.429732],
              [-115.169291, 35.431339],
              [-115.175359, 35.436162],
              [-115.176975, 35.437446],
              [-115.177383, 35.437769],
              [-115.180696, 35.440393],
              [-115.180937, 35.440584],
              [-115.184332, 35.443273],
              [-115.190641, 35.44826],
              [-115.193389, 35.450432],
              [-115.193958, 35.450882],
              [-115.194131, 35.451019],
              [-115.195163, 35.451836],
              [-115.197764, 35.453896],
              [-115.203526, 35.458462],
              [-115.204512, 35.459244],
              [-115.209179, 35.462945],
              [-115.212853, 35.465859],
              [-115.212984, 35.465963],
              [-115.214314, 35.46701],
              [-115.215511, 35.467954],
              [-115.218304, 35.470153],
              [-115.21951, 35.471103],
              [-115.219633, 35.471204],
              [-115.220778, 35.472145],
              [-115.22263, 35.473668],
              [-115.224225, 35.474956],
              [-115.225186, 35.475732],
              [-115.225378, 35.475889],
              [-115.226334, 35.476661],
              [-115.226557, 35.476842],
              [-115.229201, 35.478981],
              [-115.229615, 35.479316],
              [-115.230158, 35.479753],
              [-115.232568, 35.481691],
              [-115.233218, 35.482214],
              [-115.237685, 35.485807],
              [-115.2398, 35.487508],
              [-115.241786, 35.489105],
              [-115.242213, 35.489444],
              [-115.243281, 35.49029],
              [-115.245071, 35.49171],
              [-115.246486, 35.49283],
              [-115.246501, 35.492842],
              [-115.247554, 35.493679],
              [-115.24812, 35.494138],
              [-115.249987, 35.495651],
              [-115.25414, 35.499018],
              [-115.256692, 35.501086],
              [-115.257286, 35.501568],
              [-115.258173, 35.502287],
              [-115.25972, 35.503541],
              [-115.261509, 35.504942],
              [-115.261637, 35.505042],
              [-115.264879, 35.507581],
              [-115.265471, 35.508044],
              [-115.266348, 35.508731],
              [-115.267388, 35.509546],
              [-115.267761, 35.509838],
              [-115.269306, 35.511048],
              [-115.269457, 35.511166],
              [-115.26973, 35.511375],
              [-115.270878, 35.512256],
              [-115.271006, 35.512354],
              [-115.271432, 35.512681],
              [-115.271448, 35.512694],
              [-115.271497, 35.512734],
              [-115.271514, 35.512748],
              [-115.275475, 35.515752],
              [-115.276271, 35.516354],
              [-115.279194, 35.518572],
              [-115.282534, 35.521105],
              [-115.283972, 35.522201],
              [-115.283996, 35.52222],
              [-115.303884, 35.538062],
              [-115.305494, 35.539343],
              [-115.307368, 35.540835],
              [-115.307438, 35.540891],
              [-115.309544, 35.542569],
              [-115.311595, 35.544204],
              [-115.314992, 35.546912],
              [-115.320189, 35.551053],
              [-115.321593, 35.552172],
              [-115.323541, 35.55372],
              [-115.32428, 35.554307],
              [-115.325601, 35.555358],
              [-115.328512, 35.557671],
              [-115.329567, 35.558509],
              [-115.330969, 35.559614],
              [-115.334074, 35.562063],
              [-115.336667, 35.564107],
              [-115.336941, 35.564323],
              [-115.342334, 35.568576],
              [-115.346607, 35.571944],
              [-115.352516, 35.576604],
              [-115.356781, 35.579967],
              [-115.359394, 35.582027],
              [-115.361141, 35.583404],
              [-115.365191, 35.586598],
              [-115.366799, 35.587866],
              [-115.368588, 35.589276],
              [-115.369053, 35.589596],
              [-115.369317, 35.589813],
              [-115.369335, 35.589829],
              [-115.369392, 35.589877],
              [-115.369412, 35.589894],
              [-115.369514, 35.589977],
              [-115.369698, 35.590128],
              [-115.369816, 35.590233],
              [-115.369915, 35.590322],
              [-115.369946, 35.590346],
              [-115.369955, 35.590353],
              [-115.370148, 35.590504],
              [-115.370729, 35.590959],
              [-115.370923, 35.591111],
              [-115.37094, 35.591125],
              [-115.370991, 35.59117],
              [-115.371009, 35.591186],
              [-115.37111, 35.591263],
              [-115.371413, 35.591494],
              [-115.371515, 35.591572],
              [-115.372223, 35.592111],
              [-115.372408, 35.592252],
              [-115.372799, 35.592566],
              [-115.375723, 35.594909],
              [-115.376607, 35.595607],
              [-115.377883, 35.596614],
              [-115.380065, 35.598338],
              [-115.38075, 35.598879],
              [-115.386613, 35.60351],
              [-115.388796, 35.605234],
              [-115.388968, 35.60537],
              [-115.389485, 35.605778],
              [-115.389658, 35.605915],
              [-115.390548, 35.606546],
              [-115.391007, 35.606942],
              [-115.391139, 35.607057],
              [-115.391467, 35.607319],
              [-115.394098, 35.609422],
              [-115.395162, 35.610257],
              [-115.395286, 35.610356],
              [-115.395661, 35.610655],
              [-115.395787, 35.610755],
              [-115.397142, 35.611825],
              [-115.401207, 35.615038],
              [-115.402563, 35.616109],
              [-115.402874, 35.616355],
              [-115.40295, 35.616415],
              [-115.404111, 35.617333],
              [-115.404499, 35.617639],
              [-115.404685, 35.617842],
              [-115.404739, 35.617901],
              [-115.404847, 35.618018],
              [-115.405566, 35.61846],
              [-115.405629, 35.618478],
              [-115.405971, 35.618579],
              [-115.405926, 35.618766],
              [-115.407503, 35.620013],
              [-115.407639, 35.620121],
              [-115.408622, 35.6209],
              [-115.418086, 35.628389],
              [-115.420508, 35.630292],
              [-115.424481, 35.633413],
              [-115.429534, 35.637384],
              [-115.43273, 35.639895],
              [-115.439137, 35.644929],
              [-115.44125, 35.646589],
              [-115.444783, 35.649365],
              [-115.447047, 35.651144],
              [-115.450253, 35.653663],
              [-115.454318, 35.656857],
              [-115.458269, 35.659962],
              [-115.462059, 35.662939],
              [-115.462727, 35.663464],
              [-115.470931, 35.66991],
              [-115.472937, 35.671487],
              [-115.481134, 35.677927],
              [-115.482471, 35.678977],
              [-115.483642, 35.679897],
              [-115.484144, 35.680292],
              [-115.485063, 35.681016],
              [-115.498266, 35.691423],
              [-115.506429, 35.697855],
              [-115.507909, 35.699021],
              [-115.510205, 35.700829],
              [-115.514596, 35.704288],
              [-115.514974, 35.704585],
              [-115.517703, 35.706736],
              [-115.521888, 35.710031],
              [-115.524427, 35.712029],
              [-115.525358, 35.71276],
              [-115.525767, 35.713085],
              [-115.528901, 35.715552],
              [-115.54096, 35.725038],
              [-115.549856, 35.732036],
              [-115.557025, 35.737675],
              [-115.572613, 35.749918],
              [-115.574304, 35.751246],
              [-115.584272, 35.759069],
              [-115.587527, 35.76163],
              [-115.588854, 35.762675],
              [-115.589942, 35.763532],
              [-115.591513, 35.764774],
              [-115.591538, 35.764795],
              [-115.591802, 35.765001],
              [-115.593869, 35.766618],
              [-115.59827, 35.770071],
              [-115.601333, 35.772474],
              [-115.601407, 35.772533],
              [-115.610125, 35.779365],
              [-115.620967, 35.787865],
              [-115.621208, 35.788055],
              [-115.621299, 35.788126],
              [-115.621322, 35.788145],
              [-115.626008, 35.791815],
              [-115.626355, 35.792088],
              [-115.628218, 35.793547],
              [-115.635554, 35.799293],
              [-115.637295, 35.800657],
              [-115.64438, 35.806205],
              [-115.64584, 35.807348],
              [-115.646336, 35.807735],
              [-115.646689, 35.807997],
              [-115.647748, 35.808785],
              [-115.648323, 35.809189],
              [-115.648357, 35.809211],
              [-115.648727, 35.809474],
              [-115.648794, 35.809522],
              [-115.648896, 35.809605],
              [-115.648944, 35.809644],
              [-115.648973, 35.809668],
              [-115.649167, 35.809827],
              [-115.649373, 35.809995],
              [-115.649423, 35.810034],
              [-115.649978, 35.810468],
              [-115.650065, 35.810537],
              [-115.650326, 35.810746],
              [-115.651812, 35.811908],
              [-115.651894, 35.811972],
              [-115.652327, 35.81231],
              [-115.652813, 35.812702],
              [-115.652903, 35.812775],
              [-115.654052, 35.813669],
              [-115.654611, 35.814104],
              [-115.655125, 35.8145],
              [-115.655628, 35.814887],
              [-115.655689, 35.814935],
              [-115.656072, 35.815238],
              [-115.656203, 35.815342],
              [-115.656982, 35.815959],
              [-115.657078, 35.816035],
              [-115.657331, 35.816219],
              [-115.657351, 35.816236],
              [-115.657565, 35.816415],
              [-115.657662, 35.816479],
              [-115.657734, 35.81654],
              [-115.6578, 35.816565],
              [-115.657774, 35.816631],
              [-115.658136, 35.816872],
              [-115.658165, 35.816892],
              [-115.65857, 35.817203],
              [-115.658751, 35.817342],
              [-115.659218, 35.817697],
              [-115.659316, 35.817771],
              [-115.660558, 35.818765],
              [-115.66135, 35.819399],
              [-115.661635, 35.819627],
              [-115.662182, 35.820038],
              [-115.662388, 35.820193],
              [-115.662564, 35.820332],
              [-115.663098, 35.820753],
              [-115.663609, 35.821141],
              [-115.665052, 35.822273],
              [-115.666941, 35.823776],
              [-115.667217, 35.823984],
              [-115.667377, 35.824105],
              [-115.668965, 35.825349],
              [-115.669842, 35.826023],
              [-115.670407, 35.826457],
              [-115.670555, 35.826572],
              [-115.671341, 35.82718],
              [-115.67136, 35.827195],
              [-115.671527, 35.827327],
              [-115.673589, 35.828964],
              [-115.677764, 35.832229],
              [-115.678707, 35.832956],
              [-115.680122, 35.834064],
              [-115.680381, 35.834267],
              [-115.681205, 35.834923],
              [-115.68196, 35.835523],
              [-115.683494, 35.8367],
              [-115.683679, 35.836842],
              [-115.685678, 35.838407],
              [-115.685879, 35.83855],
              [-115.685952, 35.83861],
              [-115.686001, 35.83865],
              [-115.686054, 35.838693],
              [-115.687029, 35.839444],
              [-115.688274, 35.840373],
              [-115.688686, 35.840685],
              [-115.689285, 35.841157],
              [-115.689408, 35.841279],
              [-115.689483, 35.841388],
              [-115.689568, 35.841431],
              [-115.689581, 35.841437],
              [-115.68994, 35.841708],
              [-115.690481, 35.842149],
              [-115.692055, 35.843365],
              [-115.69245, 35.843658],
              [-115.69298, 35.844094],
              [-115.693312, 35.844347],
              [-115.693646, 35.84458],
              [-115.693749, 35.844671],
              [-115.693977, 35.844892],
              [-115.694317, 35.845162],
              [-115.694551, 35.845335],
              [-115.694631, 35.845426],
              [-115.694764, 35.845639],
              [-115.694849, 35.84576],
              [-115.695126, 35.84601],
              [-115.695437, 35.846258],
              [-115.695733, 35.846481],
              [-115.702771, 35.851899],
              [-115.702824, 35.851941],
              [-115.707478, 35.85558],
              [-115.709064, 35.85687],
              [-115.742049, 35.882581],
              [-115.755674, 35.893183],
              [-115.755784, 35.893268],
              [-115.758219, 35.895161],
              [-115.761135, 35.89743],
              [-115.775131, 35.908455],
              [-115.779525, 35.911914],
              [-115.784504, 35.915799],
              [-115.789101, 35.919248],
              [-115.78928, 35.919524],
              [-115.797575, 35.925992],
              [-115.826465, 35.948496],
              [-115.826809, 35.948763],
              [-115.834211, 35.954529],
              [-115.834582, 35.954817],
              [-115.835931, 35.955871],
              [-115.845431, 35.963269],
              [-115.845994, 35.963707],
              [-115.854051, 35.96998],
              [-115.857677, 35.972803],
              [-115.865818, 35.979137],
              [-115.869153, 35.981728],
              [-115.870681, 35.982921],
              [-115.872622, 35.98443],
              [-115.873491, 35.985099],
              [-115.873875, 35.985391],
              [-115.881633, 35.991397],
              [-115.88355, 35.992863],
              [-115.883607, 35.992924],
              [-115.884054, 35.993268],
              [-115.884932, 35.993946],
              [-115.887461, 35.995892],
              [-115.888353, 35.996585],
              [-115.890082, 35.997917],
              [-115.890604, 35.998315],
              [-115.890615, 35.998323],
              [-115.891037, 35.998645],
              [-115.891071, 35.998672],
              [-115.891554, 35.999057],
              [-115.89285, 36.000051],
              [-115.893398, 36.00047],
              [-115.893951, 36.000892],
              [-115.894256, 36.001131],
              [-115.894283, 36.001152],
              [-115.894459, 36.00129],
              [-115.895116, 36.001804],
              [-115.895151, 36.001832],
              [-115.895642, 36.002218],
              [-115.896183, 36.002648],
              [-115.897452, 36.00363],
              [-115.897609, 36.003744],
              [-115.898003, 36.004051],
              [-115.898281, 36.004268],
              [-115.899498, 36.005197],
              [-115.90059, 36.006045],
              [-115.900785, 36.0062],
              [-115.901442, 36.006722],
              [-115.903315, 36.008191],
              [-115.904089, 36.008777],
              [-115.906701, 36.010798],
              [-115.907693, 36.011574],
              [-115.908421, 36.012144],
              [-115.908747, 36.012377],
              [-115.90943, 36.012917],
              [-115.911759, 36.014721],
              [-115.911774, 36.014733],
              [-115.911992, 36.014902],
              [-115.912201, 36.015071],
              [-115.912387, 36.015221],
              [-115.912423, 36.015246],
              [-115.912452, 36.015267],
              [-115.912478, 36.015285],
              [-115.912564, 36.015324],
              [-115.913914, 36.016329],
              [-115.913964, 36.016369],
              [-115.918087, 36.019446],
              [-115.918727, 36.019923],
              [-115.918743, 36.019936],
              [-115.92134, 36.021958],
              [-115.921788, 36.022307],
              [-115.921842, 36.022349],
              [-115.923556, 36.023684],
              [-115.92385, 36.023913],
              [-115.924066, 36.024081],
              [-115.924257, 36.02423],
              [-115.924305, 36.024267],
              [-115.924364, 36.024313],
              [-115.924379, 36.024325],
              [-115.92444, 36.024373],
              [-115.924988, 36.0248],
              [-115.926931, 36.026313],
              [-115.929943, 36.028659],
              [-115.936569, 36.03382],
              [-115.93739, 36.034459],
              [-115.939056, 36.035746],
              [-115.939461, 36.036059],
              [-115.939472, 36.036068],
              [-115.939507, 36.036095],
              [-115.93962, 36.036182],
              [-115.940008, 36.036482],
              [-115.941445, 36.037592],
              [-115.943171, 36.038935],
              [-115.944593, 36.040041],
              [-115.946725, 36.041698],
              [-115.947435, 36.042251],
              [-115.947447, 36.04226],
              [-115.94747, 36.042278],
              [-115.947505, 36.042306],
              [-115.947521, 36.042318],
              [-115.948002, 36.042692],
              [-115.948798, 36.043311],
              [-115.951759, 36.045614],
              [-115.95761, 36.050165],
              [-115.962489, 36.05396],
              [-115.96643, 36.057024],
              [-115.978832, 36.066669],
              [-115.985436, 36.071805],
              [-115.988045, 36.073842],
              [-115.988844, 36.074457],
              [-115.988967, 36.074551],
              [-115.993107, 36.077771],
              [-115.9953, 36.079477],
              [-116.000655, 36.083857],
              [-116.004892, 36.087124],
              [-116.005398, 36.087515],
              [-116.00552, 36.087609],
              [-116.00555, 36.087632],
              [-116.005575, 36.087651],
              [-116.005666, 36.087721],
              [-116.005785, 36.087813],
              [-116.009481, 36.090663],
              [-116.010091, 36.091133],
              [-116.012061, 36.092653],
              [-116.012074, 36.092663],
              [-116.012091, 36.092676],
              [-116.012118, 36.092697],
              [-116.012163, 36.092732],
              [-116.012223, 36.092778],
              [-116.015577, 36.095365],
              [-116.023238, 36.101273],
              [-116.029482, 36.106088],
              [-116.031762, 36.107846],
              [-116.035692, 36.11087],
              [-116.036985, 36.111874],
              [-116.037788, 36.112498],
              [-116.041313, 36.115233],
              [-116.046739, 36.119441],
              [-116.063859, 36.13273],
              [-116.065856, 36.134274],
              [-116.066224, 36.134559],
              [-116.067922, 36.135872],
              [-116.068023, 36.13595],
              [-116.068061, 36.135979],
              [-116.068171, 36.136064],
              [-116.0683, 36.136164],
              [-116.069437, 36.137043],
              [-116.070698, 36.138019],
              [-116.071211, 36.138416],
              [-116.07155, 36.138678],
              [-116.0716, 36.138716],
              [-116.071624, 36.138735],
              [-116.071853, 36.138912],
              [-116.072576, 36.139471],
              [-116.072772, 36.139623],
              [-116.072785, 36.139633],
              [-116.075889, 36.142033],
              [-116.076196, 36.142271],
              [-116.079644, 36.144937],
              [-116.079692, 36.144974],
              [-116.080426, 36.145543],
              [-116.080694, 36.145751],
              [-116.080707, 36.145761],
              [-116.08072, 36.145771],
              [-116.081118, 36.14608],
              [-116.082553, 36.14719],
              [-116.082843, 36.147414],
              [-116.082905, 36.147462],
              [-116.082914, 36.147469],
              [-116.082941, 36.147489],
              [-116.083013, 36.147545],
              [-116.083454, 36.147886],
              [-116.08422, 36.148479],
              [-116.084385, 36.148607],
              [-116.084558, 36.148741],
              [-116.087343, 36.150897],
              [-116.088413, 36.151724],
              [-116.088682, 36.151932],
              [-116.089294, 36.152404],
              [-116.089308, 36.152415],
              [-116.090952, 36.153688],
              [-116.092575, 36.154945],
              [-116.093099, 36.155349],
              [-116.093133, 36.155374],
              [-116.093601, 36.155805],
              [-116.094166, 36.156263],
              [-116.09466, 36.156601],
              [-116.097216, 36.158346],
              [-116.098669, 36.159473],
              [-116.105191, 36.164545],
              [-116.108531, 36.167138],
              [-116.110275, 36.168495],
              [-116.115608, 36.172636],
              [-116.115614, 36.172651],
              [-116.122009, 36.177628],
              [-116.123977, 36.179134],
              [-116.125253, 36.180156],
              [-116.125888, 36.180664],
              [-116.129123, 36.183168],
              [-116.129137, 36.183176],
              [-116.130122, 36.183935],
              [-116.134064, 36.186974],
              [-116.145652, 36.195906],
              [-116.146359, 36.196451],
              [-116.14848, 36.198086],
              [-116.148596, 36.198176],
              [-116.153996, 36.202338],
              [-116.15446, 36.202696],
              [-116.15517, 36.203243],
              [-116.155287, 36.203333],
              [-116.155326, 36.203363],
              [-116.155361, 36.20339],
              [-116.155787, 36.203718],
              [-116.158345, 36.20569],
              [-116.159284, 36.206414],
              [-116.159301, 36.206427],
              [-116.159376, 36.206485],
              [-116.1637, 36.209819],
              [-116.168726, 36.213694],
              [-116.169054, 36.213947],
              [-116.176469, 36.21967],
              [-116.183884, 36.225393],
              [-116.189653, 36.229846],
              [-116.191145, 36.231001],
              [-116.19123, 36.231067],
              [-116.193737, 36.233002],
              [-116.19404, 36.233236],
              [-116.194083, 36.23327],
              [-116.194094, 36.233278],
              [-116.194986, 36.233967],
              [-116.198993, 36.23706],
              [-116.202685, 36.23991],
              [-116.203225, 36.240327],
              [-116.203818, 36.240785],
              [-116.204212, 36.241089],
              [-116.204226, 36.2411],
              [-116.204238, 36.241109],
              [-116.204249, 36.241117],
              [-116.204289, 36.241148],
              [-116.204329, 36.241179],
              [-116.204465, 36.241284],
              [-116.204986, 36.241686],
              [-116.208402, 36.244323],
              [-116.21264, 36.247595],
              [-116.215041, 36.249443],
              [-116.215054, 36.249453],
              [-116.215736, 36.249951],
              [-116.219319, 36.252701],
              [-116.22885, 36.260015],
              [-116.228918, 36.260067],
              [-116.230542, 36.261314],
              [-116.241328, 36.269593],
              [-116.245838, 36.273051],
              [-116.249662, 36.276037],
              [-116.2499, 36.276223],
              [-116.249975, 36.276282],
              [-116.249986, 36.27629],
              [-116.250781, 36.276911],
              [-116.250809, 36.276932],
              [-116.250818, 36.276939],
              [-116.261692, 36.285227],
              [-116.261828, 36.285331],
              [-116.320858, 36.330318],
              [-116.321138, 36.330532],
              [-116.326918, 36.334944],
              [-116.327804, 36.335606],
              [-116.33551, 36.341483],
              [-116.33596, 36.341833],
              [-116.34297, 36.347175],
              [-116.345579, 36.349166],
              [-116.349017, 36.351789],
              [-116.349156, 36.351895],
              [-116.349179, 36.351913],
              [-116.349217, 36.351942],
              [-116.34931, 36.352013],
              [-116.349991, 36.352532],
              [-116.350014, 36.35255],
              [-116.355034, 36.356388],
              [-116.355068, 36.356414],
              [-116.365645, 36.364464],
              [-116.368935, 36.366972],
              [-116.371221, 36.368714],
              [-116.372799, 36.369924],
              [-116.373909, 36.370876],
              [-116.374361, 36.371264],
              [-116.375012, 36.371822],
              [-116.375189, 36.371974],
              [-116.375258, 36.372033],
              [-116.375277, 36.372049],
              [-116.375305, 36.372073],
              [-116.375402, 36.372156],
              [-116.375425, 36.372176],
              [-116.375878, 36.372564],
              [-116.3759, 36.372583],
              [-116.377821, 36.373699],
              [-116.380347, 36.374961],
              [-116.404615, 36.39389],
              [-116.404643, 36.393912],
              [-116.41175, 36.399441],
              [-116.415006, 36.401992],
              [-116.415044, 36.402022],
              [-116.416865, 36.403446],
              [-116.417184, 36.403695],
              [-116.418521, 36.404737],
              [-116.418562, 36.404769],
              [-116.41871, 36.404885],
              [-116.419235, 36.405294],
              [-116.422299, 36.407682],
              [-116.439642, 36.421176],
              [-116.442358, 36.423289],
              [-116.445201, 36.425501],
              [-116.445229, 36.425523],
              [-116.472136, 36.446558],
              [-116.472579, 36.446869],
              [-116.473496, 36.447605],
              [-116.488233, 36.459097],
              [-116.488402, 36.459219],
              [-116.488431, 36.45924],
              [-116.500882, 36.468223],
              [-116.519867, 36.482853],
              [-116.522567, 36.484931],
              [-116.522577, 36.484938],
              [-116.522599, 36.484955],
              [-116.522639, 36.484986],
              [-116.522648, 36.484993],
              [-116.52266, 36.485003],
              [-116.522703, 36.485036],
              [-116.522728, 36.485055],
              [-116.522816, 36.485123],
              [-116.522977, 36.485247],
              [-116.523054, 36.485306],
              [-116.523071, 36.485319],
              [-116.523202, 36.48542],
              [-116.529694, 36.490425],
              [-116.529897, 36.490581],
              [-116.534534, 36.494173],
              [-116.541983, 36.499952],
              [-116.543618, 36.501191],
              [-116.544647, 36.501971],
              [-116.559748, 36.513422],
              [-116.560967, 36.514346],
              [-116.585487, 36.532942],
              [-116.59043, 36.536687],
              [-116.593966, 36.539367],
              [-116.595151, 36.540266],
              [-116.595297, 36.540376],
              [-116.596566, 36.541339],
              [-116.607991, 36.550004],
              [-116.618206, 36.557752],
              [-116.625941, 36.563618],
              [-116.656883, 36.587088],
              [-116.657349, 36.587438],
              [-116.658243, 36.588109],
              [-116.661668, 36.590706],
              [-116.678291, 36.603306],
              [-116.689629, 36.611901],
              [-116.691917, 36.613635],
              [-116.706282, 36.624527],
              [-116.706833, 36.624947],
              [-116.710248, 36.627535],
              [-116.711943, 36.628822],
              [-116.718507, 36.633797],
              [-116.727732, 36.640798],
              [-116.730232, 36.642683],
              [-116.750926, 36.658377],
              [-116.753148, 36.660066],
              [-116.753159, 36.660074],
              [-116.75908, 36.664573],
              [-116.76035, 36.665538],
              [-116.760391, 36.665569],
              [-116.7604, 36.665576],
              [-116.760428, 36.665598],
              [-116.760469, 36.665629],
              [-116.760698, 36.665803],
              [-116.760759, 36.665849],
              [-116.761005, 36.666036],
              [-116.762929, 36.667498],
              [-116.77494, 36.676624],
              [-116.786102, 36.685105],
              [-116.786859, 36.685682],
              [-116.787009, 36.685796],
              [-116.791488, 36.689198],
              [-116.792503, 36.689969],
              [-116.797826, 36.694015],
              [-116.81154, 36.704437],
              [-116.811886, 36.7047],
              [-116.818824, 36.709973],
              [-116.820223, 36.711033],
              [-116.82125, 36.711812],
              [-116.82625, 36.715612],
              [-116.830283, 36.718676],
              [-116.836628, 36.723498],
              [-116.837318, 36.724022],
              [-116.837522, 36.724177],
              [-116.83755, 36.724198],
              [-116.838155, 36.724657],
              [-116.84287, 36.72824],
              [-116.843472, 36.728698],
              [-116.845049, 36.729894],
              [-116.845162, 36.72998],
              [-116.845285, 36.730074],
              [-116.846219, 36.730784],
              [-116.847333, 36.731632],
              [-116.848166, 36.732264],
              [-116.849245, 36.733091],
              [-116.856646, 36.738711],
              [-116.858237, 36.739919],
              [-116.863874, 36.7442],
              [-116.866285, 36.746031],
              [-116.871442, 36.749947],
              [-116.872108, 36.750452],
              [-116.872214, 36.750532],
              [-116.872268, 36.750573],
              [-116.872287, 36.750587],
              [-116.8723, 36.750597],
              [-116.872324, 36.750616],
              [-116.873732, 36.751685],
              [-116.874254, 36.752081],
              [-116.875893, 36.753308],
              [-116.880674, 36.75692],
              [-116.889365, 36.763482],
              [-116.89402, 36.766998],
              [-116.927908, 36.792588],
              [-116.929241, 36.793591],
              [-116.929952, 36.794126],
              [-116.929999, 36.794162],
              [-116.942454, 36.803567],
              [-116.950117, 36.809354],
              [-116.950182, 36.809403],
              [-116.950243, 36.809449],
              [-116.95026, 36.809462],
              [-116.950287, 36.809483],
              [-116.950348, 36.809529],
              [-116.95042, 36.809583],
              [-116.950518, 36.809657],
              [-116.95071, 36.809802],
              [-116.952985, 36.81152],
              [-116.953384, 36.811821],
              [-116.957877, 36.815213],
              [-116.958533, 36.815707],
              [-116.958747, 36.815869],
              [-116.968407, 36.823163],
              [-116.975201, 36.828293],
              [-116.975348, 36.828404],
              [-116.975362, 36.828415],
              [-116.987068, 36.837254],
              [-116.987239, 36.837383],
              [-116.987832, 36.83783],
              [-116.994836, 36.843113],
              [-116.995161, 36.843359],
              [-116.998475, 36.845866],
              [-116.998536, 36.845912],
              [-116.998614, 36.845971],
              [-116.998626, 36.84598],
              [-116.998882, 36.846174],
              [-116.999048, 36.846299],
              [-116.99908, 36.846323],
              [-116.999322, 36.846506],
              [-116.999427, 36.846585],
              [-117.000895, 36.847694],
              [-117.004011, 36.849735],
              [-117.006443, 36.851545],
              [-117.0129, 36.856349],
              [-117.024158, 36.86485],
              [-117.025269, 36.865662],
              [-117.037976, 36.874943],
              [-117.056829, 36.888989],
              [-117.064482, 36.894681],
              [-117.068336, 36.897568],
              [-117.0866, 36.911314],
              [-117.0947, 36.917455],
              [-117.09838, 36.920245],
              [-117.098581, 36.920397],
              [-117.100143, 36.921581],
              [-117.105644, 36.925752],
              [-117.110822, 36.929678],
              [-117.120101, 36.936762],
              [-117.125899, 36.941186],
              [-117.128701, 36.943304],
              [-117.128994, 36.943526],
              [-117.131592, 36.945488],
              [-117.131975, 36.945777],
              [-117.133132, 36.946604],
              [-117.133666, 36.947004],
              [-117.13757, 36.949929],
              [-117.144198, 36.954756],
              [-117.144561, 36.955021],
              [-117.151197, 36.959868],
              [-117.166001, 36.971111],
              [-117.169002, 36.973392],
              [-117.169436, 36.97372],
              [-117.169474, 36.973748],
              [-117.169495, 36.973764],
              [-117.169537, 36.973796],
              [-117.16955, 36.973806],
              [-117.173134, 36.976514],
              [-117.182891, 36.983882],
              [-117.184948, 36.985407],
              [-117.186479, 36.98654],
              [-117.187476, 36.987278],
              [-117.190606, 36.989582],
              [-117.190913, 36.989808],
              [-117.19105, 36.989909],
              [-117.191138, 36.989973],
              [-117.191154, 36.989985],
              [-117.191309, 36.990099],
              [-117.192232, 36.990778],
              [-117.194639, 36.99255],
              [-117.194894, 36.992738],
              [-117.195982, 36.993539],
              [-117.19631, 36.99378],
              [-117.196363, 36.993819],
              [-117.196391, 36.993839],
              [-117.196426, 36.993865],
              [-117.19657, 36.993971],
              [-117.197603, 36.994731],
              [-117.199284, 36.995969],
              [-117.200534, 36.996889],
              [-117.200763, 36.997057],
              [-117.201186, 36.997368],
              [-117.201899, 36.997893],
              [-117.202789, 36.998547],
              [-117.204346, 36.999694],
              [-117.204655, 36.999922],
              [-117.205027, 37.000202],
              [-117.226619, 37.016464],
              [-117.240435, 37.02687],
              [-117.244917, 37.030244],
              [-117.250903, 37.034618],
              [-117.250903, 37.03451],
              [-117.266046, 37.04491],
              [-117.266297, 37.045098],
              [-117.280762, 37.055923],
              [-117.286036, 37.059869],
              [-117.286457, 37.060185],
              [-117.307916, 37.076243],
              [-117.31883, 37.084409],
              [-117.322727, 37.087326],
              [-117.343983, 37.103232],
              [-117.357894, 37.113642],
              [-117.371219, 37.123614],
              [-117.372462, 37.124512],
              [-117.373451, 37.124932],
              [-117.375905, 37.126843],
              [-117.386399, 37.134748],
              [-117.405485, 37.149184],
              [-117.417543, 37.158223],
              [-117.43151, 37.16876],
              [-117.444455, 37.178526],
              [-117.448638, 37.181682],
              [-117.454651, 37.186202],
              [-117.460736, 37.190776],
              [-117.474295, 37.200969],
              [-117.474517, 37.201136],
              [-117.4757, 37.202025],
              [-117.476552, 37.202666],
              [-117.478996, 37.204503],
              [-117.496533, 37.217685],
              [-117.496563, 37.217708],
              [-117.49658, 37.217721],
              [-117.496656, 37.217778],
              [-117.496739, 37.217841],
              [-117.498812, 37.219399],
              [-117.500117, 37.22038],
              [-117.500909, 37.220282],
              [-117.506173, 37.224155],
              [-117.512766, 37.229002],
              [-117.527244, 37.239622],
              [-117.534076, 37.244659],
              [-117.53415, 37.244714],
              [-117.536752, 37.246632],
              [-117.540885, 37.249931],
              [-117.544576, 37.252572],
              [-117.551209, 37.257338],
              [-117.551878, 37.257816],
              [-117.551888, 37.257829],
              [-117.552035, 37.257929],
              [-117.581418, 37.278936],
              [-117.606373, 37.297548],
              [-117.609316, 37.299744],
              [-117.610925, 37.300945],
              [-117.625457, 37.31179],
              [-117.625914, 37.312062],
              [-117.634468, 37.318526],
              [-117.647342, 37.32833],
              [-117.65341, 37.332934],
              [-117.669528, 37.34512],
              [-117.669617, 37.345188],
              [-117.669818, 37.345339],
              [-117.669834, 37.345351],
              [-117.669865, 37.345374],
              [-117.671885, 37.34689],
              [-117.672578, 37.347406],
              [-117.672638, 37.347451],
              [-117.680096, 37.353015],
              [-117.680241, 37.353124],
              [-117.680454, 37.353283],
              [-117.680464, 37.35329],
              [-117.680522, 37.353334],
              [-117.68061, 37.353399],
              [-117.685462, 37.35669],
              [-117.690315, 37.359981],
              [-117.69195, 37.36109],
              [-117.693585, 37.362199],
              [-117.69403, 37.3625],
              [-117.694475, 37.362802],
              [-117.698373, 37.365446],
              [-117.702271, 37.36809],
              [-117.702461, 37.368217],
              [-117.702651, 37.368344],
              [-117.704284, 37.369455],
              [-117.70592, 37.370564],
              [-117.709139, 37.372748],
              [-117.712358, 37.374931],
              [-117.712804, 37.375319],
              [-117.713073, 37.375552],
              [-117.713466, 37.375845],
              [-117.713843, 37.376127],
              [-117.716333, 37.377987],
              [-117.719901, 37.380644],
              [-117.721968, 37.382181],
              [-117.725625, 37.384911],
              [-117.728485, 37.387042],
              [-117.729413, 37.387735],
              [-117.731368, 37.389193],
              [-117.734249, 37.391339],
              [-117.738851, 37.394772],
              [-117.738954, 37.394849],
              [-117.738968, 37.394859],
              [-117.739075, 37.394939],
              [-117.739116, 37.39497],
              [-117.739125, 37.394977],
              [-117.739208, 37.395039],
              [-117.739919, 37.395569],
              [-117.748483, 37.401953],
              [-117.748548, 37.402002],
              [-117.750927, 37.403598],
              [-117.752197, 37.404551],
              [-117.752207, 37.40457],
              [-117.75305, 37.405192],
              [-117.753329, 37.405401],
              [-117.753377, 37.405437],
              [-117.754614, 37.406366],
              [-117.755851, 37.407294],
              [-117.760311, 37.410642],
              [-117.764771, 37.41399],
              [-117.766282, 37.415123],
              [-117.766658, 37.415405],
              [-117.767824, 37.41628],
              [-117.768546, 37.41682],
              [-117.770111, 37.417997],
              [-117.770131, 37.418013],
              [-117.770149, 37.418026],
              [-117.770181, 37.41805],
              [-117.770318, 37.418153],
              [-117.77091, 37.418598],
              [-117.773279, 37.420377],
              [-117.774116, 37.421004],
              [-117.774949, 37.421631],
              [-117.77554, 37.422074],
              [-117.77613, 37.422518],
              [-117.786929, 37.430621],
              [-117.787908, 37.431356],
              [-117.795015, 37.436691],
              [-117.795628, 37.437151],
              [-117.799443, 37.440015],
              [-117.799679, 37.440192],
              [-117.799961, 37.440403],
              [-117.80017, 37.440559],
              [-117.800193, 37.440577],
              [-117.800218, 37.440596],
              [-117.802223, 37.442097],
              [-117.802326, 37.442174],
              [-117.803147, 37.442788],
              [-117.805781, 37.44476],
              [-117.808475, 37.446776],
              [-117.814635, 37.451387],
              [-117.816764, 37.452982],
              [-117.821989, 37.456893],
              [-117.823332, 37.457898],
              [-117.827217, 37.460807],
              [-117.827533, 37.461044],
              [-117.827575, 37.461075],
              [-117.831475, 37.463993],
              [-117.83149, 37.464004],
              [-117.832726, 37.464929],
              [-117.837499, 37.468503],
              [-117.83846, 37.469222],
              [-117.838513, 37.469261],
              [-117.839157, 37.469742],
              [-117.843725, 37.473163],
              [-117.843989, 37.473361],
              [-117.844003, 37.473371],
              [-117.846194, 37.47501],
              [-117.846229, 37.475037],
              [-117.846251, 37.475054],
              [-117.851301, 37.478834],
              [-117.852675, 37.479862],
              [-117.852696, 37.479878],
              [-117.852708, 37.479887],
              [-117.856198, 37.482499],
              [-117.858241, 37.484029],
              [-117.858695, 37.484369],
              [-117.866144, 37.489946],
              [-117.866504, 37.490214],
              [-117.866811, 37.490445],
              [-117.866828, 37.490456],
              [-117.871407, 37.493884],
              [-117.875927, 37.497267],
              [-117.878323, 37.498764],
              [-117.904625, 37.515836],
              [-117.908041, 37.518406],
              [-117.913106, 37.522245],
              [-117.913304, 37.522393],
              [-117.913501, 37.522541],
              [-117.915611, 37.524125],
              [-117.919702, 37.527193],
              [-117.923791, 37.530259],
              [-117.931446, 37.536],
              [-117.935976, 37.539313],
              [-117.937025, 37.540077],
              [-117.942666, 37.544342],
              [-117.942725, 37.544386],
              [-117.975439, 37.569039],
              [-117.975776, 37.569293],
              [-117.975955, 37.569429],
              [-117.976643, 37.569949],
              [-117.979542, 37.572141],
              [-117.983035, 37.574387],
              [-117.98311, 37.574435],
              [-117.983391, 37.574616],
              [-117.986827, 37.576933],
              [-117.986954, 37.57702],
              [-117.987908, 37.577723],
              [-117.98899, 37.57852],
              [-117.991614, 37.580454],
              [-117.99172, 37.580535],
              [-117.991758, 37.580564],
              [-117.992655, 37.581259],
              [-117.992672, 37.581272],
              [-117.995572, 37.583518],
              [-117.995837, 37.583709],
              [-117.997967, 37.585242],
              [-118.000099, 37.586776],
              [-118.000962, 37.587396],
              [-118.001158, 37.587529],
              [-118.004272, 37.589649],
              [-118.00733, 37.591731],
              [-118.007388, 37.59177],
              [-118.008108, 37.592292],
              [-118.008829, 37.592815],
              [-118.009082, 37.592997],
              [-118.009331, 37.593179],
              [-118.009875, 37.593571],
              [-118.010417, 37.593963],
              [-118.01242, 37.595412],
              [-118.012466, 37.595446],
              [-118.013262, 37.596022],
              [-118.014192, 37.596697],
              [-118.015126, 37.59737],
              [-118.016265, 37.598196],
              [-118.0192, 37.60032],
              [-118.019409, 37.600471],
              [-118.019466, 37.600512],
              [-118.019487, 37.600527],
              [-118.019508, 37.600542],
              [-118.019522, 37.600553],
              [-118.019533, 37.600561],
              [-118.023286, 37.603276],
              [-118.023652, 37.603543],
              [-118.024045, 37.603809],
              [-118.024512, 37.604165],
              [-118.02455, 37.604194],
              [-118.028037, 37.606716],
              [-118.0286, 37.607124],
              [-118.031897, 37.60951],
              [-118.032041, 37.609614],
              [-118.032656, 37.610058],
              [-118.033112, 37.610387],
              [-118.03324, 37.610479],
              [-118.034762, 37.611577],
              [-118.037481, 37.613538],
              [-118.039894, 37.615332],
              [-118.039912, 37.615347],
              [-118.039963, 37.615389],
              [-118.040218, 37.615599],
              [-118.040264, 37.615636],
              [-118.040302, 37.615667],
              [-118.040332, 37.615691],
              [-118.041332, 37.616473],
              [-118.041903, 37.616919],
              [-118.043925, 37.618501],
              [-118.044164, 37.618686],
              [-118.044415, 37.618863],
              [-118.044424, 37.61887],
              [-118.045752, 37.619928],
              [-118.047099, 37.620981],
              [-118.048408, 37.622005],
              [-118.049719, 37.62303],
              [-118.050119, 37.623337],
              [-118.051006, 37.624021],
              [-118.051347, 37.624282],
              [-118.051358, 37.62429],
              [-118.051598, 37.624474],
              [-118.052189, 37.62493],
              [-118.053248, 37.625693],
              [-118.054254, 37.626417],
              [-118.054308, 37.626456],
              [-118.05461, 37.626673],
              [-118.054721, 37.626753],
              [-118.054943, 37.626913],
              [-118.058789, 37.629681],
              [-118.05886, 37.629732],
              [-118.059925, 37.630499],
              [-118.063115, 37.632796],
              [-118.063714, 37.633227],
              [-118.064883, 37.634069],
              [-118.066805, 37.635453],
              [-118.066896, 37.635519],
              [-118.067243, 37.635769],
              [-118.067823, 37.636187],
              [-118.068092, 37.636379],
              [-118.069205, 37.63718],
              [-118.06974, 37.637565],
              [-118.070836, 37.638356],
              [-118.07199, 37.639186],
              [-118.072024, 37.639211],
              [-118.072213, 37.639347],
              [-118.072457, 37.639523],
              [-118.073516, 37.640285],
              [-118.074575, 37.641047],
              [-118.076851, 37.642684],
              [-118.080722, 37.645472],
              [-118.082178, 37.64652],
              [-118.082223, 37.646554],
              [-118.083191, 37.647251],
              [-118.083216, 37.647269],
              [-118.083548, 37.647508],
              [-118.083689, 37.64761],
              [-118.089348, 37.651686],
              [-118.089429, 37.651743],
              [-118.097278, 37.657394],
              [-118.097317, 37.657422],
              [-118.099183, 37.658766],
              [-118.101046, 37.660109],
              [-118.101879, 37.660706],
              [-118.101943, 37.660752],
              [-118.10725, 37.664574],
              [-118.107407, 37.664687],
              [-118.113499, 37.669081],
              [-118.119592, 37.673477],
              [-118.123442, 37.676251],
              [-118.123467, 37.676269],
              [-118.125849, 37.677986],
              [-118.128271, 37.679744],
              [-118.129356, 37.680531],
              [-118.129378, 37.680547],
              [-118.12939, 37.680555],
              [-118.130141, 37.681102],
              [-118.130173, 37.681125],
              [-118.130179, 37.68114],
              [-118.130672, 37.681487],
              [-118.13117, 37.681849],
              [-118.131302, 37.681943],
              [-118.133555, 37.683543],
              [-118.134545, 37.684296],
              [-118.137917, 37.686741],
              [-118.138247, 37.686981],
              [-118.150733, 37.695983],
              [-118.150848, 37.696076],
              [-118.151391, 37.696459],
              [-118.153977, 37.698329],
              [-118.157105, 37.70059],
              [-118.157663, 37.700994],
              [-118.159493, 37.702319],
              [-118.159985, 37.702675],
              [-118.160543, 37.703079],
              [-118.161167, 37.703531],
              [-118.161291, 37.703619],
              [-118.162375, 37.704403],
              [-118.162902, 37.704784],
              [-118.163011, 37.704863],
              [-118.16402, 37.705592],
              [-118.164031, 37.7056],
              [-118.164056, 37.705618],
              [-118.165784, 37.706867],
              [-118.167913, 37.708406],
              [-118.173754, 37.71263],
              [-118.173939, 37.712766],
              [-118.175788, 37.714188],
              [-118.177478, 37.715489],
              [-118.177714, 37.715568],
              [-118.177844, 37.715622],
              [-118.177906, 37.715635],
              [-118.179081, 37.716578],
              [-118.18016, 37.717445],
              [-118.180345, 37.717398],
              [-118.18242, 37.718899],
              [-118.183162, 37.719436],
              [-118.184494, 37.7204],
              [-118.184698, 37.720547],
              [-118.185206, 37.720957],
              [-118.185344, 37.721058],
              [-118.185407, 37.721103],
              [-118.186619, 37.721981],
              [-118.187917, 37.72292],
              [-118.189398, 37.724009],
              [-118.189427, 37.72403],
              [-118.191344, 37.725402],
              [-118.191708, 37.725681],
              [-118.191719, 37.725689],
              [-118.192155, 37.725989],
              [-118.192478, 37.726223],
              [-118.193225, 37.726766],
              [-118.201078, 37.732486],
              [-118.201177, 37.732558],
              [-118.208628, 37.737956],
              [-118.211262, 37.739866],
              [-118.213507, 37.741491],
              [-118.21361, 37.741565],
              [-118.225439, 37.749993],
              [-118.22964, 37.753012],
              [-118.229687, 37.753056],
              [-118.229718, 37.753085],
              [-118.229785, 37.75315],
              [-118.229828, 37.753183],
              [-118.232638, 37.755222],
              [-118.232825, 37.755358],
              [-118.233452, 37.755813],
              [-118.233692, 37.755987],
              [-118.233762, 37.756037],
              [-118.238031, 37.759134],
              [-118.239227, 37.760002],
              [-118.239546, 37.760233],
              [-118.241897, 37.761939],
              [-118.242372, 37.762284],
              [-118.242686, 37.762512],
              [-118.242955, 37.762707],
              [-118.243004, 37.762743],
              [-118.243212, 37.762883],
              [-118.243491, 37.763096],
              [-118.244098, 37.763537],
              [-118.244642, 37.763931],
              [-118.244681, 37.76396],
              [-118.244693, 37.763969],
              [-118.244709, 37.76398],
              [-118.244765, 37.764019],
              [-118.244972, 37.76417],
              [-118.248203, 37.766574],
              [-118.250956, 37.768622],
              [-118.251756, 37.769198],
              [-118.253581, 37.770512],
              [-118.256507, 37.772615],
              [-118.256823, 37.772842],
              [-118.257139, 37.773069],
              [-118.259887, 37.775045],
              [-118.262635, 37.777021],
              [-118.269766, 37.782152],
              [-118.271166, 37.78316],
              [-118.271212, 37.783181],
              [-118.271219, 37.783196],
              [-118.273399, 37.784765],
              [-118.273625, 37.784928],
              [-118.274528, 37.785578],
              [-118.278004, 37.78808],
              [-118.278035, 37.788102],
              [-118.278051, 37.788114],
              [-118.278068, 37.788126],
              [-118.278084, 37.788137],
              [-118.278099, 37.788148],
              [-118.278607, 37.788514],
              [-118.278814, 37.788663],
              [-118.28395, 37.792358],
              [-118.284777, 37.792953],
              [-118.285061, 37.793156],
              [-118.289292, 37.796198],
              [-118.290501, 37.797068],
              [-118.295389, 37.800583],
              [-118.295717, 37.800812],
              [-118.296305, 37.801243],
              [-118.296509, 37.801389],
              [-118.296738, 37.801553],
              [-118.301589, 37.80504],
              [-118.31, 37.811092],
              [-118.310349, 37.811343],
              [-118.310375, 37.811362],
              [-118.313089, 37.813315],
              [-118.313354, 37.813506],
              [-118.313684, 37.813743],
              [-118.318569, 37.817258],
              [-118.327656, 37.823739],
              [-118.32861, 37.824423],
              [-118.332204, 37.827005],
              [-118.334578, 37.82871],
              [-118.338547, 37.831562],
              [-118.344572, 37.835889],
              [-118.35059, 37.840216],
              [-118.353878, 37.842578],
              [-118.357165, 37.844941],
              [-118.359163, 37.846376],
              [-118.361161, 37.84781],
              [-118.366868, 37.851912],
              [-118.372572, 37.856012],
              [-118.375936, 37.858663],
              [-118.377525, 37.859803],
              [-118.380195, 37.86172],
              [-118.398531, 37.874883],
              [-118.403866, 37.878682],
              [-118.404002, 37.878778],
              [-118.406534, 37.880651],
              [-118.406571, 37.880678],
              [-118.428144, 37.89622],
              [-118.433929, 37.900391],
              [-118.435178, 37.901289],
              [-118.437263, 37.902776],
              [-118.439126, 37.904133],
              [-118.444066, 37.907691],
              [-118.450654, 37.912437],
              [-118.451714, 37.913203],
              [-118.451923, 37.913354],
              [-118.45198, 37.913395],
              [-118.45203, 37.913431],
              [-118.452049, 37.913445],
              [-118.45206, 37.913453],
              [-118.461322, 37.920152],
              [-118.461333, 37.920162],
              [-118.46158, 37.920332],
              [-118.461838, 37.920512],
              [-118.461969, 37.920604],
              [-118.4621, 37.920695],
              [-118.47074, 37.926925],
              [-118.473595, 37.928971],
              [-118.473681, 37.929033],
              [-118.475406, 37.93027],
              [-118.482145, 37.935132],
              [-118.482159, 37.935142],
              [-118.482171, 37.935151],
              [-118.482186, 37.935162],
              [-118.482203, 37.935174],
              [-118.486418, 37.938215],
              [-118.486821, 37.938505],
              [-118.48906, 37.940119],
              [-118.492573, 37.942651],
              [-118.495588, 37.944819],
              [-118.496043, 37.945146],
              [-118.500964, 37.949023],
              [-118.516301, 37.959874],
              [-118.517184, 37.960626],
              [-118.517223, 37.960654],
              [-118.522235, 37.964274],
              [-118.522283, 37.964309],
              [-118.522327, 37.964341],
              [-118.525065, 37.966318],
              [-118.527736, 37.968232],
              [-118.530407, 37.970147],
              [-118.530418, 37.97016],
              [-118.532094, 37.971356],
              [-118.533782, 37.972565],
              [-118.536849, 37.974763],
              [-118.539916, 37.976961],
              [-118.541494, 37.97809],
              [-118.543073, 37.97922],
              [-118.544914, 37.980537],
              [-118.546091, 37.981379],
              [-118.546755, 37.981854],
              [-118.550118, 37.984266],
              [-118.553481, 37.986678],
              [-118.554104, 37.987125],
              [-118.554727, 37.987572],
              [-118.571958, 37.99993],
              [-118.573934, 38.001302],
              [-118.575003, 38.002044],
              [-118.575068, 38.002089],
              [-118.575078, 38.002096],
              [-118.575101, 38.002112],
              [-118.57511, 38.002118],
              [-118.575123, 38.002127],
              [-118.575175, 38.002163],
              [-118.575339, 38.002277],
              [-118.621676, 38.034438],
              [-118.625963, 38.03774],
              [-118.636432, 38.045396],
              [-118.640929, 38.04868],
              [-118.645081, 38.051712],
              [-118.645092, 38.05172],
              [-118.647472, 38.053457],
              [-118.647914, 38.05378],
              [-118.64814, 38.053945],
              [-118.648194, 38.053984],
              [-118.648223, 38.054005],
              [-118.648304, 38.054064],
              [-118.648324, 38.054079],
              [-118.652355, 38.057019],
              [-118.664237, 38.065699],
              [-118.666217, 38.067146],
              [-118.668197, 38.068594],
              [-118.685938, 38.081565],
              [-118.68636, 38.081835],
              [-118.711759, 38.100327],
              [-118.711962, 38.100475],
              [-118.714201, 38.102105],
              [-118.714257, 38.102144],
              [-118.715372, 38.102928],
              [-118.716523, 38.103737],
              [-118.716541, 38.10375],
              [-118.72318, 38.10842],
              [-118.729819, 38.11309],
              [-118.73031, 38.113436],
              [-118.730801, 38.113783],
              [-118.731332, 38.114157],
              [-118.734192, 38.116178],
              [-118.737053, 38.118198],
              [-118.739216, 38.119721],
              [-118.741378, 38.121244],
              [-118.743988, 38.123085],
              [-118.746598, 38.124926],
              [-118.752225, 38.128773],
              [-118.771822, 38.141841],
              [-118.771867, 38.141871],
              [-118.777363, 38.14586],
              [-118.777551, 38.145996],
              [-118.781045, 38.148532],
              [-118.781838, 38.149108],
              [-118.781915, 38.149164],
              [-118.782011, 38.149234],
              [-118.782187, 38.149362],
              [-118.782244, 38.149403],
              [-118.782254, 38.14941],
              [-118.782276, 38.149426],
              [-118.782319, 38.149457],
              [-118.783178, 38.15008],
              [-118.784441, 38.150995],
              [-118.791032, 38.155773],
              [-118.797624, 38.160551],
              [-118.797714, 38.160617],
              [-118.815812, 38.173651],
              [-118.816022, 38.173802],
              [-118.835336, 38.187711],
              [-118.836014, 38.1882],
              [-118.83626, 38.188377],
              [-118.836921, 38.188853],
              [-118.854649, 38.20162],
              [-118.854824, 38.201746],
              [-118.856132, 38.202687],
              [-118.856312, 38.202817],
              [-118.856435, 38.202905],
              [-118.858963, 38.204722],
              [-118.859084, 38.204809],
              [-118.859294, 38.204957],
              [-118.86045, 38.205773],
              [-118.862017, 38.206859],
              [-118.875873, 38.216456],
              [-118.887932, 38.225094],
              [-118.88809, 38.225207],
              [-118.890044, 38.226606],
              [-118.890118, 38.226659],
              [-118.890134, 38.22667],
              [-118.890148, 38.22668],
              [-118.890157, 38.226687],
              [-118.890629, 38.227025],
              [-118.895501, 38.230512],
              [-118.901014, 38.234459],
              [-118.901297, 38.234662],
              [-118.910346, 38.241142],
              [-118.915214, 38.244651],
              [-118.922518, 38.249919],
              [-118.93302, 38.257273],
              [-118.944968, 38.265644],
              [-118.944979, 38.265652],
              [-118.945024, 38.265683],
              [-118.945339, 38.265904],
              [-118.94918, 38.268595],
              [-118.949356, 38.268718],
              [-118.949673, 38.26894],
              [-118.94971, 38.268965],
              [-119.000975, 38.303675],
              [-119.005296, 38.30678],
              [-119.005462, 38.3069],
              [-119.010712, 38.310775],
              [-119.011077, 38.311044],
              [-119.011089, 38.311053],
              [-119.011142, 38.311092],
              [-119.011215, 38.311146],
              [-119.011901, 38.311652],
              [-119.013689, 38.312972],
              [-119.028069, 38.323587],
              [-119.028156, 38.323651],
              [-119.028271, 38.323736],
              [-119.028691, 38.324047],
              [-119.028752, 38.324041],
              [-119.029427, 38.324605],
              [-119.029601, 38.324788],
              [-119.029757, 38.324951],
              [-119.029767, 38.324962],
              [-119.029783, 38.324978],
              [-119.029813, 38.324998],
              [-119.029872, 38.325039],
              [-119.029972, 38.325108],
              [-119.030299, 38.325334],
              [-119.030327, 38.325353],
              [-119.03034, 38.325362],
              [-119.03035, 38.325369],
              [-119.030459, 38.325444],
              [-119.0306, 38.325541],
              [-119.031706, 38.326306],
              [-119.031721, 38.326316],
              [-119.031743, 38.326331],
              [-119.04349, 38.33445],
              [-119.057199, 38.343914],
              [-119.067278, 38.350871],
              [-119.074222, 38.355653],
              [-119.074268, 38.355685],
              [-119.082358, 38.361267],
              [-119.082438, 38.361332],
              [-119.088114, 38.366005],
              [-119.0968, 38.372578],
              [-119.097159, 38.372851],
              [-119.100246, 38.374916],
              [-119.124282, 38.391727],
              [-119.124487, 38.391901],
              [-119.125982, 38.39317],
              [-119.126678, 38.393654],
              [-119.126717, 38.393681],
              [-119.127421, 38.394171],
              [-119.127878, 38.394489],
              [-119.1281, 38.394644],
              [-119.128199, 38.394713],
              [-119.141537, 38.403998],
              [-119.141551, 38.404008],
              [-119.152264, 38.411468],
              [-119.156978, 38.414746],
              [-119.160074, 38.416901],
              [-119.160205, 38.416992],
              [-119.161087, 38.417606],
              [-119.161477, 38.417878],
              [-119.18173, 38.431975],
              [-119.181957, 38.432132],
              [-119.195256, 38.44139],
              [-119.195294, 38.441416],
              [-119.20347, 38.447107],
              [-119.203488, 38.44712],
              [-119.203986, 38.447468],
              [-119.204017, 38.44749],
              [-119.205016, 38.448186],
              [-119.205034, 38.448199],
              [-119.207018, 38.449581],
              [-119.207638, 38.450013],
              [-119.211355, 38.452599],
              [-119.212375, 38.453307],
              [-119.212703, 38.453506],
              [-119.212796, 38.45357],
              [-119.212811, 38.45358],
              [-119.229727, 38.465351],
              [-119.231623, 38.46667],
              [-119.232164, 38.467048],
              [-119.232184, 38.467061],
              [-119.233805, 38.468189],
              [-119.234117, 38.468405],
              [-119.23455, 38.468708],
              [-119.234964, 38.468996],
              [-119.24946, 38.479711],
              [-119.249809, 38.479968],
              [-119.250377, 38.480382],
              [-119.250988, 38.48078],
              [-119.263828, 38.489467],
              [-119.263837, 38.48948],
              [-119.263905, 38.489519],
              [-119.264244, 38.48975],
              [-119.267655, 38.492059],
              [-119.279262, 38.499914],
              [-119.279552, 38.500313],
              [-119.297704, 38.513122],
              [-119.307813, 38.520258],
              [-119.308778, 38.52094],
              [-119.328398, 38.534792],
              [-119.329092, 38.535283],
              [-119.329948, 38.535887],
              [-119.330005, 38.535928],
              [-119.333423, 38.538328],
              [-119.354565, 38.552787],
              [-119.354915, 38.553025],
              [-119.355044, 38.553114],
              [-119.357007, 38.554456],
              [-119.360167, 38.556583],
              [-119.360265, 38.556648],
              [-119.360341, 38.5567],
              [-119.370117, 38.563281],
              [-119.3703, 38.563391],
              [-119.375994, 38.566793],
              [-119.37862, 38.568663],
              [-119.379123, 38.569012],
              [-119.379403, 38.569205],
              [-119.379543, 38.569302],
              [-119.387962, 38.575301],
              [-119.398943, 38.583171],
              [-119.399335, 38.583453],
              [-119.401217, 38.584746],
              [-119.40139, 38.584871],
              [-119.427302, 38.603526],
              [-119.42789, 38.60395],
              [-119.428134, 38.604126],
              [-119.428445, 38.60435],
              [-119.433791, 38.6082],
              [-119.435064, 38.609037],
              [-119.448611, 38.618534],
              [-119.450655, 38.619968],
              [-119.450634, 38.619981],
              [-119.453769, 38.622395],
              [-119.453783, 38.622405],
              [-119.453823, 38.622433],
              [-119.457405, 38.62491],
              [-119.459131, 38.626089],
              [-119.459231, 38.626157],
              [-119.461282, 38.627533],
              [-119.463984, 38.629276],
              [-119.464027, 38.629304],
              [-119.464305, 38.629486],
              [-119.464356, 38.629519],
              [-119.47224, 38.634663],
              [-119.473687, 38.635668],
              [-119.477257, 38.638152],
              [-119.477569, 38.63837],
              [-119.488058, 38.645685],
              [-119.491567, 38.648107],
              [-119.491747, 38.648226],
              [-119.492537, 38.64875],
              [-119.492565, 38.648768],
              [-119.49347, 38.649327],
              [-119.49402, 38.649735],
              [-119.494069, 38.649758],
              [-119.494176, 38.649862],
              [-119.494571, 38.650133],
              [-119.494958, 38.650366],
              [-119.495035, 38.650417],
              [-119.500922, 38.654322],
              [-119.501, 38.654374],
              [-119.51147, 38.662182],
              [-119.511529, 38.662222],
              [-119.51181, 38.662415],
              [-119.530623, 38.675305],
              [-119.530647, 38.675321],
              [-119.540864, 38.682321],
              [-119.542712, 38.683587],
              [-119.546293, 38.686039],
              [-119.546586, 38.686241],
              [-119.54738, 38.686785],
              [-119.5476, 38.686934],
              [-119.547659, 38.686975],
              [-119.547767, 38.687049],
              [-119.547933, 38.687163],
              [-119.548089, 38.68727],
              [-119.548167, 38.687323],
              [-119.548528, 38.68757],
              [-119.548604, 38.687623],
              [-119.550104, 38.68865],
              [-119.551635, 38.6897],
              [-119.551992, 38.689944],
              [-119.552579, 38.690353],
              [-119.558726, 38.694641],
              [-119.558899, 38.694761],
              [-119.585437, 38.713212],
              [-119.587066, 38.714345],
              [-119.587174, 38.714436],
              [-119.587422, 38.714644],
              [-119.587474, 38.714688],
              [-119.587508, 38.714695],
              [-119.587647, 38.714727],
              [-119.590212, 38.716473],
              [-119.594037, 38.719099],
              [-119.594301, 38.71928],
              [-119.600273, 38.72338],
              [-119.60937, 38.729625],
              [-119.613199, 38.73223],
              [-119.619602, 38.736586],
              [-119.620854, 38.737437],
              [-119.621866, 38.738126],
              [-119.62461, 38.739993],
              [-119.625863, 38.740845],
              [-119.626004, 38.740941],
              [-119.626842, 38.74153],
              [-119.629778, 38.743594],
              [-119.630757, 38.744282],
              [-119.631464, 38.744779],
              [-119.633586, 38.74627],
              [-119.634239, 38.746729],
              [-119.634292, 38.74677],
              [-119.635047, 38.747355],
              [-119.638339, 38.749904],
              [-119.642113, 38.752497],
              [-119.656579, 38.762436],
              [-119.661077, 38.765595],
              [-119.663213, 38.767096],
              [-119.663914, 38.76758],
              [-119.666018, 38.769034],
              [-119.666626, 38.769454],
              [-119.66672, 38.769519],
              [-119.666781, 38.769561],
              [-119.666967, 38.76969],
              [-119.667029, 38.769733],
              [-119.669038, 38.771121],
              [-119.675068, 38.775288],
              [-119.677078, 38.776678],
              [-119.677446, 38.776932],
              [-119.67855, 38.777695],
              [-119.678918, 38.77795],
              [-119.679558, 38.778392],
              [-119.681479, 38.77972],
              [-119.68212, 38.780163],
              [-119.683897, 38.781391],
              [-119.689231, 38.785078],
              [-119.691009, 38.786307],
              [-119.691435, 38.786601],
              [-119.691765, 38.786827],
              [-119.695291, 38.789267],
              [-119.695346, 38.789305],
              [-119.695382, 38.78933],
              [-119.695458, 38.789382],
              [-119.695468, 38.789389],
              [-119.695505, 38.789415],
              [-119.695561, 38.789453],
              [-119.703814, 38.795158],
              [-119.725904, 38.810425],
              [-119.726793, 38.81104],
              [-119.729324, 38.81279],
              [-119.741908, 38.821486],
              [-119.74405, 38.822968],
              [-119.744356, 38.82318],
              [-119.749977, 38.827029],
              [-119.750031, 38.827066],
              [-119.751007, 38.827778],
              [-119.753903, 38.829776],
              [-119.753965, 38.829819],
              [-119.754657, 38.830297],
              [-119.756736, 38.831731],
              [-119.757429, 38.83221],
              [-119.757618, 38.832341],
              [-119.760408, 38.834268],
              [-119.760503, 38.834334],
              [-119.763551, 38.836437],
              [-119.764631, 38.837183],
              [-119.764833, 38.837323],
              [-119.769668, 38.840661],
              [-119.779027, 38.847126],
              [-119.779129, 38.847196],
              [-119.78669, 38.852415],
              [-119.78675, 38.852456],
              [-119.790794, 38.855248],
              [-119.790942, 38.85535],
              [-119.797368, 38.859776],
              [-119.797453, 38.859836],
              [-119.802597, 38.863376],
              [-119.803525, 38.86402],
              [-119.810544, 38.868823],
              [-119.813523, 38.870861],
              [-119.8142, 38.871325],
              [-119.816709, 38.873019],
              [-119.820958, 38.875894],
              [-119.821061, 38.875957],
              [-119.822084, 38.876686],
              [-119.830371, 38.882584],
              [-119.831187, 38.883161],
              [-119.831968, 38.883715],
              [-119.832004, 38.88374],
              [-119.832445, 38.884053],
              [-119.833596, 38.884833],
              [-119.833638, 38.884861],
              [-119.835142, 38.885879],
              [-119.838285, 38.888006],
              [-119.839547, 38.888859],
              [-119.839849, 38.889064],
              [-119.839939, 38.889125],
              [-119.840637, 38.889597],
              [-119.843001, 38.891197],
              [-119.84379, 38.891731],
              [-119.84426, 38.892049],
              [-119.84567, 38.893003],
              [-119.846141, 38.893322],
              [-119.846275, 38.893413],
              [-119.847026, 38.893921],
              [-119.849684, 38.89572],
              [-119.85057, 38.89632],
              [-119.850592, 38.896335],
              [-119.851986, 38.897278],
              [-119.857455, 38.901034],
              [-119.857493, 38.90106],
              [-119.864489, 38.905866],
              [-119.864501, 38.905874],
              [-119.870204, 38.909776],
              [-119.876011, 38.913748],
              [-119.877773, 38.914962],
              [-119.877821, 38.914995],
              [-119.887245, 38.921487],
              [-119.88971, 38.923199],
              [-119.897106, 38.928336],
              [-119.899572, 38.930049],
              [-119.899776, 38.930191],
              [-119.900391, 38.930617],
              [-119.900596, 38.93076],
              [-119.900651, 38.930798],
              [-119.900817, 38.930913],
              [-119.900873, 38.930952],
              [-119.901561, 38.931426],
              [-119.903626, 38.932849],
              [-119.904315, 38.933324],
              [-119.904565, 38.933497],
              [-119.905318, 38.934016],
              [-119.905569, 38.934189],
              [-119.907373, 38.935432],
              [-119.912785, 38.939162],
              [-119.91459, 38.940406],
              [-119.918041, 38.942785],
              [-119.928395, 38.949922],
              [-119.931847, 38.952301],
              [-119.932121, 38.952483],
              [-119.932943, 38.953031],
              [-119.933106, 38.953139],
              [-119.933218, 38.953214],
              [-119.933861, 38.953642],
              [-119.93579, 38.954926],
              [-119.936434, 38.955355],
              [-119.93686, 38.955642],
              [-119.938139, 38.956503],
              [-119.938566, 38.956791],
              [-119.938604, 38.956816],
              [-119.938718, 38.956893],
              [-119.938756, 38.956919],
              [-119.938795, 38.956945],
              [-119.938976, 38.957072],
              [-119.939636, 38.957535],
              [-119.939857, 38.95769],
              [-119.939899, 38.957719],
              [-119.940025, 38.957807],
              [-119.940067, 38.957837],
              [-119.94019, 38.957923],
              [-119.94056, 38.958183],
              [-119.940975, 38.958475],
              [-119.941851, 38.959089],
              [-119.942042, 38.959224],
              [-119.942143, 38.959294],
              [-119.942897, 38.959841],
              [-119.943227, 38.96008],
              [-119.945021, 38.961283],
              [-119.945215, 38.961408],
              [-119.945929, 38.96187],
              [-119.945998, 38.961916],
              [-119.946726, 38.962386],
              [-119.948912, 38.963799],
              [-119.949641, 38.96427],
              [-119.94998, 38.964503],
              [-119.951, 38.965203],
              [-119.95134, 38.965437],
              [-119.952438, 38.966191],
              [-119.953535, 38.966945],
              [-119.954125, 38.96735],
              [-119.959259, 38.970879],
              [-119.962479, 38.973092],
              [-119.964983, 38.974813],
              [-119.965124, 38.974909],
              [-119.965264, 38.975006],
              [-119.965407, 38.975104],
              [-119.965836, 38.975399],
              [-119.965979, 38.975498],
              [-119.966231, 38.975671],
              [-119.966988, 38.976191],
              [-119.967241, 38.976365],
              [-119.969131, 38.977664],
              [-119.974801, 38.981561],
              [-119.976692, 38.98286],
              [-119.979748, 38.98496],
              [-119.988916, 38.99126],
              [-119.991972, 38.993361],
              [-119.99378, 38.994603],
              [-119.999205, 38.998331],
              [-120.001014, 38.999574],
              [-120.001014, 38.99964],
              [-120.001014, 38.999841],
              [-120.001014, 38.999908],
              [-120.001045, 39.001369],
              [-120.001139, 39.005751],
              [-120.001171, 39.007213],
              [-120.001199, 39.008541],
              [-120.001283, 39.012526],
              [-120.001312, 39.013855],
              [-120.001328, 39.014634],
              [-120.001345, 39.015413],
              [-120.001407, 39.018317],
              [-120.001568, 39.025791],
              [-120.001694, 39.031706],
              [-120.00179, 39.036169],
              [-120.001869, 39.03985],
              [-120.001909, 39.041742],
              [-120.001948, 39.043531],
              [-120.002168, 39.053784],
              [-120.002267, 39.058463],
              [-120.002387, 39.064037],
              [-120.002401, 39.064727],
              [-120.002446, 39.066798],
              [-120.002461, 39.067489],
              [-120.002508, 39.069728],
              [-120.002652, 39.076446],
              [-120.0027, 39.078686],
              [-120.002712, 39.079244],
              [-120.002724, 39.079801],
              [-120.002845, 39.085486],
              [-120.003076, 39.096244],
              [-120.003283, 39.105886],
              [-120.003429, 39.112687],
              [-120.003611, 39.119844],
              [-120.003741, 39.12491],
              [-120.003834, 39.131627],
              [-120.003941, 39.141319],
              [-120.004021, 39.148478],
              [-120.004024, 39.148764],
              [-120.004297, 39.165603],
              [-120.004429, 39.177981],
              [-120.00453, 39.187445],
              [-120.005484, 39.216307],
              [-120.005541, 39.218029],
              [-120.005563, 39.218695],
              [-120.005563, 39.220022],
              [-120.00561, 39.221754],
              [-120.005634, 39.22261],
              [-120.00571, 39.225226],
              [-120.005727, 39.225828],
              [-120.005726, 39.22604],
              [-120.005724, 39.226446],
              [-120.005722, 39.226973],
              [-120.005577, 39.227426],
              [-120.005598, 39.227987],
              [-120.005611, 39.228335],
              [-120.005629, 39.228857],
              [-120.005668, 39.22936],
              [-120.005698, 39.229772],
              [-120.005693, 39.233234],
              [-120.005679, 39.236249],
              [-120.00568, 39.244038],
              [-120.005686, 39.244263],
              [-120.005746, 39.246292],
              [-120.005756, 39.246655],
              [-120.005788, 39.247746],
              [-120.005799, 39.24811],
              [-120.005929, 39.252557],
              [-120.006103, 39.258491],
              [-120.006293, 39.265901],
              [-120.006408, 39.27035],
              [-120.006455, 39.272194],
              [-120.006473, 39.272878],
              [-120.006456, 39.277729],
              [-120.006452, 39.278897],
              [-120.00645, 39.279574],
              [-120.006448, 39.279824],
              [-120.006446, 39.280577],
              [-120.006446, 39.280828],
              [-120.006439, 39.282773],
              [-120.006419, 39.288609],
              [-120.006416, 39.289501],
              [-120.006411, 39.290555],
              [-120.00641, 39.290674],
              [-120.00641, 39.291035],
              [-120.00641, 39.291155],
              [-120.006409, 39.291285],
              [-120.006408, 39.291676],
              [-120.006408, 39.291807],
              [-120.006393, 39.296042],
              [-120.00635, 39.308751],
              [-120.006336, 39.312987],
              [-120.006335, 39.313087],
              [-120.006334, 39.313387],
              [-120.006334, 39.313488],
              [-120.006333, 39.313573],
              [-120.006332, 39.313829],
              [-120.006332, 39.313915],
              [-120.006331, 39.314001],
              [-120.006331, 39.31426],
              [-120.006331, 39.314347],
              [-120.00633, 39.314405],
              [-120.00633, 39.31458],
              [-120.00633, 39.314639],
              [-120.006329, 39.314875],
              [-120.006326, 39.315582],
              [-120.006326, 39.315819],
              [-120.006325, 39.315954],
              [-120.006323, 39.31636],
              [-120.006323, 39.316496],
              [-120.006316, 39.318586],
              [-120.006294, 39.324858],
              [-120.006288, 39.326949],
              [-120.006284, 39.32795],
              [-120.006276, 39.330683],
              [-120.006274, 39.330953],
              [-120.006271, 39.331954],
              [-120.006258, 39.335557],
              [-120.006237, 39.341498],
              [-120.00622, 39.346369],
              [-120.006212, 39.349106],
              [-120.00621, 39.349973],
              [-120.006192, 39.355024],
              [-120.00614, 39.370179],
              [-120.006127, 39.374106],
              [-120.006089, 39.37523],
              [-120.006085, 39.375315],
              [-120.006077, 39.375572],
              [-120.006075, 39.375658],
              [-120.006048, 39.376458],
              [-120.005968, 39.37886],
              [-120.005942, 39.379661],
              [-120.005934, 39.379896],
              [-120.00591, 39.380603],
              [-120.005903, 39.380839],
              [-120.005902, 39.380851],
              [-120.005901, 39.38089],
              [-120.005901, 39.380903],
              [-120.005864, 39.381993],
              [-120.005848, 39.382454],
              [-120.005694, 39.387108],
              [-120.005643, 39.38866],
              [-120.005626, 39.389184],
              [-120.005567, 39.390943],
              [-120.005472, 39.393844],
              [-120.005358, 39.39727],
              [-120.00534, 39.397792],
              [-120.005265, 39.400076],
              [-120.005181, 39.402606],
              [-120.004928, 39.410197],
              [-120.004845, 39.412728],
              [-120.004774, 39.414855],
              [-120.004692, 39.417353],
              [-120.004562, 39.421239],
              [-120.004492, 39.423367],
              [-120.004468, 39.424104],
              [-120.004354, 39.427539],
              [-120.004247, 39.430769],
              [-120.004081, 39.435856],
              [-120.00394, 39.440057],
              [-120.0038, 39.44423],
              [-120.003794, 39.444395],
              [-120.003778, 39.44489],
              [-120.003773, 39.445056],
              [-120.00339, 39.456625],
              [-120.003383, 39.456849],
              [-120.003216, 39.461857],
              [-120.003056, 39.463185],
              [-120.003022, 39.463468],
              [-120.002909, 39.464402],
              [-120.002908, 39.464631],
              [-120.002906, 39.465008],
              [-120.002905, 39.465181],
              [-120.002903, 39.465635],
              [-120.002901, 39.46592],
              [-120.0029, 39.466039],
              [-120.002899, 39.466295],
              [-120.002898, 39.466462],
              [-120.002898, 39.466509],
              [-120.002898, 39.466598],
              [-120.002893, 39.467581],
              [-120.00289, 39.467956],
              [-120.002889, 39.468041],
              [-120.002888, 39.468169],
              [-120.002882, 39.468873],
              [-120.002887, 39.468894],
              [-120.002837, 39.472446],
              [-120.002836, 39.472507],
              [-120.002833, 39.472717],
              [-120.002827, 39.474381],
              [-120.002826, 39.474604],
              [-120.002819, 39.476404],
              [-120.00259, 39.483962],
              [-120.002579, 39.484331],
              [-120.002496, 39.487086],
              [-120.002487, 39.487393],
              [-120.002375, 39.491087],
              [-120.002338, 39.493404],
              [-120.002317, 39.494657],
              [-120.002303, 39.495515],
              [-120.002299, 39.495799],
              [-120.002291, 39.496279],
              [-120.002289, 39.496373],
              [-120.00222, 39.500645],
              [-120.002208, 39.501353],
              [-120.002207, 39.501433],
              [-120.002201, 39.501774],
              [-120.0022, 39.501832],
              [-120.002187, 39.502567],
              [-120.002181, 39.502882],
              [-120.002155, 39.504362],
              [-120.002154, 39.504444],
              [-120.002139, 39.505333],
              [-120.002139, 39.505357],
              [-120.00215, 39.50542],
              [-120.00216, 39.50548],
              [-120.00215, 39.506093],
              [-120.002149, 39.506161],
              [-120.002129, 39.507483],
              [-120.002114, 39.508412],
              [-120.002103, 39.508643],
              [-120.002082, 39.509099],
              [-120.002039, 39.51189],
              [-120.002035, 39.512183],
              [-120.002061, 39.51268],
              [-120.002018, 39.514956],
              [-120.002017, 39.514991],
              [-120.001936, 39.519201],
              [-120.001926, 39.519702],
              [-120.001921, 39.519959],
              [-120.001918, 39.520095],
              [-120.0019, 39.521064],
              [-120.001878, 39.522402],
              [-120.001863, 39.523275],
              [-120.001859, 39.523514],
              [-120.001841, 39.524678],
              [-120.001823, 39.525693],
              [-120.001793, 39.527391],
              [-120.00179, 39.527564],
              [-120.001789, 39.527642],
              [-120.001772, 39.52855],
              [-120.001764, 39.529027],
              [-120.001761, 39.529186],
              [-120.001736, 39.530583],
              [-120.00174, 39.534444],
              [-120.001774, 39.536772],
              [-120.001789, 39.537815],
              [-120.001806, 39.539007],
              [-120.001822, 39.540115],
              [-120.001826, 39.540338],
              [-120.001826, 39.540356],
              [-120.001847, 39.541785],
              [-120.001901, 39.545552],
              [-120.001947, 39.548724],
              [-120.001644, 39.563694],
              [-120.001599, 39.565922],
              [-120.001597, 39.566042],
              [-120.001587, 39.566531],
              [-120.001585, 39.56661],
              [-120.001581, 39.566811],
              [-120.001505, 39.57057],
              [-120.001504, 39.572666],
              [-120.001502, 39.575444],
              [-120.0015, 39.577356],
              [-120.0015, 39.577816],
              [-120.001477, 39.578827],
              [-120.001476, 39.578856],
              [-120.00121, 39.590161],
              [-120.001198, 39.590693],
              [-120.001195, 39.59081],
              [-120.001188, 39.591113],
              [-120.001185, 39.591253],
              [-120.000854, 39.605376],
              [-120.000843, 39.605854],
              [-120.00084, 39.605966],
              [-120.000835, 39.606183],
              [-120.000835, 39.606199],
              [-120.000834, 39.606244],
              [-120.00083, 39.606411],
              [-120.00082, 39.606828],
              [-120.00087, 39.610981],
              [-120.000871, 39.61109],
              [-120.000994, 39.621224],
              [-120.001027, 39.624551],
              [-120.00103, 39.624889],
              [-120.001058, 39.627634],
              [-120.001059, 39.627715],
              [-120.001067, 39.628536],
              [-120.001135, 39.635329],
              [-120.00114, 39.635795],
              [-120.00114, 39.635887],
              [-120.00114, 39.636182],
              [-120.00114, 39.636389],
              [-120.001149, 39.643372],
              [-120.001149, 39.643476],
              [-120.001149, 39.643751],
              [-120.001149, 39.643964],
              [-120.001151, 39.644726],
              [-120.001151, 39.644787],
              [-120.001151, 39.644804],
              [-120.001157, 39.650294],
              [-120.001152, 39.651253],
              [-120.00109, 39.662987],
              [-120.001089, 39.663228],
              [-120.00109, 39.664583],
              [-120.001084, 39.664743],
              [-120.001079, 39.665037],
              [-120.00105, 39.666961],
              [-120.001049, 39.66702],
              [-120.001006, 39.669819],
              [-120.000942, 39.671798],
              [-120.000934, 39.672002],
              [-120.000925, 39.672255],
              [-120.000922, 39.672585],
              [-120.000918, 39.673],
              [-120.000915, 39.673332],
              [-120.000912, 39.673642],
              [-120.000908, 39.674048],
              [-120.000906, 39.674268],
              [-120.000905, 39.674339],
              [-120.000903, 39.675144],
              [-120.000942, 39.679169],
              [-120.000959, 39.680613],
              [-120.000979, 39.714348],
              [-120.001062, 39.722418],
              [-120.001018, 39.732608],
              [-120.001014, 39.734012],
              [-120.001013, 39.734189],
              [-120.001006, 39.735405],
              [-120.001006, 39.735416],
              [-120.001032, 39.739739],
              [-120.001033, 39.742096],
              [-120.001133, 39.752229],
              [-120.001118, 39.752577],
              [-120.000891, 39.757838],
              [-120.000859, 39.758584],
              [-120.000805, 39.759846],
              [-120.000723, 39.766161],
              [-120.000702, 39.767508],
              [-120.000683, 39.768725],
              [-120.000677, 39.769372],
              [-120.000651, 39.77082],
              [-120.000548, 39.775223],
              [-120.00054, 39.779955],
              [-120.000538, 39.780788],
              [-120.000538, 39.780945],
              [-120.000528, 39.781525],
              [-120.000518, 39.782083],
              [-120.000503, 39.782914],
              [-120.000496, 39.790936],
              [-120.000495, 39.791448],
              [-120.000495, 39.791669],
              [-120.000495, 39.791706],
              [-120.000491, 39.795673],
              [-120.000491, 39.795709],
              [-120.000489, 39.797269],
              [-120.000476, 39.798081],
              [-120.000475, 39.798135],
              [-120.000455, 39.799344],
              [-120.000454, 39.799434],
              [-120.000308, 39.808396],
              [-120.000306, 39.808523],
              [-120.00017, 39.81525],
              [-120.000099, 39.818765],
              [-120.000092, 39.81913],
              [-120.000069, 39.820194],
              [-120.000028, 39.822084],
              [-120.000022, 39.823287],
              [-119.999907, 39.836513],
              [-119.999941, 39.83758],
              [-119.999843, 39.84342],
              [-119.999776, 39.847453],
              [-119.999744, 39.849386],
              [-119.999721, 39.850757],
              [-119.999716, 39.85103],
              [-119.999692, 39.852032],
              [-119.999603, 39.857399],
              [-119.999598, 39.857692],
              [-119.999461, 39.865535],
              [-119.999174, 39.874913],
              [-119.999127, 39.887384],
              [-119.999126, 39.887714],
              [-119.999104, 39.89383],
              [-119.999104, 39.893889],
              [-119.9991, 39.894773],
              [-119.999099, 39.895338],
              [-119.999099, 39.895434],
              [-119.999086, 39.903104],
              [-119.999085, 39.903817],
              [-119.999085, 39.903912],
              [-119.999085, 39.903968],
              [-119.999084, 39.904143],
              [-119.999084, 39.904257],
              [-119.999081, 39.906156],
              [-119.998833, 39.923559],
              [-119.998823, 39.924255],
              [-119.99882, 39.924461],
              [-119.998735, 39.930425],
              [-119.998729, 39.930844],
              [-119.998721, 39.931377],
              [-119.998714, 39.931881],
              [-119.998706, 39.932444],
              [-119.998696, 39.933132],
              [-119.998687, 39.93378],
              [-119.998679, 39.934335],
              [-119.998661, 39.935553],
              [-119.998658, 39.935789],
              [-119.998658, 39.935814],
              [-119.998653, 39.936166],
              [-119.998626, 39.938032],
              [-119.998498, 39.952568],
              [-119.998457, 39.954538],
              [-119.998445, 39.95511],
              [-119.998375, 39.958499],
              [-119.99837, 39.958749],
              [-119.998334, 39.960455],
              [-119.99833, 39.960677],
              [-119.998317, 39.961307],
              [-119.998298, 39.962234],
              [-119.998286, 39.962812],
              [-119.998279, 39.963105],
              [-119.998277, 39.96321],
              [-119.998263, 39.963903],
              [-119.998261, 39.964011],
              [-119.998248, 39.964653],
              [-119.998212, 39.966391],
              [-119.998189, 39.967473],
              [-119.998183, 39.967762],
              [-119.998177, 39.968035],
              [-119.998138, 39.969979],
              [-119.998138, 39.96999],
              [-119.998137, 39.970014],
              [-119.998136, 39.97006],
              [-119.998134, 39.970172],
              [-119.998107, 39.971502],
              [-119.998095, 39.972117],
              [-119.998056, 39.974041],
              [-119.997914, 39.981128],
              [-119.997908, 39.981409],
              [-119.997903, 39.981636],
              [-119.997875, 39.984262],
              [-119.997871, 39.984616],
              [-119.9978, 39.991211],
              [-119.997706, 39.999905],
              [-119.997619, 40.010653],
              [-119.997546, 40.022867],
              [-119.997534, 40.024951],
              [-119.997477, 40.032017],
              [-119.99742, 40.039203],
              [-119.997425, 40.041561],
              [-119.997409, 40.044372],
              [-119.997409, 40.044423],
              [-119.997369, 40.051893],
              [-119.997368, 40.052035],
              [-119.997346, 40.056075],
              [-119.997332, 40.057538],
              [-119.997328, 40.057569],
              [-119.997332, 40.057585],
              [-119.997308, 40.060254],
              [-119.99722, 40.069812],
              [-119.997234, 40.070142],
              [-119.997273, 40.071039],
              [-119.997276, 40.071637],
              [-119.997277, 40.071767],
              [-119.997278, 40.072095],
              [-119.997292, 40.074777],
              [-119.997304, 40.077208],
              [-119.997304, 40.077266],
              [-119.997307, 40.077756],
              [-119.99731, 40.078412],
              [-119.99731, 40.078499],
              [-119.997314, 40.079227],
              [-119.997319, 40.080166],
              [-119.997328, 40.081742],
              [-119.997328, 40.081805],
              [-119.997323, 40.083779],
              [-119.997323, 40.083907],
              [-119.997321, 40.084523],
              [-119.997318, 40.085781],
              [-119.997318, 40.085973],
              [-119.997321, 40.086958],
              [-119.997326, 40.089242],
              [-119.997326, 40.08934],
              [-119.997329, 40.090499],
              [-119.997329, 40.090513],
              [-119.99733, 40.090826],
              [-119.997332, 40.091564],
              [-119.997333, 40.091788],
              [-119.997333, 40.092114],
              [-119.997334, 40.092729],
              [-119.997344, 40.096363],
              [-119.997352, 40.099991],
              [-119.997317, 40.103794],
              [-119.997316, 40.103958],
              [-119.997312, 40.104427],
              [-119.99731, 40.104633],
              [-119.997309, 40.105062],
              [-119.997304, 40.105264],
              [-119.997304, 40.105316],
              [-119.997302, 40.105412],
              [-119.997304, 40.105495],
              [-119.997304, 40.10572],
              [-119.997285, 40.107567],
              [-119.997256, 40.110909],
              [-119.997251, 40.111338],
              [-119.997229, 40.113301],
              [-119.997215, 40.114498],
              [-119.997189, 40.116695],
              [-119.997152, 40.119786],
              [-119.997151, 40.120032],
              [-119.997137, 40.124904],
              [-119.997164, 40.12608],
              [-119.99717, 40.126322],
              [-119.997096, 40.12944],
              [-119.997002, 40.142579],
              [-119.997015, 40.153277],
              [-119.997015, 40.153427],
              [-119.997016, 40.154331],
              [-119.997016, 40.154492],
              [-119.997018, 40.15602],
              [-119.997019, 40.157158],
              [-119.996968, 40.158584],
              [-119.996968, 40.158842],
              [-119.996967, 40.159223],
              [-119.996944, 40.17158],
              [-119.996994, 40.183286],
              [-119.996995, 40.183567],
              [-119.997006, 40.186235],
              [-119.996951, 40.187449],
              [-119.996923, 40.188728],
              [-119.996666, 40.200393],
              [-119.996659, 40.200694],
              [-119.99662, 40.206145],
              [-119.996611, 40.207432],
              [-119.996567, 40.213522],
              [-119.996528, 40.21892],
              [-119.996527, 40.219],
              [-119.996451, 40.229681],
              [-119.99646, 40.230856],
              [-119.996332, 40.244268],
              [-119.996286, 40.245317],
              [-119.996284, 40.245449],
              [-119.996284, 40.245463],
              [-119.996265, 40.249906],
              [-119.996262, 40.2581],
              [-119.996262, 40.258228],
              [-119.996262, 40.258747],
              [-119.99626, 40.259209],
              [-119.99626, 40.25926],
              [-119.99626, 40.259278],
              [-119.996259, 40.259298],
              [-119.996259, 40.259331],
              [-119.996257, 40.25979],
              [-119.996256, 40.259969],
              [-119.996256, 40.260347],
              [-119.996258, 40.262429],
              [-119.996258, 40.262471],
              [-119.996258, 40.263495],
              [-119.996258, 40.263616],
              [-119.996259, 40.264117],
              [-119.996259, 40.264247],
              [-119.996265, 40.274521],
              [-119.996284, 40.277565],
              [-119.9963, 40.280076],
              [-119.9963, 40.280138],
              [-119.996302, 40.280545],
              [-119.996303, 40.28069],
              [-119.996381, 40.293172],
              [-119.996504, 40.312985],
              [-119.996504, 40.313055],
              [-119.996505, 40.313208],
              [-119.996508, 40.313671],
              [-119.996512, 40.314324],
              [-119.996515, 40.314743],
              [-119.996516, 40.314929],
              [-119.996522, 40.315832],
              [-119.996523, 40.315976],
              [-119.996536, 40.318131],
              [-119.996533, 40.31854],
              [-119.996526, 40.319792],
              [-119.996518, 40.320963],
              [-119.996517, 40.321146],
              [-119.996517, 40.321227],
              [-119.996517, 40.321252],
              [-119.996513, 40.32184],
              [-119.99651, 40.322244],
              [-119.996499, 40.324079],
              [-119.996498, 40.324162],
              [-119.996492, 40.325175],
              [-119.996491, 40.325272],
              [-119.996445, 40.332641],
              [-119.996339, 40.339194],
              [-119.996339, 40.339208],
              [-119.996336, 40.339365],
              [-119.996332, 40.339603],
              [-119.996212, 40.346999],
              [-119.996194, 40.347955],
              [-119.99596, 40.360155],
              [-119.995955, 40.360453],
              [-119.995953, 40.360547],
              [-119.995936, 40.361416],
              [-119.995923, 40.362199],
              [-119.99576, 40.372416],
              [-119.99576, 40.372427],
              [-119.995734, 40.374015],
              [-119.995705, 40.37583],
              [-119.995703, 40.384845],
              [-119.995702, 40.390414],
              [-119.995687, 40.394336],
              [-119.995675, 40.397194],
              [-119.995674, 40.397562],
              [-119.995663, 40.400204],
              [-119.995658, 40.401459],
              [-119.995656, 40.401995],
              [-119.995656, 40.402071],
              [-119.995656, 40.402189],
              [-119.995655, 40.402322],
              [-119.995655, 40.402408],
              [-119.995652, 40.40326],
              [-119.995646, 40.404718],
              [-119.995645, 40.404945],
              [-119.995645, 40.406268],
              [-119.995641, 40.416445],
              [-119.995641, 40.416525],
              [-119.995641, 40.4186],
              [-119.99564, 40.420204],
              [-119.99564, 40.420405],
              [-119.995639, 40.424195],
              [-119.995627, 40.428275],
              [-119.995625, 40.428877],
              [-119.995598, 40.438366],
              [-119.995603, 40.439405],
              [-119.995631, 40.445571],
              [-119.995632, 40.445741],
              [-119.995664, 40.452902],
              [-119.995678, 40.470159],
              [-119.995687, 40.481752],
              [-119.995796, 40.496248],
              [-119.995784, 40.499903],
              [-119.99583, 40.510725],
              [-119.995806, 40.522716],
              [-119.995806, 40.522923],
              [-119.995798, 40.526928],
              [-119.99581, 40.535255],
              [-119.995811, 40.535639],
              [-119.99582, 40.541628],
              [-119.995871, 40.549074],
              [-119.995872, 40.549192],
              [-119.995896, 40.552651],
              [-119.995897, 40.552808],
              [-119.995908, 40.554255],
              [-119.995908, 40.55431],
              [-119.995919, 40.555884],
              [-119.99592, 40.556049],
              [-119.99592, 40.556068],
              [-119.995922, 40.556511],
              [-119.995923, 40.556644],
              [-119.995976, 40.57055],
              [-119.996167, 40.580826],
              [-119.996171, 40.581045],
              [-119.996231, 40.584284],
              [-119.99626, 40.585004],
              [-119.9966, 40.598743],
              [-119.996644, 40.599447],
              [-119.996723, 40.610778],
              [-119.996726, 40.61128],
              [-119.996737, 40.612808],
              [-119.996739, 40.613132],
              [-119.996741, 40.613342],
              [-119.996761, 40.613909],
              [-119.996763, 40.614237],
              [-119.996765, 40.61464],
              [-119.996772, 40.616317],
              [-119.996793, 40.620753],
              [-119.996811, 40.624764],
              [-119.996812, 40.624905],
              [-119.996816, 40.625141],
              [-119.996817, 40.625209],
              [-119.996821, 40.625516],
              [-119.996827, 40.625897],
              [-119.996833, 40.626271],
              [-119.996833, 40.626293],
              [-119.996848, 40.627271],
              [-119.996857, 40.627853],
              [-119.996882, 40.628386],
              [-119.996903, 40.629928],
              [-119.996991, 40.63634],
              [-119.997073, 40.64232],
              [-119.997062, 40.642908],
              [-119.997238, 40.656902],
              [-119.997235, 40.657369],
              [-119.997477, 40.671422],
              [-119.997426, 40.671423],
              [-119.997466, 40.671913],
              [-119.997478, 40.67304],
              [-119.99748, 40.673185],
              [-119.99759, 40.683547],
              [-119.997837, 40.70189],
              [-119.997838, 40.701952],
              [-119.997844, 40.702416],
              [-119.997847, 40.702633],
              [-119.998088, 40.720488],
              [-119.998091, 40.720702],
              [-119.998095, 40.72104],
              [-119.998098, 40.721257],
              [-119.998387, 40.742686],
              [-119.998409, 40.744323],
              [-119.998479, 40.749899],
              [-119.998486, 40.750472],
              [-119.998502, 40.751444],
              [-119.998634, 40.759233],
              [-119.998762, 40.771596],
              [-119.998785, 40.77385],
              [-119.998772, 40.775541],
              [-119.998743, 40.779521],
              [-119.998719, 40.782735],
              [-119.998676, 40.788503],
              [-119.998657, 40.794156],
              [-119.998672, 40.796119],
              [-119.998673, 40.796212],
              [-119.998689, 40.798257],
              [-119.99869, 40.798342],
              [-119.998741, 40.804874],
              [-119.998755, 40.806728],
              [-119.998755, 40.80674],
              [-119.998757, 40.806929],
              [-119.998758, 40.80704],
              [-119.998815, 40.814381],
              [-119.998873, 40.821786],
              [-119.998901, 40.831367],
              [-119.998901, 40.831529],
              [-119.998902, 40.831811],
              [-119.998903, 40.83198],
              [-119.998903, 40.83209],
              [-119.999167, 40.85786],
              [-119.999217, 40.863799],
              [-119.999234, 40.865878],
              [-119.999234, 40.865894],
              [-119.999247, 40.867448],
              [-119.999263, 40.869339],
              [-119.999278, 40.871077],
              [-119.999279, 40.871206],
              [-119.999279, 40.871232],
              [-119.99928, 40.87137],
              [-119.999281, 40.871466],
              [-119.999288, 40.872363],
              [-119.999295, 40.873084],
              [-119.999286, 40.873101],
              [-119.999286, 40.873159],
              [-119.999285, 40.873635],
              [-119.999285, 40.873751],
              [-119.999284, 40.873966],
              [-119.999282, 40.874898],
              [-119.999267, 40.875384],
              [-119.99926, 40.875629],
              [-119.999252, 40.8759],
              [-119.999251, 40.875931],
              [-119.999288, 40.88387],
              [-119.999312, 40.889001],
              [-119.999371, 40.901339],
              [-119.999409, 40.913676],
              [-119.999409, 40.913699],
              [-119.999409, 40.913844],
              [-119.999411, 40.914415],
              [-119.999413, 40.914993],
              [-119.999415, 40.915613],
              [-119.999405, 40.915627],
              [-119.999418, 40.916761],
              [-119.999421, 40.91716],
              [-119.999444, 40.920794],
              [-119.999505, 40.930287],
              [-119.999586, 40.948701],
              [-119.999593, 40.94916],
              [-119.99964, 40.952243],
              [-119.999667, 40.959889],
              [-119.999663, 40.959903],
              [-119.999682, 40.963991],
              [-119.999875, 40.999894],
              [-119.999829, 41.005584],
              [-119.999829, 41.005639],
              [-119.99982, 41.006763],
              [-119.999815, 41.006819],
              [-119.999862, 41.007514],
              [-119.999844, 41.011915],
              [-119.999844, 41.011925],
              [-119.999832, 41.014758],
              [-119.999817, 41.018392],
              [-119.99981, 41.019914],
              [-119.99981, 41.019971],
              [-119.999802, 41.021836],
              [-119.999802, 41.021951],
              [-119.999801, 41.022211],
              [-119.999888, 41.039538],
              [-119.999875, 41.054041],
              [-119.999959, 41.065821],
              [-119.999942, 41.068505],
              [-120.000039, 41.082999],
              [-120.000008, 41.102317],
              [-120.000008, 41.102462],
              [-120.0, 41.109747],
              [-120.0, 41.112132],
              [-120.0, 41.112237],
              [-120.0, 41.112886],
              [-119.99999, 41.122559],
              [-120.000011, 41.124898],
              [-119.999974, 41.126564],
              [-119.999906, 41.137904],
              [-119.999905, 41.138051],
              [-119.999905, 41.138084],
              [-119.999905, 41.138124],
              [-119.999904, 41.13816],
              [-119.999904, 41.138185],
              [-119.999903, 41.138362],
              [-119.999898, 41.139267],
              [-119.999895, 41.139893],
              [-119.999893, 41.140235],
              [-119.999867, 41.14451],
              [-119.999867, 41.144572],
              [-119.999799, 41.155921],
              [-119.999809, 41.162269],
              [-119.999809, 41.162308],
              [-119.999807, 41.162328],
              [-119.99981, 41.162347],
              [-119.999813, 41.164348],
              [-119.999815, 41.165251],
              [-119.999815, 41.165424],
              [-119.999815, 41.165752],
              [-119.999823, 41.170569],
              [-119.999847, 41.173322],
              [-119.999847, 41.173345],
              [-119.999874, 41.176444],
              [-119.999866, 41.183974],
              [-119.999864, 41.184773],
              [-119.999848, 41.193648],
              [-119.999846, 41.194573],
              [-119.999832, 41.201508],
              [-119.999831, 41.201992],
              [-119.999825, 41.205021],
              [-119.999823, 41.205872],
              [-119.999821, 41.206701],
              [-119.999821, 41.206723],
              [-119.99982, 41.207014],
              [-119.99982, 41.207304],
              [-119.999818, 41.208543],
              [-119.999815, 41.209782],
              [-119.999814, 41.210496],
              [-119.999812, 41.21121],
              [-119.999807, 41.213462],
              [-119.999802, 41.215714],
              [-119.999792, 41.22059],
              [-119.999782, 41.225466],
              [-119.999779, 41.226809],
              [-119.999776, 41.228152],
              [-119.99977, 41.23122],
              [-119.999763, 41.234289],
              [-119.99976, 41.236061],
              [-119.999767, 41.236625],
              [-119.99978, 41.241851],
              [-119.99978, 41.241948],
              [-119.999867, 41.24427],
              [-119.999928, 41.245887],
              [-120.000073, 41.249758],
              [-120.000049, 41.256375],
              [-120.000048, 41.256765],
              [-120.00003, 41.263357],
              [-120.000028, 41.264005],
              [-120.000016, 41.266487],
              [-120.000016, 41.266548],
              [-120.000016, 41.267423],
              [-120.000016, 41.267493],
              [-119.99999, 41.274794],
              [-119.999989, 41.275012],
              [-119.999932, 41.292433],
              [-119.999931, 41.292797],
              [-119.999908, 41.29975],
              [-119.999885, 41.307067],
              [-119.999865, 41.313101],
              [-119.999836, 41.322491],
              [-119.999836, 41.32261],
              [-119.999832, 41.323637],
              [-119.99982, 41.327319],
              [-119.99982, 41.32745],
              [-119.999734, 41.355576],
              [-119.999699, 41.36511],
              [-119.9997, 41.365323],
              [-119.999716, 41.367814],
              [-119.999757, 41.37465],
              [-119.999703, 41.398212],
              [-119.999682, 41.406427],
              [-119.999674, 41.410578],
              [-119.999665, 41.41473],
              [-119.999659, 41.417667],
              [-119.999653, 41.420604],
              [-119.999661, 41.422158],
              [-119.999648, 41.42314],
              [-119.999648, 41.423275],
              [-119.999642, 41.42641],
              [-119.999635, 41.429679],
              [-119.999633, 41.43247],
              [-119.999631, 41.43526],
              [-119.999622, 41.435811],
              [-119.999622, 41.436073],
              [-119.999621, 41.436335],
              [-119.999621, 41.436413],
              [-119.999612, 41.441172],
              [-119.999602, 41.445932],
              [-119.999599, 41.447326],
              [-119.999595, 41.449265],
              [-119.99959, 41.45178],
              [-119.999581, 41.45595],
              [-119.999566, 41.463084],
              [-119.999552, 41.470218],
              [-119.999517, 41.478567],
              [-119.999517, 41.478669],
              [-119.999512, 41.480898],
              [-119.999508, 41.482727],
              [-119.999503, 41.484932],
              [-119.999498, 41.487138],
              [-119.999496, 41.487984],
              [-119.999494, 41.488826],
              [-119.999489, 41.489001],
              [-119.999494, 41.489017],
              [-119.999486, 41.492751],
              [-119.999478, 41.496458],
              [-119.999478, 41.496499],
              [-119.999475, 41.498195],
              [-119.999471, 41.499894],
              [-119.999465, 41.500228],
              [-119.999046, 41.521809],
              [-119.999043, 41.522864],
              [-119.999018, 41.525979],
              [-119.998993, 41.529094],
              [-119.998957, 41.53357],
              [-119.998921, 41.538045],
              [-119.998898, 41.540871],
              [-119.998876, 41.543698],
              [-119.998868, 41.544665],
              [-119.99886, 41.545632],
              [-119.998859, 41.545765],
              [-119.998763, 41.557834],
              [-119.998762, 41.557943],
              [-119.998745, 41.56009],
              [-119.998575, 41.581432],
              [-119.998307, 41.615544],
              [-119.998303, 41.616192],
              [-119.998295, 41.617473],
              [-119.998287, 41.618818],
              [-119.998861, 41.624937],
              [-119.998897, 41.654431],
              [-119.998902, 41.657899],
              [-119.998893, 41.658511],
              [-119.998822, 41.663708],
              [-119.998822, 41.663767],
              [-119.998819, 41.664249],
              [-119.998819, 41.664321],
              [-119.998823, 41.665148],
              [-119.998825, 41.665162],
              [-119.998754, 41.671328],
              [-119.998751, 41.674798],
              [-119.998751, 41.674861],
              [-119.998747, 41.675943],
              [-119.99873, 41.691682],
              [-119.998698, 41.720632],
              [-119.998295, 41.749915],
              [-119.998332, 41.755575],
              [-119.998472, 41.773652],
              [-119.998473, 41.773717],
              [-119.998499, 41.776915],
              [-119.9985, 41.776985],
              [-119.998629, 41.793688],
              [-119.998674, 41.799293],
              [-119.998675, 41.799478],
              [-119.998694, 41.802071],
              [-119.998695, 41.80216],
              [-119.998693, 41.80218],
              [-119.998896, 41.827404],
              [-119.998896, 41.827465],
              [-119.998957, 41.835127],
              [-119.998958, 41.83519],
              [-119.999035, 41.844746],
              [-119.999214, 41.867141],
              [-119.999276, 41.874895],
              [-119.999269, 41.882112],
              [-119.999261, 41.891286],
              [-119.999253, 41.899456],
              [-119.999253, 41.899621],
              [-119.999295, 41.902498],
              [-119.999295, 41.902842],
              [-119.999292, 41.90635],
              [-119.999292, 41.906538],
              [-119.99933, 41.907643],
              [-119.999332, 41.907702],
              [-119.999305, 41.92223],
              [-119.999304, 41.92257],
              [-119.999302, 41.923748],
              [-119.9993, 41.924925],
              [-119.999265, 41.943486],
              [-119.99923, 41.961973],
              [-119.99923, 41.962047],
              [-119.999204, 41.975163],
              [-119.99919, 41.978975],
              [-119.99919, 41.979047],
              [-119.999169, 41.99373],
              [-119.999234, 41.994944],
              [-119.998541, 41.994985],
              [-119.99663, 41.995096],
              [-119.989133, 41.995564],
              [-119.986801, 41.995709],
              [-119.984573, 41.995748],
              [-119.977694, 41.995915],
              [-119.975814, 41.995961],
              [-119.974311, 41.995989],
              [-119.973301, 41.996008],
              [-119.9687, 41.996073],
              [-119.96044, 41.996348],
              [-119.956119, 41.99649],
              [-119.951116, 41.996655],
              [-119.948657, 41.996736],
              [-119.947894, 41.996753],
              [-119.946478, 41.996768],
              [-119.940978, 41.996829],
              [-119.935893, 41.996881],
              [-119.935498, 41.996885],
              [-119.934607, 41.996894],
              [-119.934003, 41.9969],
              [-119.932996, 41.99691],
              [-119.932158, 41.996919],
              [-119.931815, 41.996922],
              [-119.931123, 41.996929],
              [-119.930198, 41.996939],
              [-119.929759, 41.996943],
              [-119.928835, 41.996953],
              [-119.928399, 41.996957],
              [-119.927226, 41.996969],
              [-119.926832, 41.996973],
              [-119.925986, 41.996981],
              [-119.925929, 41.996982],
              [-119.925836, 41.996983],
              [-119.925797, 41.996983],
              [-119.925738, 41.996984],
              [-119.925714, 41.996984],
              [-119.925633, 41.996985],
              [-119.925554, 41.996986],
              [-119.925483, 41.996986],
              [-119.925399, 41.996987],
              [-119.925276, 41.996988],
              [-119.925234, 41.996989],
              [-119.925072, 41.99699],
              [-119.924962, 41.996992],
              [-119.924864, 41.996993],
              [-119.924689, 41.996994],
              [-119.924569, 41.996995],
              [-119.924449, 41.996997],
              [-119.924401, 41.996997],
              [-119.924214, 41.996999],
              [-119.924103, 41.997],
              [-119.924064, 41.997001],
              [-119.923974, 41.997001],
              [-119.92392, 41.997002],
              [-119.9238, 41.997003],
              [-119.923616, 41.997005],
              [-119.923445, 41.997007],
              [-119.923385, 41.997007],
              [-119.923273, 41.997008],
              [-119.923042, 41.997011],
              [-119.922878, 41.997012],
              [-119.922741, 41.997014],
              [-119.922554, 41.997016],
              [-119.922395, 41.997017],
              [-119.922188, 41.997019],
              [-119.922025, 41.997021],
              [-119.921993, 41.997021],
              [-119.921799, 41.997023],
              [-119.921613, 41.997025],
              [-119.921408, 41.997027],
              [-119.921196, 41.997029],
              [-119.920999, 41.997031],
              [-119.920759, 41.997034],
              [-119.920641, 41.997035],
              [-119.920455, 41.997037],
              [-119.920052, 41.997041],
              [-119.919411, 41.997047],
              [-119.918815, 41.997053],
              [-119.917981, 41.997061],
              [-119.917472, 41.997066],
              [-119.916395, 41.997077],
              [-119.915139, 41.99709],
              [-119.913787, 41.997103],
              [-119.912993, 41.997111],
              [-119.912306, 41.997118],
              [-119.911617, 41.997125],
              [-119.910715, 41.997134],
              [-119.90991, 41.997142],
              [-119.908647, 41.997154],
              [-119.907181, 41.997174],
              [-119.905799, 41.997193],
              [-119.90552, 41.997197],
              [-119.905422, 41.997199],
              [-119.904909, 41.997207],
              [-119.904136, 41.997216],
              [-119.902967, 41.997232],
              [-119.902574, 41.997237],
              [-119.901622, 41.99725],
              [-119.900677, 41.997263],
              [-119.899827, 41.997275],
              [-119.89878, 41.997289],
              [-119.897974, 41.9973],
              [-119.89741, 41.997308],
              [-119.896701, 41.997318],
              [-119.895355, 41.997336],
              [-119.894436, 41.997349],
              [-119.893802, 41.997357],
              [-119.893542, 41.997361],
              [-119.892987, 41.997368],
              [-119.892914, 41.99737],
              [-119.891724, 41.997386],
              [-119.89105, 41.997395],
              [-119.890219, 41.997406],
              [-119.889551, 41.997415],
              [-119.888772, 41.997422],
              [-119.887733, 41.997424],
              [-119.88653, 41.997426],
              [-119.88644, 41.997425],
              [-119.883515, 41.99743],
              [-119.882393, 41.997432],
              [-119.881062, 41.997434],
              [-119.88087, 41.997436],
              [-119.873493, 41.997445],
              [-119.873264, 41.997444],
              [-119.87217, 41.997447],
              [-119.867347, 41.997454],
              [-119.865496, 41.997456],
              [-119.860323, 41.997462],
              [-119.858163, 41.997464],
              [-119.854723, 41.997468],
              [-119.854654, 41.997468],
              [-119.849974, 41.997473],
              [-119.84854, 41.997476],
              [-119.826835, 41.997477],
              [-119.824609, 41.997472],
              [-119.815829, 41.997453],
              [-119.810041, 41.997441],
              [-119.80213, 41.997417],
              [-119.801834, 41.997418],
              [-119.797259, 41.997402],
              [-119.793856, 41.997393],
              [-119.791174, 41.997384],
              [-119.789711, 41.997388],
              [-119.786765, 41.997325],
              [-119.783203, 41.997261],
              [-119.78106, 41.997224],
              [-119.780475, 41.997214],
              [-119.779418, 41.997196],
              [-119.778802, 41.99719],
              [-119.777319, 41.997159],
              [-119.775501, 41.997128],
              [-119.771485, 41.997058],
              [-119.770254, 41.997036],
              [-119.768347, 41.996972],
              [-119.764741, 41.996846],
              [-119.762022, 41.996753],
              [-119.758255, 41.996623],
              [-119.755983, 41.996545],
              [-119.754832, 41.99653],
              [-119.75368, 41.996515],
              [-119.749843, 41.996465],
              [-119.747621, 41.996436],
              [-119.74343, 41.996381],
              [-119.740471, 41.996342],
              [-119.737197, 41.996299],
              [-119.736337, 41.996288],
              [-119.733923, 41.996256],
              [-119.733556, 41.996251],
              [-119.725823, 41.996205],
              [-119.71992, 41.996198],
              [-119.710989, 41.996182],
              [-119.710269, 41.996185],
              [-119.707717, 41.996198],
              [-119.703677, 41.996212],
              [-119.69418, 41.996251],
              [-119.693627, 41.996255],
              [-119.689874, 41.996271],
              [-119.686997, 41.996282],
              [-119.685638, 41.996288],
              [-119.676598, 41.996326],
              [-119.674511, 41.996335],
              [-119.67308, 41.996342],
              [-119.671807, 41.996339],
              [-119.670753, 41.99633],
              [-119.66893, 41.996314],
              [-119.667258, 41.996299],
              [-119.666714, 41.996295],
              [-119.665462, 41.996284],
              [-119.664288, 41.996273],
              [-119.663425, 41.996266],
              [-119.662813, 41.996261],
              [-119.661911, 41.996253],
              [-119.661413, 41.996249],
              [-119.660726, 41.996242],
              [-119.655796, 41.996199],
              [-119.65547, 41.996195],
              [-119.655154, 41.996193],
              [-119.655087, 41.996193],
              [-119.653459, 41.996187],
              [-119.646336, 41.996133],
              [-119.644906, 41.996124],
              [-119.64183, 41.996106],
              [-119.640208, 41.996094],
              [-119.638785, 41.996087],
              [-119.637982, 41.99608],
              [-119.636028, 41.99607],
              [-119.635252, 41.996066],
              [-119.633833, 41.996057],
              [-119.633313, 41.996054],
              [-119.631611, 41.99604],
              [-119.630214, 41.996031],
              [-119.628987, 41.996023],
              [-119.624848, 41.995995],
              [-119.62022, 41.995959],
              [-119.619055, 41.99595],
              [-119.618174, 41.995944],
              [-119.617358, 41.995938],
              [-119.615837, 41.995927],
              [-119.615427, 41.995924],
              [-119.614701, 41.995919],
              [-119.61382, 41.995913],
              [-119.613241, 41.995909],
              [-119.611625, 41.995904],
              [-119.610142, 41.995899],
              [-119.608031, 41.995893],
              [-119.598932, 41.995864],
              [-119.591987, 41.995839],
              [-119.588239, 41.995821],
              [-119.577219, 41.995768],
              [-119.574007, 41.995752],
              [-119.564769, 41.995759],
              [-119.560133, 41.995765],
              [-119.552721, 41.995765],
              [-119.549571, 41.995774],
              [-119.536256, 41.995781],
              [-119.535826, 41.995783],
              [-119.531434, 41.995787],
              [-119.521512, 41.995774],
              [-119.511751, 41.995765],
              [-119.507551, 41.995756],
              [-119.50432, 41.995752],
              [-119.503617, 41.995748],
              [-119.502073, 41.995746],
              [-119.5013, 41.995745],
              [-119.499326, 41.995741],
              [-119.496549, 41.995735],
              [-119.493762, 41.995727],
              [-119.491197, 41.995735],
              [-119.490613, 41.995737],
              [-119.486244, 41.99575],
              [-119.484982, 41.995754],
              [-119.483771, 41.995758],
              [-119.481675, 41.995764],
              [-119.479887, 41.995769],
              [-119.479196, 41.995771],
              [-119.475951, 41.995772],
              [-119.473638, 41.995766],
              [-119.473034, 41.995765],
              [-119.472882, 41.995766],
              [-119.471302, 41.995761],
              [-119.470897, 41.99576],
              [-119.469848, 41.995758],
              [-119.468763, 41.995756],
              [-119.466423, 41.99575],
              [-119.463409, 41.995739],
              [-119.46035, 41.995729],
              [-119.455515, 41.995717],
              [-119.452804, 41.995692],
              [-119.452455, 41.995689],
              [-119.452217, 41.995687],
              [-119.451416, 41.995678],
              [-119.448862, 41.995651],
              [-119.446275, 41.995623],
              [-119.441988, 41.995574],
              [-119.440414, 41.995557],
              [-119.436322, 41.995516],
              [-119.436026, 41.995513],
              [-119.433079, 41.995474],
              [-119.426776, 41.995383],
              [-119.416523, 41.995233],
              [-119.415921, 41.995225],
              [-119.401713, 41.994977],
              [-119.3926, 41.99479],
              [-119.391939, 41.994776],
              [-119.391305, 41.994764],
              [-119.390148, 41.99474],
              [-119.388703, 41.994709],
              [-119.387366, 41.99468],
              [-119.386047, 41.994651],
              [-119.384686, 41.994621],
              [-119.383488, 41.994595],
              [-119.383184, 41.994588],
              [-119.38134, 41.99455],
              [-119.381144, 41.994546],
              [-119.381103, 41.994545],
              [-119.380444, 41.994531],
              [-119.379119, 41.994503],
              [-119.378577, 41.994492],
              [-119.377489, 41.994469],
              [-119.376546, 41.994449],
              [-119.37632, 41.994444],
              [-119.375773, 41.994438],
              [-119.375407, 41.994434],
              [-119.374743, 41.994427],
              [-119.374125, 41.994421],
              [-119.373518, 41.994415],
              [-119.373217, 41.994412],
              [-119.372597, 41.994405],
              [-119.372333, 41.994403],
              [-119.372071, 41.9944],
              [-119.370869, 41.994388],
              [-119.369608, 41.994375],
              [-119.367251, 41.994351],
              [-119.365781, 41.994336],
              [-119.362533, 41.994302],
              [-119.361752, 41.994294],
              [-119.361081, 41.994287],
              [-119.360678, 41.994283],
              [-119.360122, 41.994278],
              [-119.359408, 41.99427],
              [-119.355582, 41.994227],
              [-119.351081, 41.994144],
              [-119.350841, 41.994145],
              [-119.348477, 41.994114],
              [-119.344059, 41.994061],
              [-119.343626, 41.994053],
              [-119.343241, 41.994047],
              [-119.34252, 41.994037],
              [-119.340472, 41.994007],
              [-119.339869, 41.993998],
              [-119.338495, 41.993978],
              [-119.336778, 41.993956],
              [-119.336519, 41.993955],
              [-119.335211, 41.993952],
              [-119.324184, 41.993923]
            ]
          ]
        ]
      }
    }
  ]
}

export default ca_nv;