const parseCoords = (dataset) => {
  dataset.map((datapoint) => {
    
    var data = datapoint.node.data

    if (!data.DEG_LATITUDE || !data.DEG_LONGITUDE) { return }
    data.LATITUDE = ConvertDMSToDD(data.DEG_LATITUDE)
    data.LONGITUDE = ConvertDMSToDD(data.DEG_LONGITUDE)
  })

  return dataset;
}

const ConvertDMSToDD = (input) => {
  var parts = input.split(/[^\d\w]+/);
  const degrees = parseFloat(parts[0])
  const minutes = parseFloat(parts[1])
  const seconds = parseFloat(parts[2])
  const direction = parts[3]

  var dd = (degrees + minutes/60 + seconds/(60*60)).toFixed(6);

  if (direction === "S" || direction === "W") {
      dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}

export default parseCoords;