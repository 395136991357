import React from "react"
import * as d3 from "d3"
import _ from "underscore"
import Img from "gatsby-image"
import ListPointMedia from "./listPointMedia"

const ListPoint = ({
  node,
  mapLocation,
  setMapLocation,
  isScrolling,
  setIsScrolling,
}) => {
  const data = node.data

  React.useEffect(() => {
    // Avoid being too jumpy
    if (isScrolling) {
      return
    }

    if (isElementOnScreen(data.ID) && data.ID !== mapLocation.id) {
      setMapLocationHere()
    }
  }, [isScrolling])

  React.useEffect(() => {
    if (isScrolling || isElementOnScreen(mapLocation.id)) {
      return
    }

    slideItemToTop(mapLocation.id)
  }, [mapLocation.id])

  const clickItem = () => {
    if (!data.LONGITUDE || !data.LATITUDE) {
      return
    }
    setMapLocationHere()
  }

  const setMapLocationHere = () => {
    setMapLocation({
      id: data.ID,
      longitude: data.LONGITUDE,
      latitude: data.LATITUDE,
    })
  }

  const slideItemToTop = id => {
    const pos = d3.select(`#marker${id}`)

    if (!pos || !pos.node()) {
      return
    }
    setIsScrolling(true)

    pos.style("border-left-width", "4px")

    d3.select(".list-cont")
      .transition()
      .duration(500)
      .tween("uniquetweenname", scrollTopTween(pos.node().offsetTop))

    setIsScrolling(false)
  }

  const isElementOnScreen = id => {
    var element = document.getElementById(`marker${id}`)
    if (!element) {
      return
    }
    const elemTop = element.getBoundingClientRect().top
    // visible top of scroll list is about this far down
    const offset = 80

    // How far the top of the element can be above the visible top and still be set as current
    const topBound = -80

    // How far the top of the element can be below the visible top and still be set as current
    const bottomBound = 50

    const elemBelowTopBound = elemTop - offset > topBound
    const elemAboveBottomBound = elemTop - offset < bottomBound

    return elemBelowTopBound && elemAboveBottomBound
  }

  const scrollTopTween = scrollTop => {
    const it = d3.select(".list-cont").node()
    return function() {
      var i = d3.interpolateNumber(it.scrollTop, scrollTop)
      return function(t) {
        it.scrollTop = i(t)
      }
    }
  }

  return (
    <div
      className={`item ${data.TYPE_OF_POINT_ON_THE_MAP}`}
      id={`marker${data.ID}`}
      onClick={clickItem}
    >
      <h2>
        {node.icon && (
          <span>
            <Img className="num" fluid={node.icon.childImageSharp.fluid} />
          </span>
        )}

        {data.TYPE_OF_POINT_ON_THE_MAP === "live" && (
          <span className="live-badge">
            <span className="live-img"></span>
            <span>LIVE</span>
          </span>
        )}

        <span>{data.TITLE}</span>
      </h2>

      <div className="content">
        {data.LATITUDE && data.LONGITUDE && (
          <div className="latlng">{`${data.LATITUDE}, ${data.LONGITUDE}`}</div>
        )}
        <div className="desc">
          {data.TYPE_OF_POINT_ON_THE_MAP === "live" && data.TIME && (
            <span className="live-time">{data.TIME} - </span>
          )}
          <div className="desc-text">{data.DESCRIPTION}</div>
        </div>

        {[
          node.image1,
          node.image2,
          node.image3,
          node.media1,
          node.media2,
          node.media3,
        ].map((media, index) => {
          if (!media) {
            return
          }
          return <ListPointMedia key={index} media={media} />
        })}
      </div>
    </div>
  )
}

export default ListPoint
