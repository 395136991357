import React from "react"
import mapboxgl from "mapbox-gl"
import ca_nv from "../data/ca_nv"
import parseMiniMap from "./parseMiniMap"
import { drawMap, drawOnSvg } from "./parseDrawMap"

const MiniMap = ({
  data,
  points,
  mapLocation,
  lineCoords,
  bounds,
  bboxPoly,
}) => {
  const [miniMap, setMiniMap] = React.useState(null)

  React.useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_TOKEN
    const initializeMiniMap = ({ setMiniMap }) => {
      const miniMap = new mapboxgl.Map({
        container: "mini-map",
        style: "mapbox://styles/mapbox/light-v9?optimize=true",
        center: (data[0] && data[0].node.data.LONGITUDE)
          ? [data[0].node.data.LONGITUDE, data[0].node.data.LATITUDE]
          : [-117.3432, 38.4689],
        zoom: 5.8,
        interactive: false
      })

      miniMap.on("load", () => {
        miniMap.resize()
        setMiniMap(miniMap)
      })
    }

    if (!miniMap) initializeMiniMap({ setMiniMap })
  }, [miniMap])

  React.useEffect(() => {
    if (!bboxPoly) {
      return
    }
    drawMap(ca_nv)
    drawOnSvg(bboxPoly)
  }, [bboxPoly])

  React.useEffect(() => {
    if (!miniMap || !lineCoords || !points) {
      return
    }

    parseMiniMap(miniMap, lineCoords, points)
  }, [miniMap, lineCoords, points])

  React.useEffect(() => {
    if (!miniMap || !bounds) {
      return
    }
    const padding = 15

    miniMap.fitBounds(bounds, {
      padding: { top: padding, bottom: padding, left: padding, right: padding },
      animate: false
    })
  }, [bounds])

  React.useEffect(() => {
    if (!miniMap || !miniMap.loaded() || !mapLocation.longitude) {
      return
    }

    const routeCt = 0
    miniMap.setFilter(`pts-layer-highlighted-${String(routeCt)}`, [
      "==",
      "id",
      mapLocation.id,
    ])
  }, [miniMap, mapLocation])

  return (
    <div id="mini-map-container">
      <div id="inset"></div>
      <div id="mini-map"></div>
    </div>
  )
}

export default MiniMap
