import * as d3 from "d3"
import * as turf from "@turf/turf"

let bboxPoly,
  svg,
  path = d3.geoPath()

let width = 150,
  height = width

export const drawMap = data => {
  if (!data) {
    return
  }

  svg = d3
    .select("#inset")
    .html("")
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("class", "map")

  const projection = d3.geoMercator()

  path = d3
    .geoPath()
    .projection(projection.fitExtent([[5, 10], [140, 140]], data))

  svg
    .append("g")
    .attr("class", "states")
    .selectAll("path")
    .data(data.features)
    .enter()
    .append("path")
    .attr("d", path)
    .style("fill", "#f1f1f1")
    .style("stroke", "#474747")
    .style("stroke-width", 0.5)
    .style("opacity", 0.8)
}

export const drawOnSvg = bboxPoly => {
  let ab, shader
  let bbox = [0, 0, 10, 10]

  if (!d3.select(".area-box").nodes() || d3.select(".area-box").nodes().length === 0) {
    ab = svg.append("g").attr("class", "area-box")
    shader = svg
      .append("g")
      .classed("shader", true)
      .append("polygon")
  } else {
    d3.select(".area-box").html("")
    ab = d3.select(".area-box")
    shader = d3.select(".shader polygon")
  }

  ab.selectAll("path")
    .data([bboxPoly])
    .enter()
    .append("path")
    .classed("rect", true)
    .attr("d", path)
    .style("fill", "#777")
    .style("stroke", "#474747")
    .style("stroke-width", 1)
    .style("opacity", 0.8)

  d3.select(".rect").attr("d", () => {
    return d3
      .select(".rect")
      .attr("d")
      .split(",-")[0]
  })

  let myPoints = [
    "150,0",
    `${d3
      .select(".rect")
      .node()
      .getBBox()["x"] +
      d3
        .select(".rect")
        .node()
        .getBBox()["width"]},${
      d3
        .select(".rect")
        .node()
        .getBBox()["y"]
    }`,
    `${d3
      .select(".rect")
      .node()
      .getBBox()["x"] +
      d3
        .select(".rect")
        .node()
        .getBBox()["width"]},${d3
      .select(".rect")
      .node()
      .getBBox()["y"] +
      d3
        .select(".rect")
        .node()
        .getBBox()["height"]}`,
    "150,150",
  ]

  shader
    .attr("points", myPoints.join(" "))
    .attr("fill", "rgba(46,46,46,1)")
    .attr("opacity", 0.8)
}
