import React, { useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import * as turf from "@turf/turf"
import { callDayMap } from "./parseData"
import MiniMap from "./miniMap"
import usePrevious from "../lib/usePrevious"

const MapboxGLMap = ({ data, mapLocation, setMapLocation }) => {
  // It's not worth putting all the state in one object, too many race conditions
  const [map, setMap] = useState(null)
  const [points, setPoints] = useState(null)
  const [lineCoords, setLineCoords] = useState(null)
  const [bboxPoly, setBboxPoly] = useState(null)
  const [bounds, setBounds] = useState(null)

  const mapContainer = useRef(null)
  const prevLocation = usePrevious(mapLocation)
  const maxZoom = 11

  useEffect(() => {
    if (!data) {return}
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_TOKEN
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/outdoors-v10?optimize=true", // stylesheet location
        center: (data[0] && data[0].node.data.LONGITUDE)
          ? [data[0].node.data.LONGITUDE, data[0].node.data.LATITUDE]
          : [-118.6432, 38.4689],
        zoom: 9,
        maxZoom: maxZoom,
        pitch: 60,
      })

      map.on("load", () => {
        setMap(map)
        map.resize()
      })

      map.on("click", function(e) {
        var features = map.queryRenderedFeatures(e.point)
        if (!features[0]) {
          return
        }
        const place = features[0]["properties"]["place"]
        const id = features[0]["properties"]["id"]
        let coords = features[0]["geometry"]["coordinates"]
        if (place === undefined) {
        } else {
          setMapLocation({ id: id, longitude: coords[0], latitude: coords[1] })
        }
      })
    }

    if (!map) initializeMap({ setMap, mapContainer })
  }, [map, data])

  useEffect(() => {
    if (!map || !data || data.length === 0) {
      return
    }

    callDayMap({
      map,
      data,
      setPoints,
      setLineCoords,
      setBboxPoly,
      setBounds,
    })
  }, [map, data])

  useEffect(() => {
    const long = parseFloat(mapLocation.longitude)
    const lat = parseFloat(mapLocation.latitude)
    let bearing = 0

    if (!map || !long || !lat || mapLocation.id === prevLocation.id) {
      return
    }

    if (prevLocation.longitude && prevLocation.latitude) {
      let point1 = turf.point([
        parseFloat(prevLocation.longitude),
        parseFloat(prevLocation.latitude),
      ])
      let point2 = turf.point([long, lat])

      bearing = turf.bearing(point1, point2)
    }

    map.flyTo({
      center: [long, lat],
      zoom: maxZoom,
      bearing,
    })
  }, [map, mapLocation.id])

  return (
    <>
      <div id="map-div">
        <div
          id="map"
          className="map-container"
          ref={el => (mapContainer.current = el)}
        ></div>
      </div>
      <MiniMap
        data={data}
        mapLocation={mapLocation}
        points={points}
        lineCoords={lineCoords}
        bboxPoly={bboxPoly}
        bounds={bounds}
      />
    </>
  )
}

export default MapboxGLMap
