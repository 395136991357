import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/map"
import ContentPanel from "../components/contentPanel"
import parseCoords from "../lib/parseCoords"

const IndexPage = ({ data }) => {
  const [mapLocation, setMapLocation] = React.useState({
    id: 0,
    latitude: 0,
    longitude: 0,
  })
  const [currentDay, setCurrentDay] = React.useState(7)

  let edges = data[`day${currentDay}`].edges
  edges = parseCoords(edges)

  React.useEffect(() => {
    edges = data[`day${currentDay}`].edges
  }, [currentDay])

  return (
    <Layout>
      <ContentPanel
        data={edges}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
      />
      <Map
        data={edges}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    day0: allAirtable(
      filter: {
        table: { eq: "Day 0" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day1: allAirtable(
      filter: {
        table: { eq: "Day 1" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day2: allAirtable(
      filter: {
        table: { eq: "Day 2" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day3: allAirtable(
      filter: {
        table: { eq: "Day 3" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day4: allAirtable(
      filter: {
        table: { eq: "Day 4" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day5: allAirtable(
      filter: {
        table: { eq: "Day 5" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day6: allAirtable(
      filter: {
        table: { eq: "Day 6" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    day7: allAirtable(
      filter: {
        table: { eq: "Day 7" }
        data: { PUBLISHED: { eq: true } }
      }
      sort: { fields: [data___ID], order: ASC }
    ) {
      edges {
        node {
          data {
            ID
            TITLE
            DESCRIPTION
            DEG_LONGITUDE
            DEG_LATITUDE
            LONGITUDE
            LATITUDE
            TYPE_OF_POINT_ON_THE_MAP
          }
          icon {
            url
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media1 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media2 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          media3 {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
